import React, { useState } from 'react';
import {Container,Grid, Link,Box } from '@mui/material';
import Controls from '../components/controls/Controls';
import { Form } from '../components/useForm';
import { makeStyles } from '@material-ui/core';
import asterisk from './../assests/icons/asterisk.png'
import { AppBar, Toolbar, Typography } from '@mui/material';
import anvayyalogo from './../assests/icons/anvayyalogo.png'
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
import * as Rest from  './../services/restapi';
 
const styles = {
    root: {
      height: 40, // Set the desired height here
      borderRadius: 5, // Set the desired border radius here
      '& .MuiOutlinedInput-root': {
        borderRadius: 5, // Apply the border radius to the input element inside the TextField
        height:40,
      },
    },
 
  };
 
const useStyles = makeStyles((theme) => ({
  root: {
    height: 40, // Set the desired height here
    borderRadius: 5, // Set the desired border radius here
    '& .MuiOutlinedInput-root': {
      borderRadius: 5, // Apply the border radius to the input element inside the TextField
      height:40,
    },
  },
  container: {
    paddingTop: theme.spacing(4),
    marginTop:'6rem',
  },
  title: {
    textAlign:'center',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(2),
    padding:'0px',
    fontSize: '12px',
    marginLeft:'5px',
    color:'#666666', // Customize the message color here
  },
  customIcon: {
    width: 8, // Set the desired width of your custom icon
    height: 8, // Set the desired height of your custom icon
    verticalAlign: 'super',
    fontSize: '0.7em',
    marginRight: theme.spacing(17), 
  },
  submitButton: {
    marginTop: theme.spacing(2),
    backgroundColor:'#f79123',
    '&:hover': {
      backgroundColor: '#f79123', // Set hover color to the same as default color
    },
    borderRadius:'10px',
    height:'35px',
    marginLeft:'2rem',
    maxWidth:'13rem',
  },
  formBox: {
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(7),
    marginTop:'3rem',
    borderRadius: theme.spacing(2),
    backgroundColor:'#FCFCFC',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Customize the box-shadow here
  },
}));
 
const logoStyle = {
  width: '40px',
  height: '40px',
};
const AvLoginForm = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [UserID, setUsername] = useState('');
  const [Password, setPassword] = useState('');
  const[showPassword,setShowPassword]=useState(false);
  const [loginError, setLoginError] = useState(false);
  const [token, setToken] = useState(null);
  const employeeName = localStorage.getItem("EmployeeName");

  React.useEffect(() => {
    if (employeeName || token) {
      navigate('/allopportunity');
    }
  }, [employeeName, token, navigate]);
 
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
 
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
 
 
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!UserID || !Password) {
      setLoginError(true); 
      return;
    }
    try {
      const requestData = {
        UserID,
        Password,
        Type: "employee", // Assign the Type value here (replace with the actual value)
      };
 
      // const response = await fetch(apiUrl, {
        const response = await fetch(Rest.login,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-fiftyaccess-token": token,
        },
        body: JSON.stringify(requestData),
      });
 
      const data = await response.json();
      const fiftyAccessToken = data["x-fiftyaccess-token"];
      setToken(fiftyAccessToken);
      const employeeID = data?.Data?.EmployeeID;
      const employeeName = data?.Data?.Name;
      if (employeeID) {
        localStorage.setItem("EmployeeID", employeeID);
        localStorage.setItem("EmployeeName", employeeName);
        localStorage.setItem("X-fiftyaccess-Token", fiftyAccessToken);
      }
      if (response.status === 200) {
        if (!data.error) {
          // Navigate to the prospect page
          navigate("/allopportunity");
        } else {
          setLoginError(true);
        }
      } else {
        setLoginError(true);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.data) {
        alert(err.response.data.data);
      } else {
        alert(err.message);
      }
    }
  };
 
 
  return (
<>
<Header setToken={token} />
<AppBar position="absolute" sx={{
       height:'3.5rem',
       background: 'linear-gradient(to right, white, #FFC46A 40%, #F79123)',  // Replace with your desired gradient colors
    }}>
<Toolbar>
<img src={anvayyalogo} alt="My Logo" style={logoStyle} />
<Typography variant="h6" sx={{ flexGrow: 1 }} >
        Insurance
</Typography>
</Toolbar>
</AppBar>
<Container maxWidth="xs" className={classes.container}>
 
      <Form onSubmit={handleSubmit} >
<Grid container direction="column" spacing={2}>
<Grid item xs={12} className={classes.title}>
        Sign-In
</Grid>
<Box className={classes.formBox}>
<Grid item xs={12}>
<span className={classes.message} >Enter the User-ID you used to register with us</span>
</Grid>
<Grid item xs={12}>
<Controls.Input
        className={classes.textField}
        label={
<span>
            User-ID
<img src={asterisk} alt="Star Logo" className={classes.customIcon} />
</span>
        }
          variant="outlined"
          fullWidth
          margin="normal"
          value={UserID}
          onChange={(e) => setUsername(e.target.value)}
        />
</Grid>
<Grid item xs={12}>
<TextField
      className={classes.root}
             label={
<span>
                Password
<img src={asterisk} alt="Star Logo" className={classes.customIcon} />
</span>
            }
            variant="outlined"
            margin="normal"
            type={showPassword ? 'text' : 'password'}
            value={Password}
            onChange={handlePasswordChange}
            style={{backgroundColor:'rgb(234, 237, 240,1)'}}
            InputLabelProps={{
              style: { 
                fontSize: '12px'}, // Set the desired label font size here
            }}
            InputProps={{
              endAdornment: (
<InputAdornment position="end">
<IconButton  onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small"/>}
</IconButton>
</InputAdornment>
              ),
            }}
          />
</Grid>
<Grid item xs={12}>
         {/*  <Link href="#" style={{fontSize:'14px',marginLeft:"9rem",color:'#666666'}}>
            Forgot Password?
</Link> */}
</Grid>
<Grid item xs={12}>
<Controls.Button 
          className={classes.submitButton}
          text="Sign In"
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth
          /> 
          {loginError && (
<Typography color="error" variant="body2">
            Incorrect user-ID or password. Please try again.
</Typography>
        )}
</Grid>
</Box>
</Grid>
</Form>
 
   </Container>
</>
  );
};
 
export default AvLoginForm;