import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Container, Typography, ThemeProvider, createTheme, Grid } from '@mui/material';
import * as Rest from  './../services/restapi';
import Controls from '../components/controls/Controls';
import moment from 'moment';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';

import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';

const dashboards = [
  {value:"customerDetailsDashboard",label:'Customer Details'},
  {value:"salessummary",label:'Sales Summary'},
]


function Dashboard() {
    const [data, setData] = useState(null);
    const [saleData, setSaleData] = useState(null);
    const [startDate, setStartDate] = useState([]);
    const [endDate, setEndDate] = useState([]);
    const [selectedDashboard, setSelectedDashboard] = useState([]);
    const token = localStorage.getItem("X-fiftyaccess-Token");
    const categories = data?.prospectdetails ? Object.keys(data.prospectdetails) : [];
    const prospectseries = data?.prospectdetails ? [{ data: categories.map((key) => data.prospectdetails[key]) }] : [];
    const isCustomerMount = useRef(true);
// Now, you can use categories and prospectseries in your chart rendering


    const theme = createTheme({
        typography: {
          fontFamily: 'Arial, sans-serif', // Change the font to one that supports the characters
        },
      });
      const statusColors = {
        status1: '#FF5733',
        status2: '#33FF57',
        status3: '#5733FF',
        
       
      };
      
      
      
const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },
  {
    label: 'All time',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
      ];
    },
    appearance: 'default'
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
      ];
    },
    appearance: 'default'
  }
];

const onDateRangePicker = (date) => {
  if (Array.isArray(date) && date.length === 2) {
      const stateDate = moment(date[0]).format("DD-MM-YYYY");
      const endDate = moment(date[1]).format("DD-MM-YYYY");
      setStartDate(stateDate);
      setEndDate(endDate);
  } else {
   
  }
};

    useEffect(() => {
      if(isCustomerMount.current){
      const selectedValues = {
        startdate: Array.isArray(startDate) && startDate?.length === 0 ? "" : startDate,
        enddate: Array.isArray(endDate) && endDate?.length === 0 ? "" : endDate,
      };
      // Make an API request to fetch data
      axios.post(Rest.customerDetailsDashboard,selectedValues,{
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          setData([]);
          console.error('Error fetching data: ', error);
        });
        isCustomerMount.current=false;
      }
    }, [startDate, endDate]);

    /* useEffect(() => {
      const selectedValues = {
        startdate: Array.isArray(startDate) && startDate?.length === 0 ? "" : startDate,
        enddate: Array.isArray(endDate) && endDate?.length === 0 ? "" : endDate,
      };
      // Make an API request to fetch data
      axios
        .post(Rest.salessummary, selectedValues, {
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        })
        .then((response) => {
          setSaleData(response?.data?.data?.[0]);
        })
        .catch((error) => {
          setSaleData([]);
          console.error('Error fetching data: ', error);
        });
    }, [startDate, endDate]); // Add startDate and endDate as dependencies */
    
    
console.log("sale---->",saleData)
    /* useEffect(() => {
        // Make an API request to fetch data
        axios.post(Rest.salesDetailsDashboard,{
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        })
          .then((response) => {
            setSaleData(response?.data?.data);
          })
          .catch((error) => {
            console.error('Error fetching data: ', error);
          });
      }, []); */

    const handleDashboardChange = (event) => {
      setSelectedDashboard(event.target.value);
    };
    // const barColor = 'purple';

    return (
<div style={{ marginTop: '4.5rem', padding:'5px',marginLeft:'1rem' }}>
     <Grid container style={{ display: 'flex', flexDirection: 'row'}}>
      <h5>Dashboard</h5>
{/*       <Grid item xs={2} style={{ marginLeft: '18rem' }}>
        <Controls.Dropdown 
        label="Dashboard"
        options={dashboards}
        value={selectedDashboard}
        onChange={handleDashboardChange}
        />

        </Grid> */}
    <Grid item xs={6} style={{ marginLeft: '18rem', display: 'flex', flexDirection: 'row' }}> 
    <DateRangePicker
    format='dd-MM-yyyy'
    ranges={predefinedRanges}
    placeholder="Select Date"
    style={{ width: 220 }}
    onChange={onDateRangePicker}
  />
  </Grid> 
  
      <div style={{marginTop:'3rem'}}>
        <div style={{display:'flex',flexDirection:'row'}}>
          <div>
            <Typography variant="h6">Lead Status</Typography>
            {data && data.oportunityDetails && Object.values(data.oportunityDetails).some(value => value !== 0) ? (
  <PieChart
    series={[
      {
        data: Object.entries(data.oportunityDetails).map(([key, value]) => ({
          id: key,
          value: value,
          label: key,
        })),
      },
    ]}
    width={700}
    height={200}
    colors={(data) => statusColors[data.label]}
  />
):(
  <div style={{marginTop:'5rem',marginLeft:'10rem',width:'400px'}}>No data available</div>
)}
          </div>
          <div
              style={{
                borderRight: '1px solid #e0e0e0',
                height: '300px', // Adjust the height to your preference
                margin: '0 10px', // Add some margin to separate the charts
              }}
            ></div>
          <div>
            <Typography variant="h6">Policy Count</Typography>
            {data && data.policyCount && Object.keys(data.policyCount).length > 0 ?  (
            <PieChart
              series={[
                {
                  data: Object.entries(data.policyCount).map(([key, value]) => ({
                    id: key,
                    value: value,
                    label: key,
                  })),
                },
              ]}
              width={700}
              height={200}
            />
           ) : (
              <div style={{marginTop:'5rem',marginLeft:'10rem'}}>No data available</div>
            )}
          </div>
          </div>
     
          <hr style={{ width: '87rem', border: '1px solid #e0e0e0' }} />
        <div>
      <Typography variant="h6">Prospect Details</Typography> 
      {data && data?.prospectdetails && Object.values(data.prospectdetails).some(value => value !== 0) ? (
       <BarChart
        xAxis={[{ scaleType: 'band', data: categories }]}
        series={prospectseries}
        width={650}
        height={450}
      />
      ):(
        <div style={{marginTop:'5rem',marginLeft:'10rem'}}>No data available</div>
      )
      }
    </div>
  </div>
      </Grid>
      <Grid>
      {selectedDashboard?.includes('salessummary') && (
       <div>
       <p>Provider: {saleData.provider}</p>
       <p>Total Premium: {saleData.totalpremium}</p>
 
       <div>
         {saleData?.products?.map((product, index) => (
           <div key={index} style={{ border: '1px solid gray', borderRadius: '5px', padding: '10px', margin: '10px' }}>
             <p>Product Name: {product.productname}</p>
             <p>Count: {product.count}</p>
             <p>Total Premium: {product.totalpremium}</p>
           </div>
         ))}
       </div>
       <PieChart
         series={[
           {
             data: saleData.products?.map((product) => ({
               id: product.productname,
               value: product.totalpremium,
               label: product.productname,
             })),
           },
         ]}
         width={400}
         height={200}
       />
     </div>
      )}
      </Grid>
   </div>
    );
  }
  
  export default Dashboard;
  