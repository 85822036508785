import React,{useEffect, useRef, useState} from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Controls from '../components/controls/Controls';
import { Box, Checkbox, Grid, Snackbar, TextField } from '@mui/material';
import { Typography, makeStyles } from "@material-ui/core";
import axios from 'axios';
import { format } from 'date-fns';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import * as Rest from  './../services/restapi';
import LoadingSpinner from '../components/controls/spinner';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import dayjs from 'dayjs';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-cellRight': {
      textAlign: 'right',
    },
  },
}));

const formatCurrency = (value) => {
  return `₹${value?.toLocaleString('en-IN')}`;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      display:'row',
      backgroundColor:'White'
    },
  },
};

const status = [
    {value:'Paid',label:'Paid'},
    {value:'Invoiced',label:'Invoiced'},
    {value:'Written Off',label:'Written Off'},
];

function Invoice() {
  const classes = useStyles();
    const [invoiceValues,setInvoiceValues] = useState("")
    const [statusSelections, setStatusSelections] = useState({});
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
    const [paymentDate,setPaymentDate] = useState(dayjs)
    const rowHeight = 35;
    const navigate = useNavigate();
    const token = localStorage.getItem("X-fiftyaccess-Token");
    const [loading, setLoading] = useState(true); // Initialize loading state
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState([]);
    const [isCalculateSuccessSnackbarOpen, setIsCalculateSuccessSnackbarOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const theme = useTheme();
    const isProviderMount = useRef(true);
    const isInvoiceAllMount = useRef(true);

    function getStyles(providers, selectedProvider, theme) {
      return {
        fontWeight:
        selectedProvider.indexOf(providers) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
      };
    }
      const handleChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedProvider(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };
    const predefinedRanges = [
      {
        label: 'Today',
        value: [new Date(), new Date()],
        placement: 'left'
      },
      {
        label: 'Yesterday',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: 'left'
      },
      {
        label: 'This week',
        value: [startOfWeek(new Date()), endOfWeek(new Date())],
        placement: 'left'
      },
      {
        label: 'Last 7 days',
        value: [subDays(new Date(), 6), new Date()],
        placement: 'left'
      },
      {
        label: 'Last 30 days',
        value: [subDays(new Date(), 29), new Date()],
        placement: 'left'
      },
      {
        label: 'This month',
        value: [startOfMonth(new Date()), new Date()],
        placement: 'left'
      },
      {
        label: 'Last month',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left'
      },
      {
        label: 'This year',
        value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
        placement: 'left'
      },
      {
        label: 'Last year',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
        placement: 'left'
      },
      {
        label: 'All time',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
        placement: 'left'
      },
      {
        label: 'Last week',
        closeOverlay: false,
        value: value => {
          const [start = new Date()] = value || [];
          return [
            addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
            addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
          ];
        },
        appearance: 'default'
      },
      {
        label: 'Next week',
        closeOverlay: false,
        value: value => {
          const [start = new Date()] = value || [];
          return [
            addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
            addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
          ];
        },
        appearance: 'default'
      }
    ];

    const handleDateRangeChange = (date) => {
      if (Array.isArray(date) && date.length === 2) {
        const stateDate = moment(date[0])?.format("DD-MM-YYYY");
        const endDate = moment(date[1])?.format("DD-MM-YYYY");
        setStartDate(stateDate);
        setEndDate(endDate);
      } else {
   
      }
      // fetchDataWithinDateRange(start, end);  
    };

    const fetchAllInvoice = async (startDate, endDate, selectedProvider, token, setInvoiceValues) => {
      try {
        const response = await axios.get(Rest.viewallinvoice, {
          params: {
            startdate: startDate,
            enddate: endDate,
            provider: selectedProvider
          },
          headers: {
            'Content-Type': 'application/json',
            'X-fiftyaccess-Token': token
          }
        });
    
        if (response?.data?.code === 'S001') {
          const invoicesWithIds = response?.data?.data?.map((invoice, index) => ({
            ...invoice,
            id: index + 1, // You can use a different logic to generate IDs
          }));
          setInvoiceValues(invoicesWithIds);
        } else {
          alert(response?.data?.msg || response?.data?.data);
        }
      } catch (error) {
        alert(error.response?.data?.data?.msg);
        setInvoiceValues([]);
        console.error('Error fetching data:', error);
      }
    };

    useEffect(() => {
      if(isInvoiceAllMount.current){
      fetchAllInvoice(startDate, endDate, selectedProvider, token, setInvoiceValues);
      isInvoiceAllMount.current=false;
    }
    }, [startDate, endDate, selectedProvider, token]);

    useEffect(() => {
      if(isProviderMount.current){
      axios
        .get(Rest.ViewAllProvider, {
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        })
        .then((response) => {
          const providerData = response?.data?.Data || [];
   
          // Extract provider names from the response data
          const providerNames = providerData.map((provider) => provider.provider);
   
          // Filter the unique provider names
          const uniqueProviders = [...new Set(providerNames)];
   
          setProviders(uniqueProviders);
        })
        .catch((error) => {
          console.error('Error fetching providers:', error);
        });
        isProviderMount.current=false
      }
    }, []);

  const handleDropdownChange = (id, newValue) => {
    setStatusSelections((prevSelections) => ({
      ...prevSelections,
      [id]: newValue,
    }));
    setInvoiceValues((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, status: newValue } : row
      )
    );
  };

  const handleDueDateChange = (id, newValue) => {
    setInvoiceValues((prevRows) =>
      prevRows.map((row) =>
        row.id === id ? { ...row, duedate: new Date(newValue).getTime() / 1000 } : row
      )
    );
  };

  const handlePaymentReceivedDateChange = (id, newValue) => {
    setPaymentDate(newValue)
    setInvoiceValues((prevRows) =>
      prevRows.map((row) =>
        row.id === id
          ? { ...row, paymentrecieveddate: new Date(newValue).getTime() / 1000 }
          : row
      )
    );
  };
  
  const renderPaymentReceivedDateCell = (params) => {
    const dateValue = params.value;
    const formattedDate = dateValue ? format(new Date(dateValue * 1000), 'yyyy-MM-dd') : null;
  
    return (
      <input
        className='custom-header'
        type="date"
        value={formattedDate || ''}
        onChange={(e) => handlePaymentReceivedDateChange(params.row.id, e.target.value)}
      />
    );
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = {};

    if (isChecked) {
      invoiceValues.forEach((invoice) => {
        updatedSelectedRows[invoice.invoiceid] = true;
      });
    }

    setSelectedRows(updatedSelectedRows);
  };

  const handleRowSelect = (invoiceid) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [invoiceid]: !prevSelectedRows[invoiceid],
    }));
  };
  const handleInvoiceRowClick = (params) => {
    setSelectedInvoiceId(params.row.invoiceid);
  };
  
  const handleUpdateInvoice = () => {
    if (selectedInvoiceId === null) {
      console.error('Please select a invoice.');
      return;
    }
     // Check if paymentDate is not present
  if (!paymentDate) {
    alert('Please enter payment received date.');
    return;
  }

    const selectedInvoice = invoiceValues.find((invoice) => invoice.invoiceid === selectedInvoiceId);
    const duedate = moment(selectedInvoice.duedate*1000)?.format("DD-MM-YYYY")
    /* const paymentrecieveddate =moment(selectedInvoice.paymentrecieveddate*1000).format("DD-MM-YYYY") */
    if (!selectedInvoice) {
      console.error('Selected invoice not found.');
      return;
    }
    let paidDate = paymentDate ? moment(paymentDate)?.format('DD-MM-YYYY') : null;

    // If the formatted date is "Invalid date", set paidDate to null
    if (paidDate === 'Invalid date') {
      paidDate = null;
    }    
    const updateData = {
      saleid:selectedInvoice.saleid,
      invoiceid:selectedInvoice.invoiceid,
      externalinvoiceid:selectedInvoice.externalinvoiceid,
      duedate:duedate,
      paymentrecieveddate:paidDate,
      status:selectedInvoice.status
    };
   axios
      .put(Rest.updateinvoice, updateData,{
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
      .then((response) => {
        console.log("response",response)
        if(response?.data?.code === 'S001'){
        setIsSuccessSnackbarOpen(true); // Open success snackbar
        // Redirect after a short delay (optional)
        setTimeout(() => {
          navigate('/invoice');
          fetchAllInvoice(startDate, endDate, selectedProvider, token, setInvoiceValues);
        }, 2000); // Redirect after 2 seconds
      } else{
        alert(response?.data?.msg || response?.data?.data)
      }
      })
      .catch((err) => {
        alert("Error: " + err.message);
      });
  };

  const handleExternalInvoiceIdChange = (id, newValue) => {
    const updatedRows = invoiceValues.map((row) =>
      row.id === id ? { ...row, externalinvoiceid: newValue } : row
    );
    setInvoiceValues(updatedRows);
  };

    const columns = [
      {
        field: 'checkbox',
        width:'65',
        headerCheckboxSelection: true,
        headerClassName: 'custom-header',
        checkboxSelection: true,
        headerCheckboxProps: {
          onChange: handleSelectAll,
        },
        renderHeader: (params) => (
          <div>
            <input
              type="checkbox"
              checked={Object.keys(selectedRows).length === invoiceValues.length}
              onChange={handleSelectAll}
            />
          </div>
        ),
        renderCell: (params) => (
          <div>
            <input
              type="checkbox"
              checked={selectedRows[params.row.invoiceid] || false}
              onChange={() => handleRowSelect(params.row.invoiceid)}
            />
          </div>
        ),
      },
        { field: 'invoiceid', headerName: 'Invoice ID', width: 150,headerClassName: 'custom-header', },
        { field: 'saleid', headerName: 'Sale ID', width: 150,headerClassName: 'custom-header', },
        { field: 'invoicedate', headerName: 'Invoice Date', width: 150 ,headerClassName: 'custom-header',
        valueFormatter: (params) =>
        format(new Date(params.value * 1000), 'dd-MM-yyyy')},
        { field: 'provider', headerName: 'Provider Name', width: 140 ,headerClassName: 'custom-header',},
        { field: 'invoiceamount', headerName: 'Invoice Amount', width: 125,headerClassName: 'custom-header',
        align: 'right',
    valueFormatter: (params) => formatCurrency(params.value),
      },
      {
        field: 'externalinvoiceid',
        headerName: 'External Invoice ID',
        width: 175,
        headerClassName: 'custom-header',
        renderCell: (params) => (
          <TextField
            type="text"
            value={params.value}
            onChange={(e) => handleExternalInvoiceIdChange(params.row.id, e.target.value)}
          />
        ),
      },
        { 
        field: 'duedate', 
        headerClassName: 'custom-header',
        headerName: 'Due Date', 
        width: 150 ,
       renderCell: (params) => (
        <input
          type="date"
          className='custom-header'
          value={format(new Date(params.value * 1000), 'yyyy-MM-dd')}
          onChange={(e) => handleDueDateChange(params.row.id, e.target.value)}
        />
        ),
        },
        { field: 'paymentrecieveddate', headerName: 'Payment Recieved Date', width: 190,headerClassName: 'custom-header', 
        renderCell: renderPaymentReceivedDateCell
      },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: 'custom-header',
            width: 190,
            renderCell: (params) => (
                <Controls.Dropdown
                  options={status.map((option)=>({
                    value:option.value,
                    label:option.label
                  }))}
                  value={params.value}
                  onChange={(e) => handleDropdownChange(params.row.id, e.target.value)}
                />
              ),
            },
            { field: 'Action', headerName: 'Action',  headerClassName: 'custom-header',
            renderCell: (params) => (
            <Controls.Button
              text="Update"
              onClick={handleUpdateInvoice}
            />
          ),
        },
        {  headerClassName: 'custom-header'}
      ];

      const handleSnackbarClose = () => {
        setIsSuccessSnackbarOpen(false);
      };
      useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }, []);

  return (
    <div style={{ marginTop: '1.5rem', marginLeft: '0.5rem', minHeight: '100vh' }}>
      {loading ? (
        <LoadingSpinner />
      ) : (
    <div style={{ height: 400, width: '100%',marginTop:'4rem',padding:'10px',marginLeft:'2rem' }}>
        <Grid container style={{ display: 'flex', flexDirection: 'row', gap: '10rem' }}>
        <h5>Invoice</h5>
 
                <Grid item xs={2}>
                  {/* <Controls.Input label="Search" onChange='' /> */}
 
                  <Stack direction="row" spacing={4} alignItems="flex-start">
                    <DateRangePicker
                    format='dd-MM-yyyy'
                      ranges={predefinedRanges}
                      placeholder="Select Date"
                      style={{ width: 210, marginTop: '0.6rem', marginLeft: '3rem' }}
                      onChange={handleDateRangeChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={2} style={{ marginRight: '8rem' }}>
                <div>
  <FormControl sx={{ m: 1, width: 200 }}>
    <InputLabel sx={{ fontSize: '12px' }} id="demo-multiple-checkbox-label">
      Filter by Provider
    </InputLabel>
    <Select
      labelId="demo-multiple-checkbox-label"
      id="demo-multiple-checkbox"
      multiple
      value={selectedProvider}
      onChange={handleChange}
      input={<OutlinedInput label="Filter by Provider" />}
      renderValue={(selected) => selected.join(', ')}
      MenuProps={MenuProps}
      style={{ maxHeight: '35px', marginTop: '4px', color: 'white' ,   backgroundColor: 'white'}}
      sx={{ backgroundColor: 'white' }}
    >
      {providers.map((name) => (
        <MenuItem
          key={name}
          value={name}
          style={{
            ...getStyles(name, selectedProvider, theme),
            display: 'block',
            width: 'fit-content',
            fontSize: '12px', // Set the desired font size for dropdown options
          }}
        >
          <Checkbox
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 12, // Adjust the font-size to decrease the checkbox size
              },
            }}
            checked={selectedProvider.indexOf(name) > -1}
          />
          {name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</div>      
</Grid>            
</Grid>
        <Snackbar
        sx={{marginLeft:'30rem'}}
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
        onClose={handleSnackbarClose}
        message="Updated successfully"
      />
      {invoiceValues.length > 0 ? (
        <>
        <Box
      sx={{
        marginTop:'2rem',
        height: 380,
        width: '100%',
        '& .custom-header': {
          backgroundColor: 'rgb(234, 237, 240,1)',
        },
      }}
    >
        <DataGrid
         rowHeight={rowHeight}
        rows={invoiceValues}
        columns={columns}
        onRowClick={handleInvoiceRowClick}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        getRowId={(row) => row.invoiceid}
      />
      </Box>
      </>
       ):(
        <>
        <Box
      sx={{
        marginTop:'2rem',
        width: '100%',
        '& .custom-header': {
          backgroundColor: 'rgb(234, 237, 240,1)',
        },
      }}
    >
         <DataGrid
         rowHeight={rowHeight}
        rows={invoiceValues}
        columns={columns}
        onRowClick={handleInvoiceRowClick}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        getRowId={(row) => row.invoiceid}
      />
      </Box>
        <Typography style={{marginLeft:'33rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
        No policy details available.
      </Typography>
        </>
      )
    }
    </div>
    )}
    </div>
  );
}

export default Invoice;
