import React, { useEffect, useRef, useState } from 'react';
import { Typography, Container, Grid} from '@material-ui/core';
import Controls from '../components/controls/Controls';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from '../components/useForm';
import axios from 'axios';
import { IconButton, Snackbar,Button,DialogActions,DialogContentText,DialogContent,DialogTitle,Dialog, Fab } from '@mui/material';
import * as Rest from  './../services/restapi';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete'; 
import LoadingSpinner from '../components/controls/spinner'; // Import your LoadingSpinner component

const addons =[
  {value:'Yes',label:'Yes'},
  {value:'No',label:'No'}
]

const ncb =[
  {value:0,label:0},
  {value:20,label:20},
  {value:25,label:25},
  {value:30,label:30},
  {value:35,label:35},
  {value:50,label:50},
]


function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

const NewOpportunity = () => {
  const [ opportunityValues, setOpportunityValues] = useState(
    {
    provider:'',
    product:'',
    subproduct:'',
    prospectid:'',
    beneficiaryid:'',
    referralid:'',
    interestedpolicytype:'',
    probability:'50',
    opportunitystatus:'Open',
    businesstype:'New',
    odpremium:0,
    thirdpartypremium:0,
    addonpremium:0,
    tax:0,
    netpremium:0,
    grosspremium:0,
  }
  );
  
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [attachmentToDeleteIndex, setAttachmentToDeleteIndex] = useState(null);

  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const location =  useLocation();
  const customer = location.state?.opportunityValues?.[0];
  const prospectid = customer?.prospectid;
  const customerid = customer?.customerid;
  const beneficiaryid = customer?.beneficiaryid;
  const referralid = customer?.referralid;
  const [selectedBeneficiary, setSelectedBeneficiary] = useState('');
  const [providerOptions, setProviderOptions] = useState([]);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const [opportunityStatusOptions, setOpportunityStatusOptions] = useState([]);
  const [businessTypeOptions, setBusinessTypeOptions] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [attachments, setAttachments] = useState([{ file: null, fileName: '' }]);
  const [loading, setLoading] = useState(false); 
  const isInitialMount = useRef(true);
  const isStatusMount = useRef(true);
  const isProviderMount = useRef(true);
  const isProductMount = useRef(true);

  console.log("customerid",customerid,"prospectid",prospectid,"beneficiaryid",beneficiaryid,"referralid",referralid,customer)
  const handleFileChange = (event, index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments[index].file = event.target.files[0];
    setAttachments(updatedAttachments);
  };

  const handleFileNameChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const currentAttachment = updatedAttachments[index];
  
    const currentFile = currentAttachment.file;
    const newFileName = event.target.value;
  
    // Remove the existing extension if present and append the new extension
    const updatedFileName = removeFileExtension(newFileName) + getFileExtension(currentFile.name);
  
    const updatedFile = new File([currentFile], updatedFileName, { type: currentFile.type });
    // Update the attachment object with the modified file name and file
    updatedAttachments[index] = {
      ...updatedAttachments[index],
      file: updatedFile,
      fileName: event.target.value,
    };
  
    setAttachments(updatedAttachments);
  };
  
  // Helper function to get file extension
  const getFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(lastDotIndex) : '';
  };

  const removeFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(0, lastDotIndex) : fileName;
  };
 
  const addAttachment = () => {
    setAttachments([...attachments, { file: null, fileName: '' }]);
  };
 
  const removeAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(updatedAttachments);
  };
  const handleDeleteAttachment = (index) => {
    setAttachmentToDeleteIndex(index);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete operation
    const updatedAttachments = attachments.filter((_, i) => i !== attachmentToDeleteIndex);
    setAttachments(updatedAttachments);

    setIsDeleteDialogOpen(false);
    setAttachmentToDeleteIndex(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setAttachmentToDeleteIndex(null);
  };
  const handleCancel = () => {
    // Show the loading spinner
    setLoading(true);
  
    // Delay the navigation by a few seconds to show the spinner
    setTimeout(() => {
      setLoading(false); // Hide the loading spinner
      navigate(-1); // This will go back one step in the navigation history
    }, 200); // Adjust the duration as needed (e.g., 2000 milliseconds or 2 seconds)
  };
  
  const validateForm = () => {
    const errors = {
      beneficiary: selectedBeneficiary ? '' : 'Beneficiary is required',
      provider: opportunityValues.provider ? '' : 'Provider is required',
      product: opportunityValues.product ? '' : 'Product is required',
      subproduct: opportunityValues.subproduct ? '' : 'Sub Product is required',
      interestedpolicytype: opportunityValues.interestedpolicytype ? '' : 'Policy Type is required',
      opportunitystatus: opportunityValues.opportunitystatus ? '' : 'Opportunity Status is required',
      businesstype: opportunityValues.businesstype ? '' : 'Business Type is required',
    };
    // Set the error messages for each dropdown field
    setFormErrors(errors);
    

    // Check if there are any errors
    return Object.values(errors).every((error) => error === '');
  };
  const handleSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };

  const [premiumErrors, setPremiumErrors] = useState({});
  const subpremiums = products.find(
    (product) => product.productid === opportunityValues.product
  )?.subpremiums ?? [];

  const productdetails = products.find(
    (product) => product.productid === opportunityValues.product
  )?.attributefields;


  const subProductOptions = products.find(
    (product) => product.productid === opportunityValues.product
  )?.subProductDetails;

  const policyOption = subProductOptions
  ?.find((product) => product.productid === opportunityValues.product)
  ?.subProductDetails?.policyTypeDetails.map((policyType) => ({
      value: policyType,
      label: policyType, 
  })) || products.find((product) => product.productid === opportunityValues.product)
  ?.subProductDetails?.[0]?.policyTypeDetails.map((policyType)=>({
    value:policyType,
    label:policyType
  })) ||[]

    const beneficiaryOptions = customer?.beneficiarydetails?.map((beneficiary) => ({
      value: `${capitalizeFirstLetter(beneficiary.firstname)} ${capitalizeFirstLetter(beneficiary.lastname)}`,
      label: `${capitalizeFirstLetter(beneficiary.firstname)} ${capitalizeFirstLetter(beneficiary.lastname)}`,
    })) || [];
  
    const handleBeneficiaryChange = (event) => {
      setSelectedBeneficiary(event.target.value);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        beneficiary: '',
      }));
    };
   
    useEffect(() => {
      const newGrossPremium = opportunityValues.netpremium + parseFloat(opportunityValues.tax);
      setOpportunityValues((prevValues) => ({
        ...prevValues,
        grosspremium: newGrossPremium,
      }));
    }, [opportunityValues.netpremium, opportunityValues.tax]);
    
    const handleOpportunityChange = (field, value) => {
      // Regular expression to match numbers with optional decimal points
      const validNumberRegex = /^-?\d*\.?\d*$/;
    
      if (
        field === 'addonpremium' ||
        field === 'odpremium' ||
        field === 'grosspremium' ||
        field === 'netpremium' ||
        field === 'tax' ||
        field==='basepremium'||
        field === 'thirdpartypremium'
      ) {
        // Check if the input value is a valid number or decimal
        if (validNumberRegex.test(value) || value === '') {
          setOpportunityValues((prevValues) => ({
            ...prevValues,
            prospectid: prospectid,
            beneficiaryid: beneficiaryid,
            referralid: referralid,
            [field]: value,
          }));
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [field]: '',
          }));
        } else {
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            [field]: 'Enter only numbers', // Set the error message
          }));
        }
      } else {
        setOpportunityValues((prevValues) => ({
          ...prevValues,
          prospectid: prospectid,
          beneficiaryid: beneficiaryid,
          referralid: referralid,
          [field]: value,
        }));
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [field]: '',
        }));
      }
    };
 
    const handleProviderChange = (event) => {
      const provider = event;
      setOpportunityValues((opportunityValues)=> ({...opportunityValues,provider:provider}));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        provider: '',
      }));
    };
    const handleNetpremiumChange = () => {
      let sum = 0;
      const myObject = subpremiums[0];
    
      for (const key in myObject) {
         const value = myObject[key];           
            sum += parseInt(value);
      }
      console.log('sum',sum)
        setOpportunityValues((prevValues) => ({
          ...prevValues,
          netpremium: sum,
        }));
        };    
    
        function calculateNetpremium(subpremiums) {
          if (subpremiums && subpremiums[0]) {
            let sum = 0;
            const myObject = subpremiums[0];
        
            for (const key in myObject) {
              const value = myObject[key];
              console.log('*****',value,key,!isNaN(value))
              if (value) {
                sum += parseFloat(value);
              }
              
            }
            setOpportunityValues((prevValues) => ({
              ...prevValues,
              netpremium: sum,
            }));
            return sum;
          }
        
          return 0; // Return a default value if subpremiums are not available
        }
        const handlePremiumChange = (fieldName, value) => {
          // Create a copy of the opportunityValues object to avoid mutating the state directly
          const updatedOpportunityValues = { ...opportunityValues,subpremiums };
          
          // Update the value based on the field name
          updatedOpportunityValues.subpremiums[0][fieldName] = value;
          updatedOpportunityValues.subpremiums = updatedOpportunityValues.subpremiums[0]
          // Update the state with the updated opportunityValues
          setOpportunityValues(updatedOpportunityValues);
          setFormErrors((prevErrors) => ({
            ...prevErrors,
            value: '',
          }));
        };

    const handleAttributeChange = (fieldName, value) => {
      const updatedOpportunityValues = { ...opportunityValues,productdetails };
      updatedOpportunityValues.productdetails[0][fieldName] = value;
      if (fieldName === "Claim Status") {
        // If "Yes" is selected, set "Eligible NCB %" to 0
        if (value === "Yes") {
          updatedOpportunityValues.productdetails[0]["Eligible NCB %"] = 0;
        } else {
          // If "No" is selected, set "Eligible NCB %" to null (or any default value)
          updatedOpportunityValues.productdetails[0]["Eligible NCB %"] = null;
        }
      }
      updatedOpportunityValues.productdetails = updatedOpportunityValues.productdetails[0]
      setOpportunityValues(updatedOpportunityValues);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        value: '',
      }));
    };
      
  useEffect(() => {
    if(isInitialMount.current){
    async function fetchBusinessTypes() {
      try {
        const response = await axios.get(Rest.viewallbusinesstypes,
          {
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          }
          );
        if (response.status === 200) {
        
          const businessTypes = response.data.businesstypes;
          // Create an array of options with the required format
          const options = businessTypes.map((type) => ({
            value: type,
            label: type,
          }));
          // Set the business type options state
          setBusinessTypeOptions(options);
        }
      } catch (error) {
        console.error('Error fetching business types:', error);
      }
    }

    fetchBusinessTypes();
    isInitialMount.current=false;
  }
  }, []);

  
  useEffect(() => {
    if(isStatusMount.current){
    async function fetchOpportunityStatusOptions() {
      try {
        const response = await axios.get(Rest.opportunitystatusapi,
          {
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          }
          );
        if (response.status === 200) {
          setOpportunityStatusOptions(response?.data?.opportunitystatus);
        }
      } catch (error) {
        console.error('Error fetching Lead status options:', error);
      }
    }
    fetchOpportunityStatusOptions();
    isStatusMount.current=false;
  }
  }, []);

    useEffect(() => {
      if(isProviderMount.current){
      fetch(Rest.ViewAllProvider,
        {
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        }
        )
        .then(response => response.json())
        .then(data => {
          // Update the options state with the fetched data
          setProviderOptions(data?.Data); // Assuming `setDropdownOptions` is a state setter function
        })
        .catch(error => {
          // Handle error
        });
        isProviderMount.current=false;
      }
    }, []);

    // Fetch product data on component mount
  useEffect(() => {
    if(isProductMount.current){
    async function fetchProducts() {
      try {
        const response = await axios.get(Rest.viewallproduct,
          {
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          }
          );
        const productData = response?.data?.data
        setProducts(productData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
    fetchProducts();
    isProductMount.current=false;
  }
  }, []);
  useEffect(() => {
    // Fetch product details when selected product changes
    async function fetchSelectedProductData(productid) {
      if (productid) {
        try {
          const response = await axios
          .get(Rest.viewproduct,{
            params:{
              productid:productid
            },
            headers: {
              "X-fiftyaccess-Token": token
            },
          });
          setSelectedProduct(response?.data);
        } catch (error) {
          console.error('Error fetching selected product:', error);
        }
      }
    }

    fetchSelectedProductData(opportunityValues.product);
  }, [opportunityValues.product]);
const isValidNumber = (value) => {
  const validNumberRegex = /^-?\d*\.?\d*(%)?$/;
  return validNumberRegex.test(value);
};
  const handleSubmit = async (event) => {
    event.preventDefault();
    /* const isValid = validateForm();

    if (!isValid) {
      // If the form is not valid, don't submit and display error messages
      return;
    } */
    if(customerid!=null && (prospectid === null || prospectid === undefined || prospectid === "")){
        try {
            setLoading(true);
           const product = selectedProduct?.data?.product
     
           const formDataObject = new FormData();
             attachments.forEach((attachment, index) => {
              formDataObject.append(attachment.file ? removeFileExtension(attachment.file.name) : '', attachment.file);
             });
             formDataObject.append('prospectid',prospectid)
             formDataObject.append('customerid',customerid)
             formDataObject.append('beneficiaryid',beneficiaryid)
             formDataObject.append('referralid',referralid)
             formDataObject.append('provider',opportunityValues.provider)
             formDataObject.append('product',product)
             formDataObject.append('subproduct',opportunityValues.subproduct)
             formDataObject.append('interestedpolicytype',opportunityValues.interestedpolicytype)
             formDataObject.append('probability',opportunityValues.probability)
             formDataObject.append('businesstype',opportunityValues.businesstype)
             formDataObject.append('opportunitystatus',opportunityValues.opportunitystatus)
             formDataObject.append('tax',opportunityValues.tax)
             formDataObject.append('netpremium',opportunityValues.netpremium)
             formDataObject.append('grosspremium',opportunityValues.grosspremium)
             formDataObject.append('subpremiums',JSON.stringify(subpremiums[0]))
         formDataObject.append('productdetails', JSON.stringify(productdetails[0]))
           const response = await fetch(Rest.customercreateopportunity, {
             method: 'POST',
             headers: {
               // 'Content-Type': 'application/json',
               "X-fiftyaccess-Token": token,
             },
             body:formDataObject
           });
       const data = await response.json();
     console.log("data",data)
       if (data.code === "S001") {
         setIsSuccessSnackbarOpen(true);
     
         setTimeout(() => {
           navigate('/allopportunity');
         }, 2000);
       }  else {
         alert(data?.msg || data?.data?.data)
       }
     } catch (err) {
       alert("Error: " + err.message);
       // Handle errors
     } finally {
       // Hide the loading spinner
       setLoading(false);
     }
    } else if(prospectid!==null && (customerid === null || customerid === undefined || customerid ==="")){
        try {
            setLoading(true);
           const product = selectedProduct?.data?.product
        
           const formDataObject = new FormData();
             attachments.forEach((attachment, index) => {
               formDataObject.append(attachment.fileName, attachment.file);
             });
             formDataObject.append('prospectid',prospectid)
             formDataObject.append('customerid',customerid)
             formDataObject.append('beneficiaryid',beneficiaryid)
             formDataObject.append('referralid',referralid)
             formDataObject.append('provider',opportunityValues.provider)
             formDataObject.append('product',product)
             formDataObject.append('subproduct',opportunityValues.subproduct)
             formDataObject.append('interestedpolicytype',opportunityValues.interestedpolicytype)
             formDataObject.append('probability',opportunityValues.probability)
             formDataObject.append('businesstype',opportunityValues.businesstype)
             formDataObject.append('opportunitystatus',opportunityValues.opportunitystatus)
             formDataObject.append('tax',opportunityValues.tax)
             formDataObject.append('netpremium',opportunityValues.netpremium)
             formDataObject.append('grosspremium',opportunityValues.grosspremium)
             formDataObject.append('subpremiums',JSON.stringify(subpremiums[0]))
         formDataObject.append('productdetails', JSON.stringify(productdetails[0]))
           const response = await fetch(Rest.createopportunity, {
             method: 'POST',
             headers: {
               // 'Content-Type': 'application/json',
               "X-fiftyaccess-Token": token,
             },
             body:formDataObject
           });
        const data = await response.json();
        console.log("data",data)
        if (data.code === "S001") {
         setIsSuccessSnackbarOpen(true);
        
         setTimeout(() => {
           navigate('/allopportunity');
         }, 2000);
        }  else {
         alert(data?.msg || data?.data?.data)
        }
        } catch (err) {
        alert("Error: " + err.message);
        // Handle errors
        } finally {
          setLoading(true); // Set loading to false after the API request is completed (success or failure)
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
    }
};


  return (
    <div style={{ marginTop: '1.5rem', marginLeft: '0.5rem', minHeight: '100vh' }}>
    {/* {loading ? (
      <LoadingSpinner />
    ) : ( */}
    <div>
      <div style={{display:'flex',flexDirection:'row',gap:'25rem',marginTop:'5rem'}}>
      <Typography style={{marginLeft:'1rem'}} variant="h6" align="center" gutterBottom>
        Lead
      </Typography>
        <Typography style={{marginLeft:'32rem'}} variant="body6" align="center" gutterBottom>
        {customer?.opportunityid}
        <p>{capitalizeFirstLetter(customer?.firstname)} {capitalizeFirstLetter(customer?.lastname)}</p>
      </Typography>
     
      </div> 
      <Container>
        <Form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={4} style={{marginTop:'0.5rem'}}>
            <Controls.Dropdown 
              label='Beneficiary Name'
              options={beneficiaryOptions}
              defaultValue={selectedBeneficiary} 
              onChange={handleBeneficiaryChange}
            />
              {formErrors.beneficiary && (
          <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.beneficiary}</div>
          )}
            <Controls.Dropdown
              label='Provider'
              options={providerOptions?.map(option => ({ 
                value: option.provider, 
                label: option.provider 
              }))}
              value={opportunityValues.provider}
              onChange={(e)=>handleProviderChange( e.target.value)}
            />
              {formErrors.provider && (
              <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.provider}</div>
            )}
             <Controls.Dropdown
                options={businessTypeOptions}
                label="Business Type"
                value={opportunityValues.businesstype} // Make sure this is the correct value field
                onChange={(e) => handleOpportunityChange('businesstype', e.target.value)}
              />
                  {/* Display the error message inside the dropdown */}
              {formErrors.businesstype && (
                <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.businesstype}</div>
              )}   
               
                    
            </Grid>
            <Grid item xs={4}>
            <Controls.Dropdown
              options={products?.map((product) => ({
              value: product.productid,
              label: product.product,
              }))}
              label="Product"
              value={opportunityValues.product}
              onChange={(e) => handleOpportunityChange('product', e.target.value)}
            />
               {formErrors.product && (
             <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.product}</div>
              )}
               { subProductOptions?.length >1 && (<>
            <Controls.Dropdown
              options={subProductOptions?.map((subProduct) => ({
              value: subProduct.subproduct,
              label: subProduct.subproduct
              })) || []}
              label="Sub Product"
              value={opportunityValues.subproduct}
              onChange={(e) =>
              handleOpportunityChange('subproduct', e.target.value)
              }
              />
              {formErrors.subproduct && (
                <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.subproduct}</div>
                )}
                </>
              )}

            <Controls.Dropdown
              options={policyOption}
              label="Policy Type"
              value={opportunityValues.interestedpolicytype} 
              onChange={(e) => handleOpportunityChange('interestedpolicytype', e.target.value)}  
              />
                {formErrors.interestedpolicytype && (
              <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.interestedpolicytype}</div>
              )}
             
            
            </Grid>
            <Grid item xs={4} > 
              <Controls.Dropdown
              label="Lead Status"
              options={opportunityStatusOptions.map((option) => ({ value: option, label: option }))}
              value={opportunityValues.opportunitystatus}
              onChange={(e) => handleOpportunityChange('opportunitystatus', e.target.value)}
            /> 
             {formErrors.opportunitystatus && (
             <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.opportunitystatus}</div>
            )}  
            <Controls.Input 
              label='Probability'
              value={opportunityValues.probability}
              onChange={(e) => handleOpportunityChange('probability', e.target.value)}
            />
            </Grid>
            </Grid>
            <div>Premium Details</div>
            {/* <Grid container>
            {subpremiums[0] && Object.entries(subpremiums[0]).map(([fieldName, fieldValue]) => (
           <Grid item xs={4} key={fieldName}>
            <Controls.Input
            type="text"
            label={fieldName}
            value={fieldValue}
            onChange={(e) => {handlePremiumChange(fieldName, e.target.value);calculateNetpremium(subpremiums)}}
          />
          </Grid>
            ))} */}
             <Grid container>
  {subpremiums[0] && Object.entries(subpremiums[0]).map(([fieldName, fieldValue]) => (
    <Grid item xs={4} key={fieldName}>
      <Controls.Input
        type="text"
        label={fieldName}
        value={fieldValue}
        onChange={(e) => {  const value = e.target.value;
          if (isValidNumber(value)) {
            handlePremiumChange(fieldName, value);
            setPremiumErrors((prevErrors) => ({
              ...prevErrors,
              [fieldName]: '',
            }));
          } else {
            setPremiumErrors((prevErrors) => ({
              ...prevErrors,
              [fieldName]: 'Enter only numbers',
            }));
          }
          calculateNetpremium(subpremiums)
        }}
      />
      {premiumErrors[fieldName] && (
        <div style={{ color: 'red', fontSize: '10px' }}>{premiumErrors[fieldName]}</div>
      )}
          </Grid>
            ))}
        
                  <Grid item xs={4}>
                  <Controls.Input
                      label="Tax Amount"
                      value={opportunityValues.tax}
                      onChange={(e) =>
                        handleOpportunityChange("tax", e.target.value)
                      }
                  /> 
                   </Grid>
                  <Grid item xs={4}>
                  <Controls.Input
                      label="Net Premium"
                      value={opportunityValues.netpremium}
                      onChange={handleNetpremiumChange}
                      readOnly={true}
                  /> 
                   </Grid>
                  <Grid item xs={4}>
                  <Controls.Input
                      label="Gross Premium"
                      value={opportunityValues.grosspremium}
                      onChange={(e) =>
                        handleOpportunityChange("grosspremium", e.target.value)
                      }
                      readOnly={true}
                  />        
              </Grid>
            </Grid>
            {subProductOptions && (

            <> 
            <div>Product Details</div>
            <Grid container >
          {productdetails[0] && Object.entries(productdetails[0]).map(([fieldName, fieldValue]) => (
           <Grid item xs={4} key={fieldName}>
           {fieldName === "Addons Opted (Y/N)" ? (
            <Controls.Dropdown
              options={addons}
              label={fieldName}
              value={fieldValue}
              onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
            />
            
          
          ):fieldName === "Claim Status" ? (
            <Controls.Dropdown
              options={addons}
              label={fieldName}
              value={fieldValue}
              onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
            />
          
          ) :fieldName === "Eligible NCB %" ? (
            <Controls.Dropdown
              options={ncb}
              label={fieldName}
              value={fieldValue}
              onChange={(e) =>  handleAttributeChange(fieldName,e.target.value)}>
              </Controls.Dropdown>
            
           ) : ( 
                      
            <Controls.Input
            type="text"
            label={fieldName}
            value={fieldValue}
            onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
          />
    )}
          </Grid>
      ))}
          </Grid>
            </>
            )}
         
            <IconButton onClick={addAttachment}>
        <h6>Attachements</h6> <AddIcon />
      </IconButton>
      {attachments.map((attachment, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <label htmlFor={`uploadfile-${index}`}>
                        <input
                          type="file"
                          id={`uploadfile-${index}`}
                          style={{ display: "none" }}
                          onChange={(event) => handleFileChange(event, index)}
                        />
                  <Fab
                    style={{ backgroundColor: '#914572', color: '#ffff', marginTop: '4px', marginLeft: '10px', fontSize: '8px', padding: '10px',fontsize:'10px',textAlign:'center',borderRadius:'20px' }}
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    Upload File
                  </Fab>
                </label>
                <Grid item xs={3}>
                <Controls.Input
                  label="File Name"
                  value={attachment.file ? removeFileExtension(attachment.file.name) : ''}
                  onChange={(event) => handleFileNameChange(event, index)}
                  />
                      </Grid>
                      <IconButton onClick={() => handleDeleteAttachment(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
       <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Attachment?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this attachment? 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    

          <div style={{marginTop:'2rem', marginLeft:"65.5rem"}}>
          <Controls.Button text="Save" type="submit"/>
          {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
          <Controls.Button text="Cancel" onClick={handleCancel} />
          </div>
        </Form>
        <Snackbar
        sx={{marginLeft:'30rem'}}
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
        onClose={handleSnackbarClose}
        message="Data successfully added"

      />
    </Container>

    </div>
     {/* )
    } */}
    </div>
   
  );
};

export default NewOpportunity;