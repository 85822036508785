
import React, { useState } from 'react';
import Controls from '../components/controls/Controls';
import { Grid, Checkbox, Typography, Radio, Card } from '@material-ui/core';
import { CardContent, FormControl, RadioGroup, FormControlLabel, TextField, Input } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from '../components/useForm';
import axios from 'axios';
import * as Rest from '../services/restapi';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import calendar from './../assests/icons/calendar.png';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import dayjs from 'dayjs';
import { Link, } from '@mui/material';
import LoadingSpinner from '../components/controls/spinner';
import { Snackbar } from '@mui/material';


const today = dayjs();
const Commission = () => {
  const location = useLocation();

  //console.log("@@@@@@@!!!", location?.state)


  const [opportunityValues, setOpportunityValues] = useState(
    {


      provider: '',
      product: '',
      subproduct: '',
      policytype: '',
      netpremium: '',
      netpremiumSelected: false,
      effectivestartdate: '',
      effectiveenddate: '',
      type: '',
      basedon: '',
      ruleid: '',

    }
  );
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  var [tableData, setTableData] = useState([]);

  const [products, setProducts] = useState([]);

  const [selectedProduct, setSelectedProduct] = useState('All');

  const [providerOptions, setProviderOptions] = useState([]);

  const [value, setValue] = useState('');

  const [minPercentage, setMinPercentage] = useState('');

  const [maxPercentage, setMaxPercentage] = useState('');

  const [selectedOption, setSelectedOption] = useState('');

  const isProviderMount = useRef(true);

  const isProductMount = useRef(true);

  const isLocMount = useRef(true);

  const token = localStorage.getItem("X-fiftyaccess-Token");

  const [formErrors, setFormErrors] = useState({});

  const [isNetPremiumSelected, setIsNetPremiumSelected] = useState(true);

  const [checkboxValues, setCheckboxValues] = useState({});

  const [flatCardOpen, setFlatCardOpen] = useState(false);

  const [discountCardOpen, setDiscountCardOpen] = useState(false);

  const [slabCardOpen, setSlabCardOpen] = useState(false);

  const [selectedPolicystartdate, setSelectedPolicystartdate] = useState(null);


  const [selectedPolicyenddate, setSelectedPolicyenddate] = useState(null);

  const [subPremiumsContent, setSubPremiumsContent] = useState({});

  const [locations, setLocations] = useState({});

  const [selectedState, setSelectedState] = useState('');

  const [selectedCity, setSelectedCity] = useState('');

  const states = Object.keys(locations);

  const cities = locations[selectedState] || [];

  const [numTabs, setNumTabs] = useState(1);

  const [lowerLimit, setLowerLimit] = useState("0");

  const [upperLimit, setUpperLimit] = useState("");

  const [editIndex, setEditIndex] = useState(0);

  const [areaeditIndex, setareaeditIndex] = useState(0);

  const [disableUpperLimit, setDisableUpperLimit] = useState(false);

  const [editDiscountIndex, setEditDiscountIndex] = useState(0);

  const [editFlatIndex, setEditFlatIndex] = useState(0);

  const [premiumRadio, setPremiumRadio] = useState();
console.log("!@@####",premiumRadio)
  const navigate = useNavigate();

  const [selectedFlat, setSelectedFlat] = useState(null);
  const [selectedAreaId, setSelectedAreaId] = useState(null);



  const handleEdit = (opportunityValues, event) => {
    event.preventDefault();
    const ruleid = opportunityValues.ruleid;
    navigate(`/newversioncommission/${ruleid}`, { state: { ruleid } });
  };

  const handleNameClick = (areaid) => {
    setSelectedAreaId(areaid);
    navigate(`/area/${areaid}`, { state: { commissiondetails: opportunityValues,Type:'update' } });
  };
  
  const handleSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };


 
  const [disableDiscountUpperLimit, setDisableDiscountUpperLimit] = useState(false);
 

  console.log('000000000000')
  const [flatTabOpen, setFlatTabOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [flatTabs, setFlatTabs] = useState([
    {
      id: 1,
      heading: 'Flat ',
      note: '',
      selectedOption: 'netPremium',
      netPremiumContent: '',
      subPremiumsContent: {},
      flatRange: { lowerLimit: '0', upperLimit: '' },
    },
  ]);
  const [discountTabs, setDiscountTabs] = useState([
    {
      id: 1,
      note: '',
      selectedOption: 'netPremium',
      netPremiumContent: '',
      subPremiumsContent: {},
      discountRange: { lowerLimit: '0', upperLimit: '' },

    },]);

  const moveDiscountToNextTab = () => {
    const newIndex = (editDiscountIndex + 1) % areas.length;
    setEditDiscountIndex(newIndex);
  };

  const moveDiscountToPreviousTab = () => {
    const newIndex = editDiscountIndex === 0 ? areas.length - 1 : editDiscountIndex - 1;
    setEditDiscountIndex(newIndex);
  }

  const [areas, setAreas] = useState();
  const [selectedArea, setSelectedArea] = useState(null);
  const moveToNextTab = () => {
    const newIndex = (editIndex + 1) % areas.length;
    setEditIndex(newIndex);
    setLowerLimit("");
    setUpperLimit("");
  };

  const moveToPreviousTab = () => {
    const newIndex = editIndex === 0 ? areas.length - 1 : editIndex - 1;
    setEditIndex(newIndex);
    setLowerLimit("");
    setUpperLimit("");
  };

  const [areaTabs, setAreaTabs] = useState([]);
  const uniquename = tableData?.map((option) => option?.name);
  const areaid = tableData?.map((option) => option?.areaid);
  const addAreaTab = () => {
    const uniqueAreaNames = Array.from(new Set(tableData?.map((option) => option?.area)));
    setAreaTabs(uniqueAreaNames);
    setEditIndex(uniqueAreaNames.length || 0);
  };
  useEffect(() => {
    if (tableData) {
      addAreaTab();
    }
  }, [tableData]); // Ensure the effect runs whenever tableData changes

  const handleSubmit = async (event) => {
    //console.log("111111111111", opportunityValues)

    event.preventDefault();
    try {
      setLoading(true);

      let valueForSending;
      if (opportunityValues.type === "slab") {
        valueForSending = "slabdetails";
      } else if (opportunityValues.type === "discount") {
        valueForSending = "discountdetails";
      } else {
        valueForSending = "flatdetails";
      }



      let filteredAreas;
      if (opportunityValues.type === "slab") {

        filteredAreas = opportunityValues.slab

      } else if (opportunityValues.type === "discount") {
        filteredAreas = opportunityValues.discount

      } else {
        filteredAreas = opportunityValues.flat

      }
      if (value === "flat") {
        // Flatten the array of flatdetails into a single array
        filteredAreas = filteredAreas.reduce((accumulator, area) => {
          return accumulator.concat(area.flatdetails);
        }, []);
      }

      // dayjs.unix(opportunityValues?.effectivestartdate || 0


      // eslint-disable-next-line no-undef
      console.log("WWWWWWWW", opportunityValues.effectiveenddate)

      if (!moment(opportunityValues.effectiveenddate, "DD-MM-YYYY").isValid()) {
        console.log("not in DD-MM-YYY")


      }
      const startdate = selectedPolicystartdate ? selectedPolicystartdate : moment(opportunityValues?.effectivestartdate * 1000)?.format('DD-MM-YYYY');
      const enddate = selectedPolicyenddate ? selectedPolicyenddate : moment(opportunityValues?.effectiveenddate * 1000)?.format('DD-MM-YYYY');
      const productname = selectedProduct?.data?.product
      const requestData = {
        ruleid: opportunityValues.ruleid,
        basedon: opportunityValues.basedon,
        type: opportunityValues.type,
        provider: opportunityValues.provider,
        product: opportunityValues.product,
        subproduct: opportunityValues.subproduct,
        policytype: opportunityValues.policytype,
        effectivestartdate: startdate,
        effectiveenddate: enddate,
        [valueForSending]: filteredAreas,
      };
      console.log("@@@@@@", productname)



      const response = await fetch(Rest.updateslabcommission
        , {
          method: 'put',
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token,
          },
          body: JSON.stringify(requestData),
        });
      const data = await response.json();
      //console.log("data", data);
      if (data.code === "S001") {
        setIsSuccessSnackbarOpen(true);
        // setIsSuccessSnackbarOpen(true);
        setTimeout(() => {
          navigate('/viewcommission');
        }, 2000);
      } else {
        alert(data?.msg || data?.data?.data);
      }
    } catch (err) {
      alert("Error: " + err.message);
    } finally {
      setLoading(true); // Set loading to false after the API request is completed (success or failure)
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  }
  useEffect(() => {
    if (opportunityValues?.provider) {
      axios.get(Rest.viewarea, {
        params: {
          provider: opportunityValues?.provider
        },
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      }
      )
        .then(response => {
          if (response.data.code === "S001") {
            setTableData(response?.data?.data)
            addAreaTab(response?.data?.data);
            setAreas(response?.data?.data)
          } else {
            // alert(response?.msg || response?.data?.data);
          }
        })
        .catch(err => {
          alert("Error: " + err.message);
        });
    }
  }, [opportunityValues?.provider]);

  useEffect(() => {
    if (isLocMount.current) {
      axios.get(Rest.locations, {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
        .then((response) => {
          setLocations(response?.data?.locations);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
        });
      isLocMount.current = false;
    }
  }, []);

  const handleStartDateChange = (date) => {
    const startdate = moment(date)?.format('DD-MM-YYYY');
    setSelectedPolicystartdate(startdate)
    setOpportunityValues((opportunityValues) => ({ ...opportunityValues, startdate: startdate }));
  }


  const handleEndDateChange = (date) => {
    const enddate = moment(date)?.format('DD-MM-YYYY')
    setSelectedPolicyenddate(enddate)
    setOpportunityValues((opportunityValues) => ({ ...opportunityValues, enddate: enddate }));
  }




  // const handleStartDateChange = (date) => {
  //   // Format the selected date as 'DD-MM-YYYY'
  //   const formattedStartDate = dayjs(date, { strict: false }).format('DD-MM-YYYY');

  //   // Update the state with the formatted date as a string
  //   setOpportunityValues((opportunityValues) => ({
  //     ...opportunityValues,
  //     effectivestartdate: formattedStartDate,
  //   }));
  // };

  // const handleEndDateChange = (date) => {
  //   // Format the selected date as 'DD-MM-YYYY'
  //   const formattedEndDate = dayjs(date, { strict: false }).format('DD-MM-YYYY');

  //   // Update the state with the formatted date as a string
  //   setOpportunityValues((opportunityValues) => ({
  //     ...opportunityValues,
  //     effectiveenddate: formattedEndDate,
  //   }));
  // };


  // const handleStartDateChange = (date) => {
  //   console.log('New start date:', date);

  //   // Format the date as 'DD-MM-YYYY'
  //   const formattedStartDate = dayjs(date).format('DD-MM-YYYY');

  //   // Update the state with the formatted date as a string
  //   setOpportunityValues((opportunityValues) => ({
  //     ...opportunityValues,
  //     effectivestartdate: formattedStartDate,
  //   }));
  // };

  // const handleEndDateChange = (date) => {
  //   console.log('New end date:', date);

  //   // Format the date as 'DD-MM-YYYY'
  //   const formattedEndDate = dayjs(date).format('DD-MM-YYYY');

  //   // Update the state with the formatted date as a string
  //   setOpportunityValues((opportunityValues) => ({
  //     ...opportunityValues,
  //     effectiveenddate: formattedEndDate,
  //   }));
  // };






  const handleOpportunityChange = (field, value) => {
    // Regular expression to match numbers with optional decimal points
    const validNumberRegex = /^-?\d*\.?\d*$/;
    if (
      field === 'addonpremium' ||
      field === 'odpremium' ||
      field === 'grosspremium' ||
      field === 'netpremium' ||
      field === 'tax' ||
      field === 'basepremium' ||
      field === 'thirdpartypremium'

    ) {
      // Check if the input value is a valid number or decimal
      if (validNumberRegex.test(value) || value === '') {
        setOpportunityValues((prevValues) => ({
          ...prevValues,
          [field]: value,

        }));
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [field]: '',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,

          [field]: 'Enter only numbers', // Set the error message
        }));
      }
    } else {
      setOpportunityValues((prevValues) => ({
        ...prevValues,
        [field]: value,
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [field]: '',
      }));
    }
  };
  const handleButtonClick = (action) => {
    if (action === 'Cancel') {
      navigate('/viewcommission');
    }
  }

  useEffect(() => {
    if (isProductMount.current) {
      async function fetchProducts() {
        try {
          const response = await axios.get(Rest.viewallproduct,
            {
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            }
          );
          const productData = response?.data?.data
          setProducts(productData);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      }
      fetchProducts();
      isProductMount.current = false;
    }

  }, []);


  const isValidNumber = (value) => {
    const validNumberRegex = /^-?\d*\.?\d*(%)?$/;
    return validNumberRegex.test(value);
  };
  useEffect(() => {
    if (isProviderMount.current) {
      fetch(Rest.ViewAllProvider, {

        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      }
      ).then(response => response.json())
        .then(data => {
          // Update the options state with the fetched data
          setProviderOptions(data?.Data); // Assuming `setDropdownOptions` is a state setter function
        })
        .catch(error => {
          // Handle error
        });
      isProviderMount.current = false;
    }
  }, []);

  const [premiumErrors, setPremiumErrors] = useState({});
  const subpremiums = products.find(
    (product) => product.product === opportunityValues.product
  )?.subpremiums ?? [];
  const productdetails = products.find(
    (product) => product.product === opportunityValues.product
  )?.attributefields;
  const subProductOptions = products.find(
    (product) => product.product === opportunityValues.product
  )?.subProductDetails;
  const policyOption = subProductOptions
    ?.find((product) => product.product === opportunityValues.product)
    ?.subProductDetails?.policyTypeDetails?.map((policyType) => ({
      value: policyType,
      label: policyType,
    })) || products.find((product) => product.product === opportunityValues.product)
      ?.subProductDetails?.[0]?.policyTypeDetails?.map((policyType) => ({
        value: policyType,
        label: policyType
      })) || []
  const handleProviderChange = (event) => {
    const provider = event;
    setOpportunityValues((opportunityValues) => ({ ...opportunityValues, provider: provider }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      provider: '',
    }));
  };
  const handleNetpremiumChange = (e) => {
    const inputValue = e.target.value;
    // Check if the input is empty
    if (inputValue === '') {
      setOpportunityValues((prevValues) => ({
        ...prevValues,
        netpremium: '',
      }));
    } else {
      // Check if the input is a valid number
      if (!isNaN(inputValue)) {
        setOpportunityValues((prevValues) => ({
          ...prevValues,
          netpremium: parseInt(inputValue, 10),
        }));
      } else {
        // Handle invalid inpu
      }
    }
  };
  const addSlabTabs = (areaId, index) => {
    const updatedAreas = opportunityValues.slab.map((area) => {
      if (area.areaid === areaId) {
        const newSlabDetails = {
          maxvalue: 0,
          minvalue: 0,
          note: "",
          uptomax: "",
          netpremium: 0,
          subpremiums: {}
        };

        if (!area.slabDetails) {
          return { ...area, slabDetails: [newSlabDetails] };
        } else {
          return { ...area, slabDetails: [...area.slabDetails, newSlabDetails] };
        }
      }
      return area;
    });


    setOpportunityValues({ ...opportunityValues, slab: updatedAreas });
  };
  const removeSlabTab = (areaId) => {
    const updatedAreas = areas.map((area) => {
      if (area.areaid === areaId && area.slabDetails && area.slabDetails.length > 0) {
        const updatedSlabDetails = area.slabDetails.slice(0, -1); // Remove the last slab
        return { ...area, slabDetails: updatedSlabDetails };
      }
      return area;
    });
    setAreas(updatedAreas);
    //console.log("removeSlab", updatedAreas);
  };

  const addDiscountTabs = (areaId, index) => {
    const updatedAreas = opportunityValues.discount.map((area) => {
      if (area.areaid === areaId) {
        const newSlabDetails = {
          maxvalue: 0,
          minvalue: 0,
          note: "",
          uptomax: "",
          netpremium: 0,
          subpremiums: {}
        };

        if (!area.discountDetails) {
          return { ...area, discountDetails: [newSlabDetails] };
        } else {
          return { ...area, discountDetails: [...area.discountDetails, newSlabDetails] };
        }
      }
      return area;
    });
    setOpportunityValues({ ...opportunityValues, discount: updatedAreas });
  };
  const removeDiscountTab = (areaId) => {
    const updatedAreas = areas.map((area) => {
      if (area.areaid === areaId && area.discountDetails && area.discountDetails.length > 0) {
        const updatedSlabDetails = area.discountDetails.slice(0, -1); // Remove the last slab
        return { ...area, discountDetails: updatedSlabDetails };
      }
      return area;
    });
    setAreas(updatedAreas);

  };

  const addFlatTabs = (areaId, index) => {
    const updatedAreas = opportunityValues?.flat?.map((area) => {
      if (area.areaid === areaId) {
        const newFlatDetails = {
          upperLimit: '0',
          lowerLimit: '0',
          note: "",
          uptomax: "",
          netpremium: "",
          subpremiums: {}
        };
        if (!area.flatdetails) {
          return { ...area, flatdetails: [newFlatDetails] };
        } else {
          // const flatTabExists = area.flatdetails.some((flat) => {
          //   return /* Add your condition here */ true;
          // });
          // if (!flatTabExists) {
          //   return { ...area, flatdetails: [...area.flatdetails, newFlatDetails] };
          // };
          return { ...area, flatdetails: [...area.flatdetails, newFlatDetails] };
        }
      }
      return area;
    });
    setOpportunityValues({ ...opportunityValues, flat: updatedAreas });

  };

  useEffect(() => {
    if (location?.state?.ruleid) {   
console.log('4444444444',location?.state?.ruleid)
      const ruleid = location?.state?.ruleid;
      axios.get(Rest.viewSlabcommission, {
        params: {
          ruleid: ruleid
        },

        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token

        },

      })
        .then(response => {
          console.log(response?.data?.data, 'response?.data?.data---------------')
          setOpportunityValues(response?.data?.data);

        })
        .catch(error => {
          // Handle API errors
          console.error('API Error:', error);
        });

    }
  }, [])


  useEffect(() => {
    if (opportunityValues.type === 'discount') {

      // Open the discount card and close others
      setDiscountCardOpen(true);
      setFlatCardOpen(false);
      setSlabCardOpen(false);

    } else if (opportunityValues.type === 'flat') {

      // Open the flat card and close others
      setFlatCardOpen(true);
      setDiscountCardOpen(false);
      setSlabCardOpen(false);

    } else if (opportunityValues.type === 'slab') {

      // Open the slab card and close others

      setSlabCardOpen(true);
      setDiscountCardOpen(false);
      setFlatCardOpen(false);
    } else {
      setDiscountCardOpen(false);
      setFlatCardOpen(false);

      setSlabCardOpen(false);

    }

  })



  const handleRadioChange = (event) => {

    const selectedValue = event.target.value;

    setOpportunityValues({

      ...opportunityValues,

      type: selectedValue // Update 'type' based on the selected radio button value

    });

    if (selectedValue === 'discount') {

      // Open the discount card and close others

      setDiscountCardOpen(true);

      setFlatCardOpen(false);

      setSlabCardOpen(false);

    } else if (selectedValue === 'flat') {

      // Open the flat card and close others

      setFlatCardOpen(true);

      setDiscountCardOpen(false);

      setSlabCardOpen(false);

    } else if (selectedValue === 'slab') {

      // Open the slab card and close others

      setSlabCardOpen(true);

      setDiscountCardOpen(false);

      setFlatCardOpen(false);

    } else {
      setDiscountCardOpen(false);
      setFlatCardOpen(false);
      setSlabCardOpen(false);

    }

  };
  console.log(opportunityValues, "Finl Values")

  return (
    <>
      <Form>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5rem' }}>
          <Typography style={{ marginLeft: '1rem', marginTop: '10px' }} variant="h6" align="center" gutterBottom>
            Commission Rules
          </Typography>
          {/* <div style={{marginLeft:'55rem',marginTop: '10px'}}>
        <Controls.Button   text="New Commission Rule" type="Submit" onClick={() => { navigate(`/newversioncommission`, { state: { ruleId: opportunityValues.ruleid } }) }}
           >New commission</Controls.Button>
            </div> */}
<div style={{ marginLeft: '55rem', marginTop: '10px' }}>
  <Controls.Button
    text="New Commission Rule"
    type="Submit"
    onClick={(event) => handleEdit(opportunityValues , event)}
  >
    New commission
  </Controls.Button>
</div>

        </div>
        <Grid container>
          <Grid item xs={2} >
            <Controls.Dropdownall
              label='Provider'
              options={providerOptions?.map(option => ({

                value: option.provider,

                label: option.provider

              }))}

              value={opportunityValues.provider}

              onChange={(e) => handleProviderChange(e.target.value)}

            />
            {formErrors.provider && (
              <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.provider}</div>
            )}

          </Grid>
          <Grid item xs={2} >
            <Controls.Dropdownall
              options={products?.map((product) => ({
                value: product.product,
                label: product.product,
              }))}
              label="Product"
              value={opportunityValues.product}
              onChange={(e) => handleOpportunityChange('product', e.target.value)}
              style={{ marginBottom: '1rem' }}
            />
            {formErrors.product && (
              <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.product}</div>
            )}
          </Grid>
          {subProductOptions?.length > 1 && (
            <Grid item xs={2} >
              <>
                <Controls.Dropdownall
                  options={subProductOptions?.map((subProduct) => ({
                    value: subProduct.subproduct,
                    label: subProduct.subproduct
                  })) || []}
                  label="Sub Product"
                  value={opportunityValues.subproduct}
                  onChange={(e) =>
                    handleOpportunityChange('subproduct', e.target.value)

                  }
                />
                {formErrors.subproduct && (
                  <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.subproduct}</div>
                )}
              </>
            </Grid>
          )}
          <Grid item xs={2} >
            <Controls.Dropdownall
              options={policyOption}
              label="Policy Type"
              value={opportunityValues.policytype}
              onChange={(e) => handleOpportunityChange('policytype', e.target.value)}
            />
            {formErrors.interestedpolicytype && (

              <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.interestedpolicytype}</div>
            )}
          </Grid>
          <Grid item xs={2} style={{ marginLeft: '2px' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={[
                  'DatePicker',
                ]}
              >
                <DemoItem>
                  <DatePicker
                    label=" Effective Date"
                    value={dayjs.unix(opportunityValues?.effectivestartdate || 0)}
                    //  defaultValue={dayjs(opportunityValues.effectivestartdate*1000)}
                    // defaultValue={dayjs(opportunityValues.effectivestartdate, { unix: true })}
                    onChange={e => { handleStartDateChange(e.toDate()) }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: 'rgb(234, 237, 240,1)',
                      borderRadius: '5px',
                      '& .MuiInputLabel-root': {
                        fontSize: '12px',
                      },
                      '& .MuiSvgIcon-root': {
                        width: '24px',
                        height: '24px',
                        background: `url(${calendar})`,
                        backgroundSize: 'contain',
                        display: 'block',

                      },
                      '& input': {

                        width: '30',

                        height: '30',
                        padding: '12px',
                        fontSize: '12px',
                        alignContent: 'center',

                      },


                      '& .MuiInput-underline': {
                        '&:before': {
                          borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color
                        },
                        '&:hover:not(.Mui-disabled):before': {
                          borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color
                        },
                      },
                    }}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer
                components={[
                  'DatePicker',
                ]}
              >
                <DemoItem>
                  <DatePicker
                    label="End Date"
                    value={dayjs.unix(opportunityValues?.effectiveenddate || 0)} // Use the date from the server response or 0 as default
                    //  defaultValue={dayjs(opportunityValues.effectiveenddate*1000)}
                    // defaultValue={dayjs(opportunityValues.effectiveenddate, { unix: true })}
                    onChange={e => { handleEndDateChange(e.toDate()) }}
                    format="DD-MM-YYYY"
                    sx={{
                      backgroundColor: 'rgb(234, 237, 240,1)',
                      borderRadius: '5px',

                      '& .MuiInputLabel-root': {
                        fontSize: '12px',

                      },
                      '& .MuiSvgIcon-root': {
                        width: '24px',
                        height: '24px',
                        background: `url(${calendar})`,
                        backgroundSize: 'contain',
                        display: 'block',

                      },
                      '& input': {
                        width: '30',
                        height: '30',
                        padding: '12px',
                        fontSize: '12px',
                        alignContent: 'center',
                      },
                      '& .MuiInput-underline': {
                        '&:before': {
                          borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color
                        },
                        '&:hover:not(.Mui-disabled):before': {
                          borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color

                        },
                      },
                    }}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid style={{ marginTop: '1.5rem', cursor: 'pointer' }}>
            <Link onClick={() => { navigate("/area/", { state: { commissiondetails: opportunityValues } }) }}>Define Area</Link>
          </Grid>
          <Grid item xs={12}>
            <Radio
              value="flat"
              onChange={handleRadioChange}
              checked={opportunityValues.type === 'flat'}
            />
            Based on Flat
            <Radio
              value="discount"
              onChange={handleRadioChange}
              checked={opportunityValues.type === 'discount'}
            />
            Based on Discount
            <Radio
              value="slab"
              onChange={handleRadioChange}
              checked={opportunityValues.type === 'slab'}
            />
            Based on Slab
          </Grid>
        </Grid>
      </Form>
      {flatCardOpen && (

        <Card>
          <CardContent>
            <RadioGroup
              aria-label="premiumOptions"
              name="premiumOptions"
              value={premiumRadio || opportunityValues?.basedon}
              onChange={(e) => {
                setPremiumRadio(e.target.value)
              }}
              style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', rowGap: '10rem' }}
            >

              <FormControlLabel value="netpremium" control={<Radio />} label="Net Premium" />
              <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />
            </RadioGroup>
            <FormControl>
              <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>

                <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>

                  <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>

                    {areas

                      ?.filter(tab => opportunityValues?.flat?.some(slabItem => slabItem.areaid === tab.areaid))

                      .map((tab, index) => (

                        <li
                          key={index}
                          style={{
                            cursor: 'pointer',
                            padding: '10px',
                            borderRadius: '5px',
                            transition: 'background-color 0.3s ease',
                            marginRight: '5px',
                            minWidth: '120px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            borderBottom: editFlatIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color

                            borderLeft: '1px solid #ddd', // Added left border

                            borderRight: '1px solid #ddd', // Added right border

                            backgroundColor: tab.areaid === selectedArea ? '#f8f9fa' : '',

                            color: tab.areaid === selectedArea ? '#0056b3' : ''

                          }}

                          // onClick={() => setSelectedArea(tab.areaid)}

                          onClick={() => {

                            setSelectedArea(tab.areaid);

                            setFlatTabOpen(true); // Open the flat tab when an area tab is clicked

                          }}
                        >
                          <>
                            <span>{tab.area}</span>
                            <div>
                              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>

                              </div>
                              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                                {tableData
                                  .filter((option) => option.areaid === tab.areaid)
                                  .map((filteredOption, index) => (
                                    <div
                                      key={index}
                                      onClick={() => handleNameClick(filteredOption.areaid, filteredOption.provider)}
                                      style={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        color: selectedAreaId === filteredOption.areaid ? 'red' : 'inherit', // Change color as needed
                                        fontWeight: selectedAreaId === filteredOption.areaid ? 'bold' : 'normal', // Add this line for bold text if selected
                                      }}
                                    >
                                      {filteredOption.name}
                                    </div>
                                  ))}
                              </div>

                            </div>
                          </>
                        </li>
                      ))}
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        style={{
                          cursor: 'pointer',
                          padding: '8px 15px',
                          borderRadius: '5px',
                          marginRight: '5px',
                          transition: 'background-color 0.3s ease',
                          color: '#0056b3',
                          backgroundColor: '#f8f9fa',

                        }}
                        onClick={() => { navigate("/area/", { state: { commissiondetails: opportunityValues } }) }}

                      >
                        + Add Area
                      </span>
                    </span>
                  </ul>
                  <div style={{ padding: '20px', overflowY: 'auto' }}>
                    {areas?.map((tab, index) => (
                      <div key={tab.id} style={{ display: areaeditIndex === index ? 'block' : 'none' }}>
                      </div>
                    ))}
                    {flatCardOpen && (
                      <Card>
                        <CardContent>
                          <FormControl>
                            <h6>Area {selectedArea}</h6>
                            <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>

                              <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>
                                <li

                                  // key={index}
                                  style={{
                                    cursor: 'pointer',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    transition: 'background-color 0.3s ease',
                                    marginRight: '5px',
                                    minWidth: '120px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'center',
                                    borderBottom: '2px solid #0056b3', // Modified border color
                                    borderLeft: '1px solid #ddd', // Added left border
                                    borderRight: '1px solid #ddd', // Added right border

                                    backgroundColor: '#f8f9fa',

                                    color: '#0056b3',



                                  }}

                                  onClick={() => addFlatTabs(selectedArea)}

                                >
                                  <>
                                    <span>Flat</span>

                                    <div>

                                      <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>

                                        {'['}{'0'}-{'Max'}{']'}

                                      </div>

                                    </div>
                                  </>

                                </li>

                                {/* ))} */}



                              </ul>

                              <div style={{ padding: '20px', overflowY: 'auto' }}>

                                {opportunityValues?.flat
                                  ?.filter(tab => tab.areaid === selectedArea)
                                  .map((tab, index) => (

                                    <div key={tab.id} style={{ display: editFlatIndex === index ? 'block' : 'none' }}>

                                      <div style={{ marginTop: '7px' }}>

                                        <span>Flat Range</span>

                                        <input

                                          placeholder="Lower Limit"

                                          value={index === 0 ? "0" : tab.lowerLimit}

                                          onChange={(e) => {

                                            const newFlatDetails = areas

                                              .find((area) => area.areaid === selectedArea)

                                              ?.flatdetails?.map((slab, i) => {

                                                if (i === index) {

                                                  return { ...slab, lowerLimit: e.target.value };

                                                }

                                                return slab;

                                              });

                                            const updatedAreas = areas.map((area) => {

                                              if (area.areaid === selectedArea) {

                                                return { ...area, flatdetails: newFlatDetails };

                                              }

                                              return area;

                                            });

                                            setAreas(updatedAreas);

                                          }}
                                          disabled={true}

                                          readOnly={index === 0}

                                          style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}

                                        />

                                        <input

                                          placeholder="Upper Limit"

                                          value={tab.upperLimit}

                                          onChange={(e) => {

                                            const newFlatDetails = areas

                                              .find((area) => area.areaid === selectedArea)

                                              ?.flatdetails?.map((slab, i) => {

                                                if (i === index) {

                                                  return { ...slab, upperLimit: e.target.value };

                                                }

                                                return slab;

                                              });

                                            const updatedAreas = areas.map((area) => {

                                              if (area.areaid === selectedArea) {

                                                return { ...area, flatdetails: newFlatDetails };

                                              }

                                              return area;

                                            });

                                            setAreas(updatedAreas);

                                          }}

                                          disabled={true}

                                          style={{

                                            width: '120px',

                                            padding: '3px',

                                            borderRadius: '5px',

                                            marginRight: '10px',

                                            boxSizing: 'border-box'

                                          }}

                                        />
                                        <label htmlFor="upToMaxCheckbox" style={{ margin: '0' }}>Up to Max</label>
                                      </div>
                                      <RadioGroup
                                        aria-label={`premiumOptions_${tab.id}`}
                                        name={`premiumOptions_${tab.id}`}

                                        value={tab.selectedOption}

                                        onChange={(e) => {
                                          const newFlatDetails = areas

                                            .find((area) => area.areaid === selectedArea)

                                            ?.flatdetails?.map((slab, i) => {
                                              if (i === index) {
                                                return { ...slab, selectedOption: e.target.value };
                                              }
                                              return slab;
                                            });
                                          const updatedAreas = areas.map((area) => {
                                            if (area.areaid === selectedArea) {
                                              return { ...area, flatdetails: newFlatDetails };
                                            }
                                            return area;
                                          });
                                          setAreas(updatedAreas);
                                        }}
                                      >
                                        {/* <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium"/>  */}
                                        {premiumRadio === 'netpremium'  && (
                                          <div style={{ marginLeft: '1rem', marginTop: '20px' }}>
                                            <Typography style={{ marginRight: '0.5rem', marginTop: '1rem' }}> NetPremium:
                                              {/* <FormControlLabel
                                            control={
                                                  <Checkbox
                                                  checked={tab?.checkboxValue}
                                                  onChange={(e) => {
                                                    const updatedFlat = opportunityValues.flat
                                                    .map((slab, i) => {
                                                      // Check if the area matches the selected area and the index matches
                                                    
                                                      if (slab.areaid === selectedArea) {
                                                        // Update the note only for the specific area being edited
                                                        return { ...slab, netpremium: e.target.value };
                                                      }
                                                      return slab;
                                                    });
                                                  
                                                  const updatedOpportunityValues = { ...opportunityValues, flat: updatedFlat };
                                                  setOpportunityValues(updatedOpportunityValues);
                                                
                                                  }}
                                                  style={{ color: '#001f3f' }}
                                                />
                                              }
                                              label="NetPremium"
                                            /> */}

                                              <Controls.Input
                                                type="text"
                                                label="Commission Percentage"
                                                value={tab.netpremium}
                                                onChange={(e) => {
                                                  const updatedFlat = opportunityValues.flat
                                                    .map((slab, i) => {
                                                      // Check if the area matches the selected area and the index matches

                                                      if (slab.areaid === selectedArea) {
                                                        // Update the note only for the specific area being edited
                                                        return { ...slab, netpremium: e.target.value };
                                                      }
                                                      return slab;
                                                    });

                                                  const updatedOpportunityValues = { ...opportunityValues, flat: updatedFlat };
                                                  setOpportunityValues(updatedOpportunityValues);

                                                }}
                                              />
                                            </Typography>
                                          </div>
                                        )}

                                        {/* <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />    */}

                                      </RadioGroup>
                                      {premiumRadio === 'subPremiums' &&
                                        subpremiums[0] &&
                                        Object.entries(subpremiums[0])?.map(([fieldName]) => (
                                          <Grid container key={fieldName} alignItems='center' style={{ marginBottom: '20px', marginLeft: '1rem', marginTop: '20px' }}>
                                            <Grid item>
                                              <Checkbox
                                                checked={tab.subpremiums?.[fieldName] || false}
                                                onChange={(e) => {
                                                  const updatedFlat = opportunityValues.flat
                                                    .map((slab, i) => {
                                                      // Check if the area matches the selected area and the index matches

                                                      if (slab.areaid === selectedArea) {
                                                        // Update the note only for the specific area being edited
                                                        return { ...slab, subpremiums: { ...slab.subpremiums, [fieldName]: e.target.checked ? true : false } }
                                                      }
                                                      return slab;
                                                    });

                                                  const updatedOpportunityValues = { ...opportunityValues, flat: updatedFlat };
                                                  setOpportunityValues(updatedOpportunityValues);

                                                }}

                                                name={`${fieldName}_checkbox`}
                                                color="primary"
                                              />
                                            </Grid>
                                            <Grid item xs={5}>
                                              <FormControlLabel
                                                control={<div />}
                                                label={fieldName}
                                              />
                                            </Grid>
                                            <Grid item xs={5}>
                                              <Controls.Input
                                                type="text"
                                                label={"Commission Percentage"}
                                                value={tab.subpremiums?.[fieldName] && tab.subpremiums[fieldName] !== true ? tab.subpremiums[fieldName] : ''}

                                                onChange={(e) => {
                                                  const updatedFlat = opportunityValues.flat.map((slab, i) => {
                                                    if (slab.areaid === selectedArea) {
                                                      return { ...slab, subpremiums: { ...slab.subpremiums, [fieldName]: e.target.value } };
                                                    }
                                                    return slab;
                                                  });

                                                  const updatedOpportunityValues = { ...opportunityValues, flat: updatedFlat };
                                                  setOpportunityValues(updatedOpportunityValues);

                                                }}

                                              />

                                            </Grid>

                                          </Grid>


                                        ))}
                                      <label htmlFor={`textarea-${index}`}>Note:</label>

                                      <textarea
                                        id={`textarea-${index}`}
                                        value={tab.note}
                                        onChange={(e) => {


                                          const updatedFlat = opportunityValues.flat
                                            .map((slab, i) => {
                                              // Check if the area matches the selected area and the index matches

                                              if (slab.areaid === selectedArea) {
                                                // Update the note only for the specific area being edited
                                                return { ...slab, note: e.target.value };
                                              }
                                              return slab;
                                            });

                                          const updatedOpportunityValues = { ...opportunityValues, flat: updatedFlat };
                                          setOpportunityValues(updatedOpportunityValues);

                                        }}

                                        style={{
                                          width: '100%',
                                          padding: '10px',
                                          marginBottom: '10px',
                                          border: '1px solid #ddd',
                                          borderRadius: '5px',
                                          boxSizing: 'border-box',
                                          resize: 'vertical',
                                        }}
                                      ></textarea>

                                      <Grid style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>

                                        <Controls.Button text="Previous" type="submit" onClick={() => moveToPreviousTab('Prevous')} />

                                        <Controls.Button text="Next" type="submit" onClick={() => moveToNextTab('Next')} />



                                      </Grid>

                                    </div>

                                  ))}

                              </div>

                            </div>

                          </FormControl>

                        </CardContent>

                      </Card>

                    )}

                  </div>

                </div>

              </div>

            </FormControl>

          </CardContent>

        </Card>

      )}





      {discountCardOpen && (
        <Card>
          <CardContent>
            <RadioGroup
              aria-label="premiumOptions"
              name="premiumOptions"
              value={premiumRadio ||opportunityValues.basedon}
              onChange={(e) => {
                setPremiumRadio(e.target.value)
              }
            }

              style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', rowGap: '10rem' }}
            >
              <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium" />
              <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />
            </RadioGroup>
            <FormControl>
              <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
                  <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>
                    {areas
                      ?.filter(tab => opportunityValues?.discount?.some(discountItem => discountItem.areaid === tab.areaid))
                      .map((filteredTab, index) => (
                        <li
                          key={index}
                          style={{
                            cursor: 'pointer',
                            padding: '10px',
                            borderRadius: '5px',
                            transition: 'background-color 0.3s ease',
                            marginRight: '5px',
                            minWidth: '120px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            borderBottom: editDiscountIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color
                            borderLeft: '1px solid #ddd', // Added left border
                            borderRight: '1px solid #ddd', // Added right border



                            // backgroundColor: editDiscountIndex === index ? '#f8f9fa' : '',
                            // color: editDiscountIndex === index ? '#0056b3' : ''

                            backgroundColor: opportunityValues.discount.some(discountItem => discountItem.areaid === filteredTab.areaid) ? '#f8f9fa' : '',

                            color: opportunityValues.discount.some(discountItem => discountItem.areaid === filteredTab.areaid) ? '#0056b3' : ''

                          }}
                          onClick={() => setSelectedArea(filteredTab.areaid)}                    >
                          <>
                            <span>{filteredTab.area}</span>
                            <div>
                              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                                {tableData
                                  .filter((option) => option.areaid === filteredTab.areaid)
                                  .map((filteredOption, index) => (
                                    <div
                                      key={index}
                                      onClick={() => handleNameClick(filteredOption.areaid, filteredOption.provider)}
                                      style={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        color: selectedAreaId === filteredOption.areaid ? 'red' : 'inherit', // Change color as needed
                                        fontWeight: selectedAreaId === filteredOption.areaid ? 'bold' : 'normal', // Add this line for bold text if selected
                                      }}
                                    >
                                      {filteredOption.name}
                                    </div>
                                  ))}
                              </div>



                              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>

                              </div>
                            </div>
                          </>

                        </li>



                      ))}



                    <span style={{ display: 'flex', alignItems: 'center' }}>



                      <span



                        style={{



                          cursor: 'pointer',



                          padding: '8px 15px',



                          borderRadius: '5px',



                          marginRight: '5px',



                          transition: 'background-color 0.3s ease',



                          color: '#0056b3',



                          backgroundColor: '#f8f9fa',



                        }}

                        onClick={() => { navigate("/area/", { state: { commissiondetails: opportunityValues } }) }}
                      >
                        + Add Area
                      </span>
                    </span>
                  </ul>
                  <div style={{ padding: '20px', overflowY: 'auto' }}>
                    {areas?.map((tab, index) => (



                      <div key={tab.id} style={{ display: areaeditIndex === index ? 'block' : 'none' }}>



                      </div>



                    ))}



                    {discountCardOpen && (



                      <Card>

                        <CardContent>

                          <FormControl>

                            <h6>Area {selectedArea}</h6>

                            {/* <h2>{areas[editIndex]?.selectedOption}</h2>    */}

                            {/* <RadioGroup
                          aria-label="premiumOptions"
                          name="premiumOptions"
                          value={premiumRadio || 'netPremium'}
                          onChange={(e) => {
                            setPremiumRadio(e.target.value)
                          }}
                          style={{ marginBottom: '20px' }}
                        >
                          <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium" />
                          <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />
                        </RadioGroup> */}



                            <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
                              <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>

                                {opportunityValues.discount
                                  ?.find(discountItem => discountItem?.areaid === selectedArea)?.discountDetails?.map((filteredTab, index) => (
                                    <li

                                      key={index}

                                      style={{

                                        cursor: 'pointer',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        transition: 'background-color 0.3s ease',
                                        marginRight: '5px',
                                        minWidth: '120px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        borderBottom: editDiscountIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color
                                        borderLeft: '1px solid #ddd', // Added left border
                                        borderRight: '1px solid #ddd', // Added right border
                                        backgroundColor: editDiscountIndex === index ? '#f8f9fa' : '',
                                        color: editDiscountIndex === index ? '#0056b3' : ''
                                      }}
                                      onClick={() => setEditDiscountIndex(index)}

                                    >

                                      <>

                                        <span>Discount {index + 1}</span>

                                        <div>

                                          <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>


                                            {'['}{filteredTab.minvalue}-{filteredTab.maxvalue}{']'}
                                          </div>
                                        </div>
                                      </>
                                    </li>
                                  ))}



                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      padding: '8px 15px',
                                      borderRadius: '5px',
                                      marginRight: '5px',
                                      transition: 'background-color 0.3s ease',
                                      color: '#0056b3',
                                      backgroundColor: '#f8f9fa',
                                    }}
                                    onClick={() => addDiscountTabs(selectedArea)}
                                  >
                                    + Add
                                  </span>
                                  {editDiscountIndex !== null && (
                                    <span
                                      style={{
                                        cursor: 'pointer',
                                        padding: '8px 15px',
                                        borderRadius: '5px',
                                        transition: 'background-color 0.3s ease',
                                        color: '#0056b3',
                                        backgroundColor: '#f8f9fa',
                                      }}
                                      onClick={() => removeDiscountTab(selectedArea)}
                                    >
                                      &times; Remove
                                    </span>
                                  )}
                                </span>
                              </ul>
                              <div style={{ padding: '20px', overflowY: 'auto' }}>



                                {/* {areas
                                  .find((area) => area.areaid === selectedArea)
                                  ?.discountDetails?.map((tab, index) => (
 */}

                                {opportunityValues?.discount
                                  ?.find(discountItem => discountItem.areaid === selectedArea)?.discountDetails?.map((tab, index) => (

                                    <div key={tab.id} style={{ display: editDiscountIndex === index ? 'block' : 'none' }}>



                                      <div style={{ marginTop: '7px' }}>



                                        <span>Discount Range   </span>





                                        <input

                                          type='number'

                                          placeholder="Lower Limit"

                                          value={tab.minvalue}

                                          onChange={(e) => {
                                            const newOpportunityValues = { ...opportunityValues };
                                            const updatedDiscount = newOpportunityValues.discount.map((area) => {
                                              if (area.areaid === selectedArea && area.discountDetails) {
                                                const updatedDiscountDetails = area.discountDetails.map((discount, i) => {
                                                  if (i === index) {
                                                    return { ...discount, minvalue: e.target.value };
                                                  }
                                                  return discount;
                                                });
                                                return { ...area, discountDetails: updatedDiscountDetails };
                                              }
                                              return area;
                                            });
                                            newOpportunityValues.discount = updatedDiscount;
                                            setOpportunityValues(newOpportunityValues);
                                          }}

                                          //   const inputNumber = parseInt(e.target.value, 10);

                                          //   if (!isNaN(inputNumber) && inputNumber >= 0 && inputNumber <= 100) {

                                          //     const newDiscountDetails = areas

                                          //       .find((area) => area.areaid === selectedArea)

                                          //       ?.discountDetails?.map((slab, i) => {

                                          //         if (i === index) {

                                          //           return { ...slab, minvalue: e.target.value };

                                          //         }

                                          //         return slab;

                                          //       });



                                          //     const updatedAreas = areas.map((area) => {

                                          //       if (area.areaid === selectedArea) {

                                          //         return { ...area, discountDetails: newDiscountDetails };

                                          //       }

                                          //       return area;

                                          //     });



                                          //     setAreas(updatedAreas);

                                          //   }

                                          //   else if (e.target.value === '') {

                                          //     // Allow backspace by updating state when the input is empty

                                          //     const newDiscountDetails = areas

                                          //       .find((area) => area.areaid === selectedArea)

                                          //       ?.discountDetails?.map((slab, i) => {

                                          //         if (i === index) {

                                          //           return { ...slab, minvalue: '' };

                                          //         }

                                          //         return slab;

                                          //       });



                                          //     const updatedAreas = areas.map((area) => {

                                          //       if (area.areaid === selectedArea) {

                                          //         return { ...area, discountDetails: newDiscountDetails };

                                          //       }

                                          //       return area;

                                          //     });

                                          //     setAreas(updatedAreas);

                                          //   }

                                          // }}

                                          // readOnly={index === 0}

                                          style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}

                                        />

                                        <input

                                          type='number'

                                          placeholder="Upper Limit"
                                          value={tab.maxvalue}



                                          onChange={(e) => {

                                            //   const newValue = parseFloat(e.target.value);

                                            //   // Check if the value is within the desired range (0 to 100)
                                            //   if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
                                            //     const newDiscountDetails = areas
                                            //       .find((area) => area.areaid === selectedArea)
                                            //       ?.discountDetails?.map((slab, i) => {
                                            //         if (i === index) {
                                            //           return { ...slab, maxvalue: e.target.value };

                                            //         }



                                            //         return slab;



                                            //       });



                                            //     const updatedAreas = areas.map((area) => {



                                            //       if (area.areaid === selectedArea) {



                                            //         return { ...area, discountDetails: newDiscountDetails };



                                            //       }



                                            //       return area;



                                            //     });



                                            //     setAreas(updatedAreas);

                                            //   } else if (e.target.value === '' || e.inputType === 'deleteContentBackward') {

                                            //     // Allow backspace to clear the input field

                                            //     const newDiscountDetails = areas

                                            //       .find((area) => area.areaid === selectedArea)

                                            //       ?.discountDetails?.map((slab, i) => {

                                            //         if (i === index) {

                                            //           return { ...slab, maxvalue: '' };

                                            //         }

                                            //         return slab;

                                            //       });



                                            //     const updatedAreas = areas.map((area) => {

                                            //       if (area.areaid === selectedArea) {

                                            //         return { ...area, discountDetails: newDiscountDetails };

                                            //       }

                                            //       return area;

                                            //     });



                                            //     setAreas(updatedAreas);

                                            //   } else {

                                            //     // Handle invalid input (you may want to show an error message)

                                            //     console.error("Invalid input. Please enter a number between 0 and 100.");

                                            //   }

                                            // }}

                                            const newOpportunityValues = { ...opportunityValues };
                                            const updatedDiscount = newOpportunityValues.discount.map((area) => {
                                              if (area.areaid === selectedArea && area.discountDetails) {
                                                const updatedDiscountDetails = area.discountDetails.map((discount, i) => {
                                                  if (i === index) {
                                                    return { ...discount, maxvalue: e.target.value };
                                                  }
                                                  return discount;
                                                });
                                                return { ...area, discountDetails: updatedDiscountDetails };
                                              }
                                              return area;
                                            });
                                            newOpportunityValues.discount = updatedDiscount;
                                            setOpportunityValues(newOpportunityValues);
                                          }}
                                          disabled={disableDiscountUpperLimit}
                                          style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
                                        />
                                        <input
                                          type="checkbox"
                                          id="upToMaxCheckbox"
                                          checked={disableDiscountUpperLimit}
                                          onChange={() => setDisableDiscountUpperLimit(!disableDiscountUpperLimit)}
                                          style={{ margin: '0 5px', width: '20px', height: '20px' }}
                                        />
                                        <label htmlFor="upToMaxCheckbox" style={{ margin: '0' }}>Up to Max</label>
                                      </div>
                                      <RadioGroup
                                        aria-label={`premiumOptions_${tab.id}`}
                                        name={`premiumOptions_${tab.id}`}
                                        value={tab.selectedOption}
                                        onChange={(e) => {
                                          const newDiscountDetails = areas

                                            .find((area) => area.areaid === selectedArea)

                                            ?.discountDetails?.map((discount, i) => {
                                              if (i === index) {
                                                return { ...discount, selectedOption: e.target.value };
                                              }
                                              return discount;
                                            });
                                          const updatedAreas = areas.map((area) => {
                                            if (area.areaid === selectedArea) {
                                              return { ...area, discountDetails: newDiscountDetails };
                                            }
                                            return area;

                                          });
                                          setAreas(updatedAreas);
                                        }}
                                      >

                                        {/* <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium"/>  */}
                                        {premiumRadio === 'netPremium'  &&(
                                          <div style={{ marginLeft: '1rem', marginTop: '20px' }}>
                                            <Typography style={{ marginRight: '0.5rem', marginTop: '1rem' }}> NetPremium:
                                              {/* <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={tab.checkboxValue}
                                                  onChange={(e) => {
                                                    const newDiscountDetails = areas
                                                      .find((area) => area.areaid === selectedArea)



                                                      ?.discountDetails?.map((discount, i) => {



                                                        if (i === index) {



                                                          return { ...discount, netpremium: e.target.value };

                                                        }

                                                        return discount;

                                                      });

                                                    const updatedAreas = areas.map((area) => {

                                                      if (area.areaid === selectedArea) {

                                                        return { ...area, discountDetails: newDiscountDetails };
                                                      }
                                                      return area;
                                                    });
                                                    setAreas(updatedAreas);
                                                  }}
                                                  style={{ color: '#001f3f' }}  // Set the color to dark blue (#001f3f)
                                                />
                                              }
                                              label="NetPremium"
                                            /> */}

                                              <Controls.Input

                                                type="text"

                                                label="Commission Percentage"

                                                value={tab.netpremium}

                                                // onChange={(e) => {

                                                //   const newTabs = [...tabs];

                                                //   newTabs[index].netPremiumContent = e.target.value;

                                                //   setTabs(newTabs);

                                                // }}
                                                onChange={(e) => {
                                                  const newOpportunityValues = { ...opportunityValues };
                                                  const updatedDiscount = newOpportunityValues.discount.map((area) => {
                                                    if (area.areaid === selectedArea && area.discountDetails) {
                                                      const updatedDiscountDetails = area.discountDetails.map((discount, i) => {
                                                        if (i === index) {
                                                          return { ...discount, netpremium: e.target.value };
                                                        }
                                                        return discount;
                                                      });
                                                      return { ...area, discountDetails: updatedDiscountDetails };
                                                    }
                                                    return area;
                                                  });
                                                  newOpportunityValues.discount = updatedDiscount;
                                                  setOpportunityValues(newOpportunityValues);
                                                }}
                                              />
                                            </Typography>
                                          </div>
                                        )}
                                        {/* <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />    */}

                                      </RadioGroup>

                                      {premiumRadio === 'subPremiums' &&

                                        subpremiums[0] &&

                                        Object.entries(subpremiums[0])?.map(([fieldName]) => (

                                          <Grid container key={fieldName} alignItems='center' style={{ marginBottom: '20px', marginLeft: '1rem', marginTop: '20px' }}>

                                            <Grid item>

                                              <Checkbox

                                                checked={tab.subpremiums?.[fieldName] || false}

                                                onChange={(e) => {
                                                  const newOpportunityValues = { ...opportunityValues };
                                                  const updatedDiscount = newOpportunityValues.discount.map((area) => {
                                                    if (area.areaid === selectedArea && area.discountDetails) {
                                                      const updatedDiscountDetails = area.discountDetails.map((discount, i) => {
                                                        if (i === index) {
                                                          return { ...discount, subpremiums: { ...discount.subpremiums, [fieldName]: e.target.checked ? true : false } };
                                                        }
                                                        return discount;
                                                      });
                                                      return { ...area, discountDetails: updatedDiscountDetails };
                                                    }
                                                    return area;
                                                  });
                                                  newOpportunityValues.discount = updatedDiscount;
                                                  setOpportunityValues(newOpportunityValues);
                                                }}
                                                name={`${fieldName}_checkbox`}
                                                color="primary"
                                              />

                                            </Grid>

                                            <Grid item xs={5}>

                                              <FormControlLabel

                                                control={<div />}

                                                label={fieldName}

                                              />

                                            </Grid>

                                            <Grid item xs={5}>

                                              <Controls.Input

                                                type="text"

                                                label={"Commission Percentage"}

                                                value={tab.subpremiums?.[fieldName] && tab.subpremiums[fieldName] !== true ? tab.subpremiums[fieldName] : ''}
                                                onChange={(e) => {
                                                  const newOpportunityValues = { ...opportunityValues };
                                                  const updatedDiscount = newOpportunityValues.discount.map((area) => {
                                                    if (area.areaid === selectedArea && area.discountDetails) {
                                                      const updatedDiscountDetails = area.discountDetails.map((discount, i) => {
                                                        if (i === index) {
                                                          return { ...discount, subpremiums: { ...discount.subpremiums, [fieldName]: e.target.value } };
                                                        }
                                                        return discount;
                                                      });
                                                      return { ...area, discountDetails: updatedDiscountDetails };
                                                    }
                                                    return area;
                                                  });
                                                  newOpportunityValues.discount = updatedDiscount;
                                                  setOpportunityValues(newOpportunityValues);
                                                }}
                                              />

                                            </Grid>

                                          </Grid>

                                        ))}





                                      <label htmlFor={`textarea-${index}`}>Note:</label>
                                      <textarea
                                        id={`textarea-${index}`}
                                        value={tab.note}


                                        onChange={(e) => {
                                          //   const newDiscountDetails = areas
                                          //     .find((area) => area.areaid === selectedArea)
                                          //     ?.discountDetails?.map((slab, i) => {
                                          //       if (i === index) {
                                          //         return { ...slab, note: e.target.value };

                                          //       }
                                          //       return slab;
                                          //     });
                                          //   const updatedAreas = areas.map((area) => {

                                          //     if (area.areaid === selectedArea) {
                                          //       return { ...area, discountDetails: newDiscountDetails };

                                          //     }
                                          //     return area;
                                          //   });
                                          //   setAreas(updatedAreas);
                                          // }}
                                          const newOpportunityValues = { ...opportunityValues };
                                          const updatedDiscount = newOpportunityValues.discount.map((area) => {
                                            if (area.areaid === selectedArea && area.discountDetails) {
                                              const updatedDiscountDetails = area.discountDetails.map((discount, i) => {
                                                if (i === index) {
                                                  return { ...discount, note: e.target.value };
                                                }
                                                return discount;
                                              });
                                              return { ...area, discountDetails: updatedDiscountDetails };
                                            }
                                            return area;
                                          });
                                          newOpportunityValues.discount = updatedDiscount;
                                          setOpportunityValues(newOpportunityValues);
                                        }}

                                        style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ddd', borderRadius: '5px', boxSizing: 'border-box', resize: 'vertical' }}
                                      ></textarea>
                                      <Grid style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>



                                        <Controls.Button text="Previous" type="submit" onClick={() => moveDiscountToNextTab('Prevous')} />

                                        <Controls.Button text="Next" type="submit" onClick={() => moveDiscountToPreviousTab('Next')} />



                                      </Grid>

                                    </div>

                                  ))}

                              </div>

                            </div>

                          </FormControl>

                        </CardContent>

                      </Card>



                    )}



                  </div>



                </div>



              </div>



            </FormControl>



          </CardContent>



        </Card>



      )}





      {slabCardOpen && (

        <Card>

          <CardContent>

            <RadioGroup



              aria-label="premiumOptions"



              name="premiumOptions"



              value={premiumRadio || opportunityValues.basedon}



              onChange={(e) => {
          setPremiumRadio(e.target.value)



              }}



              style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', rowGap: '10rem' }}
            >
              <FormControlLabel value="netpremium" control={<Radio />} label="Net Premium" />
              <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />
            </RadioGroup>
            <FormControl>
              <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
                  <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>

                    {areas

                      ?.filter(tab => opportunityValues?.slab?.some(slabItem => slabItem.areaid === tab.areaid))

                      .map((filteredTab, index) => (

                        <li

                          key={index}

                          style={{

                            cursor: 'pointer',

                            padding: '10px',

                            borderRadius: '5px',

                            transition: 'background-color 0.3s ease',

                            marginRight: '5px',

                            minWidth: '120px',

                            textOverflow: 'ellipsis',

                            overflow: 'hidden',

                            whiteSpace: 'nowrap',

                            textAlign: 'center',

                            borderBottom: editDiscountIndex === index ? '2px solid #0056b3' : '1px solid #0066cc',

                            borderLeft: '1px solid #ddd',

                            borderRight: '1px solid #ddd',

                            backgroundColor: opportunityValues.slab.some(slabItem => slabItem?.areaid === filteredTab.areaid) ? '#f8f9fa' : '',

                            color: opportunityValues.slab.some(slabItem => slabItem.areaid === filteredTab.areaid) ? '#0056b3' : ''

                          }}

                          onClick={() => setSelectedArea(filteredTab.areaid)}

                        >

                          <>

                            <span>{filteredTab.area}</span>

                            <div>
                              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                                {tableData
                                  .filter((option) => option.areaid === filteredTab.areaid)
                                  .map((filteredOption, index) => (
                                    <div
                                      key={index}
                                      onClick={() => handleNameClick(filteredOption.areaid, filteredOption.provider)}
                                      style={{
                                        cursor: 'pointer',
                                        textDecoration: 'underline',
                                        color: selectedAreaId === filteredOption.areaid ? 'red' : 'inherit', // Change color as needed
                                        fontWeight: selectedAreaId === filteredOption.areaid ? 'bold' : 'normal', // Add this line for bold text if selected
                                      }}
                                    >
                                      {filteredOption.name}
                                    </div>
                                  ))}
                              </div>

                              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}></div>

                            </div>

                          </>

                        </li>

                      ))}



                    <span style={{ display: 'flex', alignItems: 'center' }}>

                      <span

                        style={{

                          cursor: 'pointer',

                          padding: '8px 15px',

                          borderRadius: '5px',

                          marginRight: '5px',

                          transition: 'background-color 0.3s ease',

                          color: '#0056b3',

                          backgroundColor: '#f8f9fa',

                        }}

                        onClick={() => { navigate("/area/", { state: { commissiondetails: opportunityValues } }) }}

                      >

                        + Add Area

                      </span>

                    </span>



                  </ul>

                  <div style={{ padding: '20px', overflowY: 'auto' }}>

                    {areas?.map((tab, index) => (

                      <div key={tab.id} style={{ display: areaeditIndex === index ? 'block' : 'none' }}>
                      </div>

                    ))}


                    {slabCardOpen && (
                      <Card>
                        <CardContent>
                          <FormControl>
                            <h6>Area {selectedArea}</h6>
                            <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
                              <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>
                                {opportunityValues.slab
                                  .find(slabItem => slabItem.areaid === selectedArea)?.slabDetails?.map((filteredTab, index) => (
                                    <li
                                      key={index}
                                      style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        transition: 'background-color 0.3s ease',
                                        marginRight: '5px',
                                        minWidth: '120px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        borderBottom: editIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color
                                        borderLeft: '1px solid #ddd', // Added left border
                                        borderRight: '1px solid #ddd', // Added right border
                                        backgroundColor: editIndex === index ? '#f8f9fa' : '',
                                        color: editIndex === index ? '#0056b3' : ''
                                      }}
                                      onClick={() => setEditIndex(index)}
                                    >
                                      <>
                                        <span>Slab {index + 1}</span>
                                        <div>
                                          <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                                            {'['}{filteredTab.minvalue}-{filteredTab.maxvalue}{']'}
                                          </div>
                                        </div>
                                      </>
                                    </li>
                                  ))}
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      padding: '8px 15px',
                                      borderRadius: '5px',
                                      marginRight: '5px',
                                      transition: 'background-color 0.3s ease',
                                      color: '#0056b3',
                                      backgroundColor: '#f8f9fa'
                                    }}
                                    onClick={() => addSlabTabs(selectedArea)}
                                  >
                                    + Add
                                  </span>
                                  {editIndex !== null && (
                                    <span
                                      style={{
                                        cursor: 'pointer',
                                        padding: '8px 15px',
                                        borderRadius: '5px',
                                        transition: 'background-color 0.3s ease',
                                        color: '#0056b3',
                                        backgroundColor: '#f8f9fa'
                                      }}
                                      onClick={() => removeSlabTab(selectedArea)}
                                    >
                                      &times; Remove
                                    </span>
                                  )}
                                </span>
                              </ul>

                              <div style={{ padding: '20px', overflowY: 'auto' }}>
                                {/* areas.find((area) => area.areaid === selectedArea)
                         ?.slabDetails?.map((slab, i) => { */}
                                {opportunityValues?.slab
                                  .find(slabItem => slabItem.areaid === selectedArea)?.slabDetails?.map((tab, index) => (

                                    <div key={tab.id} style={{ display: editIndex === index ? 'block' : 'none' }}>



                                      <div style={{ marginTop: '7px' }}>

                                        <span>Slab Range   </span>

                                        <input

                                          placeholder="Lower Limit"

                                          value={index === 0 ? 0 : tab.minvalue}



                                          //  value={index === 0 ? "0" : (parseInt(areas.find((area) => area.areaid === selectedArea)?.slabDetails[index - 1].upperLimit, 10) + 1).toString()}

                                          onChange={(e) => {
                                            const newOpportunityValues = { ...opportunityValues };
                                            const updatedSlab = newOpportunityValues.slab.map((area) => {
                                              if (area.areaid === selectedArea && area.slabDetails) {
                                                const updatedSlabDetails = area.slabDetails.map((slab, i) => {
                                                  if (i === index) {
                                                    return { ...slab, minvalue: e.target.value };
                                                  }
                                                  return slab;
                                                });
                                                return { ...area, slabDetails: updatedSlabDetails };
                                              }
                                              return area;
                                            });
                                            newOpportunityValues.slab = updatedSlab;
                                            setOpportunityValues(newOpportunityValues);
                                          }}

                                          readOnly={index === 0}

                                          style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box', color: index === 0 ? 'gray' : 'black', }}
                                        />
                                        <input

                                          placeholder="Upper Limit"

                                          value={tab.maxvalue}


                                          onChange={(e) => {
                                            const newOpportunityValues = { ...opportunityValues };
                                            const updatedSlab = newOpportunityValues.slab.map((area) => {
                                              if (area.areaid === selectedArea && area.slabDetails) {
                                                const updatedSlabDetails = area.slabDetails.map((slab, i) => {
                                                  if (i === index) {
                                                    return { ...slab, maxvalue: e.target.value };
                                                  }
                                                  return slab;
                                                });
                                                return { ...area, slabDetails: updatedSlabDetails };
                                              }
                                              return area;
                                            });
                                            newOpportunityValues.slab = updatedSlab;
                                            setOpportunityValues(newOpportunityValues);
                                          }}

                                          //   const newSlabDetails = opportunityValues.slab

                                          //     .find((area) => area.areaid === selectedArea)

                                          //     ?.slabDetails?.map((slab, i) => {

                                          //       if (i === index) {

                                          //         return { ...slab, maxvalue: e.target.value };

                                          //       }

                                          //       return slab;

                                          //     });

                                          //   const updatedAreas = areas.map((area) => {

                                          //     if (area.areaid === selectedArea) {

                                          //       return { ...area, slabDetails: newSlabDetails };

                                          //     }

                                          //     return area;

                                          //   });

                                          //   setAreas(updatedAreas);
                                          // }}
                                          disabled={disableUpperLimit}
                                          style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
                                        />

                                        <input

                                          type="checkbox"

                                          id="upToMaxCheckbox"

                                          checked={disableUpperLimit}

                                          onChange={() => setDisableUpperLimit(!disableUpperLimit)}

                                          style={{ margin: '0 5px', width: '20px', height: '20px' }}

                                        />

                                        <label htmlFor="upToMaxCheckbox" style={{ margin: '0' }}>Up to Max</label>

                                      </div>
                                      <RadioGroup

                                        aria-label={`premiumOptions_${tab.id}`}

                                        name={`premiumOptions_${tab.id}`}

                                        value={tab.selectedOption}

                                        onChange={(e) => {

                                          const newSlabDetails = areas

                                            .find((area) => area.areaid === selectedArea)

                                            ?.slabDetails?.map((slab, i) => {



                                              if (i === index) {
                                                return { ...slab, selectedOption: e.target.value };
                                              }
                                              return slab;
                                            });
                                          const updatedAreas = areas.map((area) => {
                                            if (area.areaid === selectedArea) {
                                              return { ...area, slabDetails: newSlabDetails };
                                            }

                                            return area;

                                          });
                                          setAreas(updatedAreas);
                                        }}
                                      >
                                        {premiumRadio === 'netpremium' && (

                                          <div style={{ marginLeft: '1rem', marginTop: '20px' }}>
                                            <Typography style={{ marginRight: '0.5rem', marginTop: '1rem' }}>
                                               NetPremium:
                                              {/* <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={tab.checkboxValue}
                                                  onChange={(e) => {
                                                    const newSlabDetails = areas
                                                      .find((area) => area.areaid === selectedArea)
                                                      ?.slabDetails?.map((slab, i) => {
                                                        if (i === index) {
                                                          return { ...slab, netpremium: e.target.value };
                                                        }
                                                        return slab;
                                                      });
                                                    const updatedAreas = areas.map((area) => {
                                                      if (area.areaid === selectedArea) {
                                                        return { ...area, slabDetails: newSlabDetails };
                                                      }
                                                      return area;
                                                    });
                                                    setAreas(updatedAreas);
                                                  }}
                                                  style={{ color: '#001f3f' }}  // Set the color to dark blue (#001f3f)
                                                />
                                              }
                                              label="NetPremium"
                                            /> */}
                                              <Controls.Input
                                                type="text"
                                                label="Commission Percentage"
                                                value={tab.netpremium}
                                                onChange={(e) => {
                                                  const newOpportunityValues = { ...opportunityValues };
                                                  const updatedSlab = newOpportunityValues.slab.map((area) => {
                                                    if (area.areaid === selectedArea && area.slabDetails) {
                                                      const updatedSlabDetails = area.slabDetails.map((slab, i) => {
                                                        if (i === index) {
                                                          return { ...slab, netpremium: e.target.value };
                                                        }
                                                        return slab;
                                                      });
                                                      return { ...area, slabDetails: updatedSlabDetails };
                                                    }
                                                    return area;
                                                  });
                                                  newOpportunityValues.slab = updatedSlab;
                                                  setOpportunityValues(newOpportunityValues);
                                                }}

                                              />
                                            </Typography>
                                          </div>
                                        )}
                                        {/* <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />    */}

                                      </RadioGroup>
                                      {premiumRadio === 'subPremiums'  &&
                                        subpremiums[0] &&
                                        Object.entries(subpremiums[0]).map(([fieldName]) => (
                                          <Grid container key={fieldName} alignItems='center' style={{ marginBottom: '20px', marginLeft: '1rem', marginTop: '20px' }}>
                                            <Grid item>
                                              <Checkbox
                                                checked={tab.subpremiums?.[fieldName] || false}
                                                onChange={(e) => {
                                                  const newSlabDetails = opportunityValues.slab.map((area) => {
                                                    if (area.areaid === selectedArea && area.slabDetails) {
                                                      const updatedSlabDetails = area.slabDetails.map((slab, i) => {
                                                        if (i === index) {
                                                          return {
                                                            ...slab,
                                                            subpremiums: {
                                                              ...slab.subpremiums,
                                                              [fieldName]: e.target.checked ? true : false // Update to use e.target.checked for checkbox value
                                                            },
                                                          };
                                                        }
                                                        return slab;
                                                      });
                                                      return { ...area, slabDetails: updatedSlabDetails };
                                                    }
                                                    return area;
                                                  });
                                                  setOpportunityValues((prevValues) => ({
                                                    ...prevValues,
                                                    slab: newSlabDetails,
                                                  }));
                                                }}
                                                name={`${fieldName}_checkbox`}
                                                color="primary"
                                              />
                                            </Grid>
                                            <Grid item xs={5}>
                                              <FormControlLabel control={<div />} label={fieldName} />
                                            </Grid>
                                            <Grid item xs={5}>
                                              <Controls.Input
                                                type="text"
                                                label={"Commission Percentage"}
                                                value={tab.subpremiums?.[fieldName] && tab.subpremiums[fieldName] !== true ? tab.subpremiums[fieldName] : ''}
                                                onChange={(e) => {
                                                  const newSlabDetails = opportunityValues.slab.map((area) => {
                                                    if (area.areaid === selectedArea && area.slabDetails) {
                                                      const updatedSlabDetails = area.slabDetails.map((slab, i) => {
                                                        if (i === index) {
                                                          return {
                                                            ...slab,
                                                            subpremiums: {
                                                              ...slab.subpremiums,
                                                              [fieldName]: e.target.value,
                                                            },
                                                          };
                                                        }
                                                        return slab;
                                                      });
                                                      return { ...area, slabDetails: updatedSlabDetails };
                                                    }
                                                    return area;
                                                  });
                                                  setOpportunityValues((prevValues) => ({
                                                    ...prevValues,
                                                    slab: newSlabDetails,
                                                  }));
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        ))}



                                      <label htmlFor={`textarea-${index}`}>Note:</label>
                                      <textarea
                                        id={`textarea-${index}`}
                                        value={opportunityValues.slab.find((area) => area.areaid === selectedArea)?.slabDetails[index]?.note || ''}
                                        onChange={(e) => {
                                          const newOpportunityValues = { ...opportunityValues };
                                          const updatedSlab = newOpportunityValues.slab.map((area) => {
                                            if (area.areaid === selectedArea && area.slabDetails) {
                                              const updatedSlabDetails = area.slabDetails.map((slab, i) => {
                                                if (i === index) {
                                                  return { ...slab, note: e.target.value };
                                                }
                                                return slab;
                                              });
                                              return { ...area, slabDetails: updatedSlabDetails };
                                            }
                                            return area;
                                          });
                                          newOpportunityValues.slab = updatedSlab;
                                          setOpportunityValues(newOpportunityValues);
                                        }}
                                        style={{
                                          width: '100%',
                                          padding: '10px',
                                          marginBottom: '10px',
                                          border: '1px solid #ddd',
                                          borderRadius: '5px',
                                          boxSizing: 'border-box',
                                          resize: 'vertical'
                                        }}
                                      />


                                      <Grid style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                        <Controls.Button text="Previous" type="submit" onClick={() => moveToPreviousTab('Prevous')} />
                                        <Controls.Button text="Next" type="submit" onClick={() => moveToNextTab('Next')} />
                                      </Grid>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </FormControl>
                        </CardContent>
                      </Card>

                    )}
                  </div>
                </div>
              </div>
            </FormControl>
          </CardContent>
        </Card>
      )}

      <Grid style={{ marginLeft: '66rem' }}>
        <Controls.Button text='Update' type="submit" onClick={handleSubmit} />
        {loading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9999, // Ensure the overlay is on top of other elements
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <LoadingSpinner />
            </div>
          </div>
        )}
        <Controls.Button text="Cancel" type="submit" onClick={() => handleButtonClick('Cancel')} />
        <Snackbar
          sx={{ marginLeft: '30rem' }}
          open={isSuccessSnackbarOpen}
          autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
          onClose={handleSnackbarClose}
          message="Data successfully updated"

        />
          {/* <p>Rule ID: {commissionData?.ruleid}</p> */}
      </Grid>
    </>
  );
}
export default Commission;











