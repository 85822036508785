import React, { useRef } from 'react';
import folder from './../../assests/icons/folder.png'
import './../../App.css'
import { Button as MuiButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5),
        backgroundColor: '#914572', // Your custom color
        height:'35px',
        padding:'15px',
        color: '#fff', // Set text color to white for better contrast
        '&:hover': {
          backgroundColor: '#7b3f5c', // Custom color on hover (optional)
        }
    },
    label: {
        textTransform: 'none', 
        fontSize:'12px'
   }
}))

const FileUploadButton = ({ onFileSelect,...props }) => {
    const { text, size, color, variant, onClick, ...other } = props
    const classes = useStyles();
    const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    onFileSelect(file);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <MuiButton
        variant={variant || "contained"}
        size={size || "large"}
        {...other}
        classes={{ root: classes.root, label: classes.label }}
        startIcon={<img src={folder}  className="import-lead-button" alt="Folder Icon" />}
        onClick={handleClick}
      >
        {text}
      </MuiButton>
    </>
  );
};

export default FileUploadButton;
