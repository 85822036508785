import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import Dropdown from "./Dropdown";
import Dropdowns from "./Dropdowns";
import Dropdownall from "./Dropdownall";
import FileUploadButton from "./FileUploadButton";
import DatepickerTime from './DatepickerTime'
import ReactDatepicker from './ReactDatepicker'


const Controls = {
    Input,
    RadioGroup,
    Checkbox,
    DatePicker,
    ReactDatepicker,
    DatepickerTime,
    Button,
    Dropdown,
    Dropdowns,
    Dropdownall,
    FileUploadButton,
}

export default Controls;