import React from 'react';
import { useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Routes, Route, Link, Navigate } from 'react-router-dom';
import AvProspect from '../pages/AvProspect';
import AvLoginForm from '../pages/AvLoginForm';
import AvAddLead from '../pages/AvAddLead';
import AvAddReferral from '../pages/AvAddReferral';
import anvayyalogo from '../assests/icons/anvayyalogo.png'
import AvSales from '../pages/AvSales.js';
import login from '../assests/icons/login.png'
import ViewProspect from '../pages/ViewProspect';
import UpdateProspect from '../pages/UpdateProspect';
import AvAddOpportunity from '../pages/AvAddOpportunity'; 
import AvAddCustomeropportunity from '../pages/AvAddCustomeropportunity.js';
import ConvertCustomer from '../pages/ConvertCustomer';
import AvAddPolicy from '../pages/AvAddPolicy';
import UpdateStatus from '../pages/UpdateStatus';
import AvEditPolicy from '../pages/AvEditPolicy';
import AvEditOppurtunity from '../pages/AvEditOpportunity';
import Assign from'../pages/Assign';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AvAllPolicies from '../pages/AvAllPolicies';
import AvAllOpportunity from '../pages/AvAllOpportunity';
import { useNavigate } from 'react-router-dom';
import Invoice from '../pages/Invoice';
import NewBenificiary from '../pages/NewBenificiary';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AllCustomer from '../pages/AllCustomer';
import ViewCustomer from '../pages/ViewCustomer';
import LeadTemplate from '../pages/LeadTemplate';
import OpportunityAssign from '../pages/OpportunityAssign';
import AddCustomer from '../pages/AddCustomer';
import CustomerAssign from '../pages/CustomerAssign';
import OpportunityUpdateStatus from '../pages/OpportunityUpdateStatus';
import Reports from '../pages/Reports';
import UpdateCustomerStatus from '../pages/UpdateCustomerStatus';
import CustomerAddReference from '../pages/CustomerAddReference'
import CustomerAddBeneficiary from '../pages/CustomerAddBeneficiary'
import UpdateCustomer from '../pages/UpdateCustomer'
import Dashboard from '../pages/Dashboards';
import AvAddBeneficiary from '../pages/AvAddBeneficiary';
import AvEditBeneficiary from '../pages/AvEditBeneficiary';
import ViewOpportunity from '../pages/ViewOpportunity'
import NewOpportunity from '../pages/NewOpportunity';
import OpportunityAddBeneficiary from '../pages/OpportunityAddBeneficiary';
import OpportunityUpdateBeneficiary from '../pages/OpportunityUpdateBeneficiary';
import ProspectAddBeneficiary from '../pages/ProspectAddBeneficiary';
import ProspectUpdateBeneficiary from '../pages/ProspectUpdateBeneficiary';
import Slab from '../pages/Slab';
import AvCommission from '../pages/AvCommission';
import Area from'../pages/Area';
import ViewCommission from '../pages/ViewCommission';
import UpdateSlab from '../pages/UpdateSlab';
import NewVersionCommission from '../pages/NewVersionCommission';
import * as Rest from  './../services/restapi';
import axios from 'axios';



const NotFound = () => {
  const navigation = useNavigate();
  return (
    <div style={{marginLeft:'30rem',marginTop:'15rem'}}>
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <a onClick={()=>(navigation('/allopportunity'))} style={{cursor:'pointer'}}>Go to Lead</a>
    </div>
  );
};

const Header = ({setToken}) => {
  const [leadsMenuAnchor, setLeadsMenuAnchor] = React.useState(null);
  const [salesMenuAnchor, setSalesMenuAnchor] = React.useState(null);
  const [reportsMenuAnchor, setReportsMenuAnchor] = React.useState(null);
  const [loginMenuAnchor, setLoginMenuAnchor] = React.useState(null);
  const [employeeRoles, setEmployeeRoles] = React.useState(null); // Add this state
  
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const navigate = useNavigate();
  const employeeName = localStorage.getItem("EmployeeName");
  const isLoggedIn = !!localStorage.getItem('X-fiftyaccess-Token');
  

  React.useEffect(() => {
    if (!employeeName || !isLoggedIn) {
      navigate('/login');
    }
  }, [employeeName, isLoggedIn, navigate]);


  React.useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
       
        const response = await axios.get(`${Rest.getEmployee}/${employeeIDs}`, {
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        });
        const roles = response?.data?.data?.Roles?.map(item => item.Role);
        setEmployeeRoles(roles);
      } catch (error) {
        console.error('Error fetching employee data:', error);
        // Handle error if the request fails
      }
    };

    const employeeIDs = localStorage.getItem("EmployeeID");

    if (employeeIDs) {
      fetchEmployeeData();
    }
  }, []);

  const isInsuranceManager = employeeRoles?.includes('insurancemanager');

  const handleLeadsMenuOpen = (event) => {
    setLeadsMenuAnchor(event.currentTarget);
  };

  const handleSalesMenuOpen = (event) => {
    setSalesMenuAnchor(event.currentTarget);
  };
  const handleReportMenuOpen = (event) => {
    setReportsMenuAnchor(event.currentTarget);
  };

  const handleLoginMenuOpen = (event) => {
    setLoginMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setLeadsMenuAnchor(null);
    setSalesMenuAnchor(null);
    setLoginMenuAnchor(null);
    setReportsMenuAnchor(null);
  };
  const navigateToProspect = () => {
    navigate('/prospect');
    handleMenuClose();
  };
  const navigateToOpportunity = () => {
    navigate('/allopportunity');
    handleMenuClose();
  };
  const navigateToCustomer = () => {
    navigate('/allcustomer');
    handleMenuClose();
  };

  const navigateToPolicy = () => {
    navigate('/allpolicies');
    handleMenuClose();
  };
  const navigateToSales = () => {
    navigate('/sales');
    handleMenuClose();
  };
  const navigateToReports = () => {
    navigate('/reports');
    handleMenuClose();
  };

  const navigateToDashboard = () => {
    navigate('/dashboard');
    handleMenuClose();
  };

  const navigateToInvoice = () => {
    navigate('/invoice');
    handleMenuClose();
  };
  const navigateToCommission = () => {
    navigate('/commission');
    handleMenuClose();
  };
  const navigateToSlab = () => {
    navigate('/viewcommission');
    handleMenuClose();
  };


  const handleLogout = () => {
    // Remove the access token from local storage
    localStorage.removeItem('EmployeeID');
    localStorage.removeItem('EmployeeName');
    localStorage.removeItem('X-fiftyaccess-Token');
  
    // Optionally, redirect to the login page or wherever needed
    // Example: redirecting to the login page
    navigate('/login'); // Assuming you have access to history object
  
    // Alternatively, you can trigger any other necessary actions for logout
  };
 
  const logoStyle = {
    width: '40px',
    height: '40px',
   
  };
  const loginStyle = {
    width:'40px',
    height:'40px',
  };

  return (
    <>
    <AppBar position="fixed" sx={{
        height:'3.5rem',
        background: 'linear-gradient(to right, white, #FFC46A 40%, #F79123)', // Replace with your desired gradient colors
      }}>
      <Toolbar>
      <img src={anvayyalogo} alt="My Logo" style={logoStyle} />
      <Typography variant="h6" sx={{ flexGrow: 12, display: 'flex', gap: '27px' }} >
      <div style={{paddingTop:'18.5px'}}>Insurance</div>
        <div style={{paddingTop:'18.5px'}}>
        <Button
          aria-controls="menu-leads"
          aria-haspopup="true"
          onClick={handleLeadsMenuOpen}
          color="inherit"
          endIcon={<KeyboardArrowDownIcon />}
        >
          Leads
        </Button>
        <Menu
          id="menu-leads"
          anchorEl={leadsMenuAnchor}
          keepMounted
          open={Boolean(leadsMenuAnchor)}
          onClose={handleMenuClose}
        >
          <MenuItem style={{width:'120px',textAlign:'center',display:'flex',flexDirection:'column', fontSize: '11px', fontWeight: 'bold'}} onClick={navigateToOpportunity}>Leads</MenuItem>
          <MenuItem style={{width:'120px',textAlign:'center',display:'flex',flexDirection:'column', fontSize: '11px', fontWeight: 'bold'}} onClick={navigateToProspect}>Prospects</MenuItem>
          <MenuItem style={{width:'120px',textAlign:'center',display:'flex',flexDirection:'column', fontSize: '11px', fontWeight: 'bold'}} onClick={navigateToCustomer}>Customer</MenuItem>
        </Menu>
        </div>
        <div style={{paddingTop:'18.5px'}}>
        <Button
          aria-controls="menu-sales"
          aria-haspopup="true"
          onClick={handleSalesMenuOpen}
          color="inherit"
          endIcon={<KeyboardArrowDownIcon />}
        >
          Sales
        </Button>
        <Menu
           id="menu-sales"
           anchorEl={salesMenuAnchor}
           keepMounted
           open={Boolean(salesMenuAnchor)}
           onClose={handleMenuClose}
        >
          <MenuItem style={{ width: '100px', textAlign: 'center', display: 'flex', flexDirection: 'column', fontSize: '11px', fontWeight: 'bold' }} onClick={navigateToSales}>Sales</MenuItem>
          <MenuItem style={{width:'100px',textAlign:'center',display:'flex',flexDirection:'column',fontSize:'11px',fontWeight: 'bold'}} onClick={navigateToPolicy}>Policies</MenuItem>
          <MenuItem style={{width:'100px',textAlign:'center',display:'flex',flexDirection:'column',fontSize:'11px',fontWeight: 'bold'}} onClick={navigateToInvoice}>Invoice</MenuItem>
         {/* <MenuItem style={{width:'100px',textAlign:'center',display:'flex',flexDirection:'column',fontSize:'11px',fontWeight: 'bold'}} onClick={navigateToSlab}>Commission Rule</MenuItem> */}
         { (<MenuItem style={{ width: '100px', textAlign: 'center', display: 'flex', flexDirection: 'column', fontSize: '11px', fontWeight: 'bold' }} onClick={navigateToSlab}>Commission Rule</MenuItem>
        )}
        </Menu>       
        </div>
        <div style={{paddingTop:'18.5px'}}>
        <Button
          aria-controls="menu-reports"
          aria-haspopup="true"
          onClick={handleReportMenuOpen}
          color="inherit"
          endIcon={<KeyboardArrowDownIcon />}
        >
          Reports
        </Button>
        <Menu
           id="menu-sales"
           anchorEl={reportsMenuAnchor}
           keepMounted
           open={Boolean(reportsMenuAnchor)}
           onClose={handleMenuClose}
        >
        <MenuItem style={{width:'100px',textAlign:'center',display:'flex',flexDirection:'column', fontSize: '11px', fontWeight: 'bold'}} onClick={navigateToReports}>Reports</MenuItem>
        <MenuItem style={{width:'100px',textAlign:'center',display:'flex',flexDirection:'column', fontSize: '11px', fontWeight: 'bold'}} onClick={navigateToDashboard}>Dashboard</MenuItem>
        </Menu>
        </div>
        </Typography>
        <div style={{marginLeft:"34rem",display:'flex',flexDirection:'row'}}> 
        <div style={{display:'flex',flexDirection:'column'}}> 
        <Typography style={{marginTop:'1.5rem',fontSize:'14px'}} variant="h7">{employeeName}</Typography>  
        {/* <span style={{fontSize:'10px',marginLeft:'2rem'}} onClick={handleLogout}>Sign In</span>  */}  
        </div>
        <Button   color="inherit" style={{display:"flex",flexDirection:'column'}}>
          <img onClick={handleLoginMenuOpen} src={login} alt="My Logo" style={loginStyle} />
          </Button>          
          <Menu
          id="menu-leads"
          anchorEl={loginMenuAnchor}
          keepMounted
          open={Boolean(loginMenuAnchor)}
          onClose={handleMenuClose}
          >
          <MenuItem style={{width:'100px',textAlign:'center',display:'flex',flexDirection:'column'}} onClick={handleLogout}>Logout</MenuItem>
        </Menu>
        </div>
      </Toolbar>
    </AppBar>
    <Routes>
     <Route path="*" element={<NotFound />} />
  <Route path="/" element={<Navigate to="/login" />} />
<Route exact path="/login" element={<AvLoginForm />} /> 
<Route exact path="/prospect" element={<AvProspect />} />
<Route exact path="/leadtemplate" element={<LeadTemplate />} />
<Route exact path="/viewOpportunity/:id" element={<ViewOpportunity />} />
<Route exact path="/viewCustomer/:id" element={<ViewCustomer />} />
<Route exact path="/viewprospect/:id" element={<ViewProspect />} />
<Route exact path="/editbeneficiary/:id" element={<AvEditBeneficiary />} />
<Route exact path="/updateprospect/:id" element={<UpdateProspect />} />
<Route exact path="/updatecustomer/:id" element={<UpdateCustomer />} />
<Route exact path="/sales" element={<AvSales />} />
<Route exact path="/AllCustomer" element={<AllCustomer />} />
<Route exact path="/addlead" element={<AvAddLead/>} />
<Route exact path="/addcustomer" element={<AddCustomer/>} />
<Route exact path="/addbeneficiary/:id" element={<AvAddBeneficiary/>} />
<Route exact path="/addreference/" element={<AvAddReferral/>} />
<Route exact path="/customeraddreference/" element={<CustomerAddReference/>} />
<Route exact path="/beneficiary/:id" element={<NewBenificiary/>} />
<Route exact path="/opportunityaddbeneficiary/:id" element={<OpportunityAddBeneficiary/>} />
<Route exact path="/opportunityupdatebeneficiary/:id" element={<OpportunityUpdateBeneficiary/>} />
<Route exact path="/prospectaddbeneficiary/:id" element={<ProspectAddBeneficiary/>} />
<Route exact path="/prospectupdatebeneficiary/:id" element={<ProspectUpdateBeneficiary/>} />
<Route exact path="/customeraddbeneficiary/:id" element={<CustomerAddBeneficiary/>} />
<Route exact path="/opportunity/:id" element={<AvAddOpportunity/>}/>
<Route exact path="/newopportunity/:id" element={<NewOpportunity/>}/>
<Route exact path="/customeropportunity/:id" element={<AvAddCustomeropportunity/>}/>
<Route exact path="/allopportunity/" element={<AvAllOpportunity/>}/>
<Route exact path="/editopportunity/:id" element={<AvEditOppurtunity/>}/>
<Route exact path="/invoice/" element={<Invoice />}/>
<Route exact path="/convertcustomer/:id" element={<ConvertCustomer />}/>
<Route exact path="/addpolicy/:id" element={<AvAddPolicy />}/>
<Route exact path="/allpolicies/" element={<AvAllPolicies />}/>
<Route exact path="/editpolicy/:id" element={<AvEditPolicy />}/>
<Route exact path="/updatestatus/:id" element={<UpdateStatus/>}/>
<Route exact path="/assign/" element={<Assign/>}/>
<Route exact path="/customerassign/" element={<CustomerAssign />}/>
<Route exact path="/opportunityAssign/" element={<OpportunityAssign/>}/>
<Route exact path="/opportunityupdatestatus/:id"element={<OpportunityUpdateStatus/>}/>
<Route exact path="/customerinteraction/:id"element={<UpdateCustomerStatus />}/>
<Route exact path="/reports/" element={<Reports/>}/>
<Route exact path="/dashboard/" element={<Dashboard/>}/>
<Route exact path="/avcommission/" element={<AvCommission/>}/>
<Route exact path="/area/" element={<Area/>}/>
<Route exact path="/slab/" element={<Slab/>}/>
<Route exact path="/area/:id" element={<Area/>}/>
 <Route exact path="/viewcommission" element={<ViewCommission />}/> 
 <Route exact path="/updateslab/:id" element={<UpdateSlab/>}/>
 <Route exact path="/newversioncommission/:id" element={<NewVersionCommission/>}/>
</Routes>
  </>
  );
};

export default Header;
