import React ,{ useState,useEffect  }from 'react';
import { List, ListItemButton, ListItemText, Drawer } from '@mui/material';
import businesspeople  from './../assests/icons/businesspeople.png'
import user from './../assests/icons/user.png'
import checklist from './../assests/icons/checklist.png'
import planning from './../assests/icons/planning.png'
import unassigned from  './../assests/icons/unassigned.png'
import './../App.css'
import { useNavigate } from 'react-router-dom';

// const Sidebar = ({setSelectedOption}) => {
//   const [selected, setSelected] = useState("All"); // Initialize with the default selected option
// const navigate = useNavigate()
const Sidebar = ({ setSelectedOption }) => {
  const [selected, setSelected] = useState(() => {
    // Retrieve the last selected option from local storage
    return localStorage.getItem('selectedOption') || 'All';
  });
  const navigate = useNavigate();

  useEffect(() => {
    // Update local storage when the selected option changes
    localStorage.setItem('selectedOption', selected);
  }, [selected]);
  const handleItemClick = (option) => {
    setSelected(option);
    setSelectedOption(option);
     // Navigate based on the selected option
     if (option === 'All') {
      window.location.href = window.location.href;
    } 
  };

  const getListItemStyle = (option) => {
    return option === selected ? { backgroundColor: 'lightgray' } : {};
  };
return (
  <div>
    <Drawer
      variant="permanent"
      anchor="left"
   width="60%"
    
      sx={{
        display: 'flex',
        flexDirection: 'row',
        position: 'fixed',
        justifyContent: 'flex-end',
        height: '100%',
        padding: 2,
      }}
    >
      <List
        style={{
          marginTop: '5rem',
          width: '8rem',
          height: '100%',
          padding: '13px',
          paddingLeft: '10px',
        }}
      > 
        
        <ListItemButton onClick={() => handleItemClick("All")} style={getListItemStyle("All")}>
          <div className='image-container'>
            <img src={businesspeople} alt="businesspeople" className="icon-image" />
            <ListItemText primary="All" className="list-item-text" />
          </div>
        </ListItemButton>
        <ListItemButton onClick={() => handleItemClick("Assigned to Me")} style={getListItemStyle("Assigned to Me")}>
          <div className='image-container'>
            <img src={checklist} alt="user" className="icon-image" />
            <ListItemText primary="Assigned to Me" className="list-item-text" />
          </div>
        </ListItemButton>
        <ListItemButton onClick={() => handleItemClick("To be Contacted Today")} style={getListItemStyle("To be Contacted Today")}>
          <div className='image-container'>
            <img src={planning} alt="user" className="icon-image" />
            <ListItemText primary="To be Contacted Today " className="list-item-text" />
          </div>
        </ListItemButton>
        <ListItemButton onClick={() => handleItemClick("Unassigned")} style={getListItemStyle("Unassigned")}>
          <div className='image-container'>
            <img src={unassigned} alt="user" className="icon-image" />
            <ListItemText primary="Unassigned" className="list-item-text" />
          </div>
        </ListItemButton>
      </List>
    </Drawer>
  </div>
);
};

export default Sidebar;

