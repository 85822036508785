import React, { useState } from 'react';
import Controls from '../components/controls/Controls';
import { Form } from '../components/useForm';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Rest from  './../services/restapi';
import axios from 'axios';
import { Grid, Snackbar } from '@mui/material';
import moment from 'moment';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import calendar from './../assests/icons/calendar.png';
import LoadingSpinner from '../components/controls/spinner';



const gender = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Others', label: 'Others' },
  { value: 'Not Specified', label: 'Not Specified' },
]
const communicationpreference = [
 { value: 'Call', label: 'Call' },
 { value: 'SMS', label: 'SMS' },
 { value: 'Email', label: 'Email' },
 { value: 'whatsapp', label: 'Whatsapp' },
];

const beneficiaryrelation = [
  { value: 'Spouse', label: 'Spouse' },
  { value: 'Children', label: 'Children' },
  { value: 'Others', label: 'Others' },
];
const addresstype = [
   { value: 'Home', label: 'Home' },
   { value: 'Office', label: 'Office' },
   { value: 'Other', label: 'Other' }
 ];

const ProspectAddBeneficiary = () => {
    const [beneficiaryValues, setBeneficiaryValues] = useState(
              {
              customerid:'',
               prospectid:"",
               firstname:"",
               lastname:"",
               emailid:"",
               phonenumber:"",
               beneficiaryrelation:"",
               alternateemails:[""],
               gender:"",
               dateofbirth:null,
               alternatephonenumbers:[""],
               communicationpreference:"Call",
               pannumber:"",
               aadharnumber:"",
               addressdetails:[{
                addresstype:"Home",
                addressline1:"",
                addressline2:'',
                addressline3:'',
                city:'',
                district:'',
                state:'',
                pincode:'',
                country:'India',
               }]
              },
        );
        const[selectedDate,seSelectedDate] = useState()
        const token = localStorage.getItem("X-fiftyaccess-Token");
        const location = useLocation();
        const prospect = location.state.customer
        const prospectid=prospect.prospectid
        const customerid = location.state.customer.customerid
        const navigate = useNavigate();
        const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
        const [firstNameError, setFirstNameError] = useState('');
        const [lastNameError, setLastNameError] = useState('');
        const [panNumberError, setPanNumberError] = useState('');
        const [phoneNumberError, setPhoneNumberError] = useState('');
        const[aadharNumberError,setAadharNumberError]= useState('');
        const [isLoading, setIsLoading] = useState(false);


        const handleInputChange = (event) => {
          const { name, value } = event.target;
          setBeneficiaryValues({
            ...beneficiaryValues,prospectid:prospectid,customerid:customerid,
            [name]: value,
          });
        };
      
        const isAlphabetic = (value) => /^[A-Za-z]+$/.test(value);



const handleFirstNameChange = (event) => {
  const { value } = event.target;
  // Remove any non-alphabetic characters (e.g., numbers) from the input
  const alphabeticValue = value.replace(/[^A-Za-z]/g, '');
  
  // Check if the resulting value is different from the original input
  if (value !== alphabeticValue) {
    setFirstNameError('Invalid first name');
  } else {
    setFirstNameError('');
  }

  setBeneficiaryValues({
    ...beneficiaryValues,
    prospectid: prospectid,
    customerid: customerid,
    firstname: alphabeticValue,
  });
};


const handleLastNameChange = (event) => {
  const { value } = event.target;
  // Remove any non-alphabetic characters (e.g., numbers) from the input
  const alphabeticValue = value.replace(/[^A-Za-z]/g, '');
  
  // Check if the resulting value is different from the original input
  if (value !== alphabeticValue) {
    setLastNameError('Invalid last name');
  } else {
    setLastNameError('');
  }

  setBeneficiaryValues({
    ...beneficiaryValues,
    prospectid: prospectid,
    customerid: customerid,
    lastname: alphabeticValue,
  });
};

        const handlePanNumberChange = (event) => {
          const value = event.target.value.toUpperCase(); // Convert to uppercase
          // Allow only uppercase letters and numbers, limit to 10 characters
          const formattedValue = value.replace(/[^A-Z0-9]/g, '').substring(0, 10);
          setBeneficiaryValues({
            ...beneficiaryValues,
            pannumber: formattedValue,
          });
        
          // Check the length of the formatted PAN number
          if (formattedValue.length < 10) {
            setPanNumberError('Please enter valid Pan number');
          } else {
            setPanNumberError(''); // Reset the error message if length is valid
          }
        };



        const handlePhoneNumberChange = (event) => {
          const value = event.target.value;
          // Allow only digits, limit to 10 digits
          const formattedValue = value.replace(/\D/g, '').substring(0, 10);
          setBeneficiaryValues({
            ...beneficiaryValues,
            phonenumber: formattedValue,
          });
        
          // Check the length of the formatted phone number
          if (formattedValue.length < 10) {
            setPhoneNumberError('please enter valid phone number');
          } else {
            setPhoneNumberError(''); // Reset the error message if the length is valid
          }
        };

        const handleAlternatePhoneNumberChange = (event) => {
          const value = event.target.value;
          setBeneficiaryValues({
            ...beneficiaryValues,
            alternatephonenumbers: value,
          });
        };

        const handleAadharNumberChange = (event) => {
          const value = event.target.value;
          // Allow only digits, limit to 12 digits
          const formattedValue = value.replace(/\D/g, '').substring(0, 12);
          setBeneficiaryValues({
            ...beneficiaryValues,
            aadharnumber: formattedValue,
          });
        
          // Check the length of the formatted Aadhar number
          if (formattedValue.length < 12) {
            setAadharNumberError('Aadhar number must be 12 digits long');
          } else {
            setAadharNumberError(''); // Reset the error message if the length is valid
          }
        };
        const handleAddressChange = (index, event) => {
          const { value } = event.target;
          const updatedAddresses = [...beneficiaryValues.addressdetails];
          updatedAddresses[index] = {
            ...updatedAddresses[index],
            addresstype: value,
          };
          setBeneficiaryValues({
            ...beneficiaryValues,
            addressdetails: updatedAddresses,
          });
        };
        const handleAddressInputChange = (index, event) => {
          const { name, value } = event.target;
          const updatedAddresses = [...beneficiaryValues.addressdetails];
          updatedAddresses[index] = {
            ...updatedAddresses[index],
            [name]: value,
          };
          setBeneficiaryValues({
            ...beneficiaryValues,
            addressdetails: updatedAddresses,
          });
        };

        const handleSelectChange = (fieldName, event) => {
          const { value } = event.target;
          setBeneficiaryValues({
            ...beneficiaryValues,
            [fieldName]: value,
          });
        };

        const handleDateChange = (newDate) => {
          const FormatDateofBirth = moment(newDate).format('DD-MM-YYYY')
          seSelectedDate(FormatDateofBirth)
          setBeneficiaryValues({
            ...beneficiaryValues,
            dateofbirth: FormatDateofBirth,
          });
        };

        const handleCancel = () => {
          // Use the navigate function to go back to the previous page
          navigate(-1); // This will go back one step in the navigation history
        };

        const handleSnackbarClose = () => {
          setIsSuccessSnackbarOpen(false);
        };

        const handleSubmit = async (event) => {
          event.preventDefault();
          try {
            
      setIsLoading(true);
            const response = await axios.post(Rest.addbeneficiary,beneficiaryValues,{
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            });
            console.log("response",response)
            if(response?.data?.code==='S001'){
            setIsSuccessSnackbarOpen(true); // Open success snackbar
            // Redirect after a short delay (optional)
            setTimeout(() => {
              navigate(-1);
            }, 2000); 
        } else{
          alert(response?.data?.msg || response?.data?.data)
        }
          } catch (error) {
            alert("Error: " + error.message);
            console.error('API Error:', error);
            // Handle error appropriately
          }
          finally {
            setIsLoading(true); // Set loading to false after the API request is completed (success or failure)
            setTimeout(() => {
              setIsLoading(false);
            }, 200);
          }
        };
        console.log("beneficiaryValues",beneficiaryValues)

  return (
    <div style={{marginTop:'6rem',paddingLeft:'10px'}}>
      <Form onSubmit={handleSubmit}>
        <h5>Beneficiary Information</h5>
        <Grid container>
          <Grid item xs={4}>
          <Controls.Dropdown
            label="Relation"
            options={beneficiaryrelation}
            value={beneficiaryValues.beneficiaryrelation}
            onChange={(event) => handleSelectChange('beneficiaryrelation', event)}
          />
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
    label="First Name"
    name="firstname"
    value={beneficiaryValues.firstname}
    onChange={handleFirstNameChange}
  />
  {firstNameError && (
    <div style={{ color: 'red', fontSize: '12px' }}>{firstNameError}</div>
  )}
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
    label="Last Name"
    name="lastname"
    value={beneficiaryValues.lastname}
    onChange={handleLastNameChange}
  />
  {lastNameError && (
    <div style={{ color: 'red', fontSize: '12px' }}>{lastNameError}</div>
  )}
</Grid> 
          <Grid item xs={4}>
        <Controls.Input
            label="Email ID"
            name="emailid"
            value={beneficiaryValues.emailid}
            onChange={handleInputChange}
        />
          </Grid>
          <Grid item xs={4}>
  <Controls.Input
    label="Phone Number"
    name="phonenumber"
    value={beneficiaryValues.phonenumber}
    onChange={handlePhoneNumberChange}
  />
  {phoneNumberError && (
    <div style={{ color: 'red', fontSize: '12px' }}>{phoneNumberError}</div>
  )}
</Grid>
          
          <Grid item xs={4}>
          <Controls.Dropdown
            label="Gender"
            options={gender}
            value={beneficiaryValues.gender}
            onChange={(event) => handleSelectChange('gender', event)}
          />
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
            label="Alternate Email ID"
            name="alternateemails"
            value={beneficiaryValues.alternateemails}
            onChange={handleInputChange}
          />
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
            label="Alternate Phonenumber"
            name="alternatephonenumbers"
            value={beneficiaryValues.alternatephonenumbers}
            onChange={handleAlternatePhoneNumberChange}
          />
          </Grid>
          <Grid item xs={4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DatePicker',
          'DateTimePicker',
          'TimePicker',
          'DateRangePicker',
        ]}
      >
        <DemoItem>
          <DatePicker
            label="Date Of Birth" 
            defaultValue={beneficiaryValues.dateofbirth}
            disableFuture
            onChange={e=>{
                  console.log("@@@@",e)
            handleDateChange(e.toDate())}}
            format="DD-MM-YYYY"
            sx={{
              backgroundColor: 'rgb(234, 237, 240,1)',
              borderRadius: '5px',
              '& .MuiInputLabel-root': {
                fontSize: '12px',
              },
              '& .MuiSvgIcon-root': {
                width: '24px',
                height: '24px',
                background: `url(${calendar})`,
                backgroundSize: 'contain',
                display: 'block',
              },
              '& input': {
                width: '30',
                height: '30',
                padding: '12px',
                fontSize: '12px',
                alignContent: 'center',
              },
              '& .MuiInput-underline': {
                '&:before': {
                  borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color
                },
                '&:hover:not(.Mui-disabled):before': {
                  borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color
                },
              },
            }}
            slotProps={{ textField: { size: 'small' } }}
          />
        </DemoItem>
      </DemoContainer>
      </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
          <Controls.Dropdown
            label="Communication Preference"
            options={communicationpreference}
            value={beneficiaryValues.communicationpreference}
            onChange={(event) => handleSelectChange('communicationpreference', event)}
          />
          </Grid>
          <Grid item xs={4}>
  <Controls.Input
    label="PAN Number"
    name="pannumber"
    value={beneficiaryValues.pannumber}
    onChange={handlePanNumberChange}
  />
  {panNumberError && (
    <div style={{ color: 'red', fontSize: '12px' }}>{panNumberError}</div>
  )}
</Grid>
          
<Grid item xs={4}>
  <Controls.Input
    label="Aadhar Number"
    name="aadharnumber"
    value={beneficiaryValues.aadharnumber}
    onChange={handleAadharNumberChange}
  />
  {aadharNumberError && (
    <div style={{ color: 'red', fontSize: '12px' }}>{aadharNumberError}</div>
  )}
</Grid>
        </Grid>
      {beneficiaryValues?.addressdetails?.map((address, index) => (
        <>
        <h5>Address</h5>
        <Grid container>
          
          <Grid item xs={4} key={index}>
          <Controls.Dropdown
            label="Address Type"
            options={addresstype}
            value={address.addresstype}
            onChange={(event) => handleAddressChange(index, event)}
          />
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
            label="Address Line1"
            name="addressline1"
            value={address.addressline1}
            onChange={(event) => handleAddressInputChange(index, event)}
          />
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
            label="Address Line2"
            name="addressline2"
            value={address.addressline2}
            onChange={(event) => handleAddressInputChange(index, event)}
          />
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
            label="Address Line3"
            name="addressline3"
            value={address.addressline3}
            onChange={(event) => handleAddressInputChange(index, event)}
          />
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
            label="City"
            name="city"
            value={address.city}
            onChange={(event) => handleAddressInputChange(index, event)}
          />
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
            label="District"
            name="district"
            value={address.district}
            onChange={(event) => handleAddressInputChange(index, event)}
          />
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
            label="State"
            name="state"
            value={address.state}
            onChange={(event) => handleAddressInputChange(index, event)}
          />
          </Grid>
         
          <Grid item xs={4}>
          <Controls.Input
            label="Pincode"
            name="pincode"
            value={address.pincode}
            onChange={(event) => handleAddressInputChange(index, event)}
          />
          </Grid>
          <Grid item xs={4}>
          <Controls.Input
            label="Country"
            name="country"
            value={address.country}
            onChange={(event) => handleAddressInputChange(index, event)}
          />
          </Grid>
        </Grid>
        </>
      ))}
      <Snackbar
        sx={{marginLeft:'30rem'}}
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
        onClose={handleSnackbarClose}
        message="Data successfully added"
      />
      <div style={{marginLeft:'1.5rem',marginLeft:"63.5rem"}}>
      <Controls.Button text="save" type="submit" />
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
         
        <Controls.Button text="Cancel" onClick={handleCancel}  />
      </div>
      </Form>
    </div>
  );
};

export default ProspectAddBeneficiary;
