import React, { useEffect, useRef, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { Box, Grid, TextField, Tooltip, Typography } from '@mui/material';
import Controls from '../components/controls/Controls';
import axios from 'axios';
import '../App.css'
import Sidebar from '../components/Sidebar';
import edit from "../assests/icons/edit.png";
import update from "../assests/icons/update.png";
import opportunityicon from "../assests/icons/opportunity.png";
import showdetails from "../assests/icons/showdetails.png";
import { useNavigate } from 'react-router-dom';
import { Form } from '../components/useForm';
import * as Rest from './../services/restapi';
import Dropdown from 'rsuite/Dropdown';
import 'rsuite/dist/rsuite.min.css';
import LoadingSpinner from '../components/controls/spinner'; // Import your LoadingSpinner component
import { Link } from '@material-ui/core';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from "@material-ui/core";
import { styled, useTheme } from "@mui/material/styles";

import { FormControlLabel } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { DataGridPremium, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';
import { useDemoData } from '@mui/x-data-grid-generator';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import FilterListIcon from '@mui/icons-material/FilterList';
import Pagination from '@mui/material/Pagination';

// import LoadingSpinner from './LoadingSpinner';
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start"
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'rgb(234, 237, 240,1)',
    borderRadius: '5px',
  },
  menuList: {
    backgroundColor: 'rgb(234, 237, 240,1)',
  },
  menuItem: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align all options to the left
    marginLeft: '10px',
  },
  sidebar: {
    padding: '10px',
    marginLeft: '10px',
    width: '200px',
  },
}));

const AvAllOpportunity = () => {
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [opportunityStatusOptions, setOpportunityStatusOptions] = useState([]);
  const [loading, setLoading] = useState(true); // Initialize loading state

  const [opportunities, setOpportunities] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [salesperson, setSalesPerson] = useState('');
  const [selectedOption, setSelectedOption] = useState("All");
  // const [selectedEmployee, setSelectedEmployee] = useState(''); // Store the selected employee
  const [selectedOpportunityStatus, setSelectedOpportunitySatus] = useState(''); // Store the selected status
  const [searchQuery, setSearchQuery] = useState("");
  const rowHeight = 35;

  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const employeeIDs = localStorage.getItem("EmployeeID");
  const employeeName = localStorage.getItem("EmployeeName");
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const hasFetchedAll = useRef(false);
  const isInitialMount = useRef(true);
  const [checked, setChecked] = React.useState(["", "", ""]);
  const drawerWidth = 190;
  const [selectedFilters, setSelectedFilters] = useState([]);
  const classes = useStyles();
  const [employeeID, setEmployeeID] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [perPage, setPerPage] = useState(limit);
  const selectedRowCount = Object.values(selectedRows).filter((value) => value).length;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalpages] = useState('')
  const [TotalRecords, setTotalRecords] = useState('')
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
    // You may perform data fetching or any action on page change here
  };

  const handlePerPageChange = (value) => {
    setPerPage(value); // Update perPage state when dropdown value changes
    // You can perform additional actions here based on the selected value
  };

  React.useEffect(() => {
    if (!employeeName || !token) {
      navigate('/login');
    }
  }, [employeeName, token, navigate]);


  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'side' })(
    ({ theme, side }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      ...(side && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
      position: 'relative',
    }),
  );

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'side',
  })(({ theme, side }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(side && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));


  const theme = useTheme();
  const [side, setOpen] = useState(false); // Initialize the sidebar state


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {

    setOpen(false);
  };

  const [opportunityChecked, setOpportunityChecked] = React.useState({
    opportunitystatus: [],
  });

  const [opportunitystatusLabels, setOpportunityStatusLabels] = useState([]);
  const [salespersonChecked, setSalespersonChecked] = React.useState({
    salesperson: [],
  });
  const [salespersonLabels, setSalespersonLabels] = React.useState([]);
  useEffect(() => {
    async function fetchOpportunityStatusOptions() {
      try {
        const response = await axios.get(Rest.opportunitystatusapi, {
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        });
        setOpportunityStatusLabels(response?.data?.opportunitystatus);
      } catch (error) {
        console.error('Error fetching Lead status options:', error);
      }
    }
    fetchOpportunityStatusOptions();
  }, []);
  const handleOpportunityChange = (event, parent) => {
    let selectedValues = [];

    if (parent === 'opportunitystatus') {
      selectedValues = event.target.checked ? [...opportunitystatusLabels] : [];
    } else {
      selectedValues = event.target.checked ? [parent] : [];
    }

    setOpportunityChecked({
      ...opportunityChecked,
      [parent]: selectedValues
    });
  };
  const handleOpportunityChildChange = (event, parent, childIndex) => {
    const newSelectedValues = [...opportunityChecked[parent]];

    if (event.target.checked) {
      newSelectedValues.push(opportunitystatusLabels[childIndex]);
    } else {
      const indexToRemove = newSelectedValues.indexOf(opportunitystatusLabels[childIndex]);
      if (indexToRemove !== -1) {
        newSelectedValues.splice(indexToRemove, 1);
      }
    }
    setOpportunityChecked({
      ...opportunityChecked,
      [parent]: newSelectedValues
    });
  };

  const handleSalespersonChange = (event, parent) => {
    let selectedValues = [];

    if (parent === 'salesperson') {
      selectedValues = event.target.checked ? [...salespersonLabels] : [];
    } else {
      selectedValues = event.target.checked ? [parent] : [];
    }

    setSalespersonChecked({
      ...salespersonChecked,
      [parent]: selectedValues
    });
  };

  const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];

  const handleDateRangeChange = (date) => {
    if (Array.isArray(date) && date.length === 2) {
      const stateDate = moment(date[0]).format("DD-MM-YYYY");
      const endDate = moment(date[1]).format("DD-MM-YYYY");
      setStartDate(stateDate);
      setEndDate(endDate);
    }
  };

  const handleSalespersonChildChange = (event, parent, childIndex) => {
    const newSelectedValues = [...salespersonChecked[parent]];

    if (event.target.checked) {
      newSelectedValues.push(salespersonLabels[childIndex]);
    } else {
      const indexToRemove = newSelectedValues.indexOf(salespersonLabels[childIndex]);
      if (indexToRemove !== -1) {
        newSelectedValues.splice(indexToRemove, 1);
      }
    }
    setSalespersonChecked({
      ...salespersonChecked,
      [parent]: newSelectedValues
    });
  };
  useEffect(() => {
    axios.get(Rest.viewallemployees, {
      headers: {
        "X-fiftyaccess-Token": token
      }
    })

      .then((response) => {
        const options = response.data.data.map((employee) => ({
          value: employee.EmployeeID,
          label: `${employee.FirstName} ${employee.LastName}`,
        }));
        const EmpolyeeName = response.data.data.map((employee) => (`${employee.FirstName} ${employee.LastName}`));
        setEmployeeOptions(options);
        setSalespersonLabels(EmpolyeeName)
      })
      .catch((error) => console.error('Error fetching employee data:', error));
  }, []);

  const [expanded, setExpanded] = useState([]);
  const renderOpportunityCheckbox = (label, parent) => {
    return (
      <TreeItem
        nodeId={parent}
        label={
          <FormControlLabel
            key={`parent-${parent}`}
            label={label}
            control={
              <Checkbox
                checked={opportunityChecked[parent].length > 0}
                indeterminate={false}
                onChange={(event) => handleOpportunityChange(event, parent)}
              />
            }
          />
        }
      >
        {renderOpportunityChildCheckboxes(parent)}
      </TreeItem>
    );
  };

  const renderOpportunityChildCheckboxes = (parent) => {
    return opportunitystatusLabels.map((childLabel, index) => (
      <TreeItem
        key={`child-${index}`}
        nodeId={`${parent}-${index}`}
        label={childLabel}
        sx={{ marginLeft: '1rem' }}
        icon={
          <Checkbox
            checked={opportunityChecked[parent].includes(childLabel)}
            onChange={(event) => handleOpportunityChildChange(event, parent, index)}
          />
        }
      />
    ));
  };
  const renderSalespersonCheckbox = (label, parent) => {
    return (
      <TreeItem
        nodeId={parent}
        label={
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                checked={salespersonChecked[parent].length > 0}
                onChange={(event) => handleSalespersonChange(event, parent)}
              />
            }
          />
        }
      >
        {renderSalespersonChildCheckboxes(parent)}
      </TreeItem>
    );
  };

  const renderSalespersonChildCheckboxes = (parent) => {
    return salespersonLabels.map((childLabel, index) => (
      <TreeItem
        key={`child-${index}`}
        nodeId={`${parent}-${index}`}
        label={childLabel}
        sx={{ marginLeft: '1rem' }}
        icon={
          <Checkbox
            checked={salespersonChecked[parent].includes(childLabel)}
            onChange={(event) => handleSalespersonChildChange(event, parent, index)}
          />
        }
      />
    ));
  };

  const handleFilterChange = (event) => {
    setSelectedFilters(event.target.value);
  };

  const renderSidebar = (label, parent) => {
    return (
      <div className={classes.sidebar}>


        <div>
          <h6>Filter By</h6>
          <FormControl component="fieldset">
            <TreeView
              aria-label="Leadstatus Checkbox"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              onNodeToggle={(event, nodes) => setExpanded(nodes)}
            >
              {renderOpportunityCheckbox('Leadstatus', 'opportunitystatus')}
            </TreeView>
            <TreeView
              aria-label="Salesperson Checkbox"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              expanded={expanded}
              onNodeToggle={(event, nodes) => setExpanded(nodes)}
            >
              {renderSalespersonCheckbox('Sales Person', 'salesperson')}
            </TreeView>
          </FormControl>
        </div>


      </div>
    );
  };

  // const [areAnyCheckboxesSelected, setAreAnyCheckboxesSelected] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

  const handleStatusFilter = (selectedStatus) => {
    setSelectedOpportunitySatus(selectedStatus);
    setSelectedEmployee('');
  };

  const handleEmployeeFilter = (selectedEmployeeId) => {
    setSelectedEmployee(selectedEmployeeId);
    setSelectedOpportunitySatus('');
  };

  useEffect(() => {
    // API endpoint and request body
    const requestBody = {
      phonenumber: parseInt(searchQuery),
      startDate: startDate,
      endDate: endDate
    };

    // Making the API call using axios
    axios.post(Rest.leadDetailsWIthNumber, requestBody)
      .then(response => {
        const formattedData = response?.data?.data.map((item, index) => ({
          ...item,
          id: index + 1 // Generating an id (you can adjust this according to your data structure)
        }));

        setFilteredData(formattedData);
        console.log('API Response:', formattedData);
      })
      .catch(error => {
        // Handle errors, e.g., show an error message
        console.error('Error fetching data:', error);
      });
  }, [searchQuery, opportunities]);

  // Update filtered data based on search query
  useEffect(() => {
    const filteredResults = opportunities.filter(
      opportunity =>
        opportunity?.opportunityid?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        opportunity?.firstname?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        /*  opportunity?.phonenumber?.toString().includes(searchQuery?.toString()) || */
        opportunity?.product?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        opportunity?.subproduct?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        opportunity?.interestedpolicytype?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
        opportunity?.opportunitystatus?.toLowerCase().includes(searchQuery?.toLowerCase()) //
    );
    setFilteredData(filteredResults);
  }, [searchQuery, opportunities]);


  useEffect(() => {
    if (selectedEmployee) {
      // Filter data by selected employee
      const filteredResults = opportunities.filter((opportunity) => opportunity.EmployeeID === selectedEmployee);
      setFilteredData(filteredResults);

    } else if (selectedOpportunityStatus) {
      // Filter data by selected prospect status
      const filteredResults = opportunities.filter((opportunity) => opportunity.opportunitystatus === selectedOpportunityStatus);
      setFilteredData(filteredResults);

    } else {
      // If no filter is selected, show all data
      setFilteredData(opportunities);
    }
  }, [selectedEmployee, selectedOpportunityStatus, opportunities]);


  const handleSalesPersonChange = (selectedEmployeeID) => {
    setEmployeeID(selectedEmployeeID);

    // Find the selected employee object from employeeOptions
    const selectedEmployeeObject = employeeOptions.find(option => option.value === selectedEmployeeID);
    setSelectedEmployee(selectedEmployeeObject);
  };

  useEffect(() => {
    if (isInitialMount.current) {
      axios
        .get(Rest.viewallemployees,
          {
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          }
        )
        .then((response) => {
          const options = response?.data?.data?.map((employee) => ({
            value: employee.EmployeeID,
            label: `${employee.FirstName} ${employee.LastName}`,
          }));
          setEmployeeOptions(options);
        })
        .catch((error) => console.error('Error fetching employee data:', error));


      async function fetchOpportunityStatusOptions() {
        try {
          const response = await axios.get(Rest.opportunitystatusapi,
            {
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            }
          );
          if (response.status === 200) {
            setOpportunityStatusOptions(response?.data?.opportunitystatus);
          }
        } catch (error) {
          console.error('Error fetching Lead status options:', error);
        }
      }
      fetchOpportunityStatusOptions();
      isInitialMount.current = false;
    }
  }, []);
  const selectedEmployeeNames = salespersonChecked.salesperson;
  const selectedEmployeeIDs = selectedEmployeeNames.map((selectedName) => {
    const selectedEmployee = employeeOptions.find((option) => option.label.trim() === selectedName.trim());
    return selectedEmployee ? selectedEmployee.value : null;
  });
  useEffect(() => {
    if (selectedOption === "All") {
      const fetchAll = async () => {
        setLoading(true);
        const selectedValues = {
          opportunitystatus: opportunityChecked.opportunitystatus,
          salesperson: selectedEmployeeIDs,
          limit: perPage,
          page: currentPage,
          // salesperson:salespersonChecked.salesperson,

        }
        console.log("selectedValues", selectedValues)
        try {
          const response = await axios.post(Rest.viewAllOpportunities, selectedValues,
            {
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            }
          );
          if (response?.data?.code === "S001") {
            const opportunitiesWithIds = response?.data?.data?.data?.map((opportunity, index) => ({
              ...opportunity,
              employeedetails: opportunity.employeedetailData,
              id: index + 1,// Using opportunityId as the id
            }));
            setTotalpages(response?.data?.data?.pages);
            setTotalRecords(response?.data?.data?.TotalRecords)
            setLimit(response?.data?.data?.next?.limit)
            setOpportunities(opportunitiesWithIds); // Assuming the API response is an array of policies
            setFilteredData(opportunitiesWithIds);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          } else {
            setFilteredData([])
            // alert(response?.data?.msg  || response?.data?.data)
            setLoading(false);
          }
          // Data has loaded, stop the spinner
        } catch (err) {
          setFilteredData([])
          // alert("Error: " + err.message);
          setLoading(false);
        }
      };
      fetchAll();

      /* hasFetchedAll.current = true; */
    } else if (selectedOption === "Assigned to Me") {
      const fetchAssignedtome = async () => {
        setLoading(true);
        const selectedValues = {
          EmployeeID: employeeIDs,
          opportunitystatus: opportunityChecked.opportunitystatus,
          salesperson: selectedEmployeeIDs,
          limit: perPage,
          page: currentPage,
        }
        try {
          const response = await axios
            .post(Rest.opportunityviewassignedtome, selectedValues, {
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            });
          if (response?.data?.code === "S001") {
            // Assuming the API response contains an array of data
            const formattedData = response?.data?.data?.data?.map((opportunity, index) => ({
              ...opportunity,
              employeedetails: opportunity.employeedetailData,
              id: index + 1, // Generating a simple unique ID for each row
            }));
            setTotalpages(response?.data?.data?.pages);
            setTotalRecords(response?.data?.data?.TotalRecords)
            setLimit(response?.data?.data?.next?.limit)
            setOpportunities(formattedData);
            setFilteredData(formattedData);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          } else {
            setFilteredData([])
            // alert(response?.data?.msg  || response?.data?.data)
            setLoading(false);
          }
        } catch (err) {
          setFilteredData([])
          // alert("Error: " + err.message);
          setLoading(false); // Even if there's an error, stop the spinner
        }
      };
      fetchAssignedtome();

    } else if (selectedOption === "To be Contacted Today") {
      const fetchtobecontacted = async () => {
        setLoading(true);
        const selectedValues = {
          EmployeeID: employeeIDs,
          opportunitystatus: opportunityChecked.opportunitystatus,
          salesperson: selectedEmployeeIDs,
          limit: perPage,
          page: currentPage,
        }
        try {
          const response = await axios.post(Rest.opportunitytoBeContactToday, selectedValues, {
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          }
          );
          if (response?.data?.code === "S001") {
            const opportunitiesWithIds = response?.data?.data?.data?.map((opportunity, index) => ({
              ...opportunity,
              employeedetails: opportunity.employeedetailData,
              id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
            }));
            setTotalpages(response?.data?.data?.pages);
            setTotalRecords(response?.data?.data?.TotalRecords)
            setLimit(response?.data?.data?.next?.limit)
            setOpportunities(opportunitiesWithIds);
            setFilteredData(opportunitiesWithIds);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          } else {
            setFilteredData([])
            // alert(response?.data?.msg  || response?.data?.data)
            setLoading(false);
          }
        } catch (err) {
          setFilteredData([])
          // alert("Error: " + err.message);
          setLoading(false); // Even if there's an error, stop the spinner
        }
      };
      fetchtobecontacted();
    } else if (selectedOption === "Unassigned") {
      const fetchUnassigned = async () => {
        setLoading(true);
        const selectedValues = {
          EmployeeID: employeeIDs,
          opportunitystatus: opportunityChecked.opportunitystatus,
          salesperson: selectedEmployeeIDs,
          limit: perPage,
          page: currentPage,
        }
        try {
          const response = await axios.post(Rest.unAssignedOpportunities, selectedValues,
            {
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            }
          );
          if (response?.data?.code === "S001") {
            const prospectsWithIds = response?.data?.data?.data?.map((prospect, index) => ({
              ...prospect,
              id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
            }));
            setTotalpages(response?.data?.data?.pages);
            setTotalRecords(response?.data?.data?.TotalRecords)
            setLimit(response?.data?.data?.next?.limit)
            setOpportunities(prospectsWithIds);
            setFilteredData(prospectsWithIds);
            setTimeout(() => {
              setLoading(false); // Data has loaded, stop the spinner
            }, 1000);
          } else {
            setFilteredData([])
            // alert(response?.data?.msg  || response?.data?.data)
            setLoading(false);
          }
        } catch (err) {
          setFilteredData([])
          // alert("Error: " + err.message);
          setLoading(false); // Even if there's an error, stop the spinner
        }
      };

      fetchUnassigned();
    }
  }, [selectedOption, opportunityChecked, salespersonChecked, perPage, currentPage]);

  const handleRowSelect = (opportunityid) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [opportunityid]: !prevSelectedRows[opportunityid],
    }));

    setSelectedCheckboxes((prevSelectedCheckboxes) => ({
      ...prevSelectedCheckboxes,
      [opportunityid]: !prevSelectedCheckboxes[opportunityid],
    }));
  };


  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = {};

    if (isChecked) {
      filteredData.slice(0, limit).forEach((opportunity) => {
        updatedSelectedRows[opportunity.opportunityid] = true;
      });
    }

    setSelectedRows(updatedSelectedRows);
  };

  const handleAssignClick = () => {
    const selectedOpportunities = filteredData
      .filter((opportunity) => selectedRows[opportunity.opportunityid])
      .map((opportunity) => ({
        ...opportunity,
        AssignName: salesperson,
      }));

    navigate('/opportunityAssign', { state: { selectedOpportunities, salesperson } });
  };
  const handleAssignClickone = () => {

    console.log("clicking")

    navigate('/opportunityAssign');
  };





  const handleClick = () => {
    // Navigate to the desired route when the button is clicked
    navigate('/leadtemplate');
  };

  const columns = [
    {
      field: 'checkbox',
      width: '60',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerClassName: 'custom-header',
      headerCheckboxProps: {
        onChange: handleSelectAll,
      },
      renderHeader: (params) => (
        <div>
          <input
            type="checkbox"
            checked={Object.keys(selectedRows).length === filteredData.length}
            onChange={handleSelectAll}
          />
        </div>
      ),
      renderCell: (params) => (
        <div>
          <input
            type="checkbox"
            checked={selectedRows[params.row.opportunityid] || false}
            onChange={() => handleRowSelect(params.row.opportunityid)}
          />
        </div>
      ),
    },
    { field: 'opportunityid', headerName: 'Lead ID', width: 130, headerClassName: 'custom-header' },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
      headerClassName: 'custom-header',
      valueGetter: (params) => `${params.row.firstname} ${params.row.lastname}`,
    },
    {
      field: 'phonenumber',
      headerName: 'Phone Number',
      width: 120,
      headerClassName: 'custom-header',
      valueGetter: (params) => `${params.row.phonenumber}`,
    },
    /* {
      field: 'beneficiarydetails',
      headerName: 'Beneficiary Name',
      width:150,
      headerClassName:'custom-header',
      valueGetter: (params) => {
        const beneficiaries = params.row.beneficiarydetails.map(
          (beneficiary) => `${beneficiary.firstname} ${beneficiary.lastname}`
        );
        return beneficiaries.join(', ');
      },
    }, */
    /* {
      field: 'referedperson',
      headerName: 'Referred Person',
      width:150,
      headerClassName:'custom-header',
      valueGetter: (params) => {
        const referredPeople = params.row.referedperson.map(
          (person) => `${person.firstname} ${person.lastname}`
        );
        return referredPeople.join(', ');
      },
    }, */
    {
      field: 'product', headerName: 'Product', width: 90, headerClassName: 'custom-header'
    },
    { field: 'subproduct', headerName: 'Subproduct', width: 120, headerClassName: 'custom-header' },
    /* { field: 'interestedpolicytype', headerName: 'Policy Type', width: 135, headerClassName: 'custom-header' }, */
    /* {
      field: 'contacteddate',
      headerName: 'Contacted Date',
      width:135,
      headerClassName:'custom-header',
        valueFormatter: (params) =>
          format(new Date(params.value * 1000), 'dd/MM/yyyy'), // Format the date value
    },
    {
      field: 'nextcontactdateandtime',
      headerName: 'Next Contact Date',
      width:150,
      headerClassName:'custom-header',
      valueFormatter: (params) =>
          format(new Date(params.value * 1000), 'dd/MM/yyyy'), // Format the date value
    }, */
    { field: 'opportunitystatus', headerName: 'Lead Status', width: 150, headerClassName: 'custom-header' },
    {
      field: 'salespersonname', headerName: 'Sales Person Name', width: 150, headerClassName: 'custom-header',
      renderCell: (params) => {
        const { employeefirstname, employeelastname } = params.row;
        return (
          <div>
            {employeefirstname} {employeelastname}
          </div>
        );
      },
    },

    /*     { field: 'probability', headerName: 'Probability', width:100,headerClassName:'custom-header', },
        { field: 'businesstype', headerName: 'Business Type', width:120,headerClassName:'custom-header', }, */
    {
      field: 'actions', // Custom actions column
      headerName: 'Actions',
      headerClassName: 'custom-header',
      width: 180,
      renderCell: (params) => (
        <div>
          <div className="action-buttons">
            <Tooltip title="Edit">
              <div onClick={() => handleEdit(params.row)}>
                <img src={edit} alt="Edit" className="action-button-icon" />
              </div>
            </Tooltip>
            <Tooltip title="Show Details">
              <div onClick={() => handleShowDetails(params.row)} text="Show Details">
                <img
                  src={showdetails}
                  alt="Show Details"
                  className="action-button-icon"
                />
              </div>
            </Tooltip>
            <Tooltip title="Update Status">
              <div onClick={() => handleUpdateStatus(params.row)}>
                <img src={update} alt="Update" className="action-button-icon" />
              </div>
            </Tooltip>
          </div>
        </div>
      ),
    },
    { headerClassName: 'custom-header', }

  ];

  const handleEdit = (row) => {
    const opportunityid = row.opportunityid
    // Make an API call using Axios
    axios.get(Rest.viewOpportunity, {
      params: {
        opportunityid: opportunityid
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    }
    )
      .then(response => {
        // Navigate to the updateprospect page
        navigate(`/editopportunity/${row.opportunityid}`, { state: { updateOpportunity: response?.data?.data } });
        // Handle the API response data
        console.log('API Response:', response?.data?.data);
      })
      .catch(error => {
        // Handle API errors
        console.error('API Error:', error);
      });
  };

  const handleShowDetails = (row) => {
    navigate(`/viewOpportunity/${row.opportunityid}`, { state: { customer: row } });
  };

  const handleUpdateStatus = (row) => {
    navigate(`/opportunityupdatestatus/${row.opportunityid}`, { state: { customer: row } });
  };

  const handleOpportunityDetails = (row) => {
    navigate(`/opportunity/${row.opportunityid}`, { state: { customer: row } });
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);

  return (
    <div style={{ marginTop: '1.5rem', marginLeft: '0.5rem', minHeight: '100vh' }}>
      {loading ? (
        <LoadingSpinner />
      ) : (

        <div>
          <div style={{ marginTop: '3.5rem', marginLeft: '8.5rem', }}>
            <Sidebar setSelectedOption={setSelectedOption} />
            <Form>
              <Grid container alignItems="center">
                <Grid item xs={3.5}>
                  <h5>Leads Information</h5>
                </Grid>
                <Grid item xs={2.5}>
                  <TextField
                    sx={{
                      height: '37px',
                      backgroundColor: '#ffff',
                      '& .MuiOutlinedInput-root': {
                        height: 37,
                        borderRadius: '8px',
                        '& fieldset': {
                          borderColor: 'black', // Set the border line color to black
                        },
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontSize: '14px',
                        color: 'black', // Set the color of the label text to black
                      },
                    }}
                    label="Search"
                    variant="outlined"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Grid>
                {/* <Grid item xs={2}>

                  <Stack direction="row" alignItems="flex-start">
                    <DateRangePicker
                      format='dd-MM-yyyy'
                      ranges={predefinedRanges}
                      placeholder="Select Date"
                      style={{ width: 210, marginTop: '0.6rem' }}
                      onChange={handleDateRangeChange}
                    />
                  </Stack>
                </Grid> */}
                <Grid ml={10} mt={2} style={{ display: 'flex', flexDirection: 'column', marginLeft: '5rem' }}>
                  <Controls.Button text="Import Lead" onClick={handleClick} />
                  <Link onClick={() => (navigate('/addlead'))} style={{ fontSize: '12px', marginLeft: '15px', cursor: 'pointer' }}>New Lead</Link>
                </Grid>
                {/* <Controls.Button
                  text="Assign"
                  onClick={handleAssignClick}
                  disabled={Object.values(selectedRows).every((value) => !value)}
                /> */}
                <Grid ml={1} mt={2} style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem' }}>
                  <Controls.Button
                    text="Assign"
                    onClick={handleAssignClick}
                    disabled={Object.values(selectedRows).every((value) => !value)}
                  />

                  <Link onClick={() => handleAssignClickone()} style={{ fontSize: '12px', marginLeft: '15px', cursor: 'pointer' }}>Bulk Assign</Link>
                </Grid>
                <DrawerHeader>
                  <button
                    onClick={handleDrawerOpen}
                    style={{
                      backgroundColor: '#914572',
                      display: 'flex',
                      justifyContent: 'center',  // Center horizontally
                      alignItems: 'center',      // Center vertically
                      borderRadius: '8px',
                      height: '35px',
                      fontSize: '14px',
                      // fontWeight: 'bold',  // 'bold' is the correct property for font weight
                      color: '#fff',
                      padding: '15px',
                    }}
                  >
                    <span>
                      <FilterListIcon />
                    </span>
                    <span>Filter </span>
                  </button>
                </DrawerHeader>
                <Main side={side}></Main>
              </Grid>

            </Form>
            <label style={{ marginLeft: '58.3rem' }}>Total Records : {TotalRecords}</label>
            <div>
              {filteredData.length > 0 ? (
                <>
                  {filteredData && (
                    <Box
                      sx={{
                        '& .custom-header': {
                          backgroundColor: 'rgb(234, 237, 240,1)',
                        },
                      }}
                    >
                      <DataGrid
                        rowHeight={rowHeight}
                        rows={filteredData}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        keepNonExistentRowsSelected
                        hideFooterPagination={true}

                      />
                      <div style={{ marginLeft: '38rem', display: 'flex', flexDirection: 'row' }}>
                        <label htmlFor="itemsPerPage">Leads per page:</label>
                        <select id="itemsPerPage" value={limit} onChange={(e) => handlePerPageChange(e.target.value)}>
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        <Stack spacing={2}>
                          <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                          />
                        </Stack>
                      </div>
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      marginTop: '2rem',
                      '& .custom-header': {
                        backgroundColor: 'rgb(234, 237, 240,1)',
                      },
                    }}
                  >
                    <DataGrid
                      rowHeight={rowHeight}
                      rows={filteredData}
                      columns={columns}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      disableSelectionOnClick
                      hideFooterPagination={true}

                    />

                    <Typography style={{ marginLeft: '25rem', marginTop: '8rem' }} variant="subtitle1" gutterBottom>
                      No Lead details available.
                    </Typography>
                  </Box>

                </>
              )}
              <Drawer
                width="30%"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'fixed',
                  justifyContent: 'flex-end',
                  height: '100%',
                  padding: 2,
                  width: drawerWidth,
                  flexShrink: 0,

                  [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#f5f5f5' },
                }}
                variant="persistent"
                anchor="right"
                open={side}
              >
                <DrawerHeader>
                  <IconButton onClick={handleDrawerClose} style={{ marginTop: '55px' }}>
                    {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                  </IconButton>
                </DrawerHeader>
                <List >
                  <ListItem>
                    {renderSidebar()}
                  </ListItem>
                </List>
              </Drawer>

              <DrawerHeader />

            </div>
          </div>
        </div>
      )}
    </div>
  );




}


export default AvAllOpportunity;


