import { useState } from 'react';

const useToggleColumns = (initialState = true) => {
  const [showDetails, setShowDetails] = useState(initialState);

  const toggleColumns = () => {
    setShowDetails(!showDetails);
  };

  return [showDetails, toggleColumns];
};

export default useToggleColumns;
