
import CircularProgress from '@mui/material/CircularProgress';

const Loader = () => (
    <div
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <CircularProgress />
    </div>
);


export default Loader;
