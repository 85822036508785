import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Box, MenuItem } from '@mui/material';
import Controls from '../components/controls/Controls';
import { Grid, Select } from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import { useLocation, useNavigate} from 'react-router';
import axios from 'axios';
import * as Rest from  './../services/restapi';
import { Form } from '../components/useForm';
import moment from 'moment';
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import calendar from'./../assests/icons/calendar.png';

const AvEditBeneficiary = () => {
    const navigate =useNavigate();
    const location = useLocation();
    const customer = location?.state;
const prospectid= customer?.prospectid;

 const [tabs, setTabs] = useState([]);
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const token = localStorage.getItem("X-fiftyaccess-Token");
    axios.get(Rest.viewprosect,{
        params:{
          prospectid:prospectid
        },
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      }
    )
      .then(response => {
        setTabs(response?.data?.data?.beneficiarydetails);
        console.log("prospectid",prospectid)
      })
      .catch(err => {
      });
  }, [prospectid]);
  const addTab = () => {
    const newTabs = [
      ...tabs,
      {
        beneficiaryrelation:"New Tab",
        firstname:'',
        lastname:'',
        emailid:'',
        phonenumnber:'',
       alternateemails:[""],
       gender:"",
       dateofbirth:null,
       alternatephonenumbers:[""],
       communicationpreference:"Call",
       pannumber:"",
       aadharnumber:"",
       addressdetails:[{
        addresstype:"Home",
        addressline1:"",
        addressline2:'',
        city:'',
        district:'',
        state:'',
        pincode:'',
        country:'India',
        /* fileds:[
            {
                id:'addresstype', 
                label: 'Address Type', 
                value: '',
                type: 'dropdown',
                options:[
                  { value: 'home', label: 'Home' },
                  { value: 'office', label: 'Office' },
                  { value: 'other', label: 'Other' },
                ]
              },
              {id:'addressline1', label: 'Address Line1', value: '' },
              {id:'addressline2', label: 'Address Line2', value: '' },
              {id:'city', label: 'City', value: '' },
              {id:'district', label: 'District', value: '' },
              {id:'state', label: 'State', value: '' },
              {id:'pincode', label: 'Pincode', value: '' },
              {id:'country', label: 'Country', value: ''},
        ] */
       }],
      /*  fields: [
        {id:'firstname', label: 'First Name', value: '' },
        {id:'lastname', label: 'Last Name', value: '' },
        {id:'emailid', label: 'Email ID', value: '' },
        {id:'phonenumber', label: 'Phone Number', value: '' },
        {id:'alternateemails', label: 'Alternate Email ID', value: '' },
        {
          id: 'gender',
          label: 'Gender',
          type: 'dropdown', // Add type 'dropdown' to indicate it's a dropdown field
          value: '', // Default value
          options: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
            { value: 'Others', label: 'Others' },
            { value: 'Not Specified', label: 'Not Specified' },
          ],
        },
        {
          id: 'dateofbirth',
          label: 'Date of Birth',
          type: 'datepicker', // Indicate it's a datepicker field
          value: null, // Default value for datepicker
        },
        {id:'alternatephonenumbers', label: 'Alternate Phonenumber', value: '' },
        {
         id:'communicationpreference',
         label: 'Communication Preference', 
         value: '' ,
         type: 'dropdown',
         options : [
          { value: 'Call', label: 'Call' },
          { value: 'SMS', label: 'SMS' },
          { value: 'Email', label: 'Email' },
          { value: 'Whatsapp', label: 'Whatsapp' },
        ]
        },
        {id:'pannumber', label: 'PAN Number', value: '' },
        {id:'aadharnumber', label: 'Aadhar Number', value: '' },
                 
      ], */
      },
    ];
    setTabs(newTabs);
    setValue(newTabs?.length - 1); // Switch to the newly added tab
  };
  const removeTab = () => {
    if (tabs?.length <= 1) return; // Ensure at least one tab remains
    const newTabs = [...tabs];
    newTabs.splice(value, 1);
    setTabs(newTabs);
    setValue(Math.min(value, newTabs?.length - 1));
  };

  const updateTabLabel = (index, label) => {
    const newTabs = [...tabs];
    newTabs[index].beneficiaryrelation = label;
    setTabs(newTabs);
  };
console.log("tabs===>",tabs)
const updateField = (tabIndex, fieldIndex, label, id, value) => {
    const updatedTabs = tabs.map((tab, i) => {
      if (i === tabIndex) {
        let updatedAddressDetails = [];
        if (Array.isArray(tab.addressdetails) && tab.addressdetails.length === 1) {
          updatedAddressDetails = tab.addressdetails.map((address) => {
            if (address.hasOwnProperty(id)) {
              return {
                ...address,
                [id]: value
              };
            }
            return address;
          });
        }
  
        // Update the top-level tab object
        let updatedTab; 
        if (!(tab.addressdetails[0]?.hasOwnProperty(id))) {
            // Update the top-level tab object
            updatedTab = {
              ...tab,
              [id]: value,
              ['dateofbirth']:moment(value).format("MM-DD-YYYY"),
              addressdetails: updatedAddressDetails
            };
          } else {
            // Update only the addressdetails
            updatedTab = {
              ...tab,
              addressdetails: updatedAddressDetails
            };
          }
        console.log('updatedtab',updatedTab,updatedAddressDetails)
        return updatedTab;
      }
      return tab;
    });
  
    setTabs(updatedTabs);
  };
  
  const renderFields = (tabIndex) => {
    const tab = tabs[tabIndex];
    const excludedFields = ['beneficiaryid', '_id', 'attachments', 'beneficiaryrelation', 'attachements', 'addressid'];
    const dropdownFields = ['gender', 'communicationpreference', 'addresstype'];
    const renderedFields = [];
    let currentRow = [];

    const dropdownOptions = {
        gender: [
          { value: 'Male', label: 'Male' },
          { value: 'Female', label: 'Female' },
          { value: 'Others', label: 'Others' },
          { value: 'Not Specified', label: 'Not Specified' },
        ],
        communicationpreference: [
          { value: 'Call', label: 'Call' },
          { value: 'SMS', label: 'SMS' },
          { value: 'Email', label: 'Email' },
          { value: 'Whatsapp', label: 'Whatsapp' },
        ],
        addresstype: [
          { value: 'Home', label: 'Home' },
          { value: 'Office', label: 'Office' },
          { value: 'Other', label: 'Other' },
        ],
      };
     
      
    // Function to generate renderedField for a given field and its value
    const generateRenderedField = (field, value) => {
        if (dropdownFields.includes(field)) {
          return (
            <Grid item xs={12}>
            <Controls.Dropdown
              label={field.charAt(0).toUpperCase() + field.slice(1)}
              value={value}
              options={dropdownOptions[field].map((option) => ({
                value:option.value,
                label:option.label
              }))}
              onChange={(e) => updateField(tabIndex, field, field.charAt(0).toUpperCase() + field.slice(1), field, e.target.value)}
            />
            </Grid>
          );
          
        } else if (field === 'dateofbirth') {
          return (
            <Grid item xs={12} >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          label="Date of birth"
          disableFuture
        format="DD-MM-YYYY"
          sx={{backgroundColor:'rgb(234, 237, 240,1)',
        borderRadius:'5px', 
        "& .MuiInputLabel-root": { // Applying styles to the label
          fontSize: '12px', // You can adjust this value as needed
        },
        '& .MuiSvgIcon-root': {
          width: '24px', // Adjust the width of the icon to fit your image
          height: '24px', // Adjust the height of the icon to fit your image
          background: `url(${calendar})`, // Set the custom calendar image as the background
          backgroundSize: 'contain', // Adjust the background size as needed
          display: 'block', // Set the display to block to occupy the space
        },
        '& input': {
          width: '30', // Adjust the width of the input field
          height: '30', // Adjust the height of the input field
          padding: '12px', // Adjust the padding around the input content
          fontSize: '12px', // Adjust the font size of the input content
          alignContent:'center',
        },
      }}
      slotProps={{ textField: { size: 'small' } }}
          defaultValue={dayjs(value*1000)}
          onChange={(date) => {updateField(tabIndex, field, field.charAt(0).toUpperCase() + field.slice(1), field, date.toDate())}}
        />
      </DemoContainer>
    </LocalizationProvider>
            {/* <Controls.ReactDatepicker
              selected={value}
              onChange={(date) => updateField(tabIndex, field, field.charAt(0).toUpperCase() + field.slice(1), field, date)}
            /> */}
            </Grid>
          );
        } else {
          return (
             <Grid item xs={12}>
            <Controls.Input
              label={field.charAt(0).toUpperCase() + field.slice(1)}
              value={value}
              onChange={(e) => updateField(tabIndex, field, field.charAt(0).toUpperCase() + field.slice(1), field, e.target.value)}
            />
            </Grid>
          );
        }
      };
     
      for (let field in tab) {
        if (excludedFields.includes(field)) {
          continue; // Skip rendering excluded fields
        }
       
        let renderedField;
        if (field === 'addressdetails' && tab[field]) {
          const addressDetailsInputs = tab[field].map((address, index) => {
            // Filter out excluded fields
            const filteredAddress = Object.keys(address)
              .filter(property => !excludedFields.includes(property))
              .reduce((obj, key) => {
                obj[key] = address[key];
                return obj;
              }, {});
      
            // Generate input fields for each property in the filtered address detail
            return Object.keys(filteredAddress).map((property) => generateRenderedField(`${property}`, filteredAddress[property]));
          });
      
          // Divide the addressDetailsInputs into rows of three fields each
          for (let i = 0; i < addressDetailsInputs?.[0]?.length; i += 3) {
            renderedFields.push(
              <div key={`row_${i}`} style={{display:'flex',flexDirection:'row'}}>
                {addressDetailsInputs?.[0]?.slice(i, i + 3)}
              </div>
            );
          }
        } else {
          renderedField = generateRenderedField(field, tab[field]);
          currentRow.push(
            <div key={field} style={{ marginBottom: '10px', width:'40rem' }}>
              {renderedField}
            </div>
          );
        }
      
        // Start a new row after every 3 fields
        if (currentRow.length === 3) {
          renderedFields.push(<Grid item xs={12}><div key={`row_${renderedFields.length}`} style={{ display: 'flex' }}>{currentRow}</div></Grid>);
          currentRow = [];
        }
      
      }
      if (currentRow.length>0) {
        renderedFields.push(<Grid item xs={8}><div key={`row_${renderedFields.length}`} style={{ display: 'flex' }}>{currentRow}</div></Grid>);
      }
      
      // If there are any remaining fields, push them to the last row
     
  
    return <div>{renderedFields}</div>;
  }; 

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
        const beneficiarydetails = {...tabs,prospectid}
      const response = await axios.post(Rest.updatebeneficiary, beneficiarydetails);
      console.log('API Response:', response.data);
      /* setIsSuccessSnackbarOpen(true); // Open success snackbar
      // Redirect after a short delay (optional)
      setTimeout(() => {
        navigate(-1);
      }, 2000);  */
      // Reset form or perform other actions on successful submission
    } catch (error) {
      console.error('API Error:', error);
      // Handle error appropriately
    }
  };
  
  return (
    <div style={{marginTop:'6rem'}}>
      <AppBar
          position="static"
          style={{
            marginTop: '5px',
            backgroundColor: '#F5F5F5',
            width: '1140px',
            borderRadius: '5px',
            marginLeft: '1.5rem',
            padding: '10px',
          }}
        >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tabs value={value} onChange={handleTabChange}>
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab?.beneficiaryrelation} />
          ))}
        </Tabs>
        <IconButton onClick={addTab}>
          <AddIcon />
        </IconButton>
        <IconButton onClick={removeTab}>
          <RemoveIcon />
        </IconButton>
      </div>
      </AppBar>
      <Box sx={{ bgcolor: '#f5f5f5', marginTop: '1px' }}>
      <div style={{ padding: '20px' }}>
        <div style={{ marginBottom: '10px' }}>
        <Form onSubmit={handleSubmit}>
          <Grid item xs={4}>
          <Controls.Input
            label="Relation"
            value={tabs[value]?.beneficiaryrelation}
            onChange={(e) => updateTabLabel(value, e.target.value)}
          />
          </Grid>
          <Grid>
          {renderFields(value)}
          </Grid>
          <div style={{marginLeft:"63.5rem"}}>
      <Controls.Button text="Update" type="submit" />
        <Controls.Button text="Cancel" onClick={()=>navigate(-1)}  />
      </div>
          </Form>
        </div>
       
      </div>
      </Box>
    </div>
  );
};

export default AvEditBeneficiary;