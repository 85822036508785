import React, { useState, useEffect } from 'react';
import Controls from '../components/controls/Controls';
import { useLocation } from 'react-router-dom';
import { Form } from './../components/useForm';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar } from '@material-ui/core';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as Rest from './../services/restapi';
import LoadingSpinner from '../components/controls/spinner';
import TextField from '@mui/material/TextField';

const OpportunityAssign = () => {
  const location = useLocation();
  const { selectedOpportunities } = location.state || []; // Get selected opportunities from location state
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeID, setEmployeeID] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [saving, setSaving] = useState(false); // Initialize saving state
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [numbers, setNumbers] = useState("");

  let opportunityids;

  if (selectedOpportunities && selectedOpportunities.length > 0) {
    opportunityids = selectedOpportunities.map((opportunity) => opportunity.opportunityid);

  }

  useEffect(() => {
    axios.get(Rest.viewallemployees, {
      headers: {
        "X-fiftyaccess-Token": token
      }
    })
      .then((response) => {
        const options = response?.data?.data?.map((employee) => ({
          value: employee.EmployeeID,
          label: `${employee.FirstName} ${employee.LastName}`,
        }));
        setEmployeeOptions(options);
        setLoading(false);
      })
      .catch((error) => console.error('Error fetching employee data:', error));
    setLoading(false);
  }, []);



  const handleAssignEmployee = () => {
    if (employeeID) {
      setSaving(true);

      const headers = {
        "X-fiftyaccess-Token": token,
        'Content-Type': 'application/json',

      };
      const selectedEmployeeObject = employeeOptions.find(option => option.value === employeeID);

      // Extract the first name and last name from the label
      const [employeefirstname, employeelastname] = selectedEmployeeObject.label.split(' ');

      axios.post(Rest.opportunitiesassignemployee, {
        EmployeeID: employeeID,
        opportunities: opportunityids,
        numberOfRecords: numbers,
        employeefirstname: employeefirstname,
        employeelastname: employeelastname,

      }, { headers: headers },)
        .then((response) => {
          if (response?.data?.code === "S001") {
            setIsSuccessSnackbarOpen(true);
            setTimeout(() => {
              setSaving(false);
              navigate('/allOpportunity');
            }, 500);
          } else {
            alert(response?.data?.msg || response?.data?.data)
          }
        })
        .catch((err) => {
          alert("Error: " + err.message);
        })
        .finally(() => {
          setSaving(false); // Finish saving process
        });
    } else {
      console.error('No employee selected or already saving.');
    }
  };
  const handleSnackbarClose = () => {

    setIsSuccessSnackbarOpen(false);
  };
  const handleSalesPersonChange = (selectedEmployeeID) => {

    console.log("####", selectedEmployeeID)
    setEmployeeID(selectedEmployeeID);
    const selectedEmployeeObject = employeeOptions.find(option => option.value === selectedEmployeeID);
    setSelectedEmployee(selectedEmployeeObject);
  };
  const handleOnnumberChange = (value) => {
    const sanitizedValue = value.replace(/^0+/, '');
    const parsedValue = parseInt(sanitizedValue, 10); // Parse the sanitized value to an integer
  
    if (!isNaN(parsedValue) || value === "") {
      setNumbers(isNaN(parsedValue) ? "" : parsedValue);
    }
  };
  
  return (
    <div style={{ marginTop: '5rem' }}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', padding: '5px' }}>
          <h3 style={{ marginLeft: '5px' }}>Assignee</h3>
          <Grid container style={{ marginLeft: '15rem' }}>
            <Grid item xs={6}>
              <Controls.Dropdown
                label="Sales Person"
                options={employeeOptions}
                value={employeeID}
                onChange={(e) => handleSalesPersonChange(e.target.value)}
              />

            </Grid>
            {!selectedOpportunities && (
 
<Grid item xs={6}>
  <div style={{marginTop:'10px',marginRight:'2rem'}}>Enter Number:
  <input
    placeholder="Enter number"
    type="number"
    value={numbers}
    onChange={(e) => handleOnnumberChange(e.target.value)}
 
  />
  </div>
</Grid>
            )}

            <Grid style={{ marginLeft: '38rem' }}>
              <Controls.Button
                text="Save"
                onClick={handleAssignEmployee}
                variant="contained"
                color="primary"
                disabled={saving || loading} // Disable the button when saving or loading
              >
                {saving ? <LoadingSpinner size={24} /> : 'Save'}
              </Controls.Button>

              <Controls.Button
                text="Cancel"
                variant="contained"
                color="primary"
                onClick={() => navigate('/allopportunity')}

              />
            </Grid>
          </Grid>
        </div>
        <div>
          {selectedOpportunities && selectedOpportunities.length > 0 && (<div style={{ padding: '15px', marginTop: '2rem' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: 'rgb(234, 237, 240,1)' }}>
                  <TableRow>
                    <TableCell>Lead ID</TableCell>
                    <TableCell>prospect Name</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>SubProduct</TableCell>
                    <TableCell>PolicyType</TableCell>
                    <TableCell>Lead Status</TableCell>
                    <TableCell>Probability</TableCell>
                    <TableCell>BusinessType</TableCell>
                    <TableCell>Assignee Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ backgroundColor: "#F5F5F5" }}>
                  {selectedOpportunities.map((opportunity) => (
                    <TableRow key={opportunity.opportunityid}>
                      <TableCell>{opportunity.opportunityid}</TableCell>
                      <TableCell>{opportunity.firstname} {opportunity.lastname}</TableCell>
                      <TableCell>{opportunity.product}</TableCell>
                      <TableCell>{opportunity.subproduct}</TableCell>
                      <TableCell>{opportunity.interestedpolicytype}</TableCell>
                      <TableCell>{opportunity.opportunitystatus}</TableCell>
                      <TableCell>{opportunity.probability}</TableCell>
                      <TableCell>{opportunity.businesstype}</TableCell>
                      <TableCell>{selectedEmployee ? selectedEmployee.label : ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>)}
        </div>
      </Form>
      <Snackbar
        sx={{ marginLeft: '30rem' }}
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
        onClose={handleSnackbarClose}
        message="Data successfully Updated"
      />
    </div>
  );
};

export default OpportunityAssign;
