import React, { useState } from 'react';
import Controls from '../components/controls/Controls';
import { Grid, Checkbox, Typography, Radio, Card } from '@material-ui/core';
import {  CardContent, FormControl, RadioGroup, FormControlLabel, TextField ,Input} from '@material-ui/core';
import  { useEffect, useRef } from 'react';
import { Container, } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from '../components/useForm';
import axios from 'axios';
import * as Rest from  '../services/restapi';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import calendar from './../assests/icons/calendar.png';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import dayjs from 'dayjs';
import { Link, tabScrollButtonClasses } from '@mui/material';
 
const today = dayjs();

const AvCommission = () => {
  const location = useLocation();
const commissionData = location?.state?.commissionData
//console.log("commissionData",commissionData)
const providername= commissionData?.provider;
const productname= commissionData?.product;
const subproductname= commissionData?.subproduct;
const policytypedetails= commissionData?.interestedpolicytype;
const start = commissionData?.startdate
const end= commissionData?.enddate;
    const [ opportunityValues, setOpportunityValues] = useState(
        {
        provider:''|| providername,
        product:'' || productname,
        subproduct:'' || subproductname,
        interestedpolicytype:'' || policytypedetails,
        netpremium: '',
        netpremiumSelected: false,
        startdate:today,
        enddate:''|| end
      }
      ); 
      var [tableData, setTableData] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('All');
 const [providerOptions, setProviderOptions] = useState([]);
  const [value, setValue] = useState('');
  const [minPercentage, setMinPercentage] = useState('');
  const [maxPercentage, setMaxPercentage] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const isProviderMount = useRef(true);
  const isProductMount = useRef(true);
  const isLocMount = useRef(true);
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [formErrors, setFormErrors] = useState({});
  const [isNetPremiumSelected, setIsNetPremiumSelected] = useState(true);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [flatCardOpen, setFlatCardOpen] = useState(false);
  const [discountCardOpen, setDiscountCardOpen] = useState(false);
  const [slabCardOpen, setSlabCardOpen] = useState(false);
  const [selectedPolicystartdate, setSelectedPolicystartdate] = useState(null)
  const [subPremiumsContent, setSubPremiumsContent] = useState({});
  const [locations, setLocations] = useState({});
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const states = Object.keys(locations);
  const cities = locations[selectedState] || [];
  const [numTabs, setNumTabs] = useState(1);
  const [lowerLimit, setLowerLimit] = useState("0");
  const [upperLimit, setUpperLimit] = useState("");
  const [editIndex, setEditIndex] = useState(0);
  const[areaeditIndex, setareaeditIndex] =useState(0);
  const [disableUpperLimit, setDisableUpperLimit] = useState(false);
  const [editDiscountIndex, setEditDiscountIndex] = useState(0);
const [editFlatIndex, setEditFlatIndex] = useState(0);
const navigate = useNavigate();

  const [discountTabs, setDiscountTabs] = useState([
    {
      id: 1,
      heading: 'Discount 1',
      note: '',
      selectedOption: 'netPremium', netPremiumContent: '' , subPremiumsContent: {}, 
      discountRange: { lowerLimit: '0', upperLimit: '' },
      
    },
  ]);
  const [disableDiscountUpperLimit, setDisableDiscountUpperLimit] = useState(false);
  const addDiscountTab = () => {
    const lastTabIndex = discountTabs.length - 1;
    const lastUpperLimit = lastTabIndex >= 0 ? discountTabs[lastTabIndex].discountRange.upperLimit : "0";
     const newTab = {
      heading: `Discount ${discountTabs.length + 1}`,
      content: "",
      discountRange: { lowerLimit: "", upperLimit: "" }, // Set both limits to empty strings
      selectedOption: 'netPremium',
    };
    setDiscountTabs([...discountTabs, newTab]);
    setEditDiscountIndex(discountTabs.length);
    };
    const removeDiscountTab = (index) => {
    const updatedDiscountTabs = discountTabs.filter((tab, i) => i !== index);
    setDiscountTabs(updatedDiscountTabs);
    const previousTabIndex = Math.max(index - 1, 0);
   setEditDiscountIndex(previousTabIndex);
  };
  const moveDiscountToNextTab = () => {
  const newIndex = (editDiscountIndex + 1) % discountTabs.length;
    setEditDiscountIndex(newIndex);
  };
 const moveDiscountToPreviousTab = () => {
 const newIndex = editDiscountIndex === 0 ? discountTabs.length - 1 : editDiscountIndex - 1;
 setEditDiscountIndex(newIndex);
  };

// const addTab = () => {
//     const lastTabIndex = tabs.length - 1;
//     const lastUpperLimit = lastTabIndex >= 0 ? tabs[lastTabIndex].slabRange.upperLimit : "0";

//     const newTab = {
//       heading: `Slab ${tabs.length + 1}`,
//       content: "",
//       slabRange: { lowerLimit: lastUpperLimit, upperLimit },
//     };

//     setTabs([...tabs, newTab]);
//     setEditIndex(tabs.length);
//   };

const [flatTabs, setFlatTabs] = useState([
  {
    id: 1,
    heading: 'Flat ',
    note: '',
    selectedOption: 'netPremium', netPremiumContent: ''
    , subPremiumsContent: {}, 
    flatRange: { lowerLimit: '0', upperLimit: '' },
  },
]);


  const [disableFlatUpperLimit, setDisableFlatUpperLimit] = useState(true);
  const addFlatTab = () => {
  const lastTabIndex = flatTabs.length - 1;
  const lastUpperLimit = lastTabIndex >= 0 ? flatTabs[lastTabIndex].flatRange.upperLimit : "0";
  const newTab = {
    heading: `Flat ${flatTabs.length + 1}`,
    content: "",
    flatRange: { lowerLimit: "", upperLimit: "" }, // Set both limits to empty strings
  };
  setFlatTabs([...flatTabs, newTab]);
  setEditFlatIndex(flatTabs.length);
};
  const removeFlatTab = (index) => {
  const updatedFlatTabs = flatTabs.filter((tab, i) => i !== index);
  setFlatTabs(updatedFlatTabs);
  const previousTabIndex = Math.max(index - 1, 0);
  setEditFlatIndex(previousTabIndex);
};
const moveFlatToNextTab = () => {
  const newIndex = (editFlatIndex + 1) % flatTabs.length;
  setEditFlatIndex(newIndex);
};
const moveFlatToPreviousTab = () => {
const newIndex = editFlatIndex === 0 ? flatTabs.length - 1 : editFlatIndex - 1;
  setEditFlatIndex(newIndex);
};

// const [areaTabs, setAreaTabs] = useState();
// const uniquename = tableData?.map((option)=>option?.name)
// const areaid = tableData?.map((option)=>option?.areaid)
// console.log("areatabname",areatabname,uniquename,areaid)

// const addAreaTab = (tableData) => { 
//   var areatabname = tableData?.map((option)=>option?.area)
//       const newTabs = areatabname?.map((area, index) => ({
//         id: index + 1,
//         heading: `Area ${index + 1}`,
//       }));
    
//       // Update the state with the new array of tabs
//       setAreaTabs((prevTabs) => [ ...newTabs]);
//       setEditIndex(areatabname?.length || 0);
//     };

const [areaTabs, setAreaTabs] = useState([]);
const [uniqueAreaName,setUniqueAreaName]= useState([]);

const uniquename = tableData?.map((option) => option?.name);
const areaid = tableData?.map((option) => option?.areaid);

const addAreaTab = () => {
  const areaName = Array.from(new Set(tableData?.map((option) => option?.area)));
  console.log("areaName",tableData)
  const uniqueAreaName = Array.from(new Set(tableData?.map((option) => option?.name)));
  setAreaTabs(areaName);
  setUniqueAreaName(uniqueAreaName)
  setEditIndex(areaName.length || 0);
};
console.log("uniqueAreaName",uniqueAreaName)
useEffect(() => {
  if (tableData) {
    addAreaTab();
  }
}, [tableData]);


console.log("areaTabs",areaTabs)
  const [tabs, setTabs] = useState([
   { id: 1,
     heading: 'Slab 1',
      note: '', selectedOption: 'netPremium',
       netPremiumContent: '', 
       subPremiumsContent: {},    
        slabRange: { lowerLimit: "0", upperLimit: "" }, 
      }, 
    ]);
    
    const addSlabTab = () => {
      const lastTabIndex = tabs.length - 1;
      const lastTab = lastTabIndex >= 0 ? tabs[lastTabIndex] : null;
     let newUpperLimit = "";
      if (lastTab) {
        // Calculate the new upper limit by adding 1 to the last tab's upper limit
       const lastUpperLimit = parseInt(lastTab.slabRange.upperLimit, 10);
         newUpperLimit = (lastUpperLimit + 1).toString();
       }
     
        const newTab = {
         heading: `Slab ${tabs.length + 1}`,
         content: "",
         slabRange: { lowerLimit: newUpperLimit, upperLimit: "" },
         selectedOption: 'netPremium', // Set the default option to 'netPremium'
       };
       setTabs([...tabs, newTab]);
       setEditIndex(tabs.length);
     }; 
  
   const moveToNextTab = () => {
       const newIndex = (editIndex + 1) % tabs.length;
       setEditIndex(newIndex);
      setLowerLimit("");
      setUpperLimit("");
  };
  const moveToPreviousTab = () => {
    const newIndex = editIndex === 0 ? tabs?.length - 1 : editIndex - 1;
    setEditIndex(newIndex);
    setLowerLimit("");
    setUpperLimit("");
  };
  const removeTab = (index) => {
  const updatedTabs = tabs?.filter((tab, i) => i !== index);
  setTabs(updatedTabs);

  const previousTabIndex = Math?.max(index - 1, 0);
  setEditIndex(previousTabIndex);
  };


  useEffect(() => {
    if(opportunityValues?.provider){
      axios.get(Rest.viewarea,{
        params:{
          provider:opportunityValues?.provider
        },
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      }
    )
      .then(response => {
        if(response.data.code==="S001"){
            setTableData(response?.data?.data)
            addAreaTab(response?.data?.data);
        }else{
            alert(response?.msg || response?.data?.data);
        }

      })
      .catch(err => {
        alert("Error: " + err.message);
      });
    }

  }, [opportunityValues?.provider]);

  //  const handleTabChange = (index, note) => {
  //   const newTabs = [...tabs];
  //   newTabs[index].note = note;
  //   setTabs(newTabs);
  // };
  //  const handleCheckboxChange = (fieldName, checked) => {
  //   // Assuming checkboxValues is an object
  //   setCheckboxValues((prevValues) => ({
  //     ...prevValues,
  //     [fieldName]: checked,
  //   }));
  // };

    useEffect(() => {
    if(isLocMount.current){
    axios.get(Rest.locations,{
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then((response) => {
        setLocations(response?.data?.locations);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
      isLocMount.current=false;
    }
  }, []);

const handleStartDateChange = (date) => {
    const startdate = moment(date)?.format('DD-MM-YYYY')
    setSelectedPolicystartdate(startdate)
    setOpportunityValues((opportunityValues) => ({ ...opportunityValues, startdate: startdate }));
  }
  const handleEndDateChange = (date) => {
    const enddate = moment(date)?.format('DD-MM-YYYY')
    setOpportunityValues((opportunityValues) => ({ ...opportunityValues, enddate: enddate }));
  }
  
  const handleOpportunityChange = (field, value) => {
    // Regular expression to match numbers with optional decimal points
    const validNumberRegex = /^-?\d*\.?\d*$/;
  
    if (
      field === 'addonpremium' ||
      field === 'odpremium' ||
      field === 'grosspremium' ||
      field === 'netpremium' ||
      field === 'tax' ||
      field==='basepremium'||
      field === 'thirdpartypremium'
    ) {
      // Check if the input value is a valid number or decimal
      if (validNumberRegex.test(value) || value === '') {
        setOpportunityValues((prevValues) => ({
          ...prevValues,
          [field]: value,
        }));
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [field]: '',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [field]: 'Enter only numbers', // Set the error message
        }));
      }
    } else {
      setOpportunityValues((prevValues) => ({
        ...prevValues,
        [field]: value,
      }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [field]: '',
      }));
    }
  };
 
  const handleDiscountCardSubmit = () => {
    // Handle submission logic here
    //console.log('Min Percentage:', minPercentage);
    //console.log('Max Percentage:', maxPercentage);
    //console.log('Selected Option:', selectedOption);
  };

  const handleButtonClick = (action) => {
    //console.log(`Button clicked: ${action}`);
  };

const handleRadioChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === 'basedOnDiscount') {
      setDiscountCardOpen(true);
      setFlatCardOpen(false); // Close the flat card
      setSlabCardOpen(false); // Close the slab card
    } else if (event.target.value === 'basedOnFlat') {
      setFlatCardOpen(true);
      setDiscountCardOpen(false); // Close the discount card
      setSlabCardOpen(false); // Close the slab card
    } else if (event.target.value === 'basedOnSlab') {
      setSlabCardOpen(true);
      setDiscountCardOpen(false); // Close the discount card
      setFlatCardOpen(false); 
    } else {
      setDiscountCardOpen(false);
      setFlatCardOpen(false);
      setSlabCardOpen(false);
    }
  };

  useEffect(() => {
    if(isProductMount.current){
    async function fetchProducts() {
      try {
        const response = await axios.get(Rest.viewallproduct,
          {
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          }
          );
        const productData = response?.data?.data
        setProducts(productData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
    fetchProducts();
    isProductMount.current=false;
  }
  }, []);
  useEffect(() => {
    // Fetch product details when selected product changes
    async function fetchSelectedProductData(productid) {
      if (productid) {
        try {
          const response = await axios
          .get(Rest.viewproduct,{
            params:{
              productid:productid
            },
            headers: {
              "X-fiftyaccess-Token": token
            },
          });
          setSelectedProduct(response?.data);
        } catch (error) {
          console.error('Error fetching selected product:', error);
        }
      }
    }

    fetchSelectedProductData(opportunityValues.product);
  }, [opportunityValues.product]);
  const isValidNumber = (value) => {
  const validNumberRegex = /^-?\d*\.?\d*(%)?$/;
  return validNumberRegex.test(value);
};
 useEffect(() => {
    if(isProviderMount.current){
    fetch(Rest.ViewAllProvider,
      {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      }
      )
      .then(response => response.json())
      .then(data => {
        // Update the options state with the fetched data
        setProviderOptions(data?.Data); // Assuming `setDropdownOptions` is a state setter function
      })
      .catch(error => {
        // Handle error
      });
      isProviderMount.current=false;
    }
  }, []);
  const [premiumErrors, setPremiumErrors] = useState({});
  const subpremiums = products.find(
    (product) => product.productid === opportunityValues.product
  )?.subpremiums ?? [];

  const productdetails = products.find(
    (product) => product.productid === opportunityValues.product
  )?.attributefields;


  const subProductOptions = products.find(
    (product) => product.productid === opportunityValues.product
  )?.subProductDetails;

  const policyOption = subProductOptions
  ?.find((product) => product.productid === opportunityValues.product)
  ?.subProductDetails?.policyTypeDetails?.map((policyType) => ({
      value: policyType,
      label: policyType, 
  })) || products.find((product) => product.productid === opportunityValues.product)
  ?.subProductDetails?.[0]?.policyTypeDetails?.map((policyType)=>({
    value:policyType,
    label:policyType
  })) ||[]
  const handleProviderChange = (event) => {
    const provider = event;
    setOpportunityValues((opportunityValues)=> ({...opportunityValues,provider:provider}));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      provider: '',
    }));
  };
  
  const handleNetpremiumChange = (e) => {
    const inputValue = e.target.value;
  
    // Check if the input is empty
    if (inputValue === '') {
      setOpportunityValues((prevValues) => ({
        ...prevValues,
        netpremium: '',
      }));
    } else {
      // Check if the input is a valid number
      if (!isNaN(inputValue)) {
        setOpportunityValues((prevValues) => ({
          ...prevValues,
          netpremium: parseInt(inputValue, 10),
        }));
      } else {
        // Handle invalid input
      }
    }
  };
  
  const handleNetpremiumCheckboxChange = (e) => {
    setOpportunityValues((prevValues) => ({
      ...prevValues,
      netpremiumSelected: e.target.checked,
    }));
  };
  

  const handlePremiumChange = (fieldName, value) => {
 
    // Create a copy of the opportunityValues object to avoid mutating the state directly
    const updatedOpportunityValues = { ...opportunityValues,subpremiums };
    // Update the value based on the field name
    updatedOpportunityValues.subpremiums[0][fieldName] = value;
    updatedOpportunityValues.subpremiums = updatedOpportunityValues.subpremiums[0]
    // Update the state with the updated opportunityValues
    setOpportunityValues(updatedOpportunityValues);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      value: '',
    }));
  };

const addArea=(data)=>{

    console.log("SSSSSS",data)
  }



   return (
    <>
      <Form>
      <div style={{ display: 'flex', flexDirection: 'row',  marginTop: '5rem' }}>
        <Typography style={{ marginLeft: '1rem',marginTop:'10px' }} variant="h6" align="center" gutterBottom>
          Commission Rules
        </Typography>
      </div>
        <Grid container>
        <Grid item xs={2} >
        <Controls.Dropdownall
              label='Provider'
              options={providerOptions?.map(option => ({ 
                value: option.provider, 
                label: option.provider 
              }))}
              value={opportunityValues.provider}
              onChange={(e)=>handleProviderChange( e.target.value)}
            />
              {formErrors.provider && (
              <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.provider}</div>
            )} 
        </Grid>
       
          <Grid item xs={2} >
          <Controls.Dropdownall
              options={products?.map((product) => ({
              value: product.productid,
              label: product.product,
              }))}
              label="Product"
              value={opportunityValues.product}
              onChange={(e) => handleOpportunityChange('product', e.target.value)}
              style={{ marginBottom: '1rem' }}
            />
               {formErrors.product && (
             <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.product}</div>
              )} 
           </Grid>
           { subProductOptions?.length >1   &&  (
           <Grid item xs={2} >
               <>
            <Controls.Dropdownall
              options={subProductOptions?.map((subProduct) => ({
              value: subProduct.subproduct,
              label: subProduct.subproduct
              })) || []}
              label="Sub Product"
              value={opportunityValues.subproduct}
              onChange={(e) =>
              handleOpportunityChange('subproduct', e.target.value)  
              }
              />
              {formErrors.subproduct && (
                <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.subproduct}</div>
                )}
                </>
               </Grid>
                )}
               <Grid item xs={2} >
            <Controls.Dropdownall
              options={policyOption}
              label="Policy Type"
              value={opportunityValues.interestedpolicytype} 
              onChange={(e) => handleOpportunityChange('interestedpolicytype', e.target.value)}  
              />
                {formErrors.interestedpolicytype && (
              <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.interestedpolicytype}</div>
              )}
             
           </Grid>
           {/* <Grid item xs={2} >
           <Controls.Dropdown
              options={states.map((option)=>({
                label:option,
                value:option
              }))}
              label={
                <>
              State<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                </>
              }
              value={selectedState} 
              onChange={(e) => setSelectedState(e.target.value)} 
              /> 
           </Grid>
           <Grid item xs={2} >
           <Controls.Dropdown
              options={cities.map((option)=>({
                label:option,
                value:option
              }))}
              label={
                <>
            City <span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                </>
              }
              value={selectedCity} 
              onChange={(e) => setSelectedCity(e.target.value)} 
              /> 
           </Grid> */} 
        
           <Grid item xs={2}  style={{marginLeft:'2px'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                     label=" Effective Date"
                      defaultValue={opportunityValues.startdate}
                      onChange={e => { handleStartDateChange(e.toDate()) }}
                      format="DD-MM-YYYY"
                      sx={{
                        backgroundColor: 'rgb(234, 237, 240,1)',
                        borderRadius: '5px',
                        '& .MuiInputLabel-root': {
                          fontSize: '12px',
                        },
                        '& .MuiSvgIcon-root': {
                          width: '24px',
                          height: '24px',
                          background: `url(${calendar})`,
                          backgroundSize: 'contain',
                          display: 'block',
                        },
                        '& input': {
                          width: '30',
                          height: '30',
                          padding: '12px',
                          fontSize: '12px',
                          alignContent: 'center',
                        },
                        '& .MuiInput-underline': {
                          '&:before': {
                            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color
                          },
                          '&:hover:not(.Mui-disabled):before': {
                            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color
                          },
                        },
                      }}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
              </Grid>
            <Grid item xs={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                       label=" End Date"
                      defaultValue={opportunityValues.enddate || null}
                      onChange={e => { handleEndDateChange(e.toDate()) }}
                      format="DD-MM-YYYY"
                      sx={{
                        backgroundColor: 'rgb(234, 237, 240,1)',
                        borderRadius: '5px',
                        '& .MuiInputLabel-root': {
                          fontSize: '12px',
                        },
                        '& .MuiSvgIcon-root': {
                          width: '24px',
                          height: '24px',
                          background: `url(${calendar})`,
                          backgroundSize: 'contain',
                          display: 'block',
                        },
                        '& input': {
                          width: '30',
                          height: '30',
                          padding: '12px',
                          fontSize: '12px',
                          alignContent: 'center',
                        },
                        '& .MuiInput-underline': {
                          '&:before': {
                            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color
                          },
                          '&:hover:not(.Mui-disabled):before': {
                            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color
                          },
                        },
                      }}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider> 
          </Grid>
         <Grid style={{marginTop:'1.5rem',cursor:'pointer'}}>
            <Link onClick={()=>{navigate("/area/",{ state: {  commissiondetails:opportunityValues  } })}}>Define Area</Link>
         </Grid>
           <Grid item xs={12} >
           <Radio value="basedOnFlat"
            onChange={handleRadioChange} 
            checked={value === 'basedOnFlat'} /> 
            Based on Flat

           <Radio value="basedOnDiscount"
            onChange={handleRadioChange} 
            checked={value === 'basedOnDiscount'} />
             Based on Discount

          <Radio value="basedOnSlab"
            onChange={handleRadioChange} 
            checked={value === 'basedOnSlab'} />
             Based on Slab
          </Grid> 
           </Grid>
           </Form>
            
      {discountCardOpen && (
     <Card>
      <CardContent>
      <FormControl>
        <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
         
           <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
            <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>
              {discountTabs?.map((tab, index) => (
                <li
                  key={index}
                  style={{
                    cursor: 'pointer',
                    padding: '10px',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s ease',
                    marginRight: '5px',
                    minWidth: '120px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    borderBottom: editDiscountIndex === index ? '2px solid #0056b3' : '1px solid #0066cc',
                    borderLeft: '1px solid #ddd',
                    borderRight: '1px solid #ddd',
                    backgroundColor: editDiscountIndex === index ? '#f8f9fa' : '',
                    color: editDiscountIndex === index ? '#0056b3' : ''
                  }}
                  onClick={() => setEditDiscountIndex(index)}
                >
                  {/* <span style={{ cursor: 'pointer' }}>
                    {tab.heading}
                  </span> */}
                    <>
              <span>{tab.heading}</span>
               <div>
              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                {'['}{tab.discountRange.lowerLimit}-{tab.discountRange.upperLimit}{']'}
                 </div>
                </div>
                </>
                </li>
              ))}
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{
                    cursor: 'pointer',
                    padding: '8px 15px',
                    borderRadius: '5px',
                    marginRight: '5px',
                    transition: 'background-color 0.3s ease',
                    color: '#0056b3',
                    backgroundColor: '#f8f9fa',
                  }}
                  onClick={addDiscountTab}
                >
                  + Add
                </span>
                {editDiscountIndex !== null && (
                  <span
                    style={{
                      cursor: 'pointer',
                      padding: '8px 15px',
                      borderRadius: '5px',
                      transition: 'background-color 0.3s ease',
                      color: '#0056b3',
                      backgroundColor: '#f8f9fa',
                    }}
                    onClick={() => removeDiscountTab(editDiscountIndex)}
                  >
                    &times; Remove
                  </span>
                )}
              </span>
            </ul>
            <div style={{ padding: '20px', overflowY: 'auto' }}>
              {discountTabs?.map((tab, index) => (
                <div key={tab.id} style={{ display: editDiscountIndex === index ? 'block' : 'none' }}>   
         </div>
              ))}
                     {discountCardOpen && (
     <Card>
      <CardContent>
      <FormControl>
        {/* <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}> */}
           <RadioGroup
            aria-label="premiumOptions"
            name="premiumOptions"
            value={discountTabs[editDiscountIndex]?.selectedOption || 'netPremium'}
            onChange={(e) => {
              const newDiscountTabs = [...discountTabs];
              newDiscountTabs[editDiscountIndex].selectedOption = e.target.value;
              setDiscountTabs(newDiscountTabs);
            }}
            style={{ marginBottom: '20px' }}
          >  
     
         <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium" />
         <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" /> 
              {selectedCity && (
  <div style={{ marginBottom: '20px' }}>
    <Typography style={{ marginBottom: '10px',fontSize:'16px' }}>
      Selected City:
      <span style={{ marginBottom: '10px',color: '#4b0082' ,fontSize:'16px'}}> {selectedCity}</span> 
    </Typography>
  </div>
)}
      </RadioGroup>  
           <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
            <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>
              {discountTabs?.map((tab, index) => (
                <li
                  key={index}
                  style={{
                    cursor: 'pointer',
                    padding: '10px',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s ease',
                    marginRight: '5px',
                    minWidth: '120px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    borderBottom: editDiscountIndex === index ? '2px solid #0056b3' : '1px solid #0066cc',
                    borderLeft: '1px solid #ddd',
                    borderRight: '1px solid #ddd',
                    backgroundColor: editDiscountIndex === index ? '#f8f9fa' : '',
                    color: editDiscountIndex === index ? '#0056b3' : ''
                  }}
                  onClick={() => setEditDiscountIndex(index)}
                >
                  {/* <span style={{ cursor: 'pointer' }}>
                    {tab.heading}
                  </span> */}
                    <>
              <span>{tab.heading}</span>
               <div>
              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                {'['}{tab.discountRange.lowerLimit}-{tab.discountRange.upperLimit}{']'}
                 </div>
                </div>
                </>
                </li>
              ))}
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <span
                  style={{
                    cursor: 'pointer',
                    padding: '8px 15px',
                    borderRadius: '5px',
                    marginRight: '5px',
                    transition: 'background-color 0.3s ease',
                    color: '#0056b3',
                    backgroundColor: '#f8f9fa',
                  }}
                  onClick={addDiscountTab}
                >
                  + Add
                </span>
                {editDiscountIndex !== null && (
                  <span
                    style={{
                      cursor: 'pointer',
                      padding: '8px 15px',
                      borderRadius: '5px',
                      transition: 'background-color 0.3s ease',
                      color: '#0056b3',
                      backgroundColor: '#f8f9fa',
                    }}
                    onClick={() => removeDiscountTab(editDiscountIndex)}
                  >
                    &times; Remove
                  </span>
                )}
              </span>
            </ul>
            <div style={{ padding: '20px', overflowY: 'auto' }}>
              {discountTabs?.map((tab, index) => (
                <div key={tab.id} style={{ display: editDiscountIndex === index ? 'block' : 'none' }}>
                  <div style={{ marginTop: '7px' }}>
                    <span>Discount Range   </span>
                    <input
                      // type="number"
                      placeholder="Lower Limit"
                      value={index === 0 ? "0" : tab.discountRange.lowerLimit}
                      onChange={(e) => {
                        if (index !== 0) {
                          const newDiscountTabs = [...discountTabs];
                          newDiscountTabs[index].discountRange.lowerLimit = e.target.value;
                          setDiscountTabs(newDiscountTabs);
                        }
                      }}
                      readOnly={index === 0}
                      style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
                    />
                    <input
                      // type="number"
                      placeholder="Upper Limit"
                      value={tab.discountRange.upperLimit}
                      onChange={(e) => {
                        const newDiscountTabs = [...discountTabs];
                        newDiscountTabs[index].discountRange.upperLimit = e.target.value;
                        setDiscountTabs(newDiscountTabs);
                      }}
                      disabled={disableDiscountUpperLimit}
                      style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
                    />
                    
                    <input
                      type="checkbox"
                      id="upToMaxDiscountCheckbox"
                      checked={disableDiscountUpperLimit}
                      onChange={() => setDisableDiscountUpperLimit(!disableDiscountUpperLimit)}
                      style={{ margin: '0 5px', width: '20px', height: '20px' }}
                    />
                    <label htmlFor="upToMaxDiscountCheckbox" style={{ margin: '0' }}>Up to Max</label>
                  </div>
                  <RadioGroup
                   aria-label={`premiumOptions_${tab.id}`}
                    name={`premiumOptions_${tab.id}`}
                    value={tab.selectedOption}
                   onChange={(e) => {
                  const newDiscountTabs  = [...discountTabs];
                   newDiscountTabs[index].selectedOption = e.target.value;
                   setDiscountTabs(newDiscountTabs); }}
                 >
     {/* <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium"/>  */}
             {tab.selectedOption === 'netPremium' && (
            <div style={{marginLeft:'1rem', marginTop:'20px'}}> 
           <FormControlLabel
           control={
          <Checkbox
          checked={tab.checkboxValue}
          onChange={(e) => {
          const  newDiscountTabs = [...discountTabs];
          newDiscountTabs[index].checkboxValue = e.target.checked;
          setDiscountTabs(newDiscountTabs);  }}
          style={{ color: '#001f3f' }}  // Set the color to dark blue (#001f3f)
        />
      }
    label="NetPremium"
  />
          <Controls.Input
          type="text"
        label="Commission Percentage"
         value={tab.netPremiumContent}
         onChange={(e) => {
      const newDiscountTabs =[...discountTabs];
      newDiscountTabs[index].netPremiumContent = e.target.value;
      setDiscountTabs(newDiscountTabs);
      }}
      />
      </div>
      )}
       {/* <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />   */}
    </RadioGroup>
 {tab.selectedOption === 'subPremiums' &&
  subpremiums[0] &&
  Object.entries(subpremiums[0])?.map(([fieldName]) => (
    <Grid container  key={fieldName} alignItems='center' style={{ marginBottom: '20px', marginLeft: '1rem',marginTop:'20px' }}>
        <Grid item>
      {/* <FormControlLabel */}
        {/* control={ */}
          <Checkbox
            checked={tab.subPremiumsContent?.[fieldName] || false}
            onChange={(e) => {
              const newDiscountTabs = [...discountTabs];
              newDiscountTabs[index].subPremiumsContent = {
                ...newDiscountTabs[index].subPremiumsContent,
                [fieldName]: e.target.checked,
              };
              setDiscountTabs(newDiscountTabs);
            }}
            name={`${fieldName}_checkbox`}
            color="primary"
          />
          </Grid>
          <Grid item xs={5}>
            <FormControlLabel
            control={<div/>}
            label={fieldName}
  />
    </Grid>
  <Grid item xs={5}>
      <Controls.Input
        type="text"
        label={"Commission Percentage"}
        value={tab.subPremiumsContent?.[fieldName] || ''}
        onChange={(e) => {
          const value = e.target.value;
          const newDiscountTabs = [...discountTabs];
          newDiscountTabs[index].subPremiumsContent = {
           ...newDiscountTabs[index].subPremiumsContent,
            [fieldName]: value,
          };
          setDiscountTabs(newDiscountTabs);
        }}
      />
      </Grid>
    </Grid> 
  ))}
 <label htmlFor={`discountTextarea-${index}`}>Note:</label>
                  <textarea
                    id={`discountTextarea-${index}`}
                    value={tab.note}
                    onChange={(e) => {
                      const newDiscountTabs = [...discountTabs];
                      newDiscountTabs[index].note = e.target.value;
                      setDiscountTabs(newDiscountTabs);
                    }}
                    style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ddd', borderRadius: '5px', boxSizing: 'border-box', resize: 'vertical' }}
                  ></textarea>
                  <Grid style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    <Controls.Button text="Previous" type="submit" onClick={() => moveDiscountToPreviousTab('Prevous')} />
                    <Controls.Button text="Next" type="submit" onClick={() => moveDiscountToNextTab('Next')} />
                  </Grid>
                </div>
              ))}
            </div>
          </div>
        {/* </div> */}
      </FormControl>
    </CardContent>
  </Card>
)}

            </div>
     
          </div>
        </div>
      </FormControl>
    </CardContent>
  </Card>
)}

   {flatCardOpen && (
  <Card>
    <CardContent>
      <FormControl>
        <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
          <Grid xs={12}>
        <RadioGroup
            aria-label="premiumOptions"
            name="premiumOptions"
            value={flatTabs[editFlatIndex]?.selectedOption || 'netPremium'}
            onChange={(e) => {
              const newFlatTabs = [...flatTabs];
              newFlatTabs[editFlatIndex].selectedOption = e.target.value;
              setFlatTabs(newFlatTabs);
            }}
            style={{ marginBottom: '20px' }}
          >
            <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium" />
            <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />
          </RadioGroup> 
                 
          </Grid>
          <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
             <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>
              {flatTabs?.map((tab, index) => (
                <li
                  key={index}
                  style={{
                     cursor: 'pointer',
                    padding: '10px',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s ease',
                    marginRight: '5px',
                    minWidth: '120px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    borderBottom: editFlatIndex === index ? '2px solid #0056b3' : '1px solid #0066cc',
                    borderLeft: '1px solid #ddd',
                    borderRight: '1px solid #ddd',
                    backgroundColor: editFlatIndex === index ? '#f8f9fa' : '',
                    color: editFlatIndex === index ? '#0056b3' : ''
                  }}
                  onClick={() => setEditFlatIndex(index)}
                >
               <>
             <span>{tab.heading}</span>
            <div>
              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                {'['}{tab.flatRange.lowerLimit}-{'Max'}{']'}
              </div>
            </div>
          </>
            </li>
              ))}
              {/* <span style={{ display: 'flex', alignItems: 'center' }}> */}
                {/* <span
                  style={{
                    cursor: 'pointer',
                    padding: '8px 15px',
                    borderRadius: '5px',
                    marginRight: '5px',
                    transition: 'background-color 0.3s ease',
                    color: '#0056b3',
                    backgroundColor: '#f8f9fa',
                  }} */}
                   {/* onClick={addFlatTab} */}
                 {/* > */}
                  {/* + Add */}
                 {/* </span> */}
                {/* {editFlatIndex !== null && (
                  <span
                    style={{
                      cursor: 'pointer',
                      padding: '8px 15px',
                      borderRadius: '5px',
                      transition: 'background-color 0.3s ease',
                      color: '#0056b3',
                      backgroundColor: '#f8f9fa',
                    }} */}
                     {/* onClick={() => removeFlatTab(editFlatIndex)} */}
                  {/* > */}
                    {/* &times; Remove */}
                  {/* </span> */}
                {/* )} */}
              {/* </span> */}
            </ul>
            <div style={{ padding: '20px', overflowY: 'auto' }}>
              {flatTabs?.map((tab, index) => (
                 <div key={tab.id} style={{ display: editFlatIndex === index ? 'block' : 'none' }}>
                
                  <div style={{ marginTop: '7px' }}>
                    <span>Flat Range   </span>
                    <input
                      // type="number"
                      placeholder="Lower Limit"
                      value={index === 0 ? "0" : tab.flatRange.lowerLimit}
                      onChange={(e) => {
                        if (index !== 0) {
                          const newFlatTabs = [...flatTabs];
                          newFlatTabs[index].flatRange.lowerLimit = e.target.value;
                          setFlatTabs(newFlatTabs);
                        }
                      }}
                      readOnly={index === 0}
                      style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
                    />
                    <input
         // type="number"
           placeholder="Upper Limit"
                value={tab.flatRange.upperLimit}
            onChange={(e) => {
              const newFlatTabs = [...flatTabs];
         newFlatTabs[index].flatRange.upperLimit = e.target.value;
        setFlatTabs(newFlatTabs);
  }}
        disabled={true} // Set disabled to true to always disable the input
         style={{
    width: '120px',
    padding: '3px',
    borderRadius: '5px',
    marginRight: '10px',
    boxSizing: 'border-box'
  }}
/>                 
                     {/* <input
                      type="checkbox"
                      id="upToMaxDiscountCheckbox"
                      checked={disableFlatUpperLimit}
                      onChange={() => setDisableFlatUpperLimit(!disableFlatUpperLimit)}
                      style={{ margin: '0 5px', width: '20px', height: '20px' }}
                    />
                    <label htmlFor="upToMaxDiscountCheckbox" style={{ margin: '0' }}>Up to Max</label>  */}
 
                  </div>
                  <RadioGroup
      aria-label={`premiumOptions_${tab.id}`}
      name={`premiumOptions_${tab.id}`}
      value={tab.selectedOption}
      onChange={(e) => {
        const newFlatTabs  = [...flatTabs];
        newFlatTabs[index].selectedOption = e.target.value;
        setFlatTabs(newFlatTabs);
      }}
    >
     {/* <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium"/>  */}
      {tab.selectedOption === 'netPremium' && (
<div style={{marginLeft:'1rem', marginTop:'20px'}}> 
  <FormControlLabel
    control={
      <Checkbox
        checked={tab.checkboxValue}
        onChange={(e) => {
          const  newFlatTabs = [...flatTabs];
          newFlatTabs[index].checkboxValue = e.target.checked;
          setFlatTabs(newFlatTabs);
        }}
        style={{ color: '#001f3f' }}  // Set the color to dark blue (#001f3f)
      />
    }
    label="NetPremium"
  />
  <Controls.Input
    type="text"
    label="Commission Percentage"
    value={tab.netPremiumContent}
    onChange={(e) => {
      const newFlatTabs =[...flatTabs];
      newFlatTabs[index].netPremiumContent = e.target.value;
      setFlatTabs(newFlatTabs);
    }}
  />
</div>
      )}
       {/* <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />   */}
    </RadioGroup>
 {tab.selectedOption === 'subPremiums' &&
  subpremiums[0] &&
  Object.entries(subpremiums[0])?.map(([fieldName]) => (
    <Grid container  key={fieldName} alignItems='center' style={{ marginBottom: '20px', marginLeft: '1rem',marginTop:'20px' }}>
        <Grid item>
      {/* <FormControlLabel */}
        {/* control={ */}
          <Checkbox
            checked={tab.subPremiumsContent?.[fieldName] || false}
            onChange={(e) => {
              const newFlatTabs = [...flatTabs];
              newFlatTabs[index].subPremiumsContent = {
                ...newFlatTabs[index].subPremiumsContent,
                [fieldName]: e.target.checked,
              };
              setFlatTabs(newFlatTabs);
            }}
            name={`${fieldName}_checkbox`}
            color="primary"
          />
          </Grid>
          <Grid item xs={5}>
            <FormControlLabel
            control={<div/>}
        label={fieldName}
  />
  </Grid>
  <Grid item xs={5}>
      <Controls.Input
        type="text"
        label={"Commission Percentage"}
        value={tab.subPremiumsContent?.[fieldName] || ''}
        onChange={(e) => {
          const value = e.target.value;
          const newFlatTabs = [...flatTabs];
          newFlatTabs[index].subPremiumsContent = {
            ...newFlatTabs[index].subPremiumsContent,
            [fieldName]: value,
          };
          setFlatTabs(newFlatTabs);
        }}
      />
      </Grid>
    </Grid> 
  ))}
          <label htmlFor={`flatTextarea-${index}`}>Note:</label>
                  <textarea
                    id={`flatTextarea-${index}`}
                    value={tab.note}
                    onChange={(e) => {
                      const newFlatTabs = [...flatTabs];
                      newFlatTabs[index].note = e.target.value;
                      setFlatTabs(newFlatTabs);
                    }}
                    style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ddd', borderRadius: '5px', boxSizing: 'border-box', resize: 'vertical' }}
                  ></textarea>
                  <Grid style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                    {/* <Controls.Button text="Previous" type="submit" onClick={() => moveFlatToPreviousTab('Previous')} />
                    <Controls.Button text="Next" type="submit" onClick={() => moveFlatToNextTab('Next')} /> */}
                  </Grid>
                </div>
              ))}
            </div>
          </div>
        </div>
      </FormControl>
    </CardContent>
  </Card>

)}  
   {slabCardOpen && (
      <Card>
        <CardContent>
          <FormControl>
           <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}> 
       <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden'  }}>
         <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap',margin: '0', scrollbarWidth: '10px' }}>
          {tableData?.map((tabName, index) => (
            <li
              key={index}
              style={{
                cursor: 'pointer',
                padding: '10px',
                borderRadius: '5px',
                transition: 'background-color 0.3s ease',
                marginRight: '5px',
                minWidth: '120px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                borderBottom: editIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color
                borderLeft: '1px solid #ddd', // Added left border
                borderRight: '1px solid #ddd', // Added right border
                backgroundColor: editIndex === index ? '#f8f9fa' : '',
                color: editIndex === index ? '#0056b3' : ''
              }}
              onClick={() => addArea(tabName.areaid)}
            >
             <>
             <span>{tabName.area}</span>
               <div>
           <Link   
           >
           {tabName.name}
          </Link>
  
         </div>
            <div>
              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
              </div>
            </div>
          </>
            </li>
          ))}
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                cursor: 'pointer',
                padding: '8px 15px',
                borderRadius: '5px',
                marginRight: '5px',
                transition: 'background-color 0.3s ease',
                color: '#0056b3',
                backgroundColor: '#f8f9fa',
              }}
              onClick={()=>{navigate("/area/",{ state: {  commissiondetails:opportunityValues  } })}}
            >
              + Add Area
            </span>
          </span>
        </ul> 
       
        <div style={{ padding: '20px', overflowY: 'auto'}}>
   {tabs?.map((tab, index) => (
      <div key={tab.id} style={{ display: areaeditIndex === index ? 'block' : 'none' }}>    
</div>
))}   
{slabCardOpen && (
      <Card>
        <CardContent>
          <FormControl>
            
           {/* <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>  */}
           <RadioGroup
            aria-label="premiumOptions"
            name="premiumOptions"
            value={tabs[editIndex]?.selectedOption || 'netPremium'}
            onChange={(e) => {
              const newTabs = [...tabs];
              newTabs[editIndex].selectedOption = e.target.value;
              setTabs(newTabs);
            }}
            style={{ marginBottom: '20px' }}
          >
             <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium" />
             <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />
          </RadioGroup>
         
      
       <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden'  }}>
      
         <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap',margin: '0', scrollbarWidth: '10px' }}>
          {tabs?.map((tab, index) => (
            <li
              key={index}
              style={{
                cursor: 'pointer',
                padding: '10px',
                borderRadius: '5px',
                transition: 'background-color 0.3s ease',
                marginRight: '5px',
                minWidth: '120px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                borderBottom: editIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color
                borderLeft: '1px solid #ddd', // Added left border
                borderRight: '1px solid #ddd', // Added right border
                backgroundColor: editIndex === index ? '#f8f9fa' : '',
                color: editIndex === index ? '#0056b3' : ''
              }}
              onClick={() => setEditIndex(index)}
              >
              {/* {editIndex === index ? (
                <input
                  type="text"
                  value={tab.heading}
                  onChange={(e) => {
                    const newTabs = [...tabs];
                    newTabs[index].heading = e.target.value;
                    setTabs(newTabs);
                  }} 
                   onBlur={() => updateTabTitle(index, tab.heading)} 
                   style={{ width: '100%', border: 'none', outline: 'none', textAlign: 'center', backgroundColor: 'transparent', color: '#000000' }} 
                 />
               ) : ( 
                <span style={{ cursor: 'pointer' }}>
                  {tab.heading}
                </span>
             )}  */}
            <>
            <span>{tab.heading}</span>
            <div>
              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                {'['}{tab.slabRange.lowerLimit}-{tab.slabRange.upperLimit}{']'}
              </div>
            </div>
          </>
             {/* <span style={{ cursor: 'pointer' }}>
                  {tab.heading}
                </span> */}
            </li>
          ))}
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                cursor: 'pointer',
                padding: '8px 15px',
                borderRadius: '5px',
                marginRight: '5px',
                transition: 'background-color 0.3s ease',
                color: '#0056b3',
                backgroundColor: '#f8f9fa',
              }}
              onClick={addSlabTab}
            >
              + Add
            </span>
            {editIndex !== null && (
              <span
                style={{
                  cursor: 'pointer',
                  padding: '8px 15px',
                  borderRadius: '5px',
                  transition: 'background-color 0.3s ease',
                  color: '#0056b3',
                  backgroundColor: '#f8f9fa',
                }}
                onClick={() => removeTab(editIndex)}
              >
                &times; Remove
              </span>
            )}
          </span>
        </ul>
        <div style={{ padding: '20px', overflowY: 'auto'}}>
   {tabs?.map((tab, index) => (
      <div key={tab.id} style={{ display: editIndex === index ? 'block' : 'none' }}>  
       <div style={{marginTop:'7px'}}>
      <span>Slab Range   </span> 
      <input
              // type="number"
              placeholder="Lower Limit"
              value={index === 0 ? "0" : tab.slabRange.lowerLimit}
              onChange={(e) => {
                if (index !== 0) {
                  const newTabs = [...tabs];
                  newTabs[index].slabRange.lowerLimit = e.target.value;
                  setTabs(newTabs);
                }
              }}
              readOnly={index === 0}
              style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
            />
            <input
              // type="number"
              placeholder="Upper Limit"
              value={tab.slabRange.upperLimit}
              onChange={(e) => {
                const newTabs = [...tabs];
                newTabs[index].slabRange.upperLimit = e.target.value;
                setTabs(newTabs);
              }}
              disabled={disableUpperLimit}
              style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
            />
          <input
            type="checkbox"
            id="upToMaxCheckbox"
            checked={disableUpperLimit}
            onChange={() => setDisableUpperLimit(!disableUpperLimit)}
            style={{ margin: '0 5px', width: '20px', height: '20px' }} // Increase the size of the checkbox
          />
          <label htmlFor="upToMaxCheckbox" style={{ margin: '0' }}>Up to Max</label>
             </div>
           
    <RadioGroup
      aria-label={`premiumOptions_${tab.id}`}
      name={`premiumOptions_${tab.id}`}
      value={tab.selectedOption}
      onChange={(e) => {
        const newTabs = [...tabs];
        newTabs[index].selectedOption = e.target.value;
        setTabs(newTabs);
      }}
    >
     {/* <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium"/>  */}
      {tab.selectedOption === 'netPremium' && (
<div style={{marginLeft:'1rem', marginTop:'20px'}}> 
  <FormControlLabel
    control={
      <Checkbox
        checked={tab.checkboxValue}
        onChange={(e) => {
          const newTabs = [...tabs];
          newTabs[index].checkboxValue = e.target.checked;
          setTabs(newTabs);
        }}
        style={{ color: '#001f3f' }}  // Set the color to dark blue (#001f3f)
      />
    }
    label="NetPremium"
  />
  <Controls.Input
    type="text"
    label="Commission Percentage"
    value={tab.netPremiumContent}
    onChange={(e) => {
      const newTabs = [...tabs];
      newTabs[index].netPremiumContent = e.target.value;
      setTabs(newTabs);
    }}
  />
  </div>
  )}
    {/* <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />    */}
    </RadioGroup>
 {tab.selectedOption === 'subPremiums' &&
  subpremiums[0] &&
  Object.entries(subpremiums[0])?.map(([fieldName]) => (
    <Grid container  key={fieldName} alignItems='center' style={{ marginBottom: '20px', marginLeft: '1rem',marginTop:'20px' }}>
        <Grid item>
      {/* <FormControlLabel */}
        {/* control={ */}
          <Checkbox
            checked={tab.subPremiumsContent?.[fieldName] || false}
            onChange={(e) => {
              const newTabs = [...tabs];
              newTabs[index].subPremiumsContent = {
                ...newTabs[index].subPremiumsContent,
                [fieldName]: e.target.checked,
              };
              setTabs(newTabs);
            }}
            name={`${fieldName}_checkbox`}
            color="primary"
          />
          </Grid>
          <Grid item xs={5}>
            <FormControlLabel
            control={<div/>}
        label={fieldName}
  />
  </Grid>
  <Grid item xs={5}>
      <Controls.Input
        type="text"
        label={"Commission Percentage"}
        value={tab.subPremiumsContent?.[fieldName] || ''}
        onChange={(e) => {
          const value = e.target.value;
          const newTabs = [...tabs];
          newTabs[index].subPremiumsContent = {
            ...newTabs[index].subPremiumsContent,
            [fieldName]: value,
          };
          setTabs(newTabs);
        }}
      />
      </Grid>
    </Grid> 
  ))}
 <label htmlFor={`textarea-${index}`}>Note:</label>
<textarea
  id={`textarea-${index}`}
  value={tab.note}
  onChange={(e) => {
    const newTabs = [...tabs];
    newTabs[index].note = e.target.value;
    setTabs(newTabs);
  }}
  style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ddd', borderRadius: '5px', boxSizing: 'border-box', resize: 'vertical' }}
></textarea>
<Grid style={{display:'flex',flexDirection:'row',gap:'1rem'}}>
     <Controls.Button text="Previous" type="submit" onClick={() => moveToPreviousTab('Prevous')} />
     <Controls.Button text="Next" type="submit" onClick={() => moveToNextTab('Next')} />
</Grid> 
</div>
))}          
 </div>
      </div>
    {/* </div>   */}
    </FormControl>
    </CardContent>
      </Card>   
    )}
 </div>
      </div>
    </div>  
    </FormControl>
          </CardContent>
      </Card>   
    )}

    <Grid style={{marginLeft:'66rem'}}>
    <Controls.Button text='Save' onClick={handleDiscountCardSubmit} /> 
    <Controls.Button text="Cancel" type="submit" onClick={() => handleButtonClick('Cancel')} />
    </Grid>
     </>
   );
 };

 export default AvCommission ;
