import React, { useEffect, useRef, useState } from 'react';
import { Fab, FormControl, Grid, Link, makeStyles } from '@material-ui/core';
import Controls from '../components/controls/Controls';
import { useNavigate } from 'react-router-dom';
import { Form } from '../components/useForm';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import { TextField } from '@material-ui/core';
import * as XLSX from 'xlsx';
import useToggleColumns from '../utils/toggleColumns';
import * as Rest from './../services/restapi';
import Loader from './spinner';

const useStyles = makeStyles((theme) => ({
  customTextField: {
    backgroundColor: 'rgb(234, 237, 240,1)',
    fontSize: '12px',
    height: '19px'
  },

}));

const LeadTemplate = () => {
  const classes = useStyles();
  const [opportunityValues, setOpportunityValues] = useState(
    {
      provider: '',
      product: '',
      subproduct: '',
      prospectid: '',
      beneficiaryid: '',
      referralid: '',
      contacteddate: null,
      opportunitystatus: '',
      probability: '',
      nextcontactdateandtime: '',
      businesstype: '',
      odpremium: 0,
      addonpremium: 0,
      thirdpartypremium: 0,
      tax: 0,
      netpremium: 0,
      grosspremium: 0
    }
  );
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [isTemplateSnackbarOpen, setTemplateSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state


  const [selectedHeadings, setSelectedHeadings] = useState({
    firstname: '',
    lastname: '',
    phonenumber: '',
    addressline1: '',
    addressline2: '',
    addressline3: '',
    city: '',
    state: '',
    provider: '',
    product: '',
    subproduct: '',
  });

  const [formatname, setFormatname] = useState('');
  const [file, setFile] = useState(null);
  const [uploadedHeadings, setUploadedHeadings] = useState([]);
  const [formats, setFormats] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [isTemplateVisible, setIsTemplateVisible] = useState(false);
  const [mapControls, setMapControls] = useState(null);
  const [showDetails, toggleColumns] = useToggleColumns(true);
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [productDetailsValues, setProductDetailsValues] = useState({}); // State to manage dropdown values
  const isProductMount = useRef(true);
  const isFileformatMount = useRef(true);

  const handleDropdownChange = (fieldName, selectedValue) => {
    setProductDetailsValues(prevState => ({
      ...prevState,
      [fieldName]: selectedValue,
    }));
  };

  const handleAddNewClick = () => {
    toggleColumns();
    setIsFormVisible(true);
    setIsTemplateVisible(false);
  };

  const handleTemplatChange = () => {
    toggleColumns();
    setIsTemplateVisible(true);
    setIsFormVisible(false);
  };

  useEffect(() => {
    if (isFileformatMount.current) {
      const fetchData = async () => {
        try {
          const response = await axios.get(Rest.viewfileformate, {
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          });
          setFormats(response?.data?.data);  // Assuming the API response is an array of formats
          if (response?.data?.data.length >= 1) {
            setIsFormVisible(false)
          }
        } catch (err) {
          if (err.response && err.response.data && err.response.data.data) {
            alert(err.response.data.data);
          } else {
            alert(err.message);
          }
        }
      };
      fetchData();
      isFileformatMount.current = false;
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target; // Extract name and value from the input

    // Update the state based on the input name
    setMapControls(prevMapControls => ({
      ...prevMapControls,
      [name]: value
    }));
  };
  console.log("mapcontrols--->", mapControls)
  const handleFormatChange = (event) => {
    const selectedFormat = event.target.value;
    setSelectedFormat(selectedFormat);

    // Find the selected format in the formats array
    const selectedFormatObj = formats?.find((format) => format?.productattributes?.formatname === selectedFormat)?.productattributes;
    setMapControls(selectedFormatObj);
  };
  const handleFormatDropdownChange = (selectedValue, fieldName) => {
    // Update the corresponding field in mapControls
    setMapControls((prevControls) => ({
      ...prevControls,
      [fieldName]: selectedValue,
    }));
  };

  const handleSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };

  const handleTemplateSnackbarClose = () => {
    setTemplateSnackbarOpen(false);
  };


  const handleFileChangeAndUpload = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const headings = Object.keys(XLSX.utils.sheet_to_json(worksheet)[0]);
        setUploadedHeadings(headings);
      };

      reader.readAsArrayBuffer(selectedFile);
    }

    setFile(selectedFile);
  };

  const handleChange = (event, dropdownId) => {
    const newValue = event.target.value;

    // Update the state for the corresponding dropdown
    setSelectedHeadings({
      ...selectedHeadings,
      [dropdownId]: newValue, formatname: formatname
    });
  };

  const formatOption = formats.map((format) => ({
    value: format.productattributes.formatname,
    label: format.productattributes.formatname,
  }))

  const productdetails = products.find(
    (product) => product.product === opportunityValues.product
  )?.attributefields[0];

  const subProductOptions = products?.find(
    (product) => product?.product === opportunityValues?.product
  )?.subProductDetails;

  const handleProductChange = (field, value) => {
    setOpportunityValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));

    // Update mapControls.product with the new value
    setMapControls((prevControls) => ({
      ...prevControls,
      product: value,
    }));
  };
  const handleFormatnameChange = (event) => {
    setFormatname(event.target.value);
  };

  /*  const handleAttributeChange = (fieldName, value) => {
     // Create a shallow copy of opportunityValues to update the attribute field
     const updatedOpportunityValues = { ...opportunityValues };
 
     // Update the specific attribute field
     updatedOpportunityValues.productdetails[fieldName] = value;
 
     // Set the updated state
     setOpportunityValues(updatedOpportunityValues);
   }; */

  const handleAttributeChange = (fieldName, value) => {
    // Create a copy of the opportunityValues object to avoid mutating the state directly
    const updatedOpportunityValues = { ...opportunityValues, productdetails };

    // Update the value based on the field name
    updatedOpportunityValues.productdetails[0][fieldName] = value;
    updatedOpportunityValues.productdetails = updatedOpportunityValues.productdetails[0]
    // Update the state with the updated opportunityValues
    setOpportunityValues(updatedOpportunityValues);
  };
  // Fetch product data on component mount
  useEffect(() => {
    if (isProductMount.current) {
      async function fetchProducts() {
        try {
          const response = await axios.get(Rest.viewallproduct, {
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          });
          const productData = response?.data?.data
          setProducts(productData);
        } catch (error) {
          console.error('Error fetching products:', error);
        }
      }
      fetchProducts();
      isProductMount.current = false;
    }
  }, []);

  useEffect(() => {
    // Fetch product details when selected product changes
    async function fetchSelectedProductData(productid) {
      if (productid) {
        try {
          const response = await axios
            .get(Rest.viewproduct, {
              params: {
                productid: productid
              },
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            });
          setSelectedProduct(response?.data);
        } catch (error) {
          console.error("Error fetching selected product:", error);
        }
      }
    }

    // filter product by name and get productid
    let filteredproductID = products?.find(product => product?.product === opportunityValues?.product)

    if (filteredproductID) {
      fetchSelectedProductData(filteredproductID?.productid);
    }
  }, [opportunityValues?.product, products]);

  const handleSaveTemplate = () => {
    const productname = selectedProduct?.data?.product;
    if (isFormVisible) {
      const uploadData = { ...selectedHeadings, ...productDetailsValues, formatname: formatname, product: productname };
      const formData = {
        productattributes: { ...uploadData }
      }
      axios.post(Rest.savefileformate, JSON.stringify(formData), {
        headers: {
          "Content-Type": "application/json",
          "X-fiftyaccess-Token": token,
        },
      })
        .then((response) => {
          // Handle a successful response (e.g., 200 OK)
          if (response?.data?.code === "S001") {
            setTemplateSnackbarOpen(true); // Open success snackbar
            // Redirect after a short delay (optional)
            setTimeout(() => {
              navigate('/leadtemplate');
              window.location.href = window.location.href;
            }, 2000); // Redirect after 2 seconds
          } else {
            alert(response?.data?.msg || response?.data?.data)
          }
        })
        .catch((err) => {
          // Handle errors such as network issues, timeouts, etc.
          alert("Error: " + err.message);
        });

    } else if (isTemplateVisible) {
      const uploadData = { ...mapControls, ...productDetailsValues, formatname: selectedFormat, product: productname };
      const formData = {
        productattributes: { ...uploadData }
      }
      axios.post(Rest.savefileformate, JSON.stringify(formData), {
        headers: {
          "Content-Type": "application/json",
          "X-fiftyaccess-Token": token
        },
      })
        .then((response) => {
          if (response?.data?.code === "S001") {
            setTemplateSnackbarOpen(true);  // Open success snackbar
            // Redirect after a short delay (optional)
            setTimeout(() => {
              navigate('/leadtemplate')
              window.location.href = window.location.href;
            }, 2000); // Redirect after 2 seconds
          } else {
            alert(response.data.msg)
          }
        })
        .catch((err) => {
          alert("Error: " + err.message);
        });
    }

  }



  const handleSave = () => {
    setIsLoading(true)
    const formData = new FormData();
    formData.append('file', file);
    // Extract the selected values from the selectedHeadings object
    if (isFormVisible) {
      // Create an object to send to the backend API
      const productname = selectedProduct?.data?.product;
      const uploadData = { ...selectedHeadings, ...productDetailsValues, formatname: formatname, product: productname };
      const requestBody = {
        ...uploadData
      };

      formData.append('excelToMongoMapping', JSON.stringify(requestBody));
      axios.post(Rest.prospectfromxl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type for file upload
          "X-fiftyaccess-Token": token
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false)

            setIsSuccessSnackbarOpen(true); // Open success snackbar
            // Redirect after a short delay (optional)
            setTimeout(() => {
              navigate('/allopportunity');
            }, 2000); // Redirect after 2 seconds
          }
        })
        .catch((err) => {
          setIsLoading(false)

        });
    } else if (isTemplateVisible) {
      const requestBody = {
        ...mapControls
      }
      formData.append('excelToMongoMapping', JSON.stringify(requestBody));
      // Make an API request to the backend server
      axios.post(Rest.prospectfromxl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type for file upload
          "X-fiftyaccess-Token": token
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setIsLoading(false)

            setIsSuccessSnackbarOpen(true); // Open success snackbar
            // Redirect after a short delay (optional)
            setTimeout(() => {
              navigate('/allopportunity');
            }, 2000); // Redirect after 2 seconds
          }
        })
        .catch((err) => {
          setIsLoading(false)

        });
    }
  };
  console.log("selectedHeadings====>", selectedHeadings)
  // return (
  //   <div style={{ marginTop: '9rem', marginLeft: '1.5rem' }}>
  //     {isLoading && <CircularProgress />} {/* Render CircularProgress when isLoading is true */}
  //     {/* Your existing JSX */}
  //     <button onClick={handleSave}>Save</button> {/* Example button to trigger handleSave */}
  //   </div>
  // );
  return (
    <div style={{ marginTop: '4rem', marginLeft: '1.5rem' }}>
      <Form>
        <h5>Lead Template</h5>
        <div style={{ marginLeft: '18rem', marginTop: '2rem' }}>
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={6} >
              <div style={{ marginTop: '1rem', marginLeft: '8rem' }} variant="body6" color="text.secondary">Select File</div>
              {formatOption.length > 0 && (
                <div style={{ marginTop: '2rem', marginLeft: '8rem' }} variant="body6" color="text.secondary">Select Template</div>
              )}
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <label htmlFor="uploadfile">
                  <input
                    type="file"
                    accept=".xlsx"
                    id="uploadfile"
                    name="file"
                    style={{ display: "none" }}
                    onChange={handleFileChangeAndUpload}
                  />
                  <Fab
                    style={{ backgroundColor: '#914572', color: '#ffff', marginTop: '4px', marginLeft: '10px', fontSize: '12px', padding: '18px' }}
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    File Upload
                  </Fab>
                  <span style={{ marginLeft: '15px', fontSize: '12px' }}>{file?.name}</span>
                </label>
                <Grid item xs={6} style={{ marginTop: '0.8rem' }}>
                  {formatOption.length > 0 && (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Controls.Dropdowns
                          label="Template"
                          options={formatOption}
                          value={selectedFormat}
                          onChange={handleFormatChange}
                        />
                        {selectedFormat &&
                          <Link onClick={handleTemplatChange} style={{ fontSize: '12px', width: '20px', marginTop: '30px', whiteSpace: 'nowrap', cursor: 'pointer' }}>View Map</Link>
                        }
                      </div>
                      {formatOption.length > 0 && (
                        <Link onClick={handleAddNewClick} style={{ fontSize: '12px', marginLeft: '1rem', cursor: 'pointer' }} >New Template</Link>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {showDetails &&
            <div>
              {isFormVisible && (
                <div style={{ marginLeft: '4.5rem' }}>
                  <FormControl style={{ marginTop: '1rem' }}>
                    <Grid container>
                      <Grid item xs={4} >
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Template Name"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Select Product"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controls.Input
                          name="formatname"
                          label="Template Name"
                          value={formatname}
                          onChange={handleFormatnameChange}
                        />
                        <Controls.Dropdowns
                          options={products?.map((product) => ({
                            value: product?.product,
                            label: product?.product,
                          }))}
                          label="Product"
                          value={opportunityValues?.product}
                          onChange={(e) => handleProductChange('product', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    {productdetails && Object.entries(productdetails).map(([fieldName, fieldValue]) => (
                      <>
                        <Grid container >
                          <Grid item xs={4}>
                            <TextField
                              variant="outlined"
                              size='small'
                              InputProps={{ readOnly: true, }}
                              inputProps={{ className: classes.customTextField }}
                              InputLabelProps={{ shrink: true, }}
                              value={fieldName}
                              onChange={(e) => handleAttributeChange(fieldName, e)}
                            />

                          </Grid>
                          <Grid item xs={4}>
                            <Controls.Dropdowns
                              options={uploadedHeadings.map((headings) => ({
                                value: headings,
                                label: headings,
                              }))}
                              label="Select a heading"
                              value={productDetailsValues[fieldName] || ''}
                              onChange={(e) => handleDropdownChange(fieldName, e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </>
                    ))}
                    <Grid container>
                      <Grid item xs={4}>

                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="First Name"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Last Name"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Phone Number"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Address Line 1"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Address Line 2"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Address Line 3"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="City"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="State"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Provider Name"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Sub Product"
                        />
                      </Grid>
                      <Grid item xs={4}>

                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={selectedHeadings.firstname}
                          onChange={(e) => handleChange(e, 'firstname')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={selectedHeadings.lastname}
                          onChange={(e) => handleChange(e, 'lastname')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={selectedHeadings.phonenumber}
                          onChange={(e) => handleChange(e, 'phonenumber')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={selectedHeadings.addressline1}
                          onChange={(e) => handleChange(e, 'addressline1')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={selectedHeadings.addressline2}
                          onChange={(e) => handleChange(e, 'addressline2')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={selectedHeadings.addressline3}
                          onChange={(e) => handleChange(e, 'addressline3')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={selectedHeadings.city}
                          onChange={(e) => handleChange(e, 'city')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={selectedHeadings.state}
                          onChange={(e) => handleChange(e, 'state')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={selectedHeadings.provider}
                          onChange={(e) => handleChange(e, 'provider')}
                        />

                        <Controls.Dropdowns
                          options={uploadedHeadings?.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={selectedHeadings?.subproduct}
                          onChange={(e) => handleChange(e, 'subproduct')}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </div>
              )}
            </div>
          }
          {showDetails &&
            <div>
              {isTemplateVisible && (
                <div style={{ marginLeft: '4.5rem' }}>
                  <FormControl style={{ marginTop: '1rem' }}>
                    <Grid container>
                      <Grid item xs={4} >
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Select Product"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controls.Dropdowns
                          options={products?.map((product) => ({
                            value: product.product,
                            label: product.product,
                          }))}
                          label="Product"
                          value={mapControls?.product}
                          onChange={(e) => handleProductChange('product', e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <>
                      {productdetails && Object.entries(productdetails).map(([fieldName, fieldValue]) => (
                        <>
                          <Grid container >
                            <Grid item xs={4}>
                              <TextField
                                variant="outlined"
                                size='small'
                                InputProps={{ readOnly: true, }}
                                inputProps={{ className: classes.customTextField }}
                                InputLabelProps={{ shrink: true, }}
                                value={fieldName}
                                onChange={(e) => handleAttributeChange(fieldName, e)}
                              />

                            </Grid>
                            <Grid item xs={4}>
                              {console.log("mapControls[fieldName]", mapControls[fieldName])}
                              <Controls.Dropdowns
                                options={uploadedHeadings.map((headings) => ({
                                  value: headings,
                                  label: headings,
                                }))}
                                label="Select a heading"
                                value={mapControls[fieldName] || ''}
                                onChange={(e) => handleFormatDropdownChange(e.target.value, fieldName)}
                              />
                            </Grid>
                          </Grid>
                        </>
                      ))}
                    </>
                    <Grid container>
                      <Grid item xs={4}>

                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="First Name"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Last Name"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Phone Number"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Address Line 1"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Address Line 2"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Address Line 3"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="City"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="State"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Provider Name"
                        />
                        <TextField
                          variant="outlined"
                          size='small'
                          InputProps={{ readOnly: true, }}
                          inputProps={{ className: classes.customTextField }}
                          InputLabelProps={{ shrink: true, }}
                          value="Sub Product"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={mapControls.firstname}
                          onChange={(e) => handleFormatDropdownChange(e.target.value, 'firstname')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={mapControls.lastname}
                          onChange={(e) => handleFormatDropdownChange(e.target.value, 'lastname')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={mapControls.phonenumber}
                          onChange={(e) => handleFormatDropdownChange(e.target.value, 'phonenumber')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={mapControls.addressline1}
                          onChange={(e) => handleFormatDropdownChange(e.target.value, 'addressline1')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={mapControls.addressline2}
                          onChange={(e) => handleFormatDropdownChange(e.target.value, 'addressline2')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={mapControls.addressline3}
                          onChange={(e) => handleFormatDropdownChange(e.target.value, 'addressline3')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={mapControls.city}
                          onChange={(e) => handleFormatDropdownChange(e.target.value, 'city')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={mapControls.state}
                          onChange={(e) => handleFormatDropdownChange(e.target.value, 'state')}
                        />
                        <Controls.Dropdowns
                          options={uploadedHeadings.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={mapControls.provider}
                          onChange={(e) => handleFormatDropdownChange(e.target.value, 'provider')}
                        />

                        <Controls.Dropdowns
                          options={uploadedHeadings?.map((headings) => ({
                            value: headings,
                            label: headings,
                          }))}
                          label="Select Heading"
                          value={mapControls.subproduct}
                          onChange={(e) => handleFormatDropdownChange(e.target.value, 'subproduct')}
                        />
                      </Grid>
                    </Grid>
                  </FormControl>
                </div>
              )}
            </div>
          }
          <Snackbar
            sx={{ marginLeft: '30rem' }}
            open={isSuccessSnackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message={'Data added successfully'}
          />
          <Snackbar
            sx={{ marginLeft: '30rem' }}
            open={isTemplateSnackbarOpen}
            autoHideDuration={3000}
            onClose={handleTemplateSnackbarClose}
            message={'Template added successfully'}
          />
          <div style={{ marginTop: '2rem', display: "flex", marginLeft: '8rem', gap: '3rem' }}>
            <Controls.Button text="Import Lead" onClick={handleSave} />
            <Controls.Button text="Save Template" onClick={handleSaveTemplate} />
            <Controls.Button text="Cancel" onClick={() => navigate('/allopportunity')} />
          </div>
        </div>
      </Form>
      {isLoading && <Loader />}

    </div>


  );
};

export default LeadTemplate;
