import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Box } from "@mui/material";
import Controls from "../components/controls/Controls";
import { Grid } from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import moment from "moment";

const ALL_FIELDS = [
  { id: "firstname", label: "First Name", value: "" },
  { id: "lastname", label: "Last Name", value: "" },
  { id: "emailid", label: "Email ID", value: "" },
  { id: "phonenumber", label: "Phone Number", value: "" },
  { id: "alternateemails", label: "Alternate Email ID", value: "" },
  {
    id: "gender",
    label: "Gender",
    type: "dropdown", // Add type 'dropdown' to indicate it's a dropdown field
    value: "", // Default value
    options: [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
      { value: "Others", label: "Others" },
      { value: "Not Specified", label: "Not Specified" },
    ],
  },
  {
    id: "dateofbirth",
    label: "Date of Birth",
    type: "datepicker", // Indicate it's a datepicker field
    value: null, // Default value for datepicker
  },
  {
    id: "alternatephonenumbers",
    label: "Alternate Phonenumber",
    value: "",
  },
  {
    id: "communicationpreference",
    label: "Communication Preference",
    value: "",
    type: "dropdown",
    options: [
      { value: "Call", label: "Call" },
      { value: "SMS", label: "SMS" },
      { value: "Email", label: "Email" },
      { value: "Whatsapp", label: "Whatsapp" },
    ],
  },
  { id: "pannumber", label: "PAN Number", value: "" },
  { id: "aadharnumber", label: "Aadhar Number", value: "" },
  {
    id: "addresstype",
    label: "Address Type",
    value: "",
    type: "dropdown",
    options: [
      { value: "Home", label: "Home" },
      { value: "Office", label: "Office" },
      { value: "Other", label: "Other" },
    ],
  },
  { id: "addressline1", label: "Address Line1", value: "" },
  { id: "addressline2", label: "Address Line2", value: "" },
  { id: "city", label: "City", value: "" },
  { id: "district", label: "District", value: "" },
  { id: "state", label: "State", value: "" },
  { id: "pincode", label: "Pincode", value: "" },
  { id: "country", label: "Country", value: "", type: "country",width:'10px' },
];

const DynamicTabs = ({ handleBenificaryChange, prospectdetails }) => {
  const [tabs, setTabs] = useState([
    {
      ...prospectdetails[0].beneficiarydetails?.[0]
    },
  ]);
  useEffect(()=>{
    if(prospectdetails[0].beneficiarydetails?.[0]){
      let newTab= [...tabs];
      newTab[0] = prospectdetails[0].beneficiarydetails?.[0]
      setTabs(newTab)
    }
  },[prospectdetails[0].beneficiarydetails?.[0]])

  const [value, setValue] = useState(0);
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({});

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const addTab = () => {
    const newTabs = [
      ...tabs,
      {
        beneficiaryrelation: "New Tab",
        firstname: "",
        lastname: "",
        emailid: "",
        phonenumber: " ",
        alternateemails: [""],
        gender: "",
        dateofbirth: null,
        alternatephonenumbers: [""],
        communicationpreference: "Call",
        pannumber: "",
        aadharnumber: "",
        addresstype: "Home",
        addressline1: "",
        addressline2: "",
        city: "",
        district: "",
        state: "",
        pincode: "",
        country: "India",
      },
    ];
    setTabs(newTabs);
    //handleBenificaryChange(newTabs);
    setValue(newTabs.length - 1); // Switch to the newly added tab
  };
  const removeTab = () => {
    if (tabs.length <= 1) return; // Ensure at least one tab remains
    const newTabs = [...tabs];
    newTabs.splice(value, 1);
    setTabs(newTabs);
    setValue(Math.min(value, newTabs.length - 1));
    //handleBenificaryChange(newTabs); // Switch to the previous tab
  };

  const updateTabLabel = (index, label) => {
    const newTabs = [...tabs];
    newTabs[index].beneficiaryrelation = label;
    setTabs(newTabs);
  };

  const updateField = (tabIndex, fieldIndex, label, id, value) => {
    const newTabs = [...tabs];
    newTabs[tabIndex][id] = value;
    const errors = { ...validationErrors };

    if (
      id === "firstname" ||
      id === "lastname" ||
      id === "city" ||
      id === "state" ||
      id === "country" ||
      id === "district"
    ) {
      if (!/^[A-Za-z]+$/.test(value)) {
        errors[id] = `Invalid ${id} name`;
      } else {
        delete errors[id];
      }
    } else if (id === "phonenumber") {
      if (!/^\d{10}$/.test(value)) {
        errors[id] = "Invalid phone number";
      } else {
        delete errors[id];
      }
    } else if (id === "aadharnumber") {
      if (!/^\d{12}$/.test(value)) {
        errors[id] = "Invalid Aadhar number";
      } else {
        delete errors[id];
      }
    } else if (id === "pincode") {
      if (!/^\d{6}$/.test(value)) {
        errors[id] = "Invalid pincode";
      } else {
        delete errors[id];
      }
    } else if (id === "pannumber") {
      if (!/^[A-Z\d]{10}$/.test(value)) {
        errors[id] = "PAN number is not Valid";
      } else {
        delete errors[id];
      }
    }

    setValidationErrors(errors);
    /*     setTabs(newTabs);
    handleBenificaryChange(newTabs); */
    const validFields = ALL_FIELDS.map((field) => field.id);
    if (validFields?.includes(id)) {
      tabs[tabIndex][id] = value;
      tabs[0]["dateofbirth"] = moment(value).format("DD-MM-YYYY");
    }
    newTabs[tabIndex][fieldIndex] = { id, label, value };

    setTabs(newTabs);
    handleBenificaryChange(newTabs);
  };

  const renderFields = (tabIndex) => {
    const tab = tabs[tabIndex];
    const renderedFields = [];
    let currentRow = [];
    let currentRowColumns = 0;

    const maxColumnsInRow = 3; // Define the maximum number of columns in a row

    for (let fieldIndex = 0; fieldIndex < ALL_FIELDS?.length; fieldIndex++) {
      const field = ALL_FIELDS[fieldIndex];

      if (tabIndex === 0 && field.id === "dateofbirth") {
        // Handle 'dateofbirth' field as a datepicker
        if (currentRowColumns + 1 <= maxColumnsInRow) {
          currentRow.push(
            <Grid item xs={4} key={fieldIndex}>
              <Controls.DatePicker
                disableFuture
                label={field.label}
                value={field.value}
                onChange={(date) =>
                  updateField(tabIndex, fieldIndex, field.label, field.id, date.toDate())
                }
                format="DD-MM-YYYY"
              />
            </Grid>
          );
          currentRowColumns += 1;
        }
      } else {
        const renderedField = (
          <div key={fieldIndex} style={{ marginBottom: "10px", flex: 1 }}>
            {tabIndex === 0 && field.id === "firstname" ? (
              <Controls.Input
                label={field.label}
                value={prospectdetails[0].beneficiarydetails?.[0]?.firstname}
                onChange={(e) =>
                  updateField(
                    tabIndex,
                    fieldIndex,
                    field.label,
                    field.id,
                    e.target.value
                  )
                }
                readOnly
                type={field.type || "text"}
                error={validationErrors[field.id]}
                helperText={validationErrors[field.id]}
              />
            ) : tabIndex === 0 && field.id === "lastname" ? (
              <Controls.Input
                label={field.label}
                value={prospectdetails[0].beneficiarydetails?.[0]?.lastname}
                onChange={(e) =>
                  updateField(
                    tabIndex,
                    fieldIndex,
                    field.label,
                    field.id,
                    e.target.value
                  )
                }
                readOnly
                type={field.type || "text"}
                error={validationErrors[field.id]}
                helperText={validationErrors[field.id]}
              />
            ) : tabIndex === 0 && field.id === "emailid" ? (
              <Controls.Input
                label={field.label}
                value={prospectdetails[0].beneficiarydetails?.[0]?.emailid}
                onChange={(e) =>
                  updateField(
                    tabIndex,
                    fieldIndex,
                    field.label,
                    field.id,
                    e.target.value
                  )
                }
                readOnly
                type={field.type || "text"}
                error={validationErrors[field.id]}
                helperText={validationErrors[field.id]}
              />
            ) : tabIndex === 0 && field.id === "phonenumber" ? (
              <Controls.Input
                label={field.label}
                value={prospectdetails[0].beneficiarydetails?.[0]?.phonenumber}
                onChange={(e) =>
                  updateField(
                    tabIndex,
                    fieldIndex,
                    field.label,
                    field.id,
                    e.target.value
                  )
                }
                readOnly
                type={field.type || "text"}
                error={validationErrors[field.id]}
                helperText={validationErrors[field.id]}
              />
            ) : field.type === "dropdown" ? (
              <Grid item xs={12}>
                <Controls.Dropdown
                  label={field.label}
                  value={
                    selectedDropdownValues[`${field.id}-${tabIndex}`] || ""
                  }
                  options={field.options}
                  onChange={(e) =>
                    updateDropdownValue(tabIndex, field.id, e.target.value)
                  }
                />
              </Grid>
            ) 
          /*   : field.type === "datepicker" ? (
              <Grid item xs={12}>
                <Controls.DatePicker
                  disableFuture
                  label={field.label}
                  value={field.value}
                  onChange={(date) =>
                    updateField(
                      tabIndex,
                      fieldIndex,
                      field.label,
                      field.id,
                      date
                    )
                  }
                  format="DD-MM-YYYY"
                />
              </Grid>
            )  */
            : field.type === "country" ? (
              <Grid item xs={4}>
                <Controls.Input
                  label="Country"
                  value={tab[field.id]}
                  onChange={(e) =>
                    updateField(
                      tabIndex,
                      fieldIndex,
                      field.label,
                      field.id,
                      e.target.value
                    )
                  }
                  type={field.type || "text"}
                  error={validationErrors[field.id]}
                  helperText={validationErrors[field.id]}
                />
              </Grid>
            ) 
            : (
              <Controls.Input
                label={field.label}
                value={tab[field.id]}
                onChange={(e) =>
                  updateField(
                    tabIndex,
                    fieldIndex,
                    field.label,
                    field.id,
                    e.target.value
                  )
                }
                type={field.type || "text"}
                error={validationErrors[field.id]}
                helperText={validationErrors[field.id]}
              />
            )}
          </div>
        );

        currentRow.push(renderedField);
        currentRowColumns += 1;

        if (
          currentRowColumns >= maxColumnsInRow ||
          fieldIndex === ALL_FIELDS.length - 1
        ) {
          renderedFields.push(
            <div
              key={`row-${fieldIndex}`}
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "10px",
              }}
            >
              {currentRow}
            </div>
          );
          currentRow = [];
          currentRowColumns = 0;
        }
      }
    }

    return <div>{renderedFields}</div>;
  };

  /* const renderFields = (tabIndex) => {
    const tab = tabs[tabIndex];
    const renderedFields = [];
    let currentRow = [];
    let currentRowColumns = 0;
  
    const maxColumnsInRow = 3; // Define the maximum number of columns in a row
  
    for (let fieldIndex = 0; fieldIndex < ALL_FIELDS.length; fieldIndex++) {
      const field = ALL_FIELDS[fieldIndex];
  
      if (field.id === 'dateofbirth') {
        // Handle 'dateofbirth' field as a datepicker
        if (currentRowColumns + 1 <= maxColumnsInRow) {
          currentRow.push(
            <Grid item xs={4} key={fieldIndex}>
              <Controls.DatePicker
                disableFuture
                label={field.label}
                value={field.value}
                onChange={(date) =>
                  updateField(tabIndex, fieldIndex, field.label, field.id, date)
                }
                format="DD-MM-YYYY"
              />
            </Grid>
          );
          currentRowColumns += 1;
        }
      } else {
        const renderedField = (
          <div key={fieldIndex} style={{ marginBottom: '10px', flex: 1 }}>
            {field.type === 'dropdown' ? (
              <Grid item xs={12}>
                <Controls.Dropdown
                  label={field.label}
                  value={selectedDropdownValues[`${field.id}-${tabIndex}`] || ''}
                  options={field.options}
                  onChange={(e) =>
                    updateDropdownValue(tabIndex, field.id, e.target.value)
                  }
                />
              </Grid>
            ) : field.type === 'country' ? (
              <Grid item xs={4}>
                <Controls.Input
                  label="Country"
                  value={field.value}
                  onChange={(e) =>
                    updateField(tabIndex, fieldIndex, field.label, field.id, e.target.value)
                  }
                  type={field.type || 'text'}
                  error={validationErrors[field.id]}
                  helperText={validationErrors[field.id]}
                />
              </Grid>
            ) : (
              <Controls.Input
                label={field.label}
                value={field.value}
                onChange={(e) =>
                  updateField(tabIndex, fieldIndex, field.label, field.id, e.target.value)
                }
                type={field.type || 'text'}
                error={validationErrors[field.id]}
                helperText={validationErrors[field.id]}
              />
            )}
          </div>
        );
  
        currentRow.push(renderedField);
        currentRowColumns += 1;
  
        if (currentRowColumns >= maxColumnsInRow || fieldIndex === ALL_FIELDS.length - 1) {
          renderedFields.push(
            <div
              key={`row-${fieldIndex}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '10px',
              }}
            >
              {currentRow}
            </div>
          );
          currentRow = [];
          currentRowColumns = 0;
        }
      }
    }
  
    return <div>{renderedFields}</div>;
  }; */

  const updateDropdownValue = (tabIndex, fieldId, value) => {
    setTabs((prevTabs) => {
      const updatedTabs = [...prevTabs];
      // Assuming tabs structure is an array of objects and fieldId represents an ID in tabs
      updatedTabs[tabIndex][fieldId] = value;
      return updatedTabs;
    });
    setSelectedDropdownValues((prevValues) => ({
      ...prevValues,
      [`${fieldId}-${tabIndex}`]: value,
    }));
  };

  return (
    <div>
      <AppBar
        position="static"
        style={{
          marginTop: "5px",
          backgroundColor: "#F5F5F5",
          width: "1140px",
          borderRadius: "5px",
          marginLeft: "1.5rem",
          padding: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Tabs value={value} onChange={handleTabChange}>
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.beneficiaryrelation} />
            ))}
          </Tabs>
          <IconButton onClick={addTab}>
            <AddIcon />
          </IconButton>
          <IconButton onClick={removeTab}>
            <RemoveIcon />
          </IconButton>
        </div>
      </AppBar>
      <Box sx={{ bgcolor: "#f5f5f5", marginTop: "1px" }}>
        <div style={{ padding: "20px" }}>
          <div style={{ marginBottom: "10px" }}>
            <Grid item xs={4}>
              <Controls.Input
                label="Relation"
                value={tabs[value].beneficiaryrelation}
                onChange={(e) => updateTabLabel(value, e.target.value)}
              />
            </Grid>
            <Grid>{renderFields(value)}</Grid>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default DynamicTabs;
