
let URL;
// URL = 'http://localhost:3300'
// URL = 'http://65.0.254.136:3300'
//URL = 'http://65.0.254.136:4400'
URL = 'https://insurance.anvayaa.com:4400'

let AnvayyaURL;
// AnvayyaURL = 'https://secureone.anvayaa.in'

AnvayyaURL = 'https://secureone.anvayaa.com'

export { URL }
export const login = AnvayyaURL + '/insuranceLogin';
/* export const login = URL +'/api/login'; */
//import lead 
export const savefileformate = URL + '/api/savefile';

export const viewfileformate = URL + '/api/viewfileformate';

export const prospectfromxl = URL + '/api/prospect/prospectfromxl';

export const leadDetailsWIthNumber = URL + '/api/leadDetailsWIthNumber';

//Referral 
export const addreferralpartner = URL + '/api/addreferralpartner';

//Prospects APIS
export const viewprosect = URL + '/api/prospect/viewprospect';

export const viewallProsects = URL + '/api/prospect/viewallprospect';

export const unAssignedProsects = URL + '/api/prospect/unAssignedProspects';

export const viewAssignedtoMe = URL + '/api/employee/viewassignedtome';

export const toBeContactToday = URL + '/api/employee/toBeContactToday';

export const prospectstatusapi = URL + '/api/prospectstatus';

export const viewallemployees = URL + '/api/employee/viewallemployees';

export const viewallreferredpersondetails = URL + '/api/referredperson/viewallreferredpersondetails';

export const viewallclinettypes = URL + '/api/clinettypes';

export const addprospectdetails = URL + '/api/prospect/addprospectdetails';

export const assignEmployee = URL + '/api/employee/assignemployee';

export const updateProspect = URL + '/api/prospect/updateProspect';

export const addbeneficiary = URL + '/api/prospect/addbeneficiary';

export const updatebeneficiary = URL + '/api/beneficiary/updatebeneficiary';

export const prospectwithNumber = URL + '/api/viewProspects/withNumber';

//Customer API's
export const viewcustomer = URL + '/api/customer/ViewCustomer';

export const createcustomer = URL + '/api/customer/addcustomer';

export const updateCustomer = URL + '/api/customer/updateCustomer';

export const viewallcustomers = URL + '/api/customer/viewallcustomers';

export const assignCustomer = URL + '/api/customer/assigncustomer';

export const viewCustomerassignedtome = URL + '/api/viewcustomers/viewassignedtome';

export const employeetoBeContactToday = URL + '/api/customer/employeetoBeContactToday';

export const unAssignedCustmers = URL + '/api/customers/unAssignedCustmers';

export const customercreateopportunity = URL + '/api/customer/createopportunity';

export const customerwithNumber = URL + '/api/viewCustomers/withNumber';

//Opportunity API's
export const createopportunity = URL + '/api/opportunity/createopportunity';

export const updateopportunity = URL + '/api/update/opportunity';

export const viewOpportunity = URL + '/api/opportunities/viewOpportunity';

export const viewAllOpportunities = URL + '/api/opportunities/viewAllOpportunities';

export const opportunitiesassignemployee = URL + '/api/opportunity/assignemployee';

export const opportunityviewassignedtome = URL + '/api/opportunity/viewassignedtome';

export const opportunitytoBeContactToday = URL + '/api/opportunity/opportunitytoBeContactToday';

export const unAssignedOpportunities = URL + '/api/opportunity/unAssignedOpportunities';

export const viewallbusinesstypes = URL + '/api/businesstypes';

export const opportunitystatusapi = URL + '/api/opportunitystatus';

export const ViewAllProvider = URL + '/api/provider/ViewAllProvider';

export const viewallproduct = URL + '/api/product/viewallproduct';

export const viewproduct = URL + '/api/product/viewproduct';

//interactions
export const addinteractionhistory = URL + '/api/interaction/addinteractionhistory';

export const customerInteractionhistory = URL + '/api/customer/addinteractionhistory';

export const viewInteractionhistory = URL + '/api/viewinteractionhistory';

export const lostreasons = URL + '/api/lostreasons';

//Policy API's

export const premiumfrequency = URL + '/api/premiumfrequency';

export const bookingcodes = URL + '/api/bookingcodes';

export const departments = URL + '/api/departments';

export const addpolicy = URL + '/api/policy/addpolicy';

export const updatepolicy = URL + "/api/policy/updatepolicy";

export const viewpolicy = URL + '/api/policy/viewpolicy';

export const viewallpolicies = URL + '/api/policy/viewallpolicies';

export const convertprospectcreatecustomer = URL + '/api/customer/convertprospectcreatecustomer';

export const locations = URL + '/api/locations';

//sale

export const updatesale = URL + '/api/update/sale';

export const viewallsales = URL + '/api/policy/viewallsales';

export const slabcalculation = URL + '/api/sales/slabcalculation';

export const commisionstatus = URL + '/api/commisionstatus';

// export const viewbasedonsaledate = URL + '/api/viewsales/viewbasedonsaledate';

//Invoices

export const createinvoice = URL + '/api/invoice/createinvoice';

export const viewallinvoice = URL + '/api/invoice/viewallinvoice';

export const updateinvoice = URL + '/api/invoice/updateinvoice';

//reports

export const prospectreports = URL + '/api/prospectreports';

export const viewopportunitybystatus = URL + '/api/opportunityreports';

export const employeewisereports = URL + '/api/employeewisereports';

export const salesreport = URL + '/api/salesreports';

export const invoiceReports = URL + '/api/invoiceReports';

export const MISReports = URL + '/api/view/MISReports';

export const commissionreport = URL + '/api/view/salessummary';

export const viewallemployeecontactdata = URL + '/api/viewallemployeecontactdata';

export const getAllEmployees = AnvayyaURL + '/api/employees/getAllEmployees';

export const discountcalculation = URL + '/api/discountcalculation';

export const getEmployee = AnvayyaURL + '/api/employees/getEmployee';
//Dashboards

export const customerDetailsDashboard = URL + '/api/view/customerDetailsDashboard';

export const salesDetailsDashboard = URL + '/api/view/salesDetailsDashboard';

export const salessummary = URL + '/api/view/salessummary';


//commission
export const addarea = URL + '/api/addarea';
export const viewarea = URL + '/api/viewarea';
export const updatearea = URL + '/api/updatearea';
export const updatestatus = URL + '/api/updatestatus';
export const viewcommission = URL + '/api/commission/tree';
export const createcommission = URL + '/api/commission/createcommission';
export const viewSlabcommission = URL + '/api/commission/viewSlabcommission';
export const updateslabcommission = URL + '/api/commission/updateslabcommission';
export const deletecommission = URL + '/api/commission/deletecommission';
