import React, { useEffect, useRef, useState } from 'react';
import { Typography, Container, Grid } from '@material-ui/core';
import Controls from '../components/controls/Controls';
import { Form } from '../components/useForm';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { Snackbar } from '@mui/material';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Fab, Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete'; 
import {  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,  } from "@mui/material";
import * as Rest from  './../services/restapi';
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import calendar from'./../assests/icons/calendar.png';
import asterisk from './../assests/icons/asterisk.png'
import { makeStyles } from '@material-ui/core';
import LoadingSpinner from '../components/controls/spinner'; 

const useStyles = makeStyles((theme) => ({
  customIcon: {
    width: 8, // Set the desired width of your custom icon
    height: 8, // Set the desired height of your custom icon
    verticalAlign: 'super',
    fontSize: '0.7em',
    marginRight: theme.spacing(17), 
  },
}));

const addons =[
  {value:'Yes',label:'Yes'},
  {value:'No',label:'No'}
]


const ncb =[
  {value:0,label:0},
  {value:20,label:20},
  {value:25,label:25},
  {value:30,label:30},
  {value:35,label:35},
  {value:50,label:50},
]
function capitalizeFirstLetter(string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const AvPolicy = () => {
  const classes = useStyles();
  const location =  useLocation();
  const updatePolicy = location?.state?.updatePolicy;

  const [policyValues, setPolicyValues] = useState(location?.state?.updatePolicy);


  console.log("policyValues",policyValues)
  const [openDialog, setOpenDialog] = useState(false);
  const [daysCount, setDaysCount] = useState('');
  /* const [policyValues, setPolicyValues] = useState({
    customerid:"",
    beneficiaryid:"",
    provider:"",
    product:"",
    subproduct:"",
    bookingcode:"",
    policytype:"",
    policystartdate:"",
    policyenddate:"",
    premiumpayfrequency:"",
    salespersonname:"",
    tax: 0,
    saledate:null,
    department:'',
    odpremium:0,
    thirdpartypremium:0,
    addonpremium:0,
}); */
const formatDate = (date) => {
  const dateObject = new Date(date * 1000);

  if (isNaN(dateObject.getTime())) {
    // Handle invalid date
    return "";
  }
  return dateObject;
};

const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const customer = location?.state;
  const opportunityValues=location?.state?.opportunityValues;
  const prospectid= policyValues?.prospectid;
  const customerid = policyValues.customerid;
  const beneficiaryid = policyValues.beneficiaryid;
  const referralid = customer?.referralid;
  const [selectedBeneficiary, setSelectedBeneficiary] = useState('');
  const [providerOptions, setProviderOptions] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [selectedPolicyType, setSelectedPolicyType] = useState('');
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const opportunityid= policyValues.opportunityid;
  const opportunitystatus = customer?.opportunitydetails?.[0]?.opportunitystatus;
  const navigate = useNavigate();
  const [bookingCodes, setBookingCodes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [premiumFrequencyOptions, setPremiumFrequencyOptions] = useState([]);
  const policyDetails = customer?.policydetails?.[0];
  const [file, setFile] = useState(null);
  const [filename, setFileName] = useState(null);
  const [fields, setFields] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [attachmentToDeleteIndex, setAttachmentToDeleteIndex] = useState(null);
  const [policycopy, setPolicycopy] = useState([{ file: null, fileName: '' }]);
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [attachments, setAttachments] = useState([{ file: null, fileName: '' }]);
  const [locations, setLocations] = useState({});
  const [selectedState, setSelectedState] = useState(policyValues?.state);
  const [selectedCity, setSelectedCity] = useState(policyValues?.city);
  const states = Object.keys(locations);
  const cities = locations[selectedState] || [];
  const isLocMount = useRef(true);
  const isPFMount = useRef(true);
  const isCodeMount = useRef(true);
  const isDeptMount = useRef(true);
  const isProviderMount = useRef(true);
  const isProductMount = useRef(true);
  const [loading, setLoading] = useState(false)


  const handlePolicyFileChange = (event, index) => {
    const updatedAttachments = [...policycopy];
    const file = event.target.files[0];
  
    // Check if a file is selected
    if (file) {
      updatedAttachments[index].file = file;
      setPolicycopy(updatedAttachments);
    }
  };

  const handleFileChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const file = event.target.files[0];
  
    // Check if a file is selected
    if (file) {
      updatedAttachments[index].file = file;
      setAttachments(updatedAttachments);
    }
  };

  const handleFileNameChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const currentAttachment = updatedAttachments[index];
  
    const currentFile = currentAttachment.file;
    const newFileName = event.target.value;
  
    // Remove the existing extension if present and append the new extension
    const updatedFileName = removeFileExtension(newFileName) + getFileExtension(currentFile.name);
  
    const updatedFile = new File([currentFile], updatedFileName, { type: currentFile.type });
    // Update the attachment object with the modified file name and file
    updatedAttachments[index] = {
      ...updatedAttachments[index],
      file: updatedFile,
      fileName: event.target.value,
    };
  
    setAttachments(updatedAttachments);
  };
  
  // Helper function to get file extension
  const getFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(lastDotIndex) : '';
  };

  const removeFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(0, lastDotIndex) : fileName;
  };
  
   const addAttachment = () => {
     setAttachments([...attachments, { file: null, fileName: '' }]);
   };
  
   const removeAttachment = (index) => {
     const updatedAttachments = attachments.filter((_, i) => i !== index);
     setAttachments(updatedAttachments);
   };
   const handleDeleteAttachment = (index) => {
    setAttachmentToDeleteIndex(index);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete operation
    const updatedAttachments = attachments.filter((_, i) => i !== attachmentToDeleteIndex);
    setAttachments(updatedAttachments);

    setIsDeleteDialogOpen(false);
    setAttachmentToDeleteIndex(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setAttachmentToDeleteIndex(null);
  };
  const handleSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };
  const [errorMessage, setErrorMessage] = useState('');
  const [premiumErrors, setPremiumErrors] = useState({});
  const subpremiumsdetails = products.find(product => product.product === policyValues.product)?.subpremiums[0];

  const subpremiums = Object.keys(subpremiumsdetails || {}).reduce((acc, key) => {
    acc[key] = (policyValues.subpremiums || {})[key] || subpremiumsdetails[key] || '';
    return acc;
  }, {});

  const productDetails = products.find(product => product.product === policyValues.product)?.attributefields[0];

  const productdetails = Object.keys(productDetails || {}).reduce((acc, key) => {
    acc[key] = (policyValues.productdetails || {})[key] || productDetails[key] || '';
    return acc;
  }, {});

  const subProductOptions = products.find(
    (product) => product.product === policyValues.product
  )?.subProductDetails;

  const policyOption = subProductOptions
  ?.find((product) => product.product === policyValues.product)
  ?.subProductDetails?.policyTypeDetails.map((policyType) => ({
      value: policyType,
      label: policyType, 
  })) || products.find((product) => product.product === policyValues.product)
  ?.subProductDetails?.[0]?.policyTypeDetails.map((policyType)=>({
    value:policyType,
    label:policyType
  })) || []

  useEffect(() => {
    if(isLocMount.current){
    axios.get(Rest.locations,{
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then((response) => {
        setLocations(response?.data?.locations);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
      isLocMount.current=false;
    }
  }, []);
   
  useEffect(() => {
    const netPremium = parseFloat(policyValues.netpremium) || 0;
    const tax = parseFloat(policyValues.tax) || 0;
  
    const newGrossPremium = netPremium + tax;
    setPolicyValues((prevValues) => ({
      ...prevValues,
      grosspremium: newGrossPremium || 0,
    }));
  }, [policyValues.netpremium, policyValues.tax]);

    const beneficiaryOptions = customer?.customer?.beneficiarydetails?.[0]?.map((beneficiary) => ({
      value: `${capitalizeFirstLetter(beneficiary.firstname)} ${capitalizeFirstLetter(beneficiary.lastname)}`,
      label: `${capitalizeFirstLetter(beneficiary.firstname)} ${capitalizeFirstLetter(beneficiary.lastname)}`,
    }));

    useEffect(() => {
      // When the component mounts, populate the state with the customer data
      if (policyDetails) {
        setPolicyValues(policyDetails);
      }
    }, [policyDetails]);

    // const handlePolicyChange = (field, value) => {
    //   setPolicyValues((prevValues) => ({
    //     ...prevValues,customerid:customerid,beneficiaryid:beneficiaryid,
    //     [field]: value,
    //   }));
    // };
    const handlePolicyChange = (field, value) => {
      const validNumberRegex =  /^-?\d*\.?\d*(%)?$/;
    
      if (field === 'tax') {
        if (validNumberRegex.test(value)) {
          // Clear any previous error message if the input is valid
          setErrorMessage('');
          setPolicyValues((prevValues) => ({
            ...prevValues,
            customerid: customerid,
            beneficiaryid: beneficiaryid,
            [field]: value,
          }));
        } else {
          // Set an error message for invalid input
          setErrorMessage('Please enter a value');
        }
      }
      else {
      setPolicyValues((prevValues) => ({
        ...prevValues,
        customerid: customerid,
        beneficiaryid: beneficiaryid,
        [field]: value,
      }));
    };
      setPolicyValues((prevValues) => ({
        ...prevValues,
        customerid: customerid,
        beneficiaryid: beneficiaryid,
        saledate:moment(policyValues.saledate*1000)?.format("DD-MM-YYYY"),
        policystartdate:moment(policyValues.policystartdate*1000)?.format("DD-MM-YYYY"),
        policyenddate:moment(policyValues.policyenddate*1000)?.format("DD-MM-YYYY"),
        [field]: value,
      }));
    };
    
    
    const handlePolicyTypeChange = (fieldName, value) => {
      setPolicyValues(prevPolicyValues => ({
        ...prevPolicyValues,

        policydetails: [{
          ...prevPolicyValues?.policydetails?.[0],
          [fieldName]: value
        }]
      }));
    };
    
    const handleCancel = () => {
      // Use the navigate function to go back to the ViewProspect page
      navigate(-1); // Replace with the actual path to ViewProspect
    };  
    
    const handleStartDateChange = (date) => {
      const policystartdate = moment(date)?.format("DD-MM-YYYY") 


      console.log("policystartdate",policystartdate)
      setPolicyValues((policyValues)=> ({...policyValues,policystartdate:policystartdate}));
    }
    const handleEndDateChange = (date) => {
      const policyenddate = moment(date)?.format("DD-MM-YYYY") 
      setPolicyValues((policyValues)=> ({...policyValues,policyenddate:policyenddate}));
    }

    const handleOpenDialog = (date) => {
      const startDate = moment(policyValues.policystartdate*1000).format("DD-MM-YYYY");
      const endDate = moment(date).format("DD-MM-YYYY");
      console.log("startdate",startDate,"enddate",endDate)
       const start = moment(startDate, 'DD-MM-YYYY');
       const end = moment(endDate, 'DD-MM-YYYY');
       if (!start.isValid() || !end.isValid()) {
         return 0; 
       }
       const days = end.diff(start, 'days');
      setDaysCount(days);
      setOpenDialog(true);
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false);
    };

    const handleSaleDateChange = (date) => {
      const saledate = moment(date)?.format("DD-MM-YYYY") 
      setPolicyValues((policyValues)=> ({...policyValues,saledate:saledate}));
     
    }
    
    const handleProviderChange = (event) => {
      const provider = event;
      setPolicyValues((policyValues)=> ({...policyValues,provider:provider}));
    };

    const handleStateChange = (event) => {
      const state = event;
      /* setSelectedBookingCode(bookingcodes); */
      setSelectedState(state)
      setPolicyValues((policyValues)=> ({...policyValues,state:state}));
    };

    const handleCityChange = (event) => {
      const city = event;
      /* setSelectedBookingCode(bookingcodes); */
      setPolicyValues((policyValues)=> ({...policyValues,city:city}));
    };

    const handleBookingCodeChange = (event) => {
      const bookingcodes = event;
      /* setSelectedBookingCode(bookingcodes); */
      setPolicyValues((policyValues)=> ({...policyValues,bookingcode:bookingcodes}));
    };
    const handleDepartmentChange = (event) => {
      const department =event;
      setPolicyValues((policyValues)=> ({...policyValues,department:department}));
      /* setSelectedDepartment(event.target.value); */
    };
    function calculateNetpremium(subpremiums) {
      if (subpremiums) {
        let sum = 0;
        const myObject = subpremiums;
    
        for (const key in myObject) {
          const value = myObject[key];
          if (value) {
            sum += parseFloat(value);
          }
          
        }
        setPolicyValues((prevValues) => ({
          ...prevValues,
          netpremium: sum,
        }));
        return sum;
      }
    
      return 0; // Return a default value if subpremiums are not available
    }
    const handlePremiumChange = (fieldName, value) => {
      // Create a copy of the opportunityValues object to avoid mutating the state directly
      const updatedOpportunityValues = { ...policyValues,subpremiums };
      updatedOpportunityValues.subpremiums[fieldName] = value;
      updatedOpportunityValues.subpremiums = updatedOpportunityValues.subpremiums
      setPolicyValues(updatedOpportunityValues);
    };

    const handleAttributeChange = (fieldName, value) => {
      // Create a copy of the opportunityValues object to avoid mutating the state directly
      const updatedOpportunityValues = { ...policyValues,productdetails };
      
      // Update the value based on the field name
      updatedOpportunityValues.productdetails[fieldName] = value;
      if (fieldName === "Claim Status" && value === "Yes") {
        updatedOpportunityValues["Eligible NCB %"] = 0; 
      }
      updatedOpportunityValues.productdetails = updatedOpportunityValues.productdetails
      // Update the state with the updated opportunityValues
      setPolicyValues(updatedOpportunityValues);
    };
    
    // Function to handle changes in subpremiums
  const handleSubpremiumChange = (event, index) => {
    const { name, value } = event.target;
    const updatedSubpremiums = [...policyValues.subpremiums];
    updatedSubpremiums[index][2] = value;

    setPolicyValues({
      ...policyValues,
      subpremiums: updatedSubpremiums,
    });
  };

  useEffect(() => {
    if(isPFMount.current){
    fetch(Rest.premiumfrequency,{
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response has a 'premiumfrequency' property
        const options = data?.premiumfrequency || [];
        setPremiumFrequencyOptions(options);
      })
      .catch((error) => {
        console.error('Error fetching premium frequency options:', error);
      });
      isPFMount.current=false;
    }
  }, []);

  useEffect(() => {
    if(isCodeMount.current){
    fetch(Rest.bookingcodes,{
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBookingCodes(data?.bookingcodes);
      })
      .catch((error) => {
        console.error('Error fetching booking codes:', error);
      });
      isCodeMount.current=false;
    }
  }, []);

  useEffect(() => {
    if(isDeptMount.current){
    fetch(Rest.departments,{headers: {
      'Content-Type': 'application/json',
      "X-fiftyaccess-Token": token
    },})
      .then((response) => response.json())
      .then((data) => {
        setDepartments(data?.departments);
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
      isDeptMount.current=false;
    }
  }, []);
  
      useEffect(() => {
      if(isProviderMount.current){
      fetch(Rest.ViewAllProvider,{
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
        .then(response => response.json())
        .then(data => {
          // Update the options state with the fetched data
          setProviderOptions(data.Data); // Assuming `setDropdownOptions` is a state setter function
        })
        .catch(error => {
          // Handle error
        });
        isProviderMount.current=false;
      }
    }, []);

// Fetch product data on component mount
useEffect(() => {
  if(isProductMount.current){
  async function fetchProducts() {
    try {
      const response = await axios.get(Rest.viewallproduct,{
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      });
      const productData = response?.data?.data
      setProducts(productData);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  }
  fetchProducts();
  isProductMount.current=false;
}
}, []);

useEffect(() => {
  // Fetch product details when selected product changes
  async function fetchSelectedProductData(productid) {
    if (productid) {
      try {
        const response = await axios
        .get(Rest.viewproduct,{
          params:{
            productid:productid
          },
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        }
        );
        setSelectedProduct(response?.data);
      } catch (error) {
        console.error("Error fetching selected product:", error);
      }
    }
  }
  
  // filter product by name and get productid
  let filteredproductID = products.find(product=>product.product===policyValues.product)

  if(filteredproductID){
  fetchSelectedProductData(filteredproductID.productid);}
}, [policyValues.product, products]);
const isValidNumber = (value) => {
  const validNumberRegex = /^-?\d*\.?\d*(%)?$/;;
  return validNumberRegex.test(value);
};


const handleUpdate = async () => {
  setLoading(true);
  const formData = new FormData();
  policycopy.forEach((policy, index) => {
    formData.append('policycopy', policy.file);
  });
        attachments.forEach((attachment, index) => {
          formData.append(attachment.file ? removeFileExtension(attachment.file.name) : '', attachment.file);
        });
//  const saledate = moment(policyValues.saledate*1000).format("DD-MM-YYYY")     
//  const policystartdate = moment(policyValues.policystartdate*1000).format("DD-MM-YYYY")
// const policyenddate = moment(policyValues.policyenddate*1000).format("DD-MM-YYYY")
    //  const saledate = saledate ? saledate : moment(policyValues?.saledate * 1000)?.format('DD-MM-YYYY');
    // const policystartdate = policystartdate ? policystartdate : moment(policyValues?.policystartdate * 1000)?.format('DD-MM-YYYY');
    //   const policyenddate = policyenddate ? policyenddate : moment(policyValues?.policyenddate * 1000)?.format('DD-MM-YYYY');
// console.log("saledate",saledate,"policystartdate",policystartdate,"policyenddate",policyenddate,policyValues)  
  const updatedPolicyValues = {...policyValues}
  formData.append('subpremiums', JSON.stringify(subpremiums));
  formData.append('policyvalues', JSON.stringify(updatedPolicyValues));
  try {
    const response = await fetch(Rest.updatepolicy,
      {
        method: "PUT",
        headers: {
          "X-fiftyaccess-Token": token
        },
        body: formData,
      }
    );
    if(response.status === 200){
    setIsSuccessSnackbarOpen(true); // Open success snackbar
    // Redirect after a short delay (optional)
    setTimeout(() => {
      navigate(-1);
    }, 2000); 
    }
    // You can also reset the form or perform other actions as needed
  } catch (err) {

  }finally {
    setLoading(true); // Set loading to false after the API request is completed (success or failure)
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }
};

  useEffect(() => {
    if (opportunitystatus === 'ClosedandWon' || opportunitystatus === null) {
      // Auto-fill AV Policy fields from Opportunity's Product Details
      const opportunityProductDetails = customer?.opportunitydetails?.[0]?.productdetails;
      if (opportunityProductDetails) {
      

        setPolicyValues((prevValues) => ({
          ...prevValues,
          // Map Opportunity Product Details fields to AV Policy fields
          productdetails: {
            ...prevValues.productdetails,
            "Make": opportunityProductDetails.Make || '',
            "Model": opportunityProductDetails.Model || '',
            "Year of Manufacture": opportunityProductDetails["Year of Manufacture"] || '',
            "CC": opportunityProductDetails.CC || '',
            "Fuel Type": opportunityProductDetails["Fuel Type"] || '',
           "Age of the Vechile": opportunityProductDetails["Age of the Vechile"] || '',
            "Registration Number": opportunityProductDetails["Registration Number"] || '',
            "ClaimStatus": opportunityProductDetails.ClaimStatus || '',
            "Eligible NCB %": opportunityProductDetails["Eligible NCB %"] || '',
            "Addons Opted (Y/N)": opportunityProductDetails["Addons Opted (Y/N)"] || '',
          },
          // Map Opportunity Premium Details to AV Policy fields
          
              // Auto-fill premium details from customer object
            
                odpremium: opportunityValues?.[0]?.odpremium || 0,
                addonpremium: opportunityValues?.[0]?.addonpremium || 0,
                thirdpartypremium: opportunityValues?.[0]?.thirdpartypremium || 0,
        }));
      }
    }
  }, [opportunitystatus, customer]);

  useEffect(() => {
      // Auto-fill dropdown values from Opportunity data
      const opportunityData = customer?.opportunitydetails?.[0];

      if (opportunityData) {
        // Set dropdown values based on opportunity data
        setPolicyValues((prevValues) => ({
          ...prevValues,
          provider: opportunityData.provider || '',
          product: opportunityData.product || '',
          subproduct: opportunityData.subproduct || '',
          policytype: opportunityData.policytype || '',
          premiumpayfrequency: opportunityData.premiumpayfrequency || '',
          // Add more dropdowns as needed
        }));
      }
  }, [opportunitystatus, customer]);
 
// Inside your component, update the form fields in the useEffect

  return (
    <div>
      <div style={{display:'flex',flexDirection:'row',gap:'60rem',marginTop:'5rem'}}>
      <Typography style={{marginLeft:'2rem'}} variant="h6" align="center" gutterBottom>
        Policy
      </Typography>
      <div>
      <Typography  variant="body6" align="center" gutterBottom>
        {policyValues?.customerid}
      </Typography>
      {/* <Typography  variant="body6" align="center" gutterBottom>
        <p>{capitalizeFirstLetter(customer?.firstname)} {capitalizeFirstLetter(customer?.lastname)}</p>
      </Typography> */}
      </div> 
      </div>
      <Container>
        <Form >
          <Grid container>
            <Grid item xs={4} style={{marginTop:'0.5rem'}}>
            <Controls.Dropdown
            label={
              <>
            Provider Name<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
              </>
            }
              
              options={providerOptions.map(option => ({ 
                value: option.provider, 
                label: option.provider 
              }))}
              value={policyValues.provider}
              onChange={(e)=>handleProviderChange( e.target.value)}
            />
            <Controls.Dropdown
              options={products?.map((product) => ({
              value: product.product,
              label: product.product,
              }))}
              label={
                <>
              Product<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                </>
              }
             
              value={policyValues.product}
              onChange={(e) => handlePolicyChange('product', e.target.value)}
            />
             { subProductOptions?.length >1 && (
            <Controls.Dropdown
              options={subProductOptions?.map((subProduct) => ({
              value: subProduct.subproduct,
              label: subProduct.subproduct
              })) || []}
              label="Sub Product"
              value={policyValues?.subproduct}
              onChange={(e) =>
                handlePolicyChange('subproduct', e.target.value)
              }
              />
             )}
            <Controls.Dropdown
              options={policyOption}
             
              label={
                <>
              Policy Type<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                </>
              }
              value={policyValues.policytype} 
              onChange={(e) => handlePolicyChange('policytype', e.target.value)}  
              />    
                <Controls.Dropdown
              label="Premium Frequency"
              options={premiumFrequencyOptions.map((option) => ({
              value: option,
              label: option,
              }))}
              value={policyValues.premiumpayfrequency}
              onChange={(e) => handlePolicyChange('premiumpayfrequency', e.target.value)}
            />  
                 
            </Grid>
         
           
            <Grid item xs={4} style={{marginTop:'0.3rem'}}>
            <Controls.Input 
              label='Policy Number'
              value={policyValues.policynumber}
              onChange={(e) => handlePolicyChange('policynumber', e.target.value)}
            />
            <Controls.Dropdown
            label={
              <>
            Department<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
              </>
            }
                options={departments?.map((dept)=>({
                  value:dept,
                  label:dept
                }))}
                value={policyValues.department}
                /* onChange={handleDepartmentChange} */
                onChange={(e)=>handleDepartmentChange(e.target.value)}
              />
            <Controls.Dropdown
            label={
              <>
            Booking Code<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
              </>
            }
                options={bookingCodes?.map((code)=>({
                  value:code,
                  label:code
                }))}
                value={policyValues.bookingcode}
                onChange={(e)=>handleBookingCodeChange( e.target.value)}
              />
              <Controls.Dropdown
              options={states.map((option)=>({
                label:option,
                value:option
              }))}
              label={
                <>
              State <span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                </>
              }
              
              value={policyValues.state} 
              onChange={(e) => handleStateChange(e.target.value)} 
              /> 
               <Controls.Dropdown
              options={cities.map((option)=>({
                label:option,
                value:option
              }))}
              label={
                <>
              City<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                </>
              }
            
              value={selectedCity}  
              onChange={(e) => handleCityChange(e.target.value)} 
              /> 
               {/* <Controls.Input
              label='Sale Number'
              options={providerOptions.map(option => ({ 
                value: option.provider, 
                label: option.provider 
              }))}
              value={policyValues.provider}
              onChange={(e)=>handleProviderChange( e.target.value)}
            /> */}
            </Grid>
            <Grid item xs={4}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
        format="DD-MM-YYYY"
          sx={{backgroundColor:'rgb(234, 237, 240,1)',
        borderRadius:'5px', 
        "& .MuiInputLabel-root": { // Applying styles to the label
          fontSize: '12px', // You can adjust this value as needed
        },
        '& .MuiSvgIcon-root': {
          width: '24px', // Adjust the width of the icon to fit your image
          height: '24px', // Adjust the height of the icon to fit your image
          background: `url(${calendar})`, // Set the custom calendar image as the background
          backgroundSize: 'contain', // Adjust the background size as needed
          display: 'block', // Set the display to block to occupy the space
        },
        '& input': {
          width: '30', // Adjust the width of the input field
          height: '30', // Adjust the height of the input field
          padding: '12px', // Adjust the padding around the input content
          fontSize: '12px', // Adjust the font size of the input content
          alignContent:'center',
        },
      }}
      slotProps={{ textField: { size: 'small' } }}
      label='Sale Date'
      defaultValue={dayjs(policyValues.saledate*1000)}
     onChange={e=>{handleSaleDateChange(e.toDate())}}
        />
      </DemoContainer>
    </LocalizationProvider>

    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
        format="DD-MM-YYYY"
          sx={{backgroundColor:'rgb(234, 237, 240,1)',
        borderRadius:'5px', 
        "& .MuiInputLabel-root": { // Applying styles to the label
          fontSize: '12px', // You can adjust this value as needed
        },
        '& .MuiSvgIcon-root': {
          width: '24px', // Adjust the width of the icon to fit your image
          height: '24px', // Adjust the height of the icon to fit your image
          background: `url(${calendar})`, // Set the custom calendar image as the background
          backgroundSize: 'contain', // Adjust the background size as needed
          display: 'block', // Set the display to block to occupy the space
        },
        '& input': {
          width: '30', // Adjust the width of the input field
          height: '30', // Adjust the height of the input field
          padding: '12px', // Adjust the padding around the input content
          fontSize: '12px', // Adjust the font size of the input content
          alignContent:'center',
        },
      }}
      slotProps={{ textField: { size: 'small' } }}
      label='Policy Start Date'
      defaultValue={dayjs(policyValues.policystartdate*1000)}
     onChange={e=>{handleStartDateChange(e.toDate())}}
        />
      </DemoContainer>
    </LocalizationProvider>
              
           {/*  { policyValues.policyenddate && (
              <div style={{marginTop:'0.5rem',marginLeft:'0.4rem'}}>
                 <label style={{fontSize:'10px',marginLeft:'0.5rem',paddingTop:'0px'}} htmlFor="policyenddate">Policy End Date
                  <span style={{color: 'red', fontSize: '16px', marginLeft: '2px'}}>*</span></label>
                <Controls.ReactDatepicker 
                
                label='Policy End Date '
                  defaultValue={formatDate(policyValues.policyenddate)}
                  onChange={e=>{handleEndDateChange(e)}}         
              />
              
              </div>
              )}  */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
        format="DD-MM-YYYY"
          sx={{backgroundColor:'rgb(234, 237, 240,1)',
        borderRadius:'5px', 
        "& .MuiInputLabel-root": { // Applying styles to the label
          fontSize: '12px', // You can adjust this value as needed
        },
        '& .MuiSvgIcon-root': {
          width: '24px', // Adjust the width of the icon to fit your image
          height: '24px', // Adjust the height of the icon to fit your image
          background: `url(${calendar})`, // Set the custom calendar image as the background
          backgroundSize: 'contain', // Adjust the background size as needed
          display: 'block', // Set the display to block to occupy the space
        },
        '& input': {
          width: '30', // Adjust the width of the input field
          height: '30', // Adjust the height of the input field
          padding: '12px', // Adjust the padding around the input content
          fontSize: '12px', // Adjust the font size of the input content
          alignContent:'center',
        },
      }}
      slotProps={{ textField: { size: 'small' } }}
      label='Policy End Date'
      defaultValue={dayjs(policyValues.policyenddate*1000)}
      onChange={(e) => {
        handleEndDateChange(e.toDate());
        handleOpenDialog(e.toDate())}}
        />
          
      </DemoContainer>
    </LocalizationProvider>
    <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Policy Duration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This policy will be active for {daysCount} days. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleCloseDialog}>Cancel</button>
          <button onClick={handleCloseDialog}>OK</button>
        </DialogActions>
      </Dialog>
    <Controls.Input 
              label='Dicount'
              value={policyValues.discount}
              onChange={(e) => handlePolicyChange('discount', e.target.value)}
            />  
              {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}    
        
   
            </Grid>
           
            </Grid>
            <div>Premium Details</div>
            <Grid container>
              {subpremiums && Object.entries(subpremiums).map(([fieldName, fieldValue]) => (
           <Grid item xs={4} key={fieldName}>
            <Controls.Input
            type="number"
            label={fieldName}
            value={fieldValue}
            // onChange={(e) => {handlePremiumChange(fieldName, e.target.value);calculateNetpremium(subpremiums)}}
            onChange={(e) => {  const value = e.target.value;
              if (isValidNumber(value)) {
                handlePremiumChange(fieldName, value);
                setPremiumErrors((prevErrors) => ({
                  ...prevErrors,
                  [fieldName]: '',
                }));
              } else {
                setPremiumErrors((prevErrors) => ({
                  ...prevErrors,
                  [fieldName]: 'Please Enter Only Values',
                }));
              }
              calculateNetpremium(subpremiums)
            }}
          />
          {premiumErrors[fieldName] && (
            <div style={{ color: 'red', fontSize: '10px' }}>{premiumErrors[fieldName]}</div>
          )}
          </Grid>
            ))}
                  <Grid item xs={4}>
                   <Controls.Input 
              label='Tax Amount'
              value={policyValues.tax}
              onChange={(e) => handlePolicyChange('tax', e.target.value)}
            />
       {errorMessage && <div style={{ color: 'red', fontSize: '10px' }}>
        {errorMessage}
        </div>}
            </Grid>
            <Grid item xs={4} >           
            <Controls.Input 
              label='Net Premium'
              value={policyValues.netpremium}
              onChange={(e) => handlePolicyChange('netpremium', e.target.value)}
              readOnly={true}
            />
            </Grid>
                  <Grid item xs={4}>
            <Controls.Input 
              label='Gross premium'
              value={policyValues.grosspremium}
              onChange={(e) => handlePolicyChange('grosspremium', e.target.value)}
              readOnly={true}
            />
            </Grid>
            </Grid>
            {subProductOptions && (

            <> 
            <div>Product Details</div>
            <Grid container >
            {productdetails && Object.entries(productdetails).map(([fieldName, fieldValue]) => (
            <Grid item xs={4}>
              {fieldName === "Addons Opted (Y/N)" ? (
      <Controls.Dropdown
        options={addons}
        label={fieldName}
        value={fieldValue}
        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
      />
    ):fieldName === "Claim Status" ? (
      <Controls.Dropdown
        options={addons}
        label={fieldName}
        value={fieldValue}
        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
      />
      ):fieldName === "Eligible NCB %" ? (
        <Controls.Dropdown
          options={ncb}
          label={fieldName}
          value={fieldValue || 0}
          onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
        />
    ) : (
               <Controls.Input
                  key={fieldName}
                  label={fieldName}
                  variant="outlined"
                  value={fieldValue}
                  onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
                />
    )}
            </Grid>
            ))}
          </Grid>
            </>
            )}
             <div>
            Policy Copy
          <img src={asterisk} alt="Star Logo" className={classes.customIcon} />
          </div>
          {policycopy.map((attachment, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <label htmlFor={`uploadpolicycopy-${index}`}>
                        <input
                          type="file"
                          id={`uploadpolicycopy-${index}`}
                          style={{ display: "none" }}
                          onChange={(event) => handlePolicyFileChange(event, index)}
                        />
                  <Fab
                    style={{ backgroundColor: '#914572', color: '#ffff', marginTop: '4px', marginLeft: '10px', fontSize: '8px', padding: '10px',fontsize:'10px',textAlign:'center' }}
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    Upload Policy
                  </Fab>
                </label>
                <div>{ attachment?.file?.name}</div>
                    </div>

                  ))}
            <div>
            <IconButton onClick={addAttachment}>
        <h5>Attachements</h5> <AddIcon />
      </IconButton>
      {attachments.map((attachment, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <label htmlFor={`uploadfile-${index}`}>
                        <input
                          type="file"
                          id={`uploadfile-${index}`}
                          style={{ display: "none" }}
                          onChange={(event) => handleFileChange(event, index)}
                        />
                  <Fab
                    style={{ backgroundColor: '#914572', color: '#ffff', marginTop: '4px', marginLeft: '10px', fontSize: '8px', padding: '10px',fontsize:'10px',textAlign:'center' }}
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    Upload File
                  </Fab>
                </label>
                <Grid item xs={3}>
                <Controls.Input
                  label="File Name"
                  value={attachment.file ? removeFileExtension(attachment.file.name) : ''}
                  onChange={(event) => handleFileNameChange(event, index)}
                  />
                      </Grid>
                      <IconButton onClick={() => handleDeleteAttachment(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
         </div>
         <Dialog
                  open={isDeleteDialogOpen}
                  onClose={handleCancelDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Delete Attachment?</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this attachment?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
          <div style={{marginTop:'2rem',marginLeft:"63rem"}}>
          <Controls.Button text="Save" onClick={handleUpdate}/>
          {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
            
          <Controls.Button text="Cancel" onClick={handleCancel} />
          </div>
        </Form>
        <Snackbar
        sx={{marginLeft:'30rem'}}
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
        onClose={handleSnackbarClose}
        message="Data sucessufully updated"
      />
    </Container>
    <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Remove file?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this attachment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AvPolicy;




