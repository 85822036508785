import React, { useState,useEffect} from 'react';
import { Box, Grid, Link, Snackbar, Button,DialogActions,DialogContentText,DialogContent,DialogTitle,Dialog } from '@mui/material';
import Controls from '../components/controls/Controls';
import { Form} from '../components/useForm';
import { Fab, makeStyles } from '@material-ui/core';
import AvAddOrganization from './AvAddOrganization';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import axios from 'axios';
import * as Rest from  './../services/restapi';
import LoadingSpinner from '../components/controls/spinner'; 
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete'; 
import UpdateBeneficiary from './UpdateBeneficiary';
import moment from 'moment';
import UpdateCustomerBeneficiary from './UpdateCustomerBeneficiary';



const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginLeft: theme.spacing(3),
    backgroundColor:'#914572',
    '&:hover': {
      backgroundColor: '#914572', // Set hover color to the same as default color
    },
    borderRadius:'10px',
    maxWidth:'13rem',
  },
}));

const customertype = [
  { value: 'Individual', label: 'Individual' },
  { value: 'Organization', label: 'Organization' },
]

const UpdateProspect = () => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [attachmentToDeleteIndex, setAttachmentToDeleteIndex] = useState(null);
  const [clientTypes, setClientTypes] = useState([]); 
  const location = useLocation();
  const updateCustomer = location?.state?.updateCustomer;
  const [state, setState] = useState([ updateCustomer]);

const [referedPersons, setReferedPersons] = useState([]);
const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [selectedReferedPerson, setSelectedReferedPerson] = useState('');
/* const referralid = selectedReferedPerson.value; */
const [isFirstNameEmpty, setIsFirstNameEmpty] = useState(false);
 const [isPhoneNumberEmpty, setIsPhoneNumberEmpty] = useState(false);
 const token = localStorage.getItem("X-fiftyaccess-Token");
 const [isLoading, setIsLoading] = useState(false);
 const [attachments, setAttachments] = useState([{ file: null, fileName: '' }]);
 const [isLastNameEmpty, setIsLastNameEmpty] = useState(false);
 const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleFileChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const file = event.target.files[0];
  
    // Check if a file is selected
    if (file) {
      updatedAttachments[index].file = file;
      setAttachments(updatedAttachments);
    }
  };
  
  const handleFileNameChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const currentAttachment = updatedAttachments[index];
  
    const currentFile = currentAttachment.file;
    const newFileName = event.target.value;
  
    // Remove the existing extension if present and append the new extension
    const updatedFileName = removeFileExtension(newFileName) + getFileExtension(currentFile.name);
  
    const updatedFile = new File([currentFile], updatedFileName, { type: currentFile.type });
    // Update the attachment object with the modified file name and file
    updatedAttachments[index] = {
      ...updatedAttachments[index],
      file: updatedFile,
      fileName: event.target.value,
    };
  
    setAttachments(updatedAttachments);
  };
  
  // Helper function to get file extension
  const getFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(lastDotIndex) : '';
  };
  
  // Helper function to remove file extension
  const removeFileExtension = (fileName) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName.slice(0, lastDotIndex) : fileName;
  };

 const addAttachment = () => {
   setAttachments([...attachments, { file: null, fileName: '' }]);
 };

 const removeAttachment  = (index) => {
   const updatedAttachments = attachments.filter((_, i) => i !== index);
   setAttachments(updatedAttachments);
 };

 const handleDeleteRow = (indexToDelete) => {
  const updatedRecords = records.filter((record, index) => index !== indexToDelete);
  setRecords(updatedRecords);
};


const handleDeleteAttachment = (index) => {
  setAttachmentToDeleteIndex(index);
  setIsDeleteDialogOpen(true);
};

const handleConfirmDelete = () => {
  // Perform the delete operation
  const updatedAttachments = attachments.filter((_, i) => i !== attachmentToDeleteIndex);
  setAttachments(updatedAttachments);

  setIsDeleteDialogOpen(false);
  setAttachmentToDeleteIndex(null);
};

const handleCancelDelete = () => {
  setIsDeleteDialogOpen(false);
  setAttachmentToDeleteIndex(null);
};


  useEffect(() => {
    // Fetch data from your API when the component mounts
    axios.get(Rest.viewallreferredpersondetails,
      {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      }
      )
      .then((response) => {
        setReferedPersons(response?.data?.data); // Assuming your API returns an array of referral persons
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const referedpersonName = updateCustomer?.referralpersondetails?.[0]
 ? `${updateCustomer?.referralpersondetails?.[0].firstname} ${updateCustomer?.referralpersondetails?.[0].lastname}`
 : '';

// Update the selected salesperson based on the name
const selectedReferralid = referedPersons?.find(
 (option) =>
   `${option.firstname} ${option.lastname}` === referedpersonName
)?.referralid;

  // Update the salesperson dropdown value based on the selectedEmployeeID
  useEffect(() => {
    setSelectedReferedPerson(selectedReferralid);
  }, [selectedReferralid]);

  const handleReferalChange = (selectedReferralid) => {
    const selectedValue = selectedReferralid;
    setSelectedReferedPerson(selectedValue);
    // Assuming state is an array with an object
    setState([
      {
        ...state[0],
        referralid: selectedValue,
      },
    ]);
  };

useEffect(() => {
  // Fetch client types from the backend API
  async function fetchClientTypes() {
    try {
      const response = await fetch(Rest.viewallclinettypes,
        {
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        }
        );
      if (response.ok) {
        const data = await response.json();
        setClientTypes(data?.clinettypes); // Update clientTypes state with the fetched data
      } else {
        console.error('Failed to fetch client types');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  fetchClientTypes(); // Call the function to fetch client types
}, []); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
   const [records, setRecords] = useState([]);
  const classes = useStyles();
  const [showOrganizationFields, setShowOrganizationFields] = useState(false);
  const navigate = useNavigate();
  

      const handleBenificaryChange = (beneficiaryDetails) => {
        setState((prevState) => {
          const updatedState = prevState.map((prospect, index) => {
            if (index === 0) {
              // Update the first prospect's beneficiary details
              return {
                ...prospect,
                beneficiarydetails: beneficiaryDetails,
              };
            }
            return prospect;
          });
          return updatedState;
        });
      };
      
      const fillBeneficiaryWithProspectData = (index) => {
        const updatedState = [...state];
        const prospect = updatedState[0];
      
        updatedState[0].beneficiarydetails[index] = {
          ...updatedState[0].beneficiarydetails[index],
          firstname: prospect.firstname,
          lastname: prospect.lastname,
          emailid: prospect.emailid,
          phonenumber: prospect.phonenumber,
          // Add other fields you want to copy here
        };
      
        setState(updatedState);
      };
      
      const emptySpouseFields = (index) => {
        const updatedState = [...state];
      
        updatedState[0].beneficiarydetails[index] = {
          ...updatedState[0].beneficiarydetails[index],
          firstname: '',
          lastname: '',
          emailid: '',
          phonenumber: '',
          // Add other fields you want to clear here
        };
      
        setState(updatedState);
      };
      
      
      const handleDeleteRecord = (index) => {
        const updatedRecords = [...records];
        updatedRecords.splice(index, 1);
        setRecords(updatedRecords);
      };
      const fillBeneficiaryWithEmptyProspectData = (index) => {
        const updatedState = [...state];
        const prospect = updatedState[index];
        
        updatedState[index].beneficiarydetails[0] = {
          ...updatedState[index].beneficiarydetails[0],
          firstname: "",
          lastname: "",
          emailid: "",
          phonenumber: "",
          // Add other fields you want to copy here
        };
      
        setState(updatedState);
      };
      const handleOrganizationChange = (index, field, value) => {
      setState((prevState) => {
        const updatedState = [...prevState];
        updatedState[index].organizationdetails[field] = value;
        return updatedState;
      });
    };

    const handleAlphabeticInput = (index, event) => {
      const { name, value } = event.target;
      const alphabeticValue = value.replace(/[^a-zA-Z]/g, ''); // Allow only alphabets
    
      setState((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = {
          ...updatedState[index],
          [name]: alphabeticValue,
        };
    
        return updatedState;
      });
    };
    

    const handleFirstNameChange = (event) => {
      const { value } = event.target;
      
      // Regular expression to allow only letters (both lowercase and uppercase)
      const alphabeticValue = value.replace(/[^a-zA-Z]/g, '');
  
      setState((prevState) => {
        const updatedState = [...prevState];
        updatedState[0].firstname = alphabeticValue;
        return updatedState;
      });
      
      if (alphabeticValue.trim() === '') {
        setIsFirstNameEmpty(true);
      } else {
        setIsFirstNameEmpty(false);
      }
    };
  
    const handleLastNameChange = (event) => {
      const { value } = event.target;
      
      // Regular expression to allow only letters (both lowercase and uppercase)
      const alphabeticValue = value.replace(/[^a-zA-Z]/g, '');
  
      setState((prevState) => {
        const updatedState = [...prevState];
        updatedState[0].lastname = alphabeticValue;
        return updatedState;
      });
      
      if (alphabeticValue.trim() === '') {
        setIsLastNameEmpty(true);
      } else {
        setIsLastNameEmpty(false);
      }
    };



    // const handleInputChange = (index, event) => {
    //   const { name, value } = event.target;
    
    //   // If the name is 'phonenumber', allow only numeric characters
    //   const numericValue = name === 'phonenumber' ? value.replace(/[^0-9]/g, '') : value;
    
    const handleInputChange = (index, event) => {
      const { name, value } = event.target;
    
      // If the name is 'phonenumber', allow only numeric characters and limit to 10 digits
      let numericValue = name === 'phonenumber' ? value.replace(/[^0-9]/g, '').slice(0, 10) : value;
    
      // Check if the phone number is less than 10 digits
      if (name === 'phonenumber' && numericValue.length < 10) {
        setPhoneNumberError(true);
      } else {
        setPhoneNumberError(false);
      }
    
      setState((prevState) => {
        const updatedState = [...prevState];
        updatedState[index] = {
          ...updatedState[index],
          attachments,
          [name]: numericValue,
        };
      
    
      if (name === 'firstname' && numericValue.trim() !== '') {
        setIsFirstNameEmpty(false);
      }
  
      if (name === 'phonenumber' && numericValue.trim() !== '') {
        if (numericValue.length < 10) {
          setIsPhoneNumberEmpty(true);
        } else {
          setIsPhoneNumberEmpty(false);
        }
     
      }
  
      return updatedState;
    });
  };
 

  const handleCustomerTypeChange = (index, event) => {
    const { value } = event.target;
  
    setState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = {
        ...updatedState[index],
        customertype: value,
      };
      return updatedState;
    });
    if (value  === 'Organization') {
      setShowOrganizationFields(true);
    } else {
      setShowOrganizationFields(false);
    }
  };
  const handleClientTypeChange = (index, event) => {
    const { value } = event.target;
  
    setState((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = {
        ...updatedState[index],
        clienttype: value,
      };
      return updatedState;
    });
  };

 
  /* const handleRefrenceChange = (event) => {
      const selectedReferralId = event.target.value;
  
      setState([
        {
          ...state[0],
          referencepersondetails: {
            referralid: selectedReferralId,
          },
        },
      ]);
      if (selectedReferralId === 'AN003') {
        navigate(`/add/${selectedReferralId}`,{state:state});
      } 

    }; */
    const handleCancelClick = () => {
  
        navigate('/allcustomer');
      
    };
    const handleAddRecord = () => {
      // Validate the fields
      if (!state[0].firstname) {
        setIsFirstNameEmpty(true);
      } else {
        setIsFirstNameEmpty(false);
      }
  
      if (!state[0].phonenumber) {
        setIsPhoneNumberEmpty(true);
      } else {
        setIsPhoneNumberEmpty(false);
      }
  
      // If any of the required fields are empty, do not proceed
      if (!state[0].firstname || !state[0].phonenumber) {
        return;
      }
  
      setRecords([...records, state,]);
    };

        
    const handleClick = () => {
      // Navigate to the desired route when the button is clicked
      navigate('/leadtemplate');
    };

    const handleBenficiariesInputChange = (index, field, value) => {
        if (!state || !state[0] || !state[0].beneficiarydetails) {
          console.error('Invalid state structure or missing data.');
          return;
        }
      
        const updatedBeneficiariesArray = state[0].beneficiarydetails.map((beneficiary, i) => {
          if (i === index) {
            return {
              ...beneficiary,
              [field]: value
            };
          }
          return beneficiary;
        });
      
        setState([{ ...state[0], beneficiarydetails: updatedBeneficiariesArray }]);
      };

      const handleUpdate = async () => {
        try {
          setIsLoading(true);
          const dateofbirth = moment(state?.[0]?.beneficiarydetails?.[0]?.dateofbirth).format("DD-MM-YYYY")
          const beneficiarydetails = [{...state?.beneficiarydetails?.[0],dateofbirth:dateofbirth}]
          const updatedProspectValues = {...state, beneficiarydetails};
    
          const formDataObject = new FormData();
          const beneficiaryFieldNames = state?.[0]?.beneficiarydetails?.map((beneficiary, index) => `${beneficiary.firstname}${beneficiary.lastname}_`);
          beneficiaryFieldNames.forEach((beneficiary) => {
            attachments.forEach((attachment) => {
              const fieldName = `${beneficiary.replace(/\s/g, '')}_${attachment.fileName}`;
              console.log("formDataObject", fieldName)
              formDataObject.append(fieldName, attachment.file);
            });
          });

          formDataObject.append('customerid', state?.[0]?.customerid);
          formDataObject.append('firstname', state?.[0]?.firstname);
          formDataObject.append('lastname', state?.[0]?.lastname);
          formDataObject.append('emailid', state?.[0]?.emailid);
          formDataObject.append('phonenumber', state?.[0]?.phonenumber);
          formDataObject.append('customertype', state?.[0]?.customertype);
          formDataObject.append('clienttype', state?.[0]?.clienttype);
    
    
          state?.[0]?.beneficiarydetails.forEach((beneficiary, index) => { 
            console.log("beneficiary.beneficaryid",beneficiary.beneficaryid)
            formDataObject.append(`beneficiarydetails[${index}][attachments]`, JSON.stringify(beneficiary.attachments) || '');
            formDataObject.append(`beneficiarydetails[${index}][beneficiaryid]`, beneficiary.beneficaryid || '');
            formDataObject.append(`beneficiarydetails[${index}][beneficiaryrelation]`, beneficiary.beneficiaryrelation || '');
            formDataObject.append(`beneficiarydetails[${index}][firstname]`, beneficiary.firstname || '');
            formDataObject.append(`beneficiarydetails[${index}][lastname]`, beneficiary.lastname || '');
            formDataObject.append(`beneficiarydetails[${index}][emailid]`, beneficiary.emailid || '');
            formDataObject.append(`beneficiarydetails[${index}][phonenumber]`, beneficiary.phonenumber || '');
            formDataObject.append(`beneficiarydetails[${index}][alternateemails]`, beneficiary.alternateemails[0] || '');
            formDataObject.append(`beneficiarydetails[${index}][alternatephonenumbers]`, beneficiary.alternatephonenumbers[0] || '');
            formDataObject.append(`beneficiarydetails[${index}][gender]`, beneficiary.gender || '');
            formDataObject.append(`beneficiarydetails[${index}][dateofbirth]`, dateofbirth || '');
            formDataObject.append(`beneficiarydetails[${index}][communicationpreference]`, beneficiary.communicationpreference || '');
            formDataObject.append(`beneficiarydetails[${index}][pannumber]`, beneficiary.pannumber || '');
            formDataObject.append(`beneficiarydetails[${index}][aadharnumber]`, beneficiary.aadharnumber || '');
            beneficiary?.addressdetails?.forEach((address, addressIndex) => {
              formDataObject.append(`beneficiarydetails[${index}].addressdetails[${addressIndex}][addresstype]`, address.addresstype || '');
              formDataObject.append(`beneficiarydetails[${index}].addressdetails[${addressIndex}][addressline1]`, address.addressline1 || '');
              formDataObject.append(`beneficiarydetails[${index}].addressdetails[${addressIndex}][addressline2]`, address.addressline2 || '');
              formDataObject.append(`beneficiarydetails[${index}].addressdetails[${addressIndex}][city]`, address.city || '');
              formDataObject.append(`beneficiarydetails[${index}].addressdetails[${addressIndex}][district]`, address.district || '');
              formDataObject.append(`beneficiarydetails[${index}].addressdetails[${addressIndex}][state]`, address.state || '');
              formDataObject.append(`beneficiarydetails[${index}].addressdetails[${addressIndex}][pincode]`, address.pincode || '');
              formDataObject.append(`beneficiarydetails[${index}].addressdetails[${addressIndex}][country]`, address.country || '');
            });
          });
          formDataObject.append('referralid', state?.[0]?.referralid)
          /* formDataObject.append('organizationdetails[organizationname]', state?.[0]?.organizationdetails.organizationname || '');
          formDataObject.append('organizationdetails[organizationphonenumber]', state?.[0]?.organizationdetails.organizationphonenumber || '');
          formDataObject.append('organizationdetails[organizationemailid]', state?.[0]?.organizationdetails.organizationemailid || '');
          formDataObject.append('organizationdetails[addresstype]', state?.[0]?.organizationdetails.addresstype || '')
          formDataObject.append('organizationdetails[addressline1]', state?.[0]?.organizationdetails.addressline1 || '')
          formDataObject.append('organizationdetails[addressline2]', state?.[0]?.organizationdetails.addressline2 || '')
          formDataObject.append('organizationdetails[city]', state?.[0]?.organizationdetails.city || '')
          formDataObject.append('organizationdetails[district]', state?.[0]?.organizationdetails.district || '')
          formDataObject.append('organizationdetails[state]', state?.[0]?.organizationdetails.state || '')
          formDataObject.append('organizationdetails[pincode]', state?.[0]?.organizationdetails.pincode || '')
          formDataObject.append('organizationdetails[country]', state?.[0]?.organizationdetails.country || '')  */
  
          const response = await fetch(Rest.updateCustomer, {
            method: 'PUT',
            headers: {
              /*  'Content-Type': 'application/json', */
              "X-fiftyaccess-Token": token
            },
            body: formDataObject,
          });
          const data = await response.json();
          if (data?.code ==="S001") {
            setIsSuccessSnackbarOpen(true); 
            setTimeout(() => {
              navigate('/allcustomer');
            }, 1000); 
            setIsLoading(false);
          } else {
            setIsLoading(false);
            alert(data?.msg || data?.data?.data)
           }
        } catch (err) {
          setIsLoading(false);
          alert("Error: " + err.message);
        }
      };

    const handleSnackbarClose = () => {
      setIsSuccessSnackbarOpen(false);
    };
    console.log('prospect',state)
  return (
   
    <div style={{marginTop:'5rem',marginLeft:'2rem'}}>
      <div style={{display:'flex',flexDirection:'row' ,gap:'52rem'}}>
      <h5>Customer Information</h5>
      </div>
    <Form onSubmit={(e) => e.preventDefault()}>
    {state?.map((prospect, index) => (
      <>
      <Grid container style={{width:'72rem',marginLeft:'1.5rem'}}>
        <Grid item xs={4}>
        <Controls.Input
        label="First Name"
        name="firstname"
        value={prospect?.firstname}
        onChange={handleFirstNameChange} // Use handleFirstNameChange for first name
        required
        error={isFirstNameEmpty}
      />
      {isFirstNameEmpty && (
        <p style={{ color: 'red', fontSize: '10px' }}>Invalid First Name</p>
      )}
          <Controls.Input
          label="Phone Number"
          name="phonenumber"
          value={prospect?.phonenumber}
          onChange={(e) => handleInputChange(0, e)}
          required
          error={phoneNumberError} // Set error prop to control error state
        />
        {phoneNumberError && (
          <p style={{ color: 'red', fontSize: '10px' }}>Please Enter Valid Phone Number</p>
        )}
 
     
      <Controls.Dropdown
        label="Client Type"
        value={prospect?.clienttype}
        onChange={(e) => handleClientTypeChange(index, e)}
        options={clientTypes.map((type) => ({ value: type, label: type }))}
      />
        </Grid>
        <Grid item xs={4}>
        <Controls.Input
        label="Last Name"
        name="lastname"
        value={prospect?.lastname}
        onChange={handleLastNameChange} // Use handleLastNameChange for last name
        required
        error={isLastNameEmpty}
      />
      {isLastNameEmpty && (
        <p style={{ color: 'red', fontSize: '10px' }}>Invalid Last Name</p>
      )}
        <Controls.Dropdown
          label="Customer Type"
          value={prospect?.customertype}
          onChange={(e) => handleCustomerTypeChange(index, e)}
          options={customertype}
        />
        </Grid>
        <Grid item xs={4}>
        <Controls.Input
          label="Email ID"
          name="emailid"
          value={prospect?.emailid}
          onChange={(e) => handleInputChange(index, e)}
        />
        <div style={{display:'flex',flexDirection:'column'}}>
        <Controls.Dropdown
        label="Reference Person"
        value={selectedReferedPerson}
        onChange={(e) => handleReferalChange(e.target.value)}
        options={referedPersons.map((person) => ({ value: person.referralid, label: `${person.firstname} ${person.lastname}`, }))}
      />
      <Link style={{fontSize:'12px',marginLeft:'1.5rem',cursor:'pointer'}} onClick={()=>{navigate('/addreference')}}>Add Reference</Link>
      </div>
  </Grid>
      </Grid>
      </>
       ))}
      {showOrganizationFields && (
      <AvAddOrganization organization={state[0].organizationdetails} 
        handleOrganizationChange={(field, value) => handleOrganizationChange(0, field, value)}
      />     
      )}
        <h6>Beneficiary</h6>
      <UpdateCustomerBeneficiary beneficiaryDetails={state?.[0]?.beneficiarydetails} handleBenificaryChange={handleBenificaryChange} />
   <div>
   

   </div>
      {/* <Box mt={2}> */}
      <Box  marginLeft="53rem" mt={2}>
        <Controls.Button 
        text="Update"
        onClick={handleUpdate}
        className={classes.submitButton}
        />
         {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
      
          <Controls.Button
          className={classes.submitButton}
          text="Cancel"
          onClick={handleCancelClick} // Call handleCancelClick when Cancel is clicked
        />
        </Box>
        <IconButton onClick={addAttachment}>
        <h5>Attachements</h5> <AddIcon />
      </IconButton>
      {attachments.map((attachment, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <label htmlFor={`uploadfile-${index}`}>
                        <input
                          type="file"
                          id={`uploadfile-${index}`}
                          style={{ display: "none" }}
                          onChange={(event) => handleFileChange(event, index)}
                        />
                  <Fab
                    style={{ backgroundColor: '#914572', color: '#ffff', marginTop: '4px', marginLeft: '10px', fontSize: '8px', padding: '10px',fontsize:'10px',textAlign:'center' }}
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    Upload File
                  </Fab>
                </label>
                <Grid item xs={3}>
                <Controls.Input
                  label="File Name"
                  value={attachment.file ? removeFileExtension(attachment.file.name) : ''}
                  onChange={(event) => handleFileNameChange(event, index)}
                  />
                      </Grid>
                      <IconButton onClick={() => handleDeleteAttachment(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
     
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Attachment?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this attachment? 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      </Form>
      <Snackbar
        sx={{marginLeft:'30rem'}}
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
        onClose={handleSnackbarClose}
        message="Data successfully added"
      />
    </div>
   
  );
};

export default UpdateProspect;

