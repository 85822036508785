import React from 'react'
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      '&$checked': {
        color: '#f79123', // Set the checked background color to orange
      },
    },
    checked: {}, // Empty rule to be used for the checked state
  }));

export default function Checkbox(props) {

    const { name, label, value, onChange } = props;
    const classes = useStyles();

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <FormControl>
            <FormControlLabel
                control={<MuiCheckbox
                    size='small'
                    name={name}
                    color="primary"
                    checked={value}
                    classes={{
                        root: classes.root,
                        checked: classes.checked,
                      }}
                    onChange={e => onChange(convertToDefEventPara(name, e.target.checked))}
                />}
                label={label}
            />
        </FormControl>
    )
}
