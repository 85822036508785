
import React, { useState } from 'react';
import Controls from '../components/controls/Controls';
import { Grid, Checkbox, Typography, Radio, Card } from '@material-ui/core';
import { CardContent, FormControl, RadioGroup, FormControlLabel, TextField, Input } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import { Container, } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/controls/spinner';
import { Form } from '../components/useForm';
import axios from 'axios';
import * as Rest from '../services/restapi';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import calendar from './../assests/icons/calendar.png';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import dayjs from 'dayjs';
import { Link, tabScrollButtonClasses } from '@mui/material';
import { Snackbar } from '@mui/material';
// const today = dayjs();
const today = dayjs();
// const today = formattedDate;


const Commission = () => {
  const location = useLocation();
  console.log("@@@@@@@!!!", location?.state)
  const commissionData = location?.state?.commissionData
  const providername = commissionData?.provider;
  const productname = commissionData?.product;
  const subproductname = commissionData?.subproduct;
  const policytypedetails = commissionData?.interestedpolicytype;
  const start = commissionData?.startdate
  const end = commissionData?.enddate;
  console.log('==========',commissionData?.enddate)
  const originalDate = commissionData?.enddate


let dayjsFormattedDate=end
  const parsedDate = dayjs(originalDate, 'DD-MM-YYYY');

  if (parsedDate.isValid()) {
 
    dayjsFormattedDate= dayjs(originalDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const parsedDate = dayjs(originalDate, 'DD-MM-YYYY');  
  }

  const [loading, setLoading] = useState(false)
  const [opportunityValues, setOpportunityValues] = useState(
    {
      provider: '' || providername,
      product: '' || productname,
      subproduct: '' || subproductname,
      interestedpolicytype: '' || policytypedetails,
      netpremium: '',
      netpremiumSelected: false,
      startdate: today,
      enddate: '' || dayjsFormattedDate
    }

  );
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  var [tableData, setTableData] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('All');
  const [providerOptions, setProviderOptions] = useState([]);
  const [value, setValue] = useState('');
  const [minPercentage, setMinPercentage] = useState('');
  const [maxPercentage, setMaxPercentage] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const isProviderMount = useRef(true);
  const isProductMount = useRef(true);
  const isLocMount = useRef(true);
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [formErrors, setFormErrors] = useState({});
  const [isNetPremiumSelected, setIsNetPremiumSelected] = useState(true);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [flatCardOpen, setFlatCardOpen] = useState(false);
  const [discountCardOpen, setDiscountCardOpen] = useState(false);
  const [slabCardOpen, setSlabCardOpen] = useState(false);
  const [selectedPolicystartdate, setSelectedPolicystartdate] = useState(null)
  const [subPremiumsContent, setSubPremiumsContent] = useState({});
  const [locations, setLocations] = useState({});
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const states = Object.keys(locations);
  const cities = locations[selectedState] || [];
  const [numTabs, setNumTabs] = useState(1);
  const [lowerLimit, setLowerLimit] = useState("0");
  const [upperLimit, setUpperLimit] = useState("");
  const [editIndex, setEditIndex] = useState(0);
  const [areaeditIndex, setareaeditIndex] = useState(0);
  const [disableUpperLimit, setDisableUpperLimit] = useState(false);
  const [editDiscountIndex, setEditDiscountIndex] = useState(0);
  const [editFlatIndex, setEditFlatIndex] = useState(0);
  const [premiumRadio, setPremiumRadio] = useState('netPremium');
  const navigate = useNavigate();
  const [selectedFlat, setSelectedFlat] = useState(null);
  const [disableDiscountUpperLimit, setDisableDiscountUpperLimit] = useState(false);
  const [flatTabOpen, setFlatTabOpen] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [flatTabs, setFlatTabs] = useState([
    {

      id: 1,
      heading: 'Flat ',
      note: '',
      selectedOption: 'netPremium',
      netPremiumContent: '',
      subPremiumsContent: {},
      flatRange: { lowerLimit: '0', upperLimit: '' },
    },
  ]);
  const [discountTabs, setDiscountTabs] = useState([

    {

      id: 1,
      note: '',
      selectedOption: 'netPremium',
      netPremiumContent: '',
      subPremiumsContent: {},
      discountRange: { lowerLimit: '0', upperLimit: '' },

    },
  ]);

  const moveDiscountToNextTab = () => {
    const newIndex = (editDiscountIndex + 1) % areas.length;
    setEditDiscountIndex(newIndex);
  };
  const moveDiscountToPreviousTab = () => {
    const newIndex = editDiscountIndex === 0 ? areas.length - 1 : editDiscountIndex - 1;
    setEditDiscountIndex(newIndex);
  };
  const handleSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };
  const [tabs, setTabs] = useState([
    {

      id: 1,
      heading: 'Slab 1',
      note: '',
      selectedOption: 'netPremium',
      netPremiumContent: '',
      subPremiumsContent: {},
      slabRange: { lowerLimit: "0", upperLimit: "" },

    },

  ]);

  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);

  const moveToNextTab = (action) => {
    if (action === 'Previous') {
      const newIndex = editIndex === 0 ? areas.length - 1 : editIndex - 1;
      setEditIndex(newIndex);
    } else {
      const newIndex = (editIndex + 1) % areas.length;
      setEditIndex(newIndex);
    }
    setLowerLimit("");
    setUpperLimit("");
  };

  const moveToPreviousTab = (action) => {
    if (action === 'Previous') {
      const newIndex = editIndex === 0 ? areas.length - 1 : editIndex - 1;
      setEditIndex(newIndex);
    } else {
      const newIndex = (editIndex + 1) % areas.length;
      setEditIndex(newIndex);
    }
    setLowerLimit("");
    setUpperLimit("");
  };


  const [areaTabs, setAreaTabs] = useState([]);
  const uniquename = tableData?.map((option) => option?.name);
  const areaid = tableData?.map((option) => option?.areaid);

  const addAreaTab = () => {
    const uniqueAreaNames = Array.from(new Set(tableData?.map((option) => option?.area)));

    setAreaTabs(uniqueAreaNames);
    setEditIndex(uniqueAreaNames.length || 0);
  };

  useEffect(() => {
    if (tableData) {

      addAreaTab();

    }

  }, [tableData]); // Ensure the effect runs whenever tableData changes

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      let valueForSending;
      if (value === "slab") {
        valueForSending = "slabdetails";
      } else if (value === "discount") {
        valueForSending = "discountdetails";
      } else {
        valueForSending = "flatdetails";
      }


      let filteredAreas = areas.filter((area) => {
        if (value === "slab") {
          return area.slabDetails && area.slabDetails.length > 0;
        } else if (value === "discount") {
          return area.discountDetails && area.discountDetails.length > 0;
        } else if (value === "flat") {
          return area.flatdetails && area.flatdetails.length > 0;
        }
        return false; // For other cases or undefined `value`
      });

      if (value === "flat") {
        // Flatten the array of flatdetails into a single array
        filteredAreas = filteredAreas.reduce((accumulator, area) => {
          return accumulator.concat(area.flatdetails);
        }, []);
      }

      // if (value === "flat") {
      //   filteredAreas = filteredAreas.map((area) => area.flatdetails);
      // }

      //       console.log("filteredAreas", filteredAreas)

      console.log("!!!!!!", opportunityValues?.startdate)
      console.log("@@@@@@@@++++", (!moment(opportunityValues?.startdate, "DD-MM-YYYY").isValid()))

      const startdateValue = selectedPolicystartdate ? selectedPolicystartdate : opportunityValues?.startdate.format("DD-MM-YYYY")
      const productname = selectedProduct?.data?.product;
      // const flatDetailsForAllAreas = filteredAreas.map((area) => area.flatdetails);
      const requestData = {
        basedon: premiumRadio,
        type: value,
        provider: opportunityValues.provider,
        product: productname,
        subproduct: opportunityValues.subproduct,
        policytype: opportunityValues.interestedpolicytype,
        startdate: startdateValue,
        enddate: opportunityValues?.enddate,
        //  [valueForSending]: value === "flat" ? filteredAreas[0].flatdetails : filteredAreas,
        [valueForSending]: filteredAreas,
      };

      console.log("@@@@@@@", requestData)


      const response = await fetch(Rest.createcommission, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token,
        },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      console.log("data", data);

      if (data.code === "S001") {
        setIsSuccessSnackbarOpen(true);
        // setIsSuccessSnackbarOpen(true);
        setTimeout(() => {
          navigate('/viewcommission');
        }, 2000);
      } else {
        alert(data?.msg || data?.data?.data);
      }
    } catch (err) {
      alert("Error: " + err.message);
    } finally {
      setLoading(true); // Set loading to false after the API request is completed (success or failure)
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  }


  const handleCheckboxChange = () => {
    setSelectedArea(!selectedArea);
  };

  const handleDefineAreaClick = () => {
    if (selectedArea) {
      navigate("/area/", { state: { commissiondetails: opportunityValues, Type:'create' } });
    }
  }
 

  // useEffect(() => {
  //   if (opportunityValues?.provider) {
  //     axios.get(Rest.viewarea, {
  //       params: {
  //         provider: opportunityValues?.provider

  //       },

  //       headers: {
  //         'Content-Type': 'application/json',
  //         "X-fiftyaccess-Token": token
  //       },
  //     }
  //     )
  //       .then(response => {

  //         if (response.data.code === "S001") {
  //           setTableData(response?.data?.data)
  //           addAreaTab(response?.data?.data);
  //           setAreas(response?.data?.data)
  //         } else {
  //           alert(response?.msg || response?.data?.data);
  //         }
  //       })
  //       .catch(err => {
  //         alert("Error: " + err.message);
  //       });
  //   }

  // }, [opportunityValues?.provider]);
  useEffect(() => {
    if (opportunityValues?.provider) {
      axios.get(Rest.viewarea, {
        params: {
          provider: opportunityValues?.provider
        },
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
      .then(response => {
        if (response.data.code === "S001") {
          if (response.data.data) {
            setTableData(response.data.data);
            addAreaTab(response.data.data);
            setAreas(response.data.data);
          } else {
            // No areas for this provider
            alert("No areas for this provider.");
          }
        } else {
          alert("No areas for this provider.");
        }
      })
      .catch(err => {
        alert("Error: " + err.message);
      });
    }
  }, [opportunityValues?.provider]);
  

  useEffect(() => {

    if (isLocMount.current) {
      axios.get(Rest.locations, {

        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })

        .then((response) => {

          setLocations(response?.data?.locations);

        })

        .catch((error) => {

          console.error('Error fetching data: ', error);

        });

      isLocMount.current = false;

    }

  }, []);



  const handleStartDateChange = (date) => {
    const startdate = moment(date)?.format('DD-MM-YYYY')
    setSelectedPolicystartdate(startdate)

    setOpportunityValues((opportunityValues) => ({ ...opportunityValues, startdate: startdate }));
  }

  const handleEndDateChange = (date) => {
    const enddate = moment(date)?.format('DD-MM-YYYY')
    setOpportunityValues((opportunityValues) => ({ ...opportunityValues, enddate: enddate }));

  }

  const handleOpportunityChange = (field, value) => {
    // Regular expression to match numbers with optional decimal points
    const validNumberRegex = /^-?\d*\.?\d*$/;
    if (

      field === 'addonpremium' ||

      field === 'odpremium' ||

      field === 'grosspremium' ||

      field === 'netpremium' ||

      field === 'tax' ||

      field === 'basepremium' ||

      field === 'thirdpartypremium'

    ) {

      // Check if the input value is a valid number or decimal

      if (validNumberRegex.test(value) || value === '') {

        setOpportunityValues((prevValues) => ({

          ...prevValues,

          [field]: value,

        }));

        setFormErrors((prevErrors) => ({

          ...prevErrors,

          [field]: '',

        }));

      } else {

        setFormErrors((prevErrors) => ({

          ...prevErrors,

          [field]: 'Enter only numbers', // Set the error message

        }));

      }

    } else {

      setOpportunityValues((prevValues) => ({

        ...prevValues,

        [field]: value,

      }));

      setFormErrors((prevErrors) => ({

        ...prevErrors,

        [field]: '',

      }));

    }

  };


  const handleButtonClick = () => {
    // Navigate to the "View Commission" page
    navigate('/viewcommission');
  };
  const handleRadioChange = (event) => {

    setValue(event.target.value);

    console.log("event.target.value", event.target.value)

    if (event.target.value === 'discount') {

      setDiscountCardOpen(true);

      setFlatCardOpen(false); // Close the flat card

      setSlabCardOpen(false); // Close the slab card

    } else if (event.target.value === 'flat') {

      setFlatCardOpen(true);

      setDiscountCardOpen(false); // Close the discount card

      setSlabCardOpen(false); // Close the slab card

    } else if (event.target.value === 'slab') {
      console.log("555555555555555555")

      setSlabCardOpen(true);

      setDiscountCardOpen(false); // Close the discount card

      setFlatCardOpen(false);

    } else {

      setDiscountCardOpen(false);

      setFlatCardOpen(false);

      setSlabCardOpen(false);

    }

  };



  useEffect(() => {

    if (isProductMount.current) {

      async function fetchProducts() {

        try {

          const response = await axios.get(Rest.viewallproduct,

            {

              headers: {

                'Content-Type': 'application/json',

                "X-fiftyaccess-Token": token

              },

            }

          );

          const productData = response?.data?.data

          setProducts(productData);

        } catch (error) {

          console.error('Error fetching products:', error);

        }

      }

      fetchProducts();

      isProductMount.current = false;

    }

  }, []);

  useEffect(() => {

    // Fetch product details when selected product changes

    async function fetchSelectedProductData(productid) {

      if (productid) {

        try {

          const response = await axios

            .get(Rest.viewproduct, {

              params: {

                productid: productid

              },

              headers: {

                "X-fiftyaccess-Token": token

              },

            });

          setSelectedProduct(response?.data);

        } catch (error) {

          console.error('Error fetching selected product:', error);

        }

      }

    }



    fetchSelectedProductData(opportunityValues.product);

  }, [opportunityValues.product]);

  const isValidNumber = (value) => {

    const validNumberRegex = /^-?\d*\.?\d*(%)?$/;

    return validNumberRegex.test(value);

  };

  useEffect(() => {

    if (isProviderMount.current) {

      fetch(Rest.ViewAllProvider,

        {

          headers: {

            'Content-Type': 'application/json',

            "X-fiftyaccess-Token": token

          },

        }

      )
        .then(response => response.json())
        .then(data => {
          // Update the options state with the fetched data

          setProviderOptions(data?.Data); // Assuming `setDropdownOptions` is a state setter function

        })

        .catch(error => {

          // Handle error

        });

      isProviderMount.current = false;

    }

  }, []);

  const [premiumErrors, setPremiumErrors] = useState({});

  const subpremiums = products.find(

    (product) => product.productid === opportunityValues.product

  )?.subpremiums ?? [];



  const productdetails = products.find(

    (product) => product.productid === opportunityValues.product

  )?.attributefields;



  const subProductOptions = products.find(

    (product) => product.productid === opportunityValues.product

  )?.subProductDetails;



  const policyOption = subProductOptions

    ?.find((product) => product.productid === opportunityValues.product)

    ?.subProductDetails?.policyTypeDetails?.map((policyType) => ({

      value: policyType,

      label: policyType,

    })) || products.find((product) => product.productid === opportunityValues.product)

      ?.subProductDetails?.[0]?.policyTypeDetails?.map((policyType) => ({

        value: policyType,

        label: policyType

      })) || []

  const handleProviderChange = (event) => {

    const provider = event;

    setOpportunityValues((opportunityValues) => ({ ...opportunityValues, provider: provider }));

    setFormErrors((prevErrors) => ({

      ...prevErrors,

      provider: '',

    }));

  };



  const handleNetpremiumChange = (e) => {

    const inputValue = e.target.value;



    // Check if the input is empty

    if (inputValue === '') {

      setOpportunityValues((prevValues) => ({

        ...prevValues,

        netpremium: '',

      }));

    } else {

      // Check if the input is a valid number

      if (!isNaN(inputValue)) {

        setOpportunityValues((prevValues) => ({

          ...prevValues,

          netpremium: parseInt(inputValue, 10),

        }));

      } else {

        // Handle invalid input

      }

    }

  };



  const handleNetpremiumCheckboxChange = (e) => {

    setOpportunityValues((prevValues) => ({

      ...prevValues,

      netpremiumSelected: e.target.checked,

    }));

  };



  const handlePremiumChange = (fieldName, value) => {



    // Create a copy of the opportunityValues object to avoid mutating the state directly

    const updatedOpportunityValues = { ...opportunityValues, subpremiums };

    // Update the value based on the field name

    updatedOpportunityValues.subpremiums[0][fieldName] = value;

    updatedOpportunityValues.subpremiums = updatedOpportunityValues.subpremiums[0]

    // Update the state with the updated opportunityValues

    setOpportunityValues(updatedOpportunityValues);

    setFormErrors((prevErrors) => ({

      ...prevErrors,

      value: '',

    }));

  };




  // const addSlabTabs = (areaId, index) => {

  //   const updatedAreas = areas.map((area) => {

  //     console.log("areaIdareaId", area.areaid, areaId)

  //     if (area.areaid === areaId) {

  //       const newSlabDetails = {

  //         maxvalue: '0',

  //         minvalue: '0',

  //         note: "",

  //         uptomax: "",

  //         netpremium: "",

  //         subpremiums: {}



  //       };

  //       if (!area.slabDetails) {

  //         console.log("inside loop", !area.slabDetails)

  //         return { ...area, slabDetails: [newSlabDetails] };

  //       } else {

  //         return { ...area, slabDetails: [...area.slabDetails, newSlabDetails] };

  //       }

  //     }

  //     return area;

  //   });

  //   setAreas(updatedAreas);

  //   console.log("addslab", updatedAreas)



  // };

  const addSlabTabs = (areaId, index) => {

    const updatedAreas = areas.map((area) => {
      if (area.areaid === areaId) {
        const newSlabDetails = {
          maxvalue: '0',
          minvalue: '0',
          note: "",
          uptomax: "",
          netpremium: "",
          subpremiums: {}
        };

        if (!area.slabDetails) {
          return { ...area, slabDetails: [newSlabDetails] };
        } else {
          return { ...area, slabDetails: [...area.slabDetails, newSlabDetails] };
        }
      }
      return area;
    });

    // If the area doesn't exist, create a new area with slab tabs
    if (!updatedAreas.find(area => area.areaid === areaId)) {
      const newArea = {
        areaid: areaId,
        slabDetails: [{
          maxvalue: '0',
          minvalue: '0',
          note: "",
          uptomax: "",
          netpremium: "",
          subpremiums: {}
        }]
      };
      updatedAreas.push(newArea);
    }

    setAreas(updatedAreas);
    console.log("addslab", updatedAreas);
  };


  const removeSlabTab = (areaId) => {

    const updatedAreas = areas.map((area) => {

      if (area.areaid === areaId && area.slabDetails && area.slabDetails.length > 0) {

        const updatedSlabDetails = area.slabDetails.slice(0, -1); // Remove the last slab

        return { ...area, slabDetails: updatedSlabDetails };

      }

      return area;

    });

    setAreas(updatedAreas);

    console.log("removeSlab", updatedAreas);

  };





  const [selectedAreaId, setSelectedAreaId] = useState(null);

  const handleNameClick = (areaid) => {
    setSelectedAreaId(areaid);
    navigate(`/area/${areaid}`, { state: { commissiondetails: opportunityValues } });
  };

  useEffect(() => {
    // Check if the location state contains the selected area id
    const areaIdFromState = location.state?.commissiondetails?.areaid;

    // If it does, update the selected area id state
    if (areaIdFromState) {
      setSelectedAreaId(areaIdFromState);
    }
  }, [location.state]);

  const filteredTableData = tableData.filter((option) => option.areaid === selectedAreaId);



  // const addDiscountTabs = (areaId, index) => {

  //   console.log("areaId", areaId)

  //   const updatedAreas = areas.map((area) => {

  //     console.log("areaIdareaId", area.areaid, areaId)

  //     if (area.areaid === areaId) {

  //       const newDiscountDetails = {

  //         maxvalue: '0',

  //         minvalue: '0',

  //         note: "",

  //         uptomax: "",


  //         netpremium: "",

  //         subpremiums: {}



  //       };

  //       if (!area.discountDetails) {

  //         console.log("inside loop", !area.discountDetails)

  //         return { ...area, discountDetails: [newDiscountDetails] };

  //       } else {

  //         return { ...area, discountDetails: [...area.discountDetails, newDiscountDetails] };

  //       }

  //     }

  //     return area;

  //   });

  //   setAreas(updatedAreas);

  //   console.log("addslab", updatedAreas)



  // };
  const addDiscountTabs = (areaId, index) => {

    console.log("areaId", areaId)

    const updatedAreas = areas.map((area) => {

      console.log("areaIdareaId", area.areaid, areaId)

      if (area.areaid === areaId) {

        const newDiscountDetails = {
          maxvalue: '0',
          minvalue: '0',
          note: "",
          uptomax: "",
          netpremium: "",
          subpremiums: {}
        };

        if (!area.discountDetails) {
          console.log("inside loop", !area.discountDetails)
          return { ...area, discountDetails: [newDiscountDetails] };
        } else {
          return { ...area, discountDetails: [...area.discountDetails, newDiscountDetails] };
        }

      }

      return area;

    });

    // If the area doesn't exist, create a new area with discount tabs
    if (!updatedAreas.find(area => area.areaid === areaId)) {
      const newArea = {
        areaid: areaId,
        discountDetails: [{
          maxvalue: '0',
          minvalue: '0',
          note: "",
          uptomax: "",
          netpremium: "",
          subpremiums: {}
        }]
      };
      updatedAreas.push(newArea);
    }

    setAreas(updatedAreas);

    console.log("addDiscountTabs", updatedAreas);
  };

  const removeDiscountTab = (areaId) => {
    const updatedAreas = areas.map((area) => {
      if (area.areaid === areaId && area.discountDetails && area.discountDetails.length > 0) {
        const updatedSlabDetails = area.discountDetails.slice(0, -1); // Remove the last slab
        return { ...area, discountDetails: updatedSlabDetails };

      }

      return area;

    });

    setAreas(updatedAreas);

    console.log("removeSlab", updatedAreas);

  };
  // const addFlatTabs = (areaId, index) => {

  //   console.log("areaId", areaId)

  //   const updatedAreas = areas.map((area) => {

  //     console.log("areaIdareaId", area.areaid, areaId)

  //     if (area.areaid === areaId) {

  //       const newFlatDetails = {
  //         upperLimit: '0',
  //         lowerLimit: '0',
  //         note: "",
  //         uptomax: "",
  //         netpremium: "",
  //         subpremiums: {},
  //         areaid: area.areaid
  //       };

  //       if (!area.flatdetails) {
  //         console.log("inside loop", !area.flatdetails)
  //         return { ...area, flatdetails: [newFlatDetails] };
  //       } else {
  //         // Check if flat tabs already exist for the area
  //         const flatTabsExist = area.flatdetails.some((flat) => {
  //           // You may need to adjust the condition based on your data structure
  //           return /* Add your condition here */ true;
  //         });

  //         if (!flatTabsExist) {
  //           return { ...area, flatdetails: [...area.flatdetails, newFlatDetails] };
  //         }

  //         // If flat tabs already exist, return the area as it is
  //       }
  //     }

  //     return area;
  //   });

  //   // If the area doesn't exist, create a new area with flat tabs
  //   if (!updatedAreas.find(area => area.areaid === areaId)) {
  //     const newArea = {
  //       areaid: areaId,
  //       flatdetails: [{
  //         upperLimit: '0',
  //         lowerLimit: '0',
  //         note: "",
  //         uptomax: "",
  //         netpremium: "",
  //         subpremiums: {},
  //         areaid: areaId
  //       }]
  //     };
  //     updatedAreas.push(newArea);
  //   }

  //   setAreas(updatedAreas);
  //   console.log("flatttt", updatedAreas);
  // };
  // const addFlatTabs = (areaId, index) => {
  //   console.log("areaId", areaId);

  //   const updatedAreas = areas.map((area) => {
  //     console.log("areaIdareaId", area.areaid, areaId);

  //     if (area.areaid === areaId) {
  //       const newFlatDetails = {
  //         upperLimit: '0',
  //         lowerLimit: '0',
  //         note: "",
  //         uptomax: "",
  //         netpremium: "",
  //         subpremiums: {},
  //         areaid: area.areaid
  //       };

  //       if (!area.flatdetails) {
  //         console.log("inside loop", !area.flatdetails);
  //         return { ...area, flatdetails: [newFlatDetails] };
  //       } else {
  //         // Check if flat tabs already exist for the area
  //         const flatTabsExist = area.flatdetails.length > 0;

  //         if (!flatTabsExist) {
  //           return { ...area, flatdetails: [...area.flatdetails, newFlatDetails] };
  //         }


  //       }
  //     }

  //     return area;
  //   });


  //   if (!updatedAreas.find((area) => area.areaid === areaId)) {
  //     const newArea = {
  //       areaid: areaId,
  //       flatdetails: [{
  //         upperLimit: '0',
  //         lowerLimit: '0',
  //         note: "",
  //         uptomax: "",
  //         netpremium: "",
  //         subpremiums: {},
  //         areaid: areaId
  //       }]
  //     };
  //     updatedAreas.push(newArea);
  //   }

  //   setAreas(updatedAreas);
  //   console.log("flatttt", updatedAreas);
  // };


  const addFlatTabs = (areaId, index) => {
    console.log("areaId", areaId);

    const updatedAreas = areas.map((area) => {
      console.log("areaIdareaId", area.areaid, areaId);

      if (area.areaid === areaId) {
        const newFlatDetails = {
          upperLimit: '0',
          lowerLimit: '0',
          note: "",
          uptomax: "",
          netpremium: "",
          subpremiums: {},
          areaid: area.areaid
        };

        // Ensure flatdetails is an array
        if (!Array.isArray(area.flatdetails)) {
          console.log("inside loop", !Array.isArray(area.flatdetails));
          // Assign the newFlatDetails array directly to flatdetails
          return { ...area, flatdetails: [newFlatDetails] };
        } else {
          // Assign the newFlatDetails array directly to flatdetails
          area.flatdetails = [newFlatDetails];
          return area;
        }
      }

      return area;
    });

    // If the area doesn't exist, create a new area with flatdetails
    if (!updatedAreas.find((area) => area.areaid === areaId)) {
      const newArea = {
        areaid: areaId,
        flatdetails: [{
          upperLimit: '0',
          lowerLimit: '0',
          note: "",
          uptomax: "",
          netpremium: "",
          subpremiums: {},
          areaid: areaId
        }]
      };
      updatedAreas.push(newArea);
    }

    setAreas(updatedAreas);
    console.log("flatttt", updatedAreas);
  };


  // const addFlatTabs = (areaId, index) => {

  //   console.log("areaId", areaId)

  //   const updatedAreas = areas.map((area) => {

  //     console.log("areaIdareaId", area.areaid, areaId)

  //     if (area.areaid === areaId) {

  //       const newFlatDetails = {

  //         upperLimit: '0',

  //         lowerLimit: '0',

  //         note: "",

  //         uptomax: "",


  //         netpremium: "",

  //         subpremiums: {},
  //         areaid: area.areaid



  //       };

  //       if (!area.flatdetails) {

  //         console.log("inside loop", !area.flatdetails)

  //         return { ...area, flatdetails: [newFlatDetails] };

  //       } else {
  //         const flatTabExists = area.flatdetails.some((flat) => {
  //           // You may need to adjust the condition based on your data structure
  //           return /* Add your condition here */ true;
  //         });
  //         if (!flatTabExists) {
  //           return { ...area, flatdetails: [...area.flatdetails, newFlatDetails] };
  //         };

  //         // return { ...area, flatdetails: [...area.flatdetails, newFlatDetails] };
  //       }
  //     }
  //     return area;
  //   });
  //   setAreas(updatedAreas);
  //   console.log("flatttttttttttttttttttttttttttt", updatedAreas)
  // };


  // useEffect(() => {
  //   handleEdit()

  // }, [])


  // const handleEdit = (rule) => {
  //   const ruleid = location?.state?.ruleid;
  //   // Make an API call using Axios
  //   axios.get(Rest.viewSlabcommission, {
  //     params: {
  //       ruleid: ruleid
  //     },
  //     headers: {
  //       'Content-Type': 'application/json',
  //       "X-fiftyaccess-Token": token
  //     },
  //   })
  //     .then(response => {
  //       console.log('API Response:', response?.data?.data);
  //       setOpportunityValues(response?.data?.data)
  //     })
  //     .catch(error => {
  //       // Handle API errors
  //       console.error('API Error:', error);
  //     });
  // };

  return (
    <>

      <Form>

        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '5rem' }}>

          <Typography style={{ marginLeft: '1rem', marginTop: '10px' }} variant="h6" align="center" gutterBottom>
            Commission Rules
          </Typography>


        </div>

        <Grid container>

          <Grid item xs={2} >

            <Controls.Dropdownall

              label='Provider'

              options={providerOptions?.map(option => ({

                value: option.provider,

                label: option.provider

              }))}

              value={opportunityValues.provider}

              onChange={(e) => handleProviderChange(e.target.value)}

            />

            {formErrors.provider && (

              <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.provider}</div>

            )}

          </Grid>



          <Grid item xs={2} >

            <Controls.Dropdownall

              options={products?.map((product) => ({

                value: product.productid,

                label: product.product,

              }))}

              label="Product"

              value={opportunityValues.product}

              onChange={(e) => handleOpportunityChange('product', e.target.value)}

              style={{ marginBottom: '1rem' }}

            />

            {formErrors.product && (

              <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.product}</div>

            )}

          </Grid>

          {subProductOptions?.length > 1 && (

            <Grid item xs={2} >

              <>

                <Controls.Dropdownall

                  options={subProductOptions?.map((subProduct) => ({

                    value: subProduct.subproduct,

                    label: subProduct.subproduct

                  })) || []}

                  label="Sub Product"

                  value={opportunityValues.subproduct}

                  onChange={(e) =>

                    handleOpportunityChange('subproduct', e.target.value)

                  }

                />

                {formErrors.subproduct && (

                  <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.subproduct}</div>

                )}

              </>

            </Grid>

          )}

          <Grid item xs={2} >

            <Controls.Dropdownall

              options={policyOption}

              label="Policy Type"

              value={opportunityValues.interestedpolicytype}

              onChange={(e) => handleOpportunityChange('interestedpolicytype', e.target.value)}

            />

            {formErrors.interestedpolicytype && (

              <div style={{ color: 'red', fontSize: '10px' }}>{formErrors.interestedpolicytype}</div>

            )}



          </Grid>

          <Grid item xs={2} style={{ marginLeft: '2px' }}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>

              <DemoContainer

                components={[

                  'DatePicker',

                ]}

              >

                <DemoItem>

                  <DatePicker

                    label=" Effective Date"

                     defaultValue={opportunityValues.startdate}
                  

                    // onChange={e => { handleStartDateChange(e.toDate()) }}
                    onChange={(e) => {
                      const selectedDate = e ? e.toDate() : null;
                      handleStartDateChange(selectedDate);
                    }}
                    format='DD-MM-YYYY'
                    sx={{

                      backgroundColor: 'rgb(234, 237, 240,1)',

                      borderRadius: '5px',

                      '& .MuiInputLabel-root': {

                        fontSize: '12px',

                      },

                      '& .MuiSvgIcon-root': {

                        width: '24px',

                        height: '24px',

                        background: `url(${calendar})`,

                        backgroundSize: 'contain',

                        display: 'block',

                      },

                      '& input': {

                        width: '30',

                        height: '30',

                        padding: '12px',

                        fontSize: '12px',

                        alignContent: 'center',

                      },

                      '& .MuiInput-underline': {

                        '&:before': {

                          borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color

                        },

                        '&:hover:not(.Mui-disabled):before': {

                          borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color

                        },

                      },

                    }}

                    slotProps={{ textField: { size: 'small' } }}

                  />

                </DemoItem>

              </DemoContainer>

            </LocalizationProvider>

          </Grid>

          <Grid item xs={2}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>

              <DemoContainer

                components={[

                  'DatePicker',
                ]}

              >

                <DemoItem>
              
                  <DatePicker

                    label=" End Date"
                    defaultValue={dayjs(opportunityValues.enddate)}
                    // onChange={e => { handleEndDateChange(e.toDate()) }}
                    onChange={(e) => {
                      const selectedDate = e ? e.toDate() : null;
                      handleEndDateChange(selectedDate);
                    }}

                    format="DD-MM-YYYY"
                    sx={{

                      backgroundColor: 'rgb(234, 237, 240,1)',

                      borderRadius: '5px',

                      '& .MuiInputLabel-root': {

                        fontSize: '12px',

                      },

                      '& .MuiSvgIcon-root': {

                        width: '24px',

                        height: '24px',

                        background: `url(${calendar})`,

                        backgroundSize: 'contain',

                        display: 'block',

                      },

                      '& input': {

                        width: '30',

                        height: '30',

                        padding: '12px',

                        fontSize: '12px',

                        alignContent: 'center',

                      },

                      '& .MuiInput-underline': {

                        '&:before': {

                          borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color

                        },

                        '&:hover:not(.Mui-disabled):before': {

                          borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color
                        },

                      },

                    }}

                    slotProps={{ textField: { size: 'small' } }}

                  />

                </DemoItem>

              </DemoContainer>

            </LocalizationProvider>

          </Grid>

          {/* <Grid style={{ marginTop: '1.5rem', cursor: 'pointer' }}>

            <Link onClick={() => { navigate("/area/", { state: { commissiondetails: opportunityValues } }) }}>Define Area</Link>

          </Grid> */}
          <Grid style={{ marginTop: '1.5rem', cursor: 'pointer' }}>

            <FormControlLabel
              control={
                <Checkbox
                  checked={selectedArea}
                  onChange={handleCheckboxChange}
                  inputProps={{ 'aria-label': 'Select Area' }}
                  disabled={!opportunityValues.provider}

                />
              }
              label="Select Area"
            />
            {selectedArea && (
              <Link onClick={handleDefineAreaClick}>
                Define Area
              </Link>
            )}
          </Grid>


          <Grid item xs={12} >

            <Radio value="flat"
              onChange={handleRadioChange}
              checked={value === 'flat'} />
            Based on Flat
            <Radio value="discount"
              onChange={handleRadioChange}
              checked={value === 'discount'} />
            Based on Discount
            <Radio value="slab"
              onChange={handleRadioChange}
              checked={value === 'slab'} />
            Based on Slab
          </Grid>

        </Grid>

      </Form>

      {flatCardOpen && (
        <Card>
          <CardContent>
            <RadioGroup

              aria-label="premiumOptions"

              name="premiumOptions"

              value={premiumRadio || 'netpremium'}

              onChange={(e) => {

                setPremiumRadio(e.target.value)

              }}


              style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', rowGap: '10rem' }}

            >

              <FormControlLabel value="netpremium" control={<Radio />} label="Net Premium" />

              <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />

            </RadioGroup>
            <FormControl>

              <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
                  <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>
                    {areas?.map((tab, index) => (
                      <li
                        key={index}
                        style={{
                          cursor: 'pointer',
                          padding: '10px',
                          borderRadius: '5px',
                          transition: 'background-color 0.3s ease',
                          marginRight: '5px',
                          minWidth: '120px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textAlign: 'center',
                          borderBottom: editFlatIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color
                          borderLeft: '1px solid #ddd', // Added left border
                          borderRight: '1px solid #ddd', // Added right border
                          backgroundColor: tab.areaid === selectedArea ? '#f8f9fa' : '',
                          color: tab.areaid === selectedArea ? '#0056b3' : ''
                        }}
                        // onClick={() => setSelectedArea(tab.areaid)} 
                        onClick={() => {
                          setSelectedArea(tab.areaid);
                          setFlatTabOpen(true); // Open the flat tab when an area tab is clicked
                        }}
                      >
                        <>
                          <span>{tab.area}</span>
                          <div>
                            <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                              {tableData
                                .filter((option) => option.areaid === tab.areaid)
                                .map((filteredOption, index) => (
                                  <div
                                    key={index}
                                    onClick={() => handleNameClick(filteredOption.areaid, filteredOption.provider)}
                                    style={{
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                      color: selectedAreaId === filteredOption.areaid ? 'red' : 'inherit', // Change color as needed
                                      fontWeight: selectedAreaId === filteredOption.areaid ? 'bold' : 'normal', // Add this line for bold text if selected
                                    }}
                                  >
                                    {filteredOption.name}
                                  </div>
                                ))}
                            </div>
                            <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                            </div>
                          </div>
                        </>
                      </li>
                    ))}

                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        style={{
                          cursor: 'pointer',
                          padding: '8px 15px',
                          borderRadius: '5px',
                          marginRight: '5px',
                          transition: 'background-color 0.3s ease',
                          color: '#0056b3',
                          backgroundColor: '#f8f9fa',
                        }}
                        onClick={() => { navigate("/area/", { state: { commissiondetails: opportunityValues } }) }}
                      >
                        + Add Area
                      </span>
                    </span>

                  </ul>
                  <div style={{ padding: '20px', overflowY: 'auto' }}>
                    {areas?.map((tab, index) => (
                      <div key={tab.id} style={{ display: areaeditIndex === index ? 'block' : 'none' }}>
                      </div>

                    ))}
                    {flatCardOpen && (

                      <Card>
                        <CardContent>
                          <FormControl>

                            <h6>Area {selectedArea}</h6>

                            <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
                              <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>

                                <li
                                  // key={index}
                                  style={{
                                    cursor: 'pointer',
                                    padding: '10px',
                                    borderRadius: '5px',
                                    transition: 'background-color 0.3s ease',
                                    marginRight: '5px',
                                    minWidth: '120px',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textAlign: 'center',
                                    borderBottom: '2px solid #0056b3', // Modified border color
                                    borderLeft: '1px solid #ddd', // Added left border
                                    borderRight: '1px solid #ddd', // Added right border
                                    backgroundColor: '#f8f9fa',
                                    color: '#0056b3',

                                  }}
                                  onClick={() => addFlatTabs(selectedArea)}
                                >
                                  {/* </span> */}
                                  <>
                                    <span>Flat</span>
                                    <div>
                                      <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                                        {'['}{'0'}-{'Max'}{']'}
                                      </div>
                                    </div>


                                  </>
                                </li>
                                {/* ))} */}

                              </ul>
                              <div style={{ padding: '20px', overflowY: 'auto' }}>
                                {areas
                                  .find((area) => area.areaid === selectedArea)
                                  ?.flatdetails?.map((tab, index) => (
                                    <div key={tab.id} style={{ display: editFlatIndex === index ? 'block' : 'none' }}>
                                      <div style={{ marginTop: '7px' }}>
                                        <span>Flat Range   </span>
                                        <input
                                          placeholder="Lower Limit"

                                          value={index === 0 ? "0" : tab.lowerLimit}
                                          onChange={(e) => {
                                            const newFlatDetails = areas
                                              .find((area) => area.areaid === selectedArea)
                                              ?.flatdetails?.map((slab, i) => {
                                                if (i === index) {
                                                  return { ...slab, lowerLimit: e.target.value };
                                                }
                                                return slab;
                                              });
                                            const updatedAreas = areas.map((area) => {
                                              if (area.areaid === selectedArea) {
                                                return { ...area, flatdetails: newFlatDetails };
                                              }
                                              return area;
                                            });
                                            setAreas(updatedAreas);
                                          }}
                                          disabled={true}
                                          readOnly={index === 0}
                                          style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
                                        />
                                        <input
                                          placeholder="Upper Limit"
                                          value={tab.upperLimit}
                                          onChange={(e) => {
                                            const newFlatDetails = areas
                                              .find((area) => area.areaid === selectedArea)
                                              ?.flatdetails?.map((slab, i) => {
                                                if (i === index) {
                                                  return { ...slab, upperLimit: e.target.value };
                                                }
                                                return slab;
                                              });
                                            const updatedAreas = areas.map((area) => {
                                              if (area.areaid === selectedArea) {
                                                return { ...area, flatdetails: newFlatDetails };
                                              }
                                              return area;
                                            });
                                            setAreas(updatedAreas);
                                          }}
                                          disabled={true}
                                          style={{
                                            width: '120px',
                                            padding: '3px',
                                            borderRadius: '5px',
                                            marginRight: '10px',
                                            boxSizing: 'border-box'
                                          }}
                                        />

                                        <label htmlFor="upToMaxCheckbox" style={{ margin: '0' }}>Up to Max</label>
                                      </div>
                                      <RadioGroup
                                        aria-label={`premiumOptions_${tab.id}`}
                                        name={`premiumOptions_${tab.id}`}
                                        value={tab.selectedOption}
                                        onChange={(e) => {
                                          const newFlatDetails = areas
                                            .find((area) => area.areaid === selectedArea)
                                            ?.flatdetails?.map((slab, i) => {

                                              if (i === index) {

                                                return { ...slab, selectedOption: e.target.value };
                                              }
                                              return slab;

                                            });

                                          const updatedAreas = areas.map((area) => {

                                            if (area.areaid === selectedArea) {

                                              return { ...area, flatdetails: newFlatDetails };

                                            }

                                            return area;

                                          });

                                          setAreas(updatedAreas);

                                        }}
                                      >
                                        {/* <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium"/>  */}

                                        {premiumRadio === 'netpremium' && (
                                          <div style={{ marginLeft: '1rem', marginTop: '20px' }}>
                                            <Typography style={{ marginRight: '0.5rem', marginTop: '1rem' }}> NetPremium:
                                              {/* <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={tab.checkboxValue}
                                                  onChange={(e) => {
                                                    const newFlatDetails = areas
                                                      .find((area) => area.areaid === selectedArea)
                                                      ?.flatdetails?.map((slab, i) => {
                                                        if (i === index) {
                                                          return { ...slab, netpremium: e.target.value };
                                                        }
                                                        return slab;
                                                      });
                                                    const updatedAreas = areas.map((area) => {
                                                      if (area.areaid === selectedArea) {
                                                        return { ...area, flatdetails: newFlatDetails };
                                                      }
                                                      return area;
                                                    });
                                                    setAreas(updatedAreas);
                                                  }}
                                                  style={{ color: '#001f3f' }}  // Set the color to dark blue (#001f3f)
                                                />
                                              }
                                              label="NetPremium"
                                            /> */}
                                              <Controls.Input
                                                type="text"
                                                label="Commission Percentage"
                                                value={tab.netpremium}


                                                onChange={(e) => {
                                                  const newFlatDetails = areas
                                                    .find((area) => area.areaid === selectedArea)
                                                    ?.flatdetails?.map((slab, i) => {
                                                      if (i === index) {
                                                        return { ...slab, netpremium: e.target.value };
                                                      }
                                                      return slab;
                                                    });
                                                  const updatedAreas = areas.map((area) => {
                                                    if (area.areaid === selectedArea) {
                                                      return { ...area, flatdetails: newFlatDetails };
                                                    }
                                                    return area;
                                                  });
                                                  setAreas(updatedAreas);
                                                }}
                                              />
                                            </Typography>
                                          </div>
                                        )}
                                        {/* <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />    */}
                                      </RadioGroup>
                                      {premiumRadio === 'subPremiums' &&
                                        subpremiums[0] &&
                                        Object.entries(subpremiums[0])?.map(([fieldName]) => (
                                          <Grid container key={fieldName} alignItems='center' style={{ marginBottom: '20px', marginLeft: '1rem', marginTop: '20px' }}>
                                            <Grid item>
                                              <Checkbox
                                                checked={tab.subpremiums?.[fieldName] || false}
                                                onChange={(e) => {
                                                  const newFlatDetails = areas
                                                    .find((area) => area.areaid === selectedArea)
                                                    ?.flatdetails?.map((slab, i) => {
                                                      if (i === index) {
                                                        return { ...slab, subpremiums: { ...slab.subpremiums, [fieldName]: e.target.checked ? true : false } };
                                                      }
                                                      return slab;
                                                    });
                                                  const updatedAreas = areas.map((area) => {
                                                    if (area.areaid === selectedArea) {
                                                      return { ...area, flatdetails: newFlatDetails };
                                                    }
                                                    return area;
                                                  });
                                                  setAreas(updatedAreas);
                                                }}
                                                name={`${fieldName}_checkbox`}
                                                color="primary"
                                              />
                                            </Grid>
                                            <Grid item xs={5}>
                                              <FormControlLabel
                                                control={<div />}
                                                label={fieldName}
                                              />
                                            </Grid>
                                            <Grid item xs={5}>
                                              <Controls.Input
                                                type="text"
                                                label={"Commission Percentage"}
                                                value={tab.subpremiums?.[fieldName] && tab.subpremiums[fieldName] !== true ? tab.subpremiums[fieldName] : ''}
                                                onChange={(e) => {
                                                  const newFlatDetails = areas
                                                    .find((area) => area.areaid === selectedArea)
                                                    ?.flatdetails?.map((slab, i) => {
                                                      if (i === index) {
                                                        return { ...slab, subpremiums: { ...slab.subpremiums, [fieldName]: e.target.value } };
                                                      }
                                                      return slab;
                                                    });
                                                  const updatedAreas = areas.map((area) => {
                                                    if (area.areaid === selectedArea) {
                                                      return { ...area, flatdetails: newFlatDetails };
                                                    }
                                                    return area;
                                                  });
                                                  setAreas(updatedAreas);
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        ))}

                                      <label htmlFor={`textarea-${index}`}>Note:</label>
                                      <textarea
                                        id={`textarea-${index}`}

                                        value={tab.note}

                                        onChange={(e) => {

                                          const newFlatDetails = areas

                                            .find((area) => area.areaid === selectedArea)

                                            ?.flatdetails?.map((slab, i) => {

                                              if (i === index) {

                                                return { ...slab, note: e.target.value };

                                              }
                                              return slab;
                                            });
                                          const updatedAreas = areas.map((area) => {
                                            if (area.areaid === selectedArea) {
                                              return { ...area, flatdetails: newFlatDetails };
                                            }
                                            return area;
                                          });
                                          setAreas(updatedAreas);
                                        }}
                                        style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ddd', borderRadius: '5px', boxSizing: 'border-box', resize: 'vertical' }}
                                      > </textarea>
                                      {/* <Grid style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                        <Controls.Button text="Previous" type="submit" onClick={() => moveToPreviousTab('Prevous')} />
                                        <Controls.Button text="Next" type="submit" onClick={() => moveToNextTab('Next')} />

                                      </Grid> */}
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </FormControl>
                        </CardContent>
                      </Card>
                    )}
                  </div>
                </div>
              </div>

            </FormControl>
          </CardContent>
        </Card>
      )}


      {discountCardOpen && (
        <Card>
          <CardContent>
            <RadioGroup

              aria-label="premiumOptions"

              name="premiumOptions"

              value={premiumRadio || 'netPremium'}

              onChange={(e) => {

                setPremiumRadio(e.target.value)

              }}

              style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', rowGap: '10rem' }}

            >

              <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium" />

              <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />

            </RadioGroup>
            <FormControl>

              <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>

                <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>

                  <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>

                    {areas?.map((tab, index) => (

                      <li

                        key={index}

                        style={{

                          cursor: 'pointer',

                          padding: '10px',

                          borderRadius: '5px',

                          transition: 'background-color 0.3s ease',

                          marginRight: '5px',

                          minWidth: '120px',

                          textOverflow: 'ellipsis',

                          overflow: 'hidden',

                          whiteSpace: 'nowrap',

                          textAlign: 'center',

                          borderBottom: editDiscountIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color

                          borderLeft: '1px solid #ddd', // Added left border

                          borderRight: '1px solid #ddd', // Added right border

                          // backgroundColor: editDiscountIndex === index ? '#f8f9fa' : '',

                          // color: editDiscountIndex === index ? '#0056b3' : ''
                          backgroundColor: tab.areaid === selectedArea ? '#f8f9fa' : '',
                          color: tab.areaid === selectedArea ? '#0056b3' : ''
                        }}

                        onClick={() => setSelectedArea(tab.areaid)}                      >

                        <>

                          <span>{tab.area}</span>

                          <div>
                            <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                              {tableData
                                .filter((option) => option.areaid === tab.areaid)
                                .map((filteredOption, index) => (
                                  <div
                                    key={index}
                                    onClick={() => handleNameClick(filteredOption.areaid, filteredOption.provider)}
                                    style={{
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                      color: selectedAreaId === filteredOption.areaid ? 'red' : 'inherit', // Change color as needed
                                      fontWeight: selectedAreaId === filteredOption.areaid ? 'bold' : 'normal', // Add this line for bold text if selected
                                    }}
                                  >
                                    {filteredOption.name}
                                  </div>
                                ))}
                            </div>

                            <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>

                            </div>

                          </div>

                        </>

                      </li>

                    ))}

                    <span style={{ display: 'flex', alignItems: 'center' }}>

                      <span

                        style={{

                          cursor: 'pointer',

                          padding: '8px 15px',

                          borderRadius: '5px',

                          marginRight: '5px',

                          transition: 'background-color 0.3s ease',

                          color: '#0056b3',

                          backgroundColor: '#f8f9fa',

                        }}

                        onClick={() => { navigate("/area/", { state: { commissiondetails: opportunityValues } }) }}

                      >

                        + Add Area

                      </span>

                    </span>

                  </ul>
                  <div style={{ padding: '20px', overflowY: 'auto' }}>

                    {areas?.map((tab, index) => (

                      <div key={tab.id} style={{ display: areaeditIndex === index ? 'block' : 'none' }}>

                      </div>

                    ))}

                    {discountCardOpen && (

                      <Card>
                        <CardContent>
                          <FormControl>
                            <h6>Area {selectedArea}</h6>

                            <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>

                              <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>

                                {areas

                                  .find((area) => area.areaid === selectedArea)

                                  ?.discountDetails?.map((tab, index) => (

                                    <li

                                      key={index}

                                      style={{

                                        cursor: 'pointer',

                                        padding: '10px',

                                        borderRadius: '5px',

                                        transition: 'background-color 0.3s ease',

                                        marginRight: '5px',

                                        minWidth: '120px',

                                        textOverflow: 'ellipsis',

                                        overflow: 'hidden',

                                        whiteSpace: 'nowrap',

                                        textAlign: 'center',

                                        borderBottom: editDiscountIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color

                                        borderLeft: '1px solid #ddd', // Added left border

                                        borderRight: '1px solid #ddd', // Added right border

                                        backgroundColor: editDiscountIndex === index ? '#f8f9fa' : '',

                                        color: editDiscountIndex === index ? '#0056b3' : ''

                                      }}

                                      onClick={() => setEditDiscountIndex(index)}

                                    >
                                      <>
                                        <span>Discount {index + 1}</span>
                                        <div>
                                          <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>

                                            {'['}{tab.minvalue}-{tab.maxvalue}{']'}

                                          </div>

                                        </div>

                                      </>

                                    </li>

                                  ))}

                                <span style={{ display: 'flex', alignItems: 'center' }}>

                                  <span

                                    style={{

                                      cursor: 'pointer',

                                      padding: '8px 15px',

                                      borderRadius: '5px',

                                      marginRight: '5px',

                                      transition: 'background-color 0.3s ease',

                                      color: '#0056b3',

                                      backgroundColor: '#f8f9fa',

                                    }}

                                    onClick={() => addDiscountTabs(selectedArea)}

                                  >

                                    + Add

                                  </span>

                                  {editDiscountIndex !== null && (

                                    <span

                                      style={{

                                        cursor: 'pointer',

                                        padding: '8px 15px',

                                        borderRadius: '5px',

                                        transition: 'background-color 0.3s ease',

                                        color: '#0056b3',

                                        backgroundColor: '#f8f9fa',

                                      }}

                                      onClick={() => removeDiscountTab(selectedArea)}

                                    >

                                      &times; Remove

                                    </span>

                                  )}

                                </span>

                              </ul>

                              <div style={{ padding: '20px', overflowY: 'auto' }}>

                                {areas

                                  .find((area) => area.areaid === selectedArea)

                                  ?.discountDetails?.map((tab, index) => (

                                    <div key={tab.id} style={{ display: editDiscountIndex === index ? 'block' : 'none' }}>

                                      <div style={{ marginTop: '7px' }}>

                                        <span>Discount Range   </span>

                                        {/* <input
                                      type='number'
                                      placeholder="Lower Limit"

                                      value={index === 0 ? "0" : tab.lowerLimit}

                                      onChange={(e) => {

                                        const newDiscountDetails = areas

                                          .find((area) => area.areaid === selectedArea)

                                          ?.discountDetails?.map((slab, i) => {

                                            if (i === index) {

                                              return { ...slab, lowerLimit: e.target.value };

                                            }

                                            return slab;

                                          });

                                        const updatedAreas = areas.map((area) => {

                                          if (area.areaid === selectedArea) {

                                            return { ...area, discountDetails: newDiscountDetails };

                                          }

                                          return area;

                                        });

                                        setAreas(updatedAreas);

                                      }}

                                      readOnly={index === 0}

                                      style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
                                   
                                    /> */}
                                        <input
                                          type='number'
                                          placeholder="Lower Limit"
                                          value={tab.minvalue}
                                          onChange={(e) => {
                                            const inputNumber = parseInt(e.target.value, 10);
                                            if (!isNaN(inputNumber) && inputNumber >= 0 && inputNumber <= 100) {
                                              const newDiscountDetails = areas
                                                .find((area) => area.areaid === selectedArea)
                                                ?.discountDetails?.map((slab, i) => {
                                                  if (i === index) {
                                                    return { ...slab, minvalue: e.target.value };
                                                  }
                                                  return slab;
                                                });

                                              const updatedAreas = areas.map((area) => {
                                                if (area.areaid === selectedArea) {
                                                  return { ...area, discountDetails: newDiscountDetails };
                                                }
                                                return area;
                                              });

                                              setAreas(updatedAreas);
                                            }
                                            else if (e.target.value === '') {
                                              // Allow backspace by updating state when the input is empty
                                              const newDiscountDetails = areas
                                                .find((area) => area.areaid === selectedArea)
                                                ?.discountDetails?.map((slab, i) => {
                                                  if (i === index) {
                                                    return { ...slab, minvalue: '' };
                                                  }
                                                  return slab;
                                                });

                                              const updatedAreas = areas.map((area) => {
                                                if (area.areaid === selectedArea) {
                                                  return { ...area, discountDetails: newDiscountDetails };
                                                }
                                                return area;
                                              });
                                              setAreas(updatedAreas);
                                            }
                                          }}
                                          // readOnly={index === 0}
                                          style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
                                        />
                                        <input
                                          type='number'
                                          placeholder="Upper Limit"

                                          value={tab.maxvalue}

                                          onChange={(e) => {
                                            const newValue = parseFloat(e.target.value);

                                            // Check if the value is within the desired range (0 to 100)
                                            if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {


                                              const newDiscountDetails = areas

                                                .find((area) => area.areaid === selectedArea)

                                                ?.discountDetails?.map((slab, i) => {

                                                  if (i === index) {

                                                    return { ...slab, maxvalue: e.target.value };

                                                  }

                                                  return slab;

                                                });

                                              const updatedAreas = areas.map((area) => {

                                                if (area.areaid === selectedArea) {

                                                  return { ...area, discountDetails: newDiscountDetails };

                                                }

                                                return area;

                                              });

                                              setAreas(updatedAreas);
                                            } else if (e.target.value === '' || e.inputType === 'deleteContentBackward') {
                                              // Allow backspace to clear the input field
                                              const newDiscountDetails = areas
                                                .find((area) => area.areaid === selectedArea)
                                                ?.discountDetails?.map((slab, i) => {
                                                  if (i === index) {
                                                    return { ...slab, maxvalue: '' };
                                                  }
                                                  return slab;
                                                });

                                              const updatedAreas = areas.map((area) => {
                                                if (area.areaid === selectedArea) {
                                                  return { ...area, discountDetails: newDiscountDetails };
                                                }
                                                return area;
                                              });

                                              setAreas(updatedAreas);
                                            } else {
                                              // Handle invalid input (you may want to show an error message)
                                              console.error("Invalid input. Please enter a number between 0 and 100.");
                                            }
                                          }}


                                          disabled={disableDiscountUpperLimit}

                                          style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}

                                        />

                                        <input

                                          type="checkbox"

                                          id="upToMaxCheckbox"

                                          checked={disableDiscountUpperLimit}

                                          onChange={() => setDisableDiscountUpperLimit(!disableDiscountUpperLimit)}

                                          style={{ margin: '0 5px', width: '20px', height: '20px' }}

                                        />

                                        <label htmlFor="upToMaxCheckbox" style={{ margin: '0' }}>Up to Max</label>

                                      </div>
                                      <RadioGroup

                                        aria-label={`premiumOptions_${tab.id}`}
                                        name={`premiumOptions_${tab.id}`}
                                        value={tab.selectedOption}
                                        onChange={(e) => {
                                          const newDiscountDetails = areas
                                            .find((area) => area.areaid === selectedArea)
                                            ?.discountDetails?.map((slab, i) => {

                                              if (i === index) {

                                                return { ...slab, selectedOption: e.target.value };

                                              }

                                              return slab;

                                            });

                                          const updatedAreas = areas.map((area) => {

                                            if (area.areaid === selectedArea) {

                                              return { ...area, discountDetails: newDiscountDetails };

                                            }

                                            return area;

                                          });

                                          setAreas(updatedAreas);

                                        }}

                                      >
                                        {/* <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium"/>  */}
                                        {premiumRadio === 'netPremium' && (
                                          <div style={{ marginLeft: '1rem', marginTop: '20px' }}>
                                            <Typography style={{ marginRight: '0.5rem', marginTop: '1rem' }}> NetPremium:
                                              {/* <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={tab.checkboxValue}

                                                  onChange={(e) => {

                                                    const newDiscountDetails = areas

                                                      .find((area) => area.areaid === selectedArea)

                                                      ?.discountDetails?.map((slab, i) => {

                                                        if (i === index) {

                                                          return { ...slab, netpremium: e.target.value };
                                                        }
                                                        return slab;
                                                      });
                                                    const updatedAreas = areas.map((area) => {
                                                      if (area.areaid === selectedArea) {
                                                        return { ...area, discountDetails: newDiscountDetails };
                                                      }
                                                      return area;
                                                    });
                                                    setAreas(updatedAreas);

                                                  }}

                                                  style={{ color: '#001f3f' }}  // Set the color to dark blue (#001f3f)
                                                />
                                              }
                                              label="NetPremium"
                                            /> */}
                                              <Controls.Input
                                                type="text"
                                                label="Commission Percentage"
                                                value={tab.netpremium}


                                                onChange={(e) => {
                                                  const newDiscountDetails = areas
                                                    .find((area) => area.areaid === selectedArea)

                                                    ?.discountDetails?.map((slab, i) => {

                                                      if (i === index) {

                                                        return { ...slab, netpremium: e.target.value };

                                                      }

                                                      return slab;

                                                    });

                                                  const updatedAreas = areas.map((area) => {

                                                    if (area.areaid === selectedArea) {

                                                      return { ...area, discountDetails: newDiscountDetails };

                                                    }

                                                    return area;

                                                  });

                                                  setAreas(updatedAreas);

                                                }}

                                              />
                                            </Typography>
                                          </div>
                                        )}
                                        {/* <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />    */}
                                      </RadioGroup>
                                      {premiumRadio === 'subPremiums' &&
                                        subpremiums[0] &&
                                        Object.entries(subpremiums[0])?.map(([fieldName]) => (
                                          <Grid container key={fieldName} alignItems='center' style={{ marginBottom: '20px', marginLeft: '1rem', marginTop: '20px' }}>
                                            <Grid item>
                                              <Checkbox
                                                checked={tab.subpremiums?.[fieldName] || false}
                                                onChange={(e) => {
                                                  const newDiscountDetails = areas
                                                    .find((area) => area.areaid === selectedArea)
                                                    ?.discountDetails?.map((slab, i) => {
                                                      if (i === index) {
                                                        return { ...slab, subpremiums: { ...slab.subpremiums, [fieldName]: e.target.checked ? true : false } };
                                                      }
                                                      return slab;
                                                    });
                                                  const updatedAreas = areas.map((area) => {
                                                    if (area.areaid === selectedArea) {
                                                      return { ...area, discountDetails: newDiscountDetails };
                                                    }
                                                    return area;
                                                  });
                                                  setAreas(updatedAreas);
                                                }}
                                                name={`${fieldName}_checkbox`}
                                                color="primary"
                                              />
                                            </Grid>
                                            <Grid item xs={5}>
                                              <FormControlLabel
                                                control={<div />}
                                                label={fieldName}
                                              />
                                            </Grid>
                                            <Grid item xs={5}>
                                              <Controls.Input
                                                type="text"
                                                label={"Commission Percentage"}
                                                value={tab.subpremiums?.[fieldName] && tab.subpremiums[fieldName] !== true ? tab.subpremiums[fieldName] : ''}
                                                onChange={(e) => {
                                                  const newDiscountDetails = areas
                                                    .find((area) => area.areaid === selectedArea)
                                                    ?.discountDetails?.map((slab, i) => {
                                                      if (i === index) {
                                                        return { ...slab, subpremiums: { ...slab.subpremiums, [fieldName]: e.target.value } };
                                                      }
                                                      return slab;
                                                    });
                                                  const updatedAreas = areas.map((area) => {
                                                    if (area.areaid === selectedArea) {
                                                      return { ...area, discountDetails: newDiscountDetails };
                                                    }
                                                    return area;
                                                  });
                                                  setAreas(updatedAreas);
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        ))}
                                      <label htmlFor={`textarea-${index}`}>Note:</label>

                                      <textarea
                                        id={`textarea-${index}`}
                                        value={tab.note}
                                        onChange={(e) => {
                                          const newDiscountDetails = areas
                                            .find((area) => area.areaid === selectedArea)

                                            ?.discountDetails?.map((slab, i) => {

                                              if (i === index) {

                                                return { ...slab, note: e.target.value };

                                              }

                                              return slab;

                                            });

                                          const updatedAreas = areas.map((area) => {

                                            if (area.areaid === selectedArea) {

                                              return { ...area, discountDetails: newDiscountDetails };

                                            }

                                            return area;

                                          });

                                          setAreas(updatedAreas);

                                        }}

                                        style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ddd', borderRadius: '5px', boxSizing: 'border-box', resize: 'vertical' }}

                                      ></textarea>

                                      <Grid style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>

                                        <Controls.Button text="Previous" type="submit" onClick={() => moveDiscountToNextTab('Prevous')} />
                                        <Controls.Button text="Next" type="submit" onClick={() => moveDiscountToPreviousTab('Next')} />

                                      </Grid>

                                    </div>
                                  ))}
                              </div>
                            </div>

                          </FormControl>

                        </CardContent>
                      </Card>

                    )}

                  </div>

                </div>

              </div>

            </FormControl>

          </CardContent>

        </Card>

      )}

      {slabCardOpen && (
        <Card>
          <CardContent>
            <RadioGroup

              aria-label="premiumOptions"

              name="premiumOptions"

              value={premiumRadio || 'netPremium'}

              onChange={(e) => {

                setPremiumRadio(e.target.value)

              }}

              style={{ marginBottom: '20px', display: 'flex', flexDirection: 'row', rowGap: '10rem' }}

            >

              <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium" />

              <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />

            </RadioGroup>
            <FormControl>

              <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>

                <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
                  <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', scrollbarWidth: '10px', width: '100%' }}>
                    <ul style={{ listStyle: 'none', display: 'flex', alignItems: 'center', padding: '10px', overflowX: 'auto', whiteSpace: 'nowrap', margin: '0', scrollbarWidth: '10px' }}>

                      {areas?.map((tab, index) => (

                        <li
                          key={index}
                          style={{

                            cursor: 'pointer',

                            padding: '10px',

                            borderRadius: '5px',

                            transition: 'background-color 0.3s ease',

                            marginRight: '5px',

                            minWidth: '120px',

                            textOverflow: 'ellipsis',

                            overflow: 'hidden',

                            whiteSpace: 'nowrap',

                            textAlign: 'center',

                            borderBottom: editIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color

                            borderLeft: '1px solid #ddd', // Added left border

                            borderRight: '1px solid #ddd', // Added right border
                            backgroundColor: tab.areaid === selectedArea ? '#f8f9fa' : '',
                            color: tab.areaid === selectedArea ? '#0056b3' : ''


                          }}
                          onClick={() => setSelectedArea(tab.areaid)}
                        >
                          <>
                            <span>{tab.area}</span>

                            <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                              {tableData
                                .filter((option) => option.areaid === tab.areaid)
                                .map((filteredOption, index) => (
                                  <div
                                    key={index}
                                    onClick={() => handleNameClick(filteredOption.areaid, filteredOption.provider)}
                                    style={{
                                      cursor: 'pointer',
                                      textDecoration: 'underline',
                                      color: selectedAreaId === filteredOption.areaid ? 'red' : 'inherit', // Change color as needed
                                      fontWeight: selectedAreaId === filteredOption.areaid ? 'bold' : 'normal', // Add this line for bold text if selected
                                    }}
                                  >
                                    {filteredOption.name}
                                  </div>
                                ))}
                            </div>

                            <div>
                              <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                              </div>
                            </div>
                          </>
                        </li>
                      ))}
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <span
                          style={{
                            cursor: 'pointer',
                            padding: '8px 15px',
                            borderRadius: '5px',
                            marginRight: '5px',
                            transition: 'background-color 0.3s ease',
                            color: '#0056b3',
                            backgroundColor: '#f8f9fa',
                          }}
                          onClick={() => { navigate("/area/", { state: { commissiondetails: opportunityValues } }) }}
                        >
                          + Add Area
                        </span>
                      </span>

                    </ul>
                  </div>
                  <div style={{ padding: '20px', overflowY: 'auto' }}>
                    {areas?.map((tab, index) => (
                      <div key={tab.id} style={{ display: areaeditIndex === index ? 'block' : 'none' }}>
                      </div>
                    ))}

                    {slabCardOpen && (

                      <Card>
                        <CardContent>
                          <FormControl>

                            <h6>Area {selectedArea}</h6>

                            <div style={{ border: '1px solid #ddd', borderRadius: '5px', overflowX: 'hidden' }}>
                              {/* <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', scrollbarWidth: '10px', width: '100%' }}> */}
                              <ul style={{
                                listStyle: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                overflowX: 'auto',
                                whiteSpace: 'nowrap',
                                margin: '0',
                                scrollbarWidth: '10px',
                                overflowY: 'auto',
                              }}>

                                {areas
                                  .find((area) => area.areaid === selectedArea)
                                  ?.slabDetails?.map((tab, index) => (
                                    <li
                                      key={index}
                                      style={{
                                        cursor: 'pointer',
                                        padding: '10px',
                                        borderRadius: '5px',
                                        transition: 'background-color 0.3s ease',
                                        marginRight: '5px',
                                        minWidth: '120px',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textAlign: 'center',
                                        borderBottom: editIndex === index ? '2px solid #0056b3' : '1px solid #0066cc', // Modified border color
                                        borderLeft: '1px solid #ddd', // Added left border
                                        borderRight: '1px solid #ddd', // Added right border
                                        backgroundColor: editIndex === index ? '#f8f9fa' : '',
                                        color: editIndex === index ? '#0056b3' : ''

                                      }}
                                      onClick={() => setEditIndex(index)}
                                    >
                                      <>
                                        <span>Slab {index + 1}</span>
                                        <div>
                                          <div style={{ marginLeft: '1px', fontSize: '10px', color: 'purple' }}>
                                            {'['}{tab.minvalue}-{tab.maxvalue}{']'}
                                          </div>
                                        </div>
                                      </>
                                    </li>
                                  ))}
                                {/* <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px'}}> </div> */}
                                <span style={{ display: 'flex', alignItems: 'center' }}>

                                  <span
                                    style={{
                                      cursor: 'pointer',
                                      padding: '8px 15px',
                                      borderRadius: '5px',
                                      marginRight: '5px',
                                      transition: 'background-color 0.3s ease',
                                      color: '#0056b3',
                                      backgroundColor: '#f8f9fa',
                                    }}
                                    onClick={() => addSlabTabs(selectedArea)}
                                  >
                                    + Add
                                  </span>
                                  {editIndex !== null && (
                                    <span
                                      style={{
                                        cursor: 'pointer',
                                        padding: '8px 15px',
                                        borderRadius: '5px',
                                        transition: 'background-color 0.3s ease',
                                        color: '#0056b3',
                                        backgroundColor: '#f8f9fa',
                                      }}
                                      onClick={() => removeSlabTab(selectedArea)}
                                    >
                                      &times; Remove
                                    </span>
                                  )}

                                </span>


                              </ul>

                              {/* </div> */}
                              {/* <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', scrollbarWidth: '10px', width: '100%' }}> */}
                              <div style={{ padding: '20px', overflowY: 'auto', maxHeight: '400px' }}>
                                {areas
                                  .find((area) => area.areaid === selectedArea)
                                  ?.slabDetails?.map((tab, index) => (
                                    <div key={tab.id} style={{ display: editIndex === index ? 'block' : 'none' }}>
                                      <div style={{ marginTop: '7px' }}>
                                        <span>Slab Range   </span>
                                        <input
                                          placeholder="Lower Limit"
                                          type='number'
                                          value={index === 0 ? "0" : tab.minvalue}
                                          onChange={(e) => {
                                            const newSlabDetails = areas
                                              .find((area) => area.areaid === selectedArea)
                                              ?.slabDetails?.map((slab, i) => {
                                                if (i === index) {
                                                  return { ...slab, minvalue: e.target.value };
                                                }
                                                return slab;
                                              });

                                            const updatedAreas = areas.map((area) => {
                                              if (area.areaid === selectedArea) {
                                                return { ...area, slabDetails: newSlabDetails };
                                              }
                                              return area;
                                            });
                                            setAreas(updatedAreas);
                                          }}
                                          //  readOnly
                                          readOnly={index === 0}
                                          style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box', color: index === 0 ? 'gray' : 'black', }}

                                        />
                                        <input
                                          placeholder="Upper Limit"
                                          type="number"
                                          value={tab.maxvalue}
                                          onChange={(e) => {

                                            const newSlabDetails = areas
                                              .find((area) => area.areaid === selectedArea)
                                              ?.slabDetails?.map((slab, i) => {
                                                if (i === index) {
                                                  return { ...slab, maxvalue: e.target.value };


                                                }
                                                return slab;
                                              });
                                            const updatedAreas = areas.map((area) => {
                                              if (area.areaid === selectedArea) {
                                                return { ...area, slabDetails: newSlabDetails };
                                              }
                                              return area;
                                            });
                                            setAreas(updatedAreas);
                                          }}
                                          disabled={disableUpperLimit}
                                          style={{ width: '120px', padding: '3px', borderRadius: '5px', marginRight: '10px', boxSizing: 'border-box' }}
                                        />
                                        <input
                                          type="checkbox"
                                          id="upToMaxCheckbox"
                                          checked={disableUpperLimit}
                                          onChange={() => setDisableUpperLimit(!disableUpperLimit)}
                                          style={{ margin: '0 5px', width: '20px', height: '20px' }}
                                        />
                                        <label htmlFor="upToMaxCheckbox" style={{ margin: '0' }}>Up to Max</label>
                                      </div>
                                      <RadioGroup
                                        aria-label={`premiumOptions_${tab.id}`}
                                        name={`premiumOptions_${tab.id}`}
                                        value={tab.selectedOption}
                                        onChange={(e) => {
                                          const newSlabDetails = areas
                                            .find((area) => area.areaid === selectedArea)
                                            ?.slabDetails?.map((slab, i) => {

                                              if (i === index) {

                                                return { ...slab, selectedOption: e.target.value };

                                              }

                                              return slab;

                                            });

                                          const updatedAreas = areas.map((area) => {

                                            if (area.areaid === selectedArea) {

                                              return { ...area, slabDetails: newSlabDetails };

                                            }

                                            return area;

                                          });

                                          setAreas(updatedAreas);

                                        }}


                                      >
                                        {/* <FormControlLabel value="netPremium" control={<Radio />} label="Net Premium"/>  */}
                                        {premiumRadio === 'netPremium' && (
                                          <div style={{ marginLeft: '1rem', marginTop: '20px' }}>
                                            <Typography style={{ marginRight: '0.5rem', marginTop: '1rem' }}> NetPremium:
                                              {/* <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={tab.checkboxValue}
                                                 
                                                  onChange={(e) => {
                                                    const newSlabDetails = areas
                                                      .find((area) => area.areaid === selectedArea)
                                                      ?.slabDetails?.map((slab, i) => {
                                                        if (i === index) {
                                                          return { ...slab, netpremium: e.target.value };
                                                        }
                                                        return slab;
                                                      });
                                                    const updatedAreas = areas.map((area) => {
                                                      if (area.areaid === selectedArea) {
                                                        return { ...area, slabDetails: newSlabDetails };
                                                      }
                                                      return area;
                                                    });
                                                    setAreas(updatedAreas);
                                                  }}
                                                  style={{ color: '#001f3f' }}  // Set the color to dark blue (#001f3f)
                                                />
                                              }
                                              label="NetPremium"
                                            /> */}


                                              <Controls.Input
                                                type="number"
                                                // type="text"
                                                label="Commission Percentage"
                                                value={tab.netpremium}

                                                onChange={(e) => {
                                                  const newSlabDetails = areas
                                                    .find((area) => area.areaid === selectedArea)
                                                    ?.slabDetails?.map((slab, i) => {
                                                      if (i === index) {
                                                        return { ...slab, netpremium: e.target.value };
                                                      }
                                                      return slab;
                                                    });
                                                  const updatedAreas = areas.map((area) => {
                                                    if (area.areaid === selectedArea) {
                                                      return { ...area, slabDetails: newSlabDetails };
                                                    }
                                                    return area;
                                                  });
                                                  setAreas(updatedAreas);

                                                }}

                                              />
                                            </Typography>
                                          </div>

                                        )}

                                        {/* <FormControlLabel value="subPremiums" control={<Radio />} label="Sub Premiums" />    */}
                                      </RadioGroup>
                                      {premiumRadio === 'subPremiums' &&
                                        subpremiums[0] &&
                                        Object.entries(subpremiums[0])?.map(([fieldName]) => (
                                          <Grid container key={fieldName} alignItems='center' style={{ marginBottom: '20px', marginLeft: '1rem', marginTop: '20px' }}>
                                            <Grid item>
                                              <Checkbox
                                                checked={tab.subpremiums?.[fieldName] || false}

                                                onChange={(e) => {
                                                  const newSlabDetails = areas
                                                    .find((area) => area.areaid === selectedArea)
                                                    ?.slabDetails?.map((slab, i) => {
                                                      if (i === index) {
                                                        return { ...slab, subpremiums: { ...slab.subpremiums, [fieldName]: e.target.checked ? true : false } };
                                                      }
                                                      return slab;
                                                    });
                                                  const updatedAreas = areas.map((area) => {
                                                    if (area.areaid === selectedArea) {
                                                      return { ...area, slabDetails: newSlabDetails };
                                                    }
                                                    return area;
                                                  });
                                                  setAreas(updatedAreas);
                                                }}
                                                name={`${fieldName}_checkbox`}
                                                color="primary"
                                              />

                                            </Grid>
                                            <Grid item xs={5}>
                                              <FormControlLabel
                                                control={<div />}
                                                label={fieldName}
                                              />
                                            </Grid>
                                            <Grid item xs={5}>
                                              <Controls.Input
                                                // type="text"
                                                type="number"
                                                label={"Commission Percentage"}
                                                value={tab.subpremiums?.[fieldName] && tab.subpremiums[fieldName] !== true ? tab.subpremiums[fieldName] : ''}

                                                onChange={(e) => {
                                                  const newSlabDetails = areas
                                                    .find((area) => area.areaid === selectedArea)
                                                    ?.slabDetails?.map((slab, i) => {
                                                      if (i === index) {
                                                        return { ...slab, subpremiums: { ...slab.subpremiums, [fieldName]: e.target.value } };
                                                      }
                                                      return slab;
                                                    });
                                                  const updatedAreas = areas.map((area) => {
                                                    if (area.areaid === selectedArea) {
                                                      return { ...area, slabDetails: newSlabDetails };
                                                    }
                                                    return area;
                                                  });
                                                  setAreas(updatedAreas);
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                        ))}

                                      <label htmlFor={`textarea-${index}`}>Note:</label>
                                      <textarea
                                        id={`textarea-${index}`}
                                        value={tab.note}

                                        onChange={(e) => {

                                          const newSlabDetails = areas

                                            .find((area) => area.areaid === selectedArea)

                                            ?.slabDetails?.map((slab, i) => {

                                              if (i === index) {

                                                return { ...slab, note: e.target.value };

                                              }
                                              return slab;
                                            });
                                          const updatedAreas = areas.map((area) => {
                                            if (area.areaid === selectedArea) {

                                              return { ...area, slabDetails: newSlabDetails };
                                            }
                                            return area;
                                          });
                                          setAreas(updatedAreas);
                                        }}
                                        style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ddd', borderRadius: '5px', boxSizing: 'border-box', resize: 'vertical' }}
                                      > </textarea>
                                      <Grid style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                                        <Controls.Button text="Previous" type="submit" onClick={() => moveToPreviousTab('Previous')} />
                                        <Controls.Button text="Next" type="submit" onClick={() => moveToNextTab('Next')} />
                                      </Grid>
                                    </div>
                                  ))}
                              </div>
                            </div>


                          </FormControl>

                        </CardContent>

                      </Card>

                    )}

                  </div>

                </div>

              </div>

            </FormControl>

          </CardContent>

        </Card>

      )}
      <Grid style={{ marginLeft: '66rem' }}>

        <Controls.Button text='Save' type="submit" onClick={handleSubmit} />
        {loading && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9999, // Ensure the overlay is on top of other elements
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <LoadingSpinner />
            </div>
          </div>
        )}
        <Controls.Button text="Cancel" type="submit" onClick={() => handleButtonClick('Cancel')} />
        <Snackbar
          sx={{ marginLeft: '30rem' }}
          open={isSuccessSnackbarOpen}
          autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
          onClose={handleSnackbarClose}
          message="Data successfully added"

        />

      </Grid>

    </>

  );
}

export default Commission;






