import React, { useEffect, useRef, useState } from 'react';
import { Typography, Container, Grid, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import Controls from '../components/controls/Controls';
import { Form } from '../components/useForm';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { IconButton, Snackbar } from '@mui/material';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import calendar from './../assests/icons/calendar.png';
import asterisk from './../assests/icons/asterisk.png'
import { makeStyles } from '@material-ui/core';
import * as Rest from '../services/restapi';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingSpinner from '../components/controls/spinner';

const useStyles = makeStyles((theme) => ({
  customIcon: {
    width: 8, // Set the desired width of your custom icon
    height: 8, // Set the desired height of your custom icon
    verticalAlign: 'super',
    fontSize: '0.7em',
    marginRight: theme.spacing(17), 
  },
}));
const addons = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' }
]


const ncb =[
  {value:0,label:0},
  {value:20,label:20},
  {value:25,label:25},
  {value:30,label:30},
  {value:35,label:35},
  {value:50,label:50},
]
function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}
const today = dayjs();

const AvPolicy = () => {
  const classes = useStyles();
  const [policyValues, setPolicyValues] = useState({
    customerid: "",
    beneficiaryid: "",
    provider: "",
    product: "",
    subproduct: "",
    bookingcode: "Anvayaa",
    policytype: "",
    policystartdate: today,
    policyenddate: "",
    premiumpayfrequency: "Annual",
    salesperson: "",
    policynumber: "",
    saledate: today,
    department: 'Anvayaa sales',
    odpremium: 0,
    thirdpartypremium: 0,
    addonpremium: 0,
    tax: 0,
    netpremium: 0,
    grosspremium: 0,
    discount:"",
  });
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeID, setEmployeeID] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const location = useLocation();
  const customer = location.state.prospectValues || location.state;
  const opportunityValues = location.state.opportunity;
  const prospectid = customer?.prospectid || opportunityValues?.prospectid;
  const customerid = customer?.customerid || opportunityValues?.customerid;
  const beneficiaryid = customer?.beneficiarydetails?.[0]?.beneficiaryid || opportunityValues?.beneficiarydetails?.[0]?.beneficiaryid;
  const referralid = customer?.referralid || opportunityValues?.referralid;
  const [providerOptions, setProviderOptions] = useState([]);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const opportunityid = opportunityValues?.opportunityid;
  const opportunitystatus = customer?.opportunitydetails?.[0]?.opportunitystatus;
  const navigate = useNavigate();
  const [bookingCodes, setBookingCodes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [premiumFrequencyOptions, setPremiumFrequencyOptions] = useState([]);
  const [selectedSaledate, setSelectedSaledate] = useState(null)
  const [selectedPolicystartdate, setSelectedPolicystartdate] = useState(null)
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const employeeName = localStorage.getItem("EmployeeName");
  const interactionValues = location.state.interactionValues
  const [policycopy, setPolicycopy] = useState([{ file: null, fileName: '' }]);
  const [attachments, setAttachments] = useState([{ file: null, fileName: '' }]);
  const policyid = location.state.policydetails?.[0]?.policyid
  const [locations, setLocations] = useState({});
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const states = Object.keys(locations);
  const cities = locations[selectedState] || [];
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [attachmentToDeleteIndex, setAttachmentToDeleteIndex] = useState(null);
  const [premiumErrors, setPremiumErrors] = useState({});
  const [policyNumber, setPolicyNumber] = useState('');
  const [discount, setDiscount] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [daysCount, setDaysCount] = useState('');

  const isLocMount = useRef(true);
  const isEmpMount = useRef(true);
  const isPFMount = useRef(true);
  const isCodeMount = useRef(true);
  const isDeptMount = useRef(true);
  const isProviderMount = useRef(true);
  const isProductMount = useRef(true);
  const [isLoading, setIsLoading] = useState(false);
    //const salespersoName = opportunityValues.employeeDetails.FirstName +' '+  opportunityValues.employeeDetails.LastName

  const salespersonName = opportunityValues.employeeDetails
    ? `${opportunityValues.employeeDetails.FirstName} ${opportunityValues.employeeDetails.LastName}`
    : '';
  // Update the selected salesperson based on the name
  const selectedEmployeeID = opportunityValues.EmployeeID

  // Update the salesperson dropdown value based on the selectedEmployeeID
  useEffect(() => {
    setEmployeeID(selectedEmployeeID);
  }, [selectedEmployeeID]);

  const handlePolicyFileChange = (event, index) => {
    const updatedPolicycopy = [...policycopy];
    const file = event.target.files[0];
  
    // Check if a file is selected
    if (file) {
      updatedPolicycopy[index].file = file;
      setPolicycopy(updatedPolicycopy);
    }
  };
console.log("policycopy",policycopy[0].file)
  const handleFileChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const file = event.target.files[0];
  
    // Check if a file is selected
    if (file) {
      updatedAttachments[index].file = file;
      setAttachments(updatedAttachments);
    }
  };

  const handleFileNameChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const currentAttachment = updatedAttachments[index];
  
    const currentFile = currentAttachment.file;
    const newFileName = event.target.value;
  
    // Remove the existing extension if present and append the new extension
    const updatedFileName = removeFileExtension(newFileName) + getFileExtension(currentFile.name);
  
    const updatedFile = new File([currentFile], updatedFileName, { type: currentFile.type });
    // Update the attachment object with the modified file name and file
    updatedAttachments[index] = {
      ...updatedAttachments[index],
      file: updatedFile,
      fileName: event.target.value,
    };
  
    setAttachments(updatedAttachments);
  };
  
  // Helper function to get file extension
  const getFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(lastDotIndex) : '';
  };

  const removeFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(0, lastDotIndex) : fileName;
  };

  const addAttachment = () => {
    setAttachments([...attachments, { file: null, fileName: '' }]);
  };

  const handleDeleteAttachment = (index) => {
    setAttachmentToDeleteIndex(index);
    setIsDeleteDialogOpen(true);
  };
  const removeAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(updatedAttachments);
  };
  const handleConfirmDelete = () => {
    // Perform the delete operation
    const updatedAttachments = attachments.filter((_, i) => i !== attachmentToDeleteIndex);
    setAttachments(updatedAttachments);

    setIsDeleteDialogOpen(false);
    setAttachmentToDeleteIndex(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setAttachmentToDeleteIndex(null);
  };

  const handlePolicyNumber = (event) => {
    const policyNumberValue = event.target.value;
    setPolicyNumber(policyNumberValue);
    setPolicyValues((prevValues) => ({
      ...prevValues,
      policynumber: policyNumberValue,
    }));
  };
  const handleDiscount = (event) => {
    const discountValue = event.target.value;
  
    // Regular expression to check if the input is a valid number, percentage, or decimal
    const validInputRegex = /^(\d*\.?\d+|\.\d+)%?$/;
  
    if (discountValue.trim() === '') {
      // Clear the error message if the input is empty
      setErrorMessage('');
      setDiscount('');
      setPolicyValues((prevValues) => ({
        ...prevValues,
        discount: '',
      }));
    } else if (!validInputRegex.test(discountValue)) {
      // Set the error message for invalid input
      setErrorMessage('Please enter a valid number');
    } else {
      // Clear the error message if the input is valid
      setErrorMessage('');
      setDiscount(discountValue);
      setPolicyValues((prevValues) => ({
        ...prevValues,
        discount: discountValue,
      }));
    }
  };
  
  // const handleDiscount = (event) => {
  //   const discountValue = event.target.value;

  //   // Regular expression to check if the input is a valid number, percentage, or decimal
  //   const validInputRegex = /^(\d*\.?\d+|\.\d+)%?$/;

  //   if (!validInputRegex.test(discountValue)) {
  //     // Set the error message for invalid input
  //     setErrorMessage('Please enter a valid number');
  //   } else {
  //     // Clear the error message if the input is valid
  //     setErrorMessage('');
  //     setDiscount(discountValue);
  //     setPolicyValues((prevValues) => ({
  //       ...prevValues,
  //       discount: discountValue,
  //     }));
  //   }
  // };

  const handleSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };
  const handleCancel = () => {
    // Use the navigate function to go back to the ViewProspect page
    navigate(-1); // Replace with the actual path to ViewProspect
  };
  const subpremiumsdetails = products.find(product => product.product === policyValues.product)?.subpremiums[0];

  const subpremiums = Object.keys(subpremiumsdetails || {}).reduce((acc, key) => {
    acc[key] = (policyValues.subpremiums || {})[key] || subpremiumsdetails[key] || '';
    return acc;
  }, {});
  
console.log("subpremiums1111",subpremiums)
  const productDetails = products.find(product => product.product === policyValues.product)?.attributefields[0];

  const productdetails = Object.keys(productDetails || {}).reduce((acc, key) => {
    acc[key] = (policyValues.productdetails || {})[key] || productDetails[key] || '';
    return acc;
  }, {});


  const subProductOptions = products.find(
    (product) => product.product === policyValues.product
  )?.subProductDetails;

  const policyOption = subProductOptions
    ?.find((product) => product.product === policyValues.product)
    ?.subProductDetails?.policyTypeDetails.map((policyType) => ({
      value: policyType,
      label: policyType,
    })) || products.find((product) => product.product === policyValues.product)
      ?.subProductDetails?.[0]?.policyTypeDetails?.map((policyType) => ({
        value: policyType,
        label: policyType
      })) || []

      const handleNetpremiumChange = () => {
        let sum = 0;
        const myObject = subpremiums[0];
      
        for (const key in myObject) {
           const value = myObject[key];           
              sum += parseInt(value);
        }
        console.log('sum',sum)
        setPolicyValues((prevValues) => ({
            ...prevValues,
            netpremium: sum,
          }));
          };    
      
          function calculateNetpremium(subpremiums) {
            if (subpremiums) {
              let sum = 0;
              const myObject = subpremiums;
          
              for (const key in myObject) {
                const value = myObject[key];
                console.log('*****',value,key,!isNaN(value))
                if (value) {
                  sum += parseFloat(value);
                }
                
              }
              setPolicyValues((prevValues) => ({
                ...prevValues,
                netpremium: sum,
              }));
              return sum;
            }
          
            return 0; // Return a default value if subpremiums are not available
          }

  useEffect(() => {
    const newGrossPremium = policyValues.netpremium + parseFloat(policyValues.tax);
    setPolicyValues((prevValues) => ({
      ...prevValues,
      grosspremium: newGrossPremium || 0,
    }));
  }, [policyValues.netpremium, policyValues.tax]);

  const beneficiaryOptions = customer?.customer?.beneficiarydetails?.[0]?.map((beneficiary) => ({
    value: `${capitalizeFirstLetter(beneficiary.firstname)} ${capitalizeFirstLetter(beneficiary.lastname)}`,
    label: `${capitalizeFirstLetter(beneficiary.firstname)} ${capitalizeFirstLetter(beneficiary.lastname)}`,
  }));

  const handlePolicyChange = (field, value) => {
    setPolicyValues((prevValues) => ({
      ...prevValues, customerid: customerid, beneficiaryid: beneficiaryid, salesperson: employeeID,
      [field]: value,
    }));
  };


  const handleStartDateChange = (date) => {
    const policystartdate = moment(date)?.format('DD-MM-YYYY')
    setSelectedPolicystartdate(policystartdate)
    setPolicyValues((policyValues) => ({ ...policyValues, policystartdate: policystartdate }));
  }
  const handleEndDateChange = (date) => {
    const policyenddate = moment(date)?.format('DD-MM-YYYY')
    setPolicyValues((policyValues) => ({ ...policyValues, policyenddate: policyenddate }));
  }

  const handleOpenDialog = (date) => {
    const startDate = moment(policyValues.policystartdate).format("DD-MM-YYYY");
    const endDate = moment(date).format("DD-MM-YYYY");
     const start = moment(startDate, 'DD-MM-YYYY');
     const end = moment(endDate, 'DD-MM-YYYY');
     if (!start.isValid() || !end.isValid()) {
       return 0; 
     }
     const days = end.diff(start, 'days');
    setDaysCount(days);
    setOpenDialog(true);
  };
  
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSaleDateChange = (date) => {
    const saledate = moment(today)?.format('DD-MM-YYYY') ? moment(date)?.format('DD-MM-YYYY') : moment(today)?.format('DD-MM-YYYY')
    setSelectedSaledate(saledate)
    setPolicyValues((policyValues) => ({ ...policyValues, saledate: saledate }));
  }
  const handleProviderChange = (event) => {
    const provider = event;
    setPolicyValues((policyValues) => ({ ...policyValues, provider: provider }));
  };

  const handleBookingCodeChange = (event) => {
    const bookingcodes = event;
    /* setSelectedBookingCode(bookingcodes); */
    setPolicyValues((policyValues) => ({ ...policyValues, bookingcode: bookingcodes }));
  };
  const handleDepartmentChange = (event) => {
    const department = event;
    setPolicyValues((policyValues) => ({ ...policyValues, department: department }));
    /* setSelectedDepartment(event.target.value); */
  };

  
  const handlePremiumChange = (fieldName, value) => {
     // Create a copy of the opportunityValues object to avoid mutating the state directly
     const updatedOpportunityValues = { ...policyValues, subpremiums };
     updatedOpportunityValues.subpremiums[fieldName] = value;
     // Update the state with the updated opportunityValues
     setPolicyValues(updatedOpportunityValues);
  };
  const handleAttributeChange = (fieldName, value) => {
    // Create a copy of the opportunityValues object to avoid mutating the state directly
    const updatedOpportunityValues = { ...policyValues, productdetails };

    // Update the value based on the field name
    updatedOpportunityValues.productdetails[fieldName] = value;
    if (fieldName === "Claim Status" && value === "Yes") {
      updatedOpportunityValues["Eligible NCB %"] = 0; 
    }

    // Update the state with the updated opportunityValues
    setPolicyValues(updatedOpportunityValues);
  };

  // Function to handle changes in subpremiums
  const handleSubpremiumChange = (event, index) => {
    const { name, value } = event.target;
    const updatedSubpremiums = [...policyValues.subpremiums];
    updatedSubpremiums[index][2] = value;

    setPolicyValues({
      ...policyValues,
      subpremiums: updatedSubpremiums,
    });
  };
  const handleSalesPersonChange = (selectedEmployeeID) => {
    setEmployeeID(selectedEmployeeID);
    setPolicyValues({
      ...policyValues,
      salesperson: selectedEmployeeID,
    });

    // Find the selected employee object from employeeOptions
    const selectedEmployeeObject = employeeOptions.find(option => option.value === selectedEmployeeID);
    setSelectedEmployee(selectedEmployeeObject);
  };

  useEffect(() => {
    if(isEmpMount.current){
    axios.get(Rest.viewallemployees,{
      
      headers: {
          "X-fiftyaccess-Token": token
      }
    })
      .then((response) => {
        const options = response?.data?.data?.map((employee) => ({
          value: employee.EmployeeID,
          label: `${employee.FirstName} ${employee.LastName}`,
        
        }));
        setEmployeeOptions(options);
       
      })
      .catch((error) => console.error('Error fetching employee data:', error));
      isEmpMount.current=false;
    }
  }, []);


  useEffect(() => {
    if(isPFMount.current){
    fetch(Rest.premiumfrequency, {
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response has a 'premiumfrequency' property
        const options = data?.premiumfrequency || [];
        setPremiumFrequencyOptions(options);
      })
      .catch((error) => {
        console.error('Error fetching premium frequency options:', error);
      });
      isPFMount.current=false;
    }
  }, []);

  useEffect(() => {
    if(isCodeMount.current){
    fetch(Rest.bookingcodes, {
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBookingCodes(data?.bookingcodes);
      })
      .catch((error) => {
        console.error('Error fetching booking codes:', error);
      });
      isCodeMount.current=false;
    }
  }, []);

  useEffect(() => {
    /* if(isDeptMount.code){ */
    fetch(Rest.departments, {
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setDepartments(data?.departments);
      })
      .catch((error) => {
        console.error('Error fetching departments:', error);
      });
      /* isDeptMount.current=false;
    } */
  }, []);

  useEffect(() => {
    if(isProviderMount.current){
    fetch(Rest.ViewAllProvider, {
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then(response => response.json())
      .then(data => {
        // Update the options state with the fetched data
        setProviderOptions(data.Data); // Assuming `setDropdownOptions` is a state setter function
      })
      .catch(error => {
        // Handle error
      });
      isProviderMount.current=false;
    }
  }, []);

  // Fetch product data on component mount
  useEffect(() => {
    if(isProductMount.current){
    async function fetchProducts() {
      try {
        const response = await axios.get(Rest.viewallproduct, {
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        });
        const productData = response?.data?.data
        setProducts(productData);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    }
    fetchProducts();
    isProductMount.current=false;
  }
  }, []);

  useEffect(() => {
    // Fetch product details when selected product changes
    async function fetchSelectedProductData(productid) {
      if (productid) {
        try {
          const response = await axios
            .get(
              Rest.viewproduct, {
              params: {
                productid: productid
              },
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            });
          setSelectedProduct(response?.data);
        } catch (error) {
          console.error('Error fetching selected product:', error);
        }
      }
    }

    // filter product by name and get productid
    let filteredproductID = products.find(product => product.product === policyValues.product)

    if (filteredproductID) {
      fetchSelectedProductData(filteredproductID.productid);
    }
  }, [policyValues.product, products]);

  const isValidNumber = (value) => {
    const validNumberRegex = /^-?\d*\.?\d*(%)?$/;;
    return validNumberRegex.test(value);
  };
  console.log("convert interactionValues",interactionValues)
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (interactionValues.opportunitystatus === 'ClosedandWon' || (opportunityid !== null && prospectid !== null)) {
        setIsLoading(true);
        const product = selectedProduct?.data?.product;
        const saleDate = selectedSaledate ? selectedSaledate : policyValues.saledate?.format("DD-MM-YYYY")
        const startDate = selectedPolicystartdate ? selectedPolicystartdate : policyValues.policystartdate?.format("DD-MM-YYYY")
        const updatedPolicyValues = { ...policyValues, product: product, prospectid: prospectid, opportunityid: opportunityid, customerid: null, saledate: saleDate, policystartdate: startDate }

        const formDataObject = new FormData();
        policycopy.forEach((policy, index) => {
          formDataObject.append('policycopy', policy.file);
        });
        attachments.forEach((attachment, index) => {
          formDataObject.append(attachment.file ? removeFileExtension(attachment.file.name) : '', attachment.file);
        });
        formDataObject.append('customerid', customerid)
        formDataObject.append('prospectid', prospectid)
        formDataObject.append('beneficiaryid', beneficiaryid)
        formDataObject.append('opportunityid', opportunityid)
        formDataObject.append('provider', policyValues.provider)
        formDataObject.append('product', product)
        formDataObject.append('subproduct', policyValues.subproduct)
        formDataObject.append('policytype', policyValues.policytype)
        formDataObject.append('policystartdate', startDate)
        formDataObject.append('policyenddate', policyValues.policyenddate)
        formDataObject.append('premiumpayfrequency', policyValues.premiumpayfrequency)
        formDataObject.append('bookingcode', policyValues.bookingcode)
        formDataObject.append('department', policyValues.department)
        formDataObject.append('saledate', saleDate)
        formDataObject.append('salesperson', employeeID)
        formDataObject.append('employeefirstname', opportunityValues.employeefirstname)
        formDataObject.append('employeelastname', opportunityValues.employeelastname)
        formDataObject.append('tax', policyValues.tax)
        formDataObject.append('netpremium', policyValues.netpremium)
        formDataObject.append('grosspremium', policyValues.grosspremium)
        formDataObject.append('state', selectedState)
        formDataObject.append('city', selectedCity)
         formDataObject.append('policynumber',policyValues.policynumber)
         formDataObject.append('discount',policyValues.discount)
        formDataObject.append('subpremiums',JSON.stringify(subpremiums))
        formDataObject.append('productdetails', JSON.stringify(productdetails));
        //interactions 
        formDataObject.append('interactiondateandtime', interactionValues.interactiondateandtime);
        formDataObject.append('nextoppointmentdateandtime', interactionValues.nextoppointmentdateandtime);
        formDataObject.append('notes', interactionValues.notes);
        formDataObject.append('opportunitystatus', interactionValues.opportunitystatus);
        formDataObject.append('status', interactionValues.status);
        const response = await fetch(Rest.convertprospectcreatecustomer, {
          method: 'POST',
          headers: {
            /*  'Content-Type': 'application/json', */
            "X-fiftyaccess-Token": token
          },
          body: formDataObject,
        });
        const data = await response.json();
        if (data?.code === "S001") {
          setIsSuccessSnackbarOpen(true); // Open success snackbar
          // Redirect after a short delay (optional)
          setTimeout(() => {
            navigate('/allcustomer');
          }, 2000); // Redirect after 2 seconds
        } else{
          alert(data?.msg || data?.data?.data)
        }
      }
    } catch (err) {
      alert("Error: " + err.message);
    }finally {
      setIsLoading(true); // Set loading to false after the API request is completed (success or failure)
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }
  };
  useEffect(() => {
    if(isLocMount.current){
    axios.get(Rest.locations, {
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then((response) => {
        setLocations(response?.data?.locations);
      })
      .catch((error) => {
        console.error('Error fetching data: ', error);
      });
      isLocMount.current=false;
    }
  }, []);

  useEffect(() => {

    if (opportunitystatus === 'ClosedandWon' || opportunityid !== null) {
      if (opportunityValues) {
        // Set dropdown values based on opportunity data
        setPolicyValues((prevValues) => ({
          ...prevValues,
          provider: opportunityValues?.provider || '',
          product: opportunityValues?.product || '',
          subproduct: opportunityValues?.subproduct || '',
          policytype: opportunityValues?.interestedpolicytype || '',
          premiumpayfrequency: opportunityValues?.premiumpayfrequency || '',
          businesstype: opportunityValues?.businesstype || '',
          department: opportunityValues?.department || '',
          odpremium: opportunityValues?.odpremium || 0,
          addonpremium: opportunityValues?.addonpremium || 0,
          thirdpartypremium: opportunityValues?.thirdpartypremium || 0,
          netpremium: opportunityValues?.netpremium || 0,
          tax: opportunityValues?.tax || 0,
          grosspremium: opportunityValues?.grosspremium || 0,
          beneficiaryid: opportunityValues?.beneficiarydetails?.beneficiaryid
        }));
      }
    }
  }, [opportunitystatus, customer]);

  useEffect(() => {
    if (opportunitystatus === 'ClosedandWon' || opportunityid !== null) {

      const opportunityProductDetails = opportunityValues?.productdetails;
      const subpremiumsdetails = opportunityValues?.subpremiums;

      if (opportunityProductDetails) {
        const updatedProductDetails = {
          Make: opportunityProductDetails.Make || '',
          Model: opportunityProductDetails.Model || '',
          "Year of Manufacture": opportunityProductDetails["Year of Manufacture"] || '',
          CC: opportunityProductDetails.CC || '',
          "Fuel Type": opportunityProductDetails["Fuel Type"] || '',
          "Age of the Vechile": opportunityProductDetails["Age of the Vechile"] || '',
          "Registration Number": opportunityProductDetails["Registration Number"] || '',
          "Claim Status": opportunityProductDetails["Claim Status"] || '',
          "Eligible NCB %": opportunityProductDetails["Eligible NCB %"] || '',
          "Addons Opted (Y/N)": opportunityProductDetails["Addons Opted (Y/N)"] || '',
          "Sum Insured": opportunityProductDetails["Sum Insured"] || '',
          Occupancy: opportunityProductDetails.Occupancy || '',
          Location: opportunityProductDetails.Location || '',
          "Per Sending Limit": opportunityProductDetails["Per Sending Limit"] || '',
          "Nature of Goods": opportunityProductDetails["Nature of Goods"] || '',
          "From and To Location": opportunityProductDetails["From and To Location"] || ''

        };
        setPolicyValues((prevValues) => ({
          ...prevValues,
          productdetails: updatedProductDetails
        }));
      }
      if (subpremiumsdetails) {
        const updatedsubpremiumsDetails = {
          "OD Premium":subpremiumsdetails["OD Premium"] || '',
          "Add-on Premium":subpremiumsdetails["Add-on Premium"] || '',
          "Third-Party":subpremiumsdetails["Third-Party"] || '',
          "Base Premium":subpremiumsdetails["Base Premium"] || ''
        }
        setPolicyValues((prevValues) => ({
          ...prevValues,
          subpremiums: updatedsubpremiumsDetails
        }));
      }

    }
  }, [opportunitystatus, opportunityValues]);


  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: '60rem', marginTop: '5rem' }}>
        <Typography style={{ marginLeft: '2rem' }} variant="h6" align="center" gutterBottom>
          Policy
        </Typography>
        <div>
          <Typography variant="body6" align="center" gutterBottom>{console.log("pro0000=>",location.state)}
            {customer?.customerid || opportunityValues?.prospectid || opportunityValues?.customerid}
          </Typography>
          <Typography variant="body6" align="center" gutterBottom>{console.log("loc===>",location?.state)}
            <p>{capitalizeFirstLetter(customer?.firstname || opportunityValues?.firstname)} {capitalizeFirstLetter(customer?.lastname || opportunityValues?.lastname)} </p>
          </Typography>
        </div>
      </div>
      <Container>
        <Form onSubmit={handleSubmit}>
          <Grid container>

            <Grid item xs={4} style={{ marginTop: '0.9rem' }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                      label="Sale Date"
                      defaultValue={policyValues.saledate}
                      onChange={e => { handleSaleDateChange(e.toDate()) }}
                      disablePast
                      format="DD-MM-YYYY"
                      sx={{
                        backgroundColor: 'rgb(234, 237, 240,1)',
                        borderRadius: '5px',
                        '& .MuiInputLabel-root': {
                          fontSize: '12px',
                        },
                        '& .MuiSvgIcon-root': {
                          width: '24px',
                          height: '24px',
                          background: `url(${calendar})`,
                          backgroundSize: 'contain',
                          display: 'block',
                        },
                        '& input': {
                          width: '30',
                          height: '30',
                          padding: '12px',
                          fontSize: '12px',
                          alignContent: 'center',
                        },
                        '& .MuiInput-underline': {
                          '&:before': {
                            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color
                          },
                          '&:hover:not(.Mui-disabled):before': {
                            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color
                          },
                        },
                      }}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                     label={
                      <>
                      Policy Start Date <span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                      </>
                    }
                     
                      // label="Policy Start Date"
                      defaultValue={policyValues.policystartdate}
                      onChange={e => { handleStartDateChange(e.toDate()) }}
                      disablePast
                      format="DD-MM-YYYY"
                      sx={{
                        backgroundColor: 'rgb(234, 237, 240,1)',
                        borderRadius: '5px',
                        '& .MuiInputLabel-root': {
                          fontSize: '12px',
                        },
                        '& .MuiSvgIcon-root': {
                          width: '24px',
                          height: '24px',
                          background: `url(${calendar})`,
                          backgroundSize: 'contain',
                          display: 'block',
                        },
                        '& input': {
                          width: '30',
                          height: '30',
                          padding: '12px',
                          fontSize: '12px',
                          alignContent: 'center',
                        },
                        '& .MuiInput-underline': {
                          '&:before': {
                            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color
                          },
                          '&:hover:not(.Mui-disabled):before': {
                            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color
                          },
                        },
                      }}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
              {/* <Controls.DatePicker 
              label='Policy End Date'
              value={policyValues.policyenddate}
              onChange={e=>{handleEndDateChange(e.toDate())}}         
              /> */}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={[
                    'DatePicker',
                    'DateTimePicker',
                    'TimePicker',
                    'DateRangePicker',
                  ]}
                >
                  <DemoItem>
                    <DatePicker
                     label={
                      <>
                      Policy End Date <span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                      </>
                    }
                     
                      // label="Policy End Date"
                      defaultValue={policyValues.policyenddate || null}
                      onChange={(e) => {
                      handleEndDateChange(e.toDate());
                      handleOpenDialog(e.toDate())}}
                      disablePast
                      format="DD-MM-YYYY"
                      sx={{
                        backgroundColor: 'rgb(234, 237, 240,1)',
                        borderRadius: '5px',
                        '& .MuiInputLabel-root': {
                          fontSize: '12px',
                        },
                        '& .MuiSvgIcon-root': {
                          width: '24px',
                          height: '24px',
                          background: `url(${calendar})`,
                          backgroundSize: 'contain',
                          display: 'block',
                        },
                        '& input': {
                          width: '30',
                          height: '30',
                          padding: '12px',
                          fontSize: '12px',
                          alignContent: 'center',
                        },
                        '& .MuiInput-underline': {
                          '&:before': {
                            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color
                          },
                          '&:hover:not(.Mui-disabled):before': {
                            borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color
                          },
                        },
                      }}
                      slotProps={{ textField: { size: 'small' } }}
                    />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
              <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Policy Duration</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This policy will be active for {daysCount} days. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleCloseDialog}>Cancel</button>
          <button onClick={handleCloseDialog}>OK</button>
        </DialogActions>
      </Dialog>
              {/* <Controls.Input 
              label='Sales Person Name'
              value={employeeName}
              onChange={(e) => handlePolicyChange('salespersonname', e.target.value)}
            /> */}
              <Controls.Dropdown
               label={
                <>
                Sales Person <span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                </>
              }
               
                // label="Sales Person"
                options={employeeOptions}
                value={employeeID}
                onChange={(e) => handleSalesPersonChange(e.target.value)}
              />
              <Controls.Input
          variant="outlined"
          label={
            <>
            Policy Number <span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
            </>
          }
            // label="Policy Number"
            name="policynumber"
         value={policyNumber}
         onChange={handlePolicyNumber}

/>
            </Grid>
            <Grid item xs={4} style={{ marginTop: '1.4rem' }}>
            <Controls.Dropdown
              
              label={
               <>
               Booking Code<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
               </>
             }
                // label="Booking Code"
                options={bookingCodes?.map((code) => ({
                  value: code,
                  label: code
                }))}
                value={policyValues.bookingcode}
                /* onChange={handleBookingCodeChange} */
                onChange={(e) => handleBookingCodeChange(e.target.value)}
              />
              <Controls.Dropdown
              label={
                <>
                Provider <span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                </>
              }
                // label='Provider Name'
                options={providerOptions.map(option => ({
                  value: option.provider,
                  label: option.provider
                }))}
                value={policyValues.provider}
                onChange={(e) => handleProviderChange(e.target.value)}
              />
              <Controls.Dropdown
                options={products?.map((product) => ({
                  value: product.product,
                  label: product.product,
                }))}
                label={
                  <>
                  Product<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                  </>
                }
                
                // label="Product"
                value={policyValues.product}
                onChange={(e) => handlePolicyChange('product', e.target.value)}
              />
              {subProductOptions?.length > 1 && (
                <Controls.Dropdown
                  options={subProductOptions?.map((subProduct) => ({
                    value: subProduct.subproduct,
                    label: subProduct.subproduct
                  })) || []}
                  label="Sub Product"
                  value={policyValues.subproduct}
                  onChange={(e) =>
                    handlePolicyChange('subproduct', e.target.value)
                  }
                />
              )}
              <Controls.Dropdown
                options={policyOption}
                // label="Policy Type"
                label={
                  <>
              Policy Type<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                  </>
                }
                value={policyValues.policytype}
                onChange={(e) => handlePolicyChange('policytype', e.target.value)}
              />

            </Grid>
            <Grid item xs={4} style={{ marginTop: '1.4rem' }}>
              <Controls.Dropdown
               label={
                <>
            Premium Frequency<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                </>
              }
                // label="Premium Frequency"
                options={premiumFrequencyOptions.map((option) => ({
                  value: option,
                  label: option,
                }))}
                value={policyValues.premiumpayfrequency}
                onChange={(e) => handlePolicyChange('premiumpayfrequency', e.target.value)}
              />
              <Controls.Dropdown
               label={
                <>
          Department<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                </>
              }
                // label="Department"
                options={departments?.map((dept) => ({
                  value: dept,
                  label: dept
                }))}
                value={policyValues.department}
                /* onChange={handleDepartmentChange} */
                onChange={(e) => handleDepartmentChange(e.target.value)}
              />
             <Controls.Dropdown
                options={states.map((option) => ({
                  label: option,
                  value: option
                }))}
                // label="State"
                label={
                  <>
            State<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                  </>
                }
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
              />
              <Controls.Dropdown
                options={cities.map((option) => ({
                  label: option,
                  value: option
                }))}
                label={
                  <>
            City<span style={{ color: 'red' ,fontSize:'1.1rem' }}>*</span>
                  </>
                }
                // label="City"
                value={selectedCity}
                onChange={(e) => setSelectedCity(e.target.value)}
              />
           <Controls.Input
          variant="outlined"    
           label="Discount"
            name="discount"
         value={discount}
         onChange={handleDiscount}
/>
{errorMessage && <div style={{ color: 'red',fontSize:'10px' }}>{errorMessage}</div>}
            </Grid>
          </Grid>
          <div>Premium Details</div>

            <Grid container>
            {subpremiums && Object.entries(subpremiums).map(([fieldName, fieldValue]) => (
           <Grid item xs={4} key={fieldName}>
            <Controls.Input
            type="number"
            label={fieldName}
            value={fieldValue}
          //   onChange={(e) => {handlePremiumChange(fieldName, e.target.value);calculateNetpremium(subpremiums)}}
          // />
          onChange={(e) => {  const value = e.target.value;
            if (isValidNumber(value)) {
              handlePremiumChange(fieldName, value);
              setPremiumErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: '',
              }));
            } else {
              setPremiumErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'Enter only numbers',
              }));
            }
            calculateNetpremium(subpremiums)
          }}
        />
        {premiumErrors[fieldName] && (
          <div style={{ color: 'red', fontSize: '10px' }}>{premiumErrors[fieldName]}</div>
          
        )}

          </Grid>
            ))}
            <Grid item xs={4}>
              <Controls.Input
                label='Net Premium'
                value={policyValues.netpremium}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={4}>
              <Controls.Input
                label='Tax Amount'
                value={policyValues.tax}
                onChange={(e) => handlePolicyChange('tax', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <Controls.Input
                label='Gross premium'
                value={policyValues.grosspremium}
                onChange={(e) => handlePolicyChange('grosspremium', e.target.value)}
                readOnly={true}
              />
            </Grid>
          </Grid>
          {subProductOptions && (

            <>
              <div>Product Details</div>
              <Grid container >
                {productdetails && Object.entries(productdetails).map(([fieldName, fieldValue]) => (
                  <Grid item xs={4}>
                    {fieldName === "Addons Opted (Y/N)" ? (
                      <Controls.Dropdown
                        options={addons}
                        label={fieldName}
                        value={fieldValue}
                        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
                      />
                    ):fieldName === "Claim Status" ? (
                      <Controls.Dropdown
                        options={addons}
                        label={fieldName}
                        value={fieldValue}
                        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
                      />
                      ):fieldName === "Eligible NCB %" ? (
                        <Controls.Dropdown
                          options={ncb}
                          label={fieldName}
                          value={fieldValue || 0}
                          onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
                        />

                    ): (
                      <Controls.Input
                        key={fieldName}
                        label={fieldName}
                        variant="outlined"
                        value={fieldValue}
                        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
                      />
                    )}
                  </Grid>
                ))}
                {/* {policyValues.productdetails ? (
  policyValues.productdetails && Object.entries(policyValues.productdetails).map(([fieldName, fieldValue]) => (
    <Grid item xs={4} key={fieldName}>
      {fieldName === "Addons Opted (Y/N)" ? (
      <Controls.Dropdown
        options={addons}
        label={fieldName}
        value={fieldValue}
        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
      />
    ) : (
      <Controls.Input
        type="text"
        label={fieldName}
        value={fieldValue}
        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
      />
      )}
    </Grid>
  ))
) : (
  productdetails[0] && Object.entries(productdetails[0]).map(([fieldName, fieldValue]) => (
    <Grid item xs={4} key={fieldName}>
       {fieldName === "Addons Opted (Y/N)" ? (
      <Controls.Dropdown
        options={addons}
        label={fieldName}
        value={fieldValue}
        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
      />
    ) : (
      <Controls.Input
        type="text"
        label={fieldName}
        value={fieldValue}
        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
      />
      )}
    </Grid>
  ))
)} */}
              </Grid>
            </>
          )}
          <div>
            Policy Copy
          <img src={asterisk} alt="Star Logo" className={classes.customIcon} />
          </div>
          {policycopy.map((attachment, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <label htmlFor={`uploadpolicycopy-${index}`}>
                        <input
                          type="file"
                          id={`uploadpolicycopy-${index}`}
                          style={{ display: "none" }}
                          onChange={(event) => handlePolicyFileChange(event, index)}
                        />
                  <Fab
                    style={{ backgroundColor: '#914572', color: '#ffff', marginTop: '4px', marginLeft: '10px', fontSize: '8px', padding: '10px',fontsize:'10px',textAlign:'center' }}
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    Upload Policy
                  </Fab>
                </label>
                <div>{ attachment?.file?.name}</div>
                    </div>

                  ))}

          <div>
            <IconButton onClick={addAttachment}>
              <h5>Attachements</h5> <AddIcon />
            </IconButton>
            {attachments.map((attachment, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <label htmlFor={`uploadfile-${index}`}>
                        <input
                          type="file"
                          id={`uploadfile-${index}`}
                          style={{ display: "none" }}
                          onChange={(event) => handleFileChange(event, index)}
                        />
                  <Fab
                    style={{ backgroundColor: '#914572', color: '#ffff', marginTop: '4px', marginLeft: '10px', fontSize: '8px', padding: '10px',fontsize:'10px',textAlign:'center' }}
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    Upload File
                  </Fab>
                </label>
                <Grid item xs={3}>
                <Controls.Input
                  label="File Name"
                  value={attachment.file ? removeFileExtension(attachment.file.name) : ''}
                  onChange={(event) => handleFileNameChange(event, index)}
                  />
                      </Grid>
                      <IconButton onClick={() => handleDeleteAttachment(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
          </div>
          <Dialog
                  open={isDeleteDialogOpen}
                  onClose={handleCancelDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">Delete Attachment?</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Are you sure you want to delete this attachment?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
          <div style={{ marginTop: '2rem', marginLeft: "64rem" }}>
            <Controls.Button text="Save" type="submit" />
            {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
            <Controls.Button text="Cancel" onClick={handleCancel} />
          </div>
        </Form>
        <Snackbar
          sx={{ marginLeft: '30rem' }}
          open={isSuccessSnackbarOpen}
          autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
          onClose={handleSnackbarClose}
          message="successfully converted customer"
        />
      </Container>
    </div>
  );
};

export default AvPolicy;







