import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import Controls from '../components/controls/Controls';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import { Grid, makeStyles } from "@material-ui/core";
import { styled, useTheme } from "@mui/material/styles";
import * as Rest from  './../services/restapi';
import { Box,Typography } from '@mui/material';
import {  FormControlLabel } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { format } from 'date-fns';
import moment from 'moment';
import {GridToolbarContainer,GridToolbarExport} from '@mui/x-data-grid-premium';
import MuiAppBar from '@mui/material/AppBar';
import FilterListIcon from '@mui/icons-material/FilterList';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import LoadingSpinner from '../components/controls/spinner';

const formatCurrency = (value) => {
  return `₹${value?.toLocaleString('en-IN')}`;
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start"
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'rgb(234, 237, 240,1)',
    borderRadius: '5px',
  },
  menuList: {
    backgroundColor: 'rgb(234, 237, 240,1)',
  },
  menuItem: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align all options to the left
    marginLeft: '10px',
  },
  sidebar: {
    padding: '10px',
    marginLeft: '10px',
    width: '200px',
  },
}));

const headings = [
  'employeename',
  "opportunityid",
  "name",
  "emailid",
  "provider",
  "product",
  "subproduct",
  "interestedpolicytype",
  "opportunitystatus"
];

const filters = [
  {value:"Prospects Report",label:'Prospects Report'},
  {value:"Leads Report",label:'Leads Report'},
  {value:"Sales Report",label:'Sales Report'},
  {value:"Invoice List",label:'Invoice List'},
  {value:"Commission Report",label:'Commission Report'},
  {value:"Empolyee Wise Report",label:'Empolyee Wise Report'},
  {value:"Cashback Report",label:'Cashback Report'},
  {value:"MIS Report",label:'MIS Report'}
  
]
const Reports = () => {
  const [filedNames, setFiledNames] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeID, setEmployeeID] = useState('');
  const [employeeRoles, setEmployeeRoles] = useState(null);
  const classes = useStyles();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const drawerWidth = 190;
  
  const [prospectReportData, setProspectReportData] = useState([]);
  const [opportunityReportData, setOpportunityReportData] = useState([]);
  const [salesReportData, setSalesReportData] = useState([]);
  const [invoiceReportData, setInvoiceReportData] = useState([]);
  const [empolyeeReportData, setEmpolyeeReportData] = useState([]);
  const [unassignedData, setUnassignedData] = useState('');
  const [MISReportData, setMISReportData] = useState([]);
  const[DiscountReport,setDiscountReport]=useState([]);
  const [commissionReportData, setCommissionReportData] = useState([]);
  const [startDate, setStartDate] = useState([]);
  const [endDate, setEndDate] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isProspectStatusMount = useRef(true);
  const isOpportunityStatusMount = useRef(true);
  const isEmpMount = useRef(true);
  const isProviderMount = useRef(true);
  const isProductMount = useRef(true);
  const employeeIDs = localStorage.getItem("EmployeeID");
const predefinedRanges = [
  {
    label: 'Today',
    value: [new Date(), new Date()],
    placement: 'left'
  },
  {
    label: 'Yesterday',
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: 'left'
  },
  {
    label: 'This week',
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: 'left'
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left'
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left'
  },
  {
    label: 'This month',
    value: [startOfMonth(new Date()), new Date()],
    placement: 'left'
  },
  {
    label: 'Last month',
    value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
    placement: 'left'
  },
  {
    label: 'This year',
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last year',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
    placement: 'left'
  },
  {
    label: 'All time',
    value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
    placement: 'left'
  },
  {
    label: 'Last week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
      ];
    },
    appearance: 'default'
  },
  {
    label: 'Next week',
    closeOverlay: false,
    value: value => {
      const [start = new Date()] = value || [];
      return [
        addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
        addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
      ];
    },
    appearance: 'default'
  }
];

  
  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'side' })(
    ({ theme, side }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      ...(side && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
      position: 'relative',
    }),
  );
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'side',
  })(({ theme, side}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(side && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));


  const theme = useTheme();
   const [side, setOpen] = useState(false); // Initialize the sidebar state

 
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {

    setOpen(false);
    };
 
    function CustomToolbar() {
      const isInsuranceManager = employeeRoles?.includes('insurancemanager');
      console.log("isInsuranceManager",isInsuranceManager)
      return (
        <GridToolbarContainer>
          {isInsuranceManager && <GridToolbarExport />}
        </GridToolbarContainer>
      );
    }

  const [prospectChecked, setProspectChecked] = React.useState({
    Status: [],
    Assigned: false,
    Unassigned: false,
  });

  const [prospectstatusLabels, setProspectStatusLabels] = useState([]);
  const [opportunityChecked, setOpportunityChecked] = React.useState({
    opportunitystatus: [],
  });

  const [opportunitystatusLabels, setOpportunityStatusLabels] = useState([]);

  const [providerChecked, setProviderChecked] = React.useState({
    provider: [],
  });

  const [providerLabels, setProviderLabels] = useState([]);

  const [productChecked, setProductChecked] = React.useState({
    product: [],
  });
  const [productLabels, setProductLabels] = useState([]);

  const [salespersonChecked, setSalespersonChecked] = React.useState({
    salesperson: [],
  });
  const [salespersonLabels, setSalespersonLabels] = React.useState([]);

  const [managerChecked, setManagerChecked] = React.useState({
    manager: [],
  });

  const [managerLabels, setmanagerLabels] = React.useState([]);

  const [employeeOptions, setEmployeeOptions] = React.useState([]);
  
  // const [managerOptions, setManagerOptions] = React.useState([]);
  const [invoiceChecked, setInvoiceChecked] = React.useState({
    Status: [],
    dueDate: new Date().getTime() / 1000,
  });

  const invoiceLabels = ['Paid', 'Invoiced'];

const onDateRangePicker = (date) => {
  if (Array.isArray(date) && date.length === 2) {
      const stateDate = moment(date[0]).format("DD-MM-YYYY");
      const endDate = moment(date[1]).format("DD-MM-YYYY");
      setStartDate(stateDate);
      setEndDate(endDate);
  } else {
   
  }
};

useEffect(() => {
  const fetchEmployeeData = async () => {
    try {
      const response = await axios.get(`${Rest.getEmployee}/${employeeIDs}`, {
       headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      });
      const roles = response?.data?.data?.Roles?.map(item => item.Role);
      setEmployeeRoles(roles);
    } catch (error) {
      console.error('Error fetching employee data:', error);
      // Handle error if the request fails
    }
  };

  if (employeeIDs) {
    fetchEmployeeData();
  }
}, [employeeIDs]);

  useEffect(() => {
    if(isOpportunityStatusMount.current){
  async function fetchOpportunityStatusOptions() {
    try {
      const response = await axios.get(Rest.opportunitystatusapi,{
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      });
        setOpportunityStatusLabels(response?.data?.opportunitystatus);
    } catch (error) {
      console.error('Error fetching Lead status options:', error);
    }
  }
  fetchOpportunityStatusOptions();
  isOpportunityStatusMount.current=false;
}
}, []);

useEffect(() => {
  if(isProspectStatusMount.current){
  async function fetchProspectStatusOptions() {
    try {
      const response = await axios.get(Rest.prospectstatusapi,{
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      });
      setProspectStatusLabels(response?.data?.prospectstatus);
    } catch (error) {
      console.error('Error fetching status options:', error);
    }
  }
  fetchProspectStatusOptions();
  isProspectStatusMount.current=false;
}
}, []);

useEffect(() => {
  if(isProviderMount.current){
  fetch(Rest.ViewAllProvider,
    {
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    }
    )
    .then(response => response.json())
    .then(data => {
      const provider = data?.Data.map((name)=>(name.provider))
      // Update the options state with the fetched data
      setProviderLabels(provider); // Assuming `setDropdownOptions` is a state setter function
    })
    .catch(error => {
      // Handle error
    });
    isProviderMount.current=false;
  }
}, []);

// Fetch product data on component mount
useEffect(() => {
  if(isProductMount.current){
async function fetchProducts() {
  try {
    const response = await axios.get(Rest.viewallproduct,
      {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      }
      );
    const productData = response?.data?.data
    const product = productData.map((name)=>(name.product))
    setProductLabels(product);
  } catch (error) {
    console.error('Error fetching products:', error);
  }
}
fetchProducts();
isProductMount.current=false;
  }
}, []);

  const handleParentChange = (event, parent) => {
    let selectedValues = [];

    if (parent === 'Status') {
      selectedValues = event.target.checked ? [...prospectstatusLabels] : [];
    } else {
      selectedValues = event.target.checked ? [parent] : [];
    }
console.log('selectedvalues',selectedValues)
    setProspectChecked({
      ...prospectChecked,
      [parent]: selectedValues
    });
  };

  const handleChildChange = (event, parent, childIndex) => {
    const newSelectedValues = [...prospectChecked[parent]];

    if (event.target.checked) {
      newSelectedValues.push(prospectstatusLabels[childIndex]);
    } else {
      const indexToRemove = newSelectedValues.indexOf(prospectstatusLabels[childIndex]);
      if (indexToRemove !== -1) {
        newSelectedValues.splice(indexToRemove, 1);
      }
    }
    console.log('newSelectedValues',newSelectedValues)

    setProspectChecked({
      ...prospectChecked,
      [parent]: newSelectedValues
    });
  };

  const handleOpportunityChange = (event, parent) => {
    let selectedValues = [];

    if (parent === 'opportunitystatus') {
      selectedValues = event.target.checked ? [...opportunitystatusLabels] : [];
    } else {
      selectedValues = event.target.checked ? [parent] : [];
    }

    setOpportunityChecked({
      ...opportunityChecked,
      [parent]: selectedValues
    });
  };

  const handleOpportunityChildChange = (event, parent, childIndex) => {
    const newSelectedValues = [...opportunityChecked[parent]];

    if (event.target.checked) {
      newSelectedValues.push(opportunitystatusLabels[childIndex]);
    } else {
      const indexToRemove = newSelectedValues.indexOf(opportunitystatusLabels[childIndex]);
      if (indexToRemove !== -1) {
        newSelectedValues.splice(indexToRemove, 1);
      }
    }
    setOpportunityChecked({
      ...opportunityChecked,
      [parent]: newSelectedValues
    });
  };

  const handleSalespersonChange = (event, parent) => {
    let selectedValues = [];

    if (parent === 'salesperson') {
      selectedValues = event.target.checked ? [...salespersonLabels] : [];
    } else {
      selectedValues = event.target.checked ? [parent] : [];
    }

    setSalespersonChecked({
      ...salespersonChecked,
      [parent]: selectedValues
    });
  };

  const handleSalespersonChildChange = (event, parent, childIndex) => {
    const newSelectedValues = [...salespersonChecked[parent]];

    if (event.target.checked) {
      newSelectedValues.push(salespersonLabels[childIndex]);
    } else {
      const indexToRemove = newSelectedValues.indexOf(salespersonLabels[childIndex]);
      if (indexToRemove !== -1) {
        newSelectedValues.splice(indexToRemove, 1);
      }
    }
    setSalespersonChecked({
      ...salespersonChecked,
      [parent]: newSelectedValues
    });
  };


  const handleManagerChange = (event, parent) => {
    let selectedValues = [];

    if (parent === 'Manager') {
      selectedValues = event.target.checked ? [...managerLabels] : [];
    } else {
      selectedValues = event.target.checked ? [parent] : [];
    }

    setManagerChecked({
      ...managerChecked,
      [parent]: selectedValues
    });
  };

  const handleManagerChildChange = (event, parent, childIndex) => {
    const newSelectedValues = [...managerChecked[parent]];

    if (event.target.checked) {
      newSelectedValues.push(managerLabels[childIndex]);
    } else {
      const indexToRemove = newSelectedValues.indexOf(managerLabels[childIndex]);
      if (indexToRemove !== -1) {
        newSelectedValues.splice(indexToRemove, 1);
      }
    }
    setManagerChecked({
      ...managerChecked,
      [parent]: newSelectedValues
    });
  };
 
const renderAssignCheckbox = (label, parent) => {
  return (
    <FormControlLabel
      key={`parent-${parent}`}
      label={label}
      control={
        <Checkbox
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: 16, // Adjust the font-size to decrease the checkbox size
            },
          }}
          checked={prospectChecked[parent]?.length > 0}
          indeterminate={false}
          onChange={(event) => handleParentChange(event, parent)}
        />
      }
    />
  );
};
  const renderParentCheckbox = (label, parent) => {
    return (
      <TreeItem
      nodeId={parent}
      label={
      <FormControlLabel
        key={`parent-${parent}`}
        label={label}
        control={
          <Checkbox
            checked={prospectChecked[parent]?.length > 0}
            indeterminate={false}
            onChange={(event) => handleParentChange(event, parent)}
            />
          }
        />
      }
    >
      {renderChildCheckboxes(parent)}
    </TreeItem>
  );
};

const renderChildCheckboxes = (parent) => {
  return prospectstatusLabels.map((childLabel, index) => (
    <TreeItem
      key={`child-${index}`}
      nodeId={`${parent}-${index}`}
      label={childLabel}
      sx={{marginLeft:'1rem'}}
      icon={
        <Checkbox
          checked={prospectChecked[parent].includes(childLabel)}
          onChange={(event) => handleChildChange(event, parent, index)}
        />
      }
    />
  ));
};
  /* const renderChildCheckboxes = (parent) => {
    if (parent === 'Status') {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }} key={parent}>
          {prospectstatusLabels.map((childLabel, index) => (
            <FormControlLabel
              key={`child-${index}`}
              label={childLabel}
              control={
                <Checkbox
                  checked={prospectChecked[parent].includes(childLabel)}
                  onChange={(event) => handleChildChange(event, parent, index)}
                />
              }
            />
          ))}
        </Box>
      );
    } else {
      return (
        <FormControlLabel
          key={`parent-${parent}`}
          label={parent}
          control={
            <Checkbox
              checked={prospectChecked[parent]}
              onChange={(event) => handleParentChange(event, parent)}
            />
          }
        />
      );
    }
  }; */
  const [expanded, setExpanded] = useState([]);
  const renderOpportunityCheckbox = (label, parent) => {
    return (
      <TreeItem
        nodeId={parent}
        label={
      <FormControlLabel
        key={`parent-${parent}`}
        label={label}
        control={
          <Checkbox
            checked={opportunityChecked[parent].length > 0}
            indeterminate={false}
            onChange={(event) => handleOpportunityChange(event, parent)}
            />
          }
          />
        }
      >
        {renderOpportunityChildCheckboxes(parent)}
      </TreeItem>
    );
  };

  const renderOpportunityChildCheckboxes = (parent) => {
    return opportunitystatusLabels.map((childLabel, index) => (
      <TreeItem
        key={`child-${index}`}
        nodeId={`${parent}-${index}`}
        label={childLabel}
        sx={{marginLeft:'1rem'}}
        icon={
          <Checkbox
            checked={opportunityChecked[parent].includes(childLabel)}
            onChange={(event) => handleOpportunityChildChange(event, parent, index)}
          />
        }
      />
    ));
  };
  const handleProvideChange = (event, parent) => {
    let selectedValues = [];

    if (parent === 'provider') {
      selectedValues = event.target.checked ? [...providerLabels] : [];
    } else {
      selectedValues = event.target.checked ? [parent] : [];
    }

    setProviderChecked({
      ...providerChecked,
      [parent]: selectedValues
    });
  };

  const handleProviderChildChange = (event, parent, childIndex) => {
    const newSelectedValues = [...providerChecked[parent]];

    if (event.target.checked) {
      newSelectedValues.push(providerLabels[childIndex]);
    } else {
      const indexToRemove = newSelectedValues.indexOf(providerLabels[childIndex]);
      if (indexToRemove !== -1) {
        newSelectedValues.splice(indexToRemove, 1);
      }
    }
    setProviderChecked({
      ...providerChecked,
      [parent]: newSelectedValues
    });
  };

  const renderProviderCheckbox = (label, parent) => {
    return (
      <TreeItem
      nodeId={parent}
      label={
      <FormControlLabel
        key={`parent-${parent}`}
        label={label}
        control={
          <Checkbox
            checked={providerChecked[parent].length > 0}
            indeterminate={false}
            onChange={(event) => handleProvideChange(event, parent)}
            />
          }
        />
      }
    >
      {renderProviderChildCheckboxes(parent)}
    </TreeItem>
  );
};

const renderProviderChildCheckboxes = (parent) => {
  return providerLabels.map((childLabel, index) => (
    <TreeItem
      key={`child-${index}`}
      nodeId={`${parent}-${index}`}
      label={childLabel}
      sx={{marginLeft:'1rem'}}
      icon={
        <Checkbox
          checked={providerChecked[parent].includes(childLabel)}
          onChange={(event) => handleProviderChildChange(event, parent, index)}
        />
      }
    />
  ));
};
  const handleProductChange = (event, parent) => {
    let selectedValues = [];

    if (parent === 'product') {
      selectedValues = event.target.checked ? [...productLabels] : [];
    } else {
      selectedValues = event.target.checked ? [parent] : [];
    }

    setProductChecked({
      ...productChecked,
      [parent]: selectedValues
    });
  };

  const handleProductChildChange = (event, parent, childIndex) => {
    const newSelectedValues = [...productChecked[parent]];

    if (event.target.checked) {
      newSelectedValues.push(productLabels[childIndex]);
    } else {
      const indexToRemove = newSelectedValues.indexOf(productLabels[childIndex]);
      if (indexToRemove !== -1) {
        newSelectedValues.splice(indexToRemove, 1);
      }
    }
    setProductChecked({
      ...productChecked,
      [parent]: newSelectedValues
    });
  };

  const renderProductCheckbox = (label, parent) => {
    return (
      <TreeItem
        nodeId={parent}
        label={
      <FormControlLabel
        key={`parent-${parent}`}
        label={label}
        control={
          <Checkbox
            checked={productChecked[parent].length > 0}
            indeterminate={false}
            onChange={(event) => handleProductChange(event, parent)}
            />
          }
        />
      }
    >
      {renderProductChildCheckboxes(parent)}
    </TreeItem>
  );
};

const renderProductChildCheckboxes = (parent) => {
  return productLabels.map((childLabel, index) => (
    <TreeItem
      key={`child-${index}`}
      nodeId={`${parent}-${index}`}
      label={childLabel}
      sx={{marginLeft:'1rem'}}
      icon={
        <Checkbox
          checked={productChecked[parent].includes(childLabel)}
          onChange={(event) => handleProductChildChange(event, parent, index)}
        />
      }
    />
  ));
};
const handleInvoiceChange = (event, parent) => {
  let selectedValues = [];

  if (parent === 'Status') {
    selectedValues = event.target.checked ? [...invoiceLabels] : [];
  } else {
    selectedValues = event.target.checked ? [parent] : [];
  }

  setInvoiceChecked({
    ...invoiceChecked,
    [parent]: selectedValues
  });
};

const handleInvoiceChildChange = (event, parent, childIndex) => {
  const newSelectedValues = [...invoiceChecked[parent]];

  if (event.target.checked) {
    newSelectedValues.push(invoiceLabels[childIndex]);
  } else {
    const indexToRemove = newSelectedValues.indexOf(invoiceLabels[childIndex]);
    if (indexToRemove !== -1) {
      newSelectedValues.splice(indexToRemove, 1);
    }
  }

  setInvoiceChecked({
    ...invoiceChecked,
    [parent]: newSelectedValues
  });
};

const handleDueDateChange = (date, parent) => {
  if (date) {
    setInvoiceChecked({
      ...invoiceChecked,
      [parent]: new Date(date).getTime() / 1000
    });
  } else {
    // If the date input is cleared or empty, set the dueDate to an empty string
    setInvoiceChecked({
      ...invoiceChecked,
      [parent]: null
    });
  }
};

const renderInvoiceCheckbox = (label, parent) => {
  return (
    <FormControlLabel
      key={`parent-${parent}`}
      label={label}
      control={
        <Checkbox
          checked={invoiceChecked[parent].length > 0}
          indeterminate={false}
          onChange={(event) => handleInvoiceChange(event, parent)}
        />
      }
    />
  );
};

const renderInvoiceChildCheckboxes = (parent) => {
  if (parent === 'Status') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }} key={parent}>
        {invoiceLabels.map((childLabel, index) => (
          <FormControlLabel
            key={`child-${index}`}
            label={childLabel}
            control={
              <Checkbox
                checked={invoiceChecked[parent].includes(childLabel)}
                onChange={(event) => handleInvoiceChildChange(event, parent, index)}
              />
            }
          />
        ))}
      </Box>
    );
  }
}
const renderDuedateInput = (label, parent) => {
  if (parent === 'dueDate') {
    return (
      <div>
        <label>{label}</label>
        <input
  type="date"
  className='custom-header'
  value={invoiceChecked.dueDate !== null ? format(new Date(invoiceChecked.dueDate * 1000), 'yyyy-MM-dd') : ''}
  onChange={(e) => handleDueDateChange(e.target.value, parent)}
/>
      </div>
    );
  } 
}
  const renderSalespersonCheckbox = (label, parent) => {
    return (
      <TreeItem
        nodeId={parent}
        label={
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                checked={salespersonChecked[parent].length > 0}
                onChange={(event) => handleSalespersonChange(event, parent)}
              />
            }
          />
        }
      >
        {renderSalespersonChildCheckboxes(parent)}
      </TreeItem>
    );
  };

  const renderSalespersonChildCheckboxes = (parent) => {
    return salespersonLabels.map((childLabel, index) => (
      <TreeItem
        key={`child-${index}`}
        nodeId={`${parent}-${index}`}
        label={childLabel}
        sx={{marginLeft:'1rem'}}
        icon={
          <Checkbox
            checked={salespersonChecked[parent].includes(childLabel)}
            onChange={(event) => handleSalespersonChildChange(event, parent, index)}
          />
        }
      />
    ));
  };
  const renderManagerCheckbox = (label, parent) => {
    return (
      <TreeItem
        nodeId={parent}
        label={
          <FormControlLabel
            label={label}
            control={
              <Checkbox
                checked={managerChecked[parent].length > 0}
                onChange={(event) => handleManagerChange(event, parent)}
              />
            }
          />
        }
      >
        {renderManagerChildCheckboxes(parent)}
      </TreeItem>
    );
  };

  const renderManagerChildCheckboxes = (parent) => {
    return managerLabels.map((childLabel, index) => (
      <TreeItem
        key={`child-${index}`}
        nodeId={`${parent}-${index}`}
        label={childLabel}
        sx={{marginLeft:'1rem'}}
        icon={
          <Checkbox
            checked={managerChecked[parent].includes(childLabel)}
            onChange={(event) => handleManagerChildChange(event, parent, index)}
          />
        }
      />
    ));
  };


  const handleFilterChange = (event) => {
    setSelectedFilters(event.target.value);
  };

   const renderSidebar = (label, parent) => {
    return (
      <div className={classes.sidebar}>
        {selectedFilters.includes('Prospects Report') && (
          <div>
            <h6>Filter By</h6>
            <FormControl component="fieldset">
            <TreeView
                aria-label="Status Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderParentCheckbox('Status', 'Status')}
              </TreeView>
              <TreeView
                aria-label="Assigned Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{marginLeft:'2rem'}}
                >
                {renderAssignCheckbox('Assigned', 'Assigned')}
                {renderAssignCheckbox('Unassigned', 'Unassigned')}
                </TreeView>
            </FormControl>           
          </div>
        )}
         {selectedFilters.includes('Leads Report') && (
          <div>
            <h6>Filter By</h6>
            <FormControl component="fieldset">
              <TreeView
                aria-label="Leadstatus Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderOpportunityCheckbox('Leadstatus', 'opportunitystatus')}
              </TreeView>
              <TreeView
                aria-label="Provider Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProviderCheckbox('Provider', 'provider')}
              </TreeView>
              <TreeView
                aria-label="Product Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProductCheckbox('Product', 'product')}
              </TreeView>
              <TreeView
                aria-label="Salesperson Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderSalespersonCheckbox('Sales Person', 'salesperson')}
              </TreeView>
            
            </FormControl>
          </div>
        )}
        
        {selectedFilters.includes('salespersonreport') && (
          <div>
            <h6>Filter By</h6>
            <FormControl component="fieldset">
            <TreeView
                aria-label="Salesperson Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderSalespersonCheckbox('Sales Person', 'salesperson')}
              </TreeView>
              <TreeView
                aria-label="Leadstatus Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderOpportunityCheckbox('Leadstatus', 'opportunitystatus')}
              </TreeView>
              <TreeView
                aria-label="Provider Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProviderCheckbox('Provider', 'provider')}
              </TreeView>
              <TreeView
                aria-label="Product Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProductCheckbox('Product', 'product')}
              </TreeView>
            </FormControl>
          </div>
        )}
    {selectedFilters.includes('Sales Report') && (
          <div>
            <h6>Filter By</h6>
            <FormControl component="fieldset">
            <TreeView
                aria-label="Provider Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProviderCheckbox('Provider', 'provider')}
              </TreeView>
              <TreeView
                aria-label="Product Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProductCheckbox('Product', 'product')}
              </TreeView>
              <TreeView
                aria-label="Salesperson Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderSalespersonCheckbox('Sales Person', 'salesperson')}
              </TreeView>
              {/* <TreeView
                aria-label="opportunitystatus Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderOpportunityCheckbox('Opportunitystatus', 'opportunitystatus')}
              </TreeView> */}
            </FormControl>           
          </div>
        )}
          {selectedFilters.includes('Invoice List') && (
          <div>
            <h6>Filter By</h6>
            <FormControl component="fieldset">
            <TreeView
                aria-label="Provider Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProviderCheckbox('Provider', 'provider')}
              </TreeView>
              <div style={{marginLeft:'2rem'}}>
              {renderInvoiceCheckbox('Status', 'Status')}
              {renderInvoiceChildCheckboxes('Status')}
              {renderDuedateInput('Payment Due By', 'dueDate')}
              </div>
              
            </FormControl>
          </div>
        )}
        {selectedFilters.includes('Commission Report') && (
          <div>
            <h6>Filter By</h6>
            <FormControl component="fieldset">
              <TreeView
                aria-label="Provider Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProviderCheckbox('Provider', 'provider')}
              </TreeView>
            </FormControl>
          </div>
        )}
        {selectedFilters.includes('Empolyee Wise Report') && (
          <div>
            <h6>Filter By</h6>
            <FormControl component="fieldset">
              {/* <TreeView
                aria-label="opportunitystatus Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderOpportunityCheckbox('Leadstatus', 'opportunitystatus')}
              </TreeView> */}
              {/* <TreeView
                aria-label="Provider Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProviderCheckbox('Provider', 'provider')}
              </TreeView>
              <TreeView
                aria-label="Product Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProductCheckbox('Product', 'product')}
              </TreeView> */}
              <TreeView
                aria-label="Salesperson Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderSalespersonCheckbox('Sales Person', 'salesperson')}
              </TreeView>
            </FormControl>
          </div>
        )}
         {selectedFilters.includes('Cashback Report') && (
          <div>
            <h6>Filter By</h6>
            <FormControl component="fieldset">
    
              <TreeView
                aria-label="Manager Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderManagerCheckbox('Manager', 'manager')}
              </TreeView>
            </FormControl>
          </div>
        )}
        {selectedFilters.includes('MIS Report') && (
          <div>
            <h6>Filter By</h6>
            <FormControl component="fieldset">
              {/* <TreeView
                aria-label="opportunitystatus Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderOpportunityCheckbox('Opportunitystatus', 'opportunitystatus')}
              </TreeView> */}
              <TreeView
                aria-label="Provider Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProviderCheckbox('Provider', 'provider')}
              </TreeView>
              <TreeView
                aria-label="Product Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderProductCheckbox('Product', 'product')}
              </TreeView>
              <TreeView
                aria-label="Salesperson Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderSalespersonCheckbox('Sales Person', 'salesperson')}
              </TreeView>
            </FormControl>
          </div>
        )}
      </div>
    );
  }; 
  const rowHeight = 35;

  const handleGenerateReport = () => {
    try {
      setIsLoading(true);
    if(selectedFilters.includes('Prospects Report')){
      handleProspectReport()
    }
    if(selectedFilters.includes('Leads Report')){
      handleOpportunitystatusReport()
    }
    if(selectedFilters.includes('Sales Report')){
      handleSalesReport()
    }
    if(selectedFilters.includes('Invoice List')){
      handleInvoiceReport()
    }
    if(selectedFilters.includes('Commission Report')){
      handleCommissionReport()
    }
    if(selectedFilters.includes('Empolyee Wise Report')){
      handleEmpolyeeWiseReport()
    }
    if(selectedFilters.includes('Cashback Report')){
      handleDiscountReport()
    }
    if(selectedFilters.includes('MIS Report')){
      handleMISReport()
    }
    handleDrawerClose();
 
  } catch (error) {
    // Handle error if any of the report generation fails
    console.error('Error generating report:', error);
  } finally {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Change the duration (in milliseconds) as per your requirement
  }
};

  
   // const handleClear = () => {
  //   // Add code to clear or reset the selected options and data
  // };
 
  const handleClear = () => {
    // Clear the selected filters for the prospect filter
    setProspectChecked({
      Status: [],
      Assigned: false,
      Unassigned: false,
    });
  
    // Clear the selected filters for the opportunity filter
    setOpportunityChecked({
      opportunitystatus: [],
    });
  
    // Clear the selected filters for the provider filter
    setProviderChecked({
      provider: [],
    });
  
    // Clear the selected filters for the product filter
    setProductChecked({
      product: [],
    });
  
    // Clear the selected filters for the salesperson filter
    setSalespersonChecked({
      salesperson: [],
    });
    setManagerChecked({
      manager: [],
    });
    // Clear the selected filters for the invoice filter
    setInvoiceChecked({
      Status: [],
      dueDate: new Date().getTime() / 1000,
    });
  
    // Optionally, you can reset other state variables or perform additional clearing logic here.
  
    // You may also close the drawer if needed.
    // For example, you can add handleDrawerClose() here to close the drawer.
  
    // You can return null or some feedback if you want, or simply do nothing.
    return null;
  };
 

  // const handleCancel = () => { 
  // };
  const handleCancel = () => {
    // Reset the filter states to their initial values
    setProspectChecked({
      Status: [],
      Assigned: false,
      Unassigned: false,
    });
  
    setOpportunityChecked({
      opportunitystatus: [],
    });
  
    setProviderChecked({
      provider: [],
    });
  
    setProductChecked({
      product: [],
    });
  
    setSalespersonChecked({
      salesperson: [],
    });
    setManagerChecked({
      manager: [],
    });
    setInvoiceChecked({
      Status: [],
      dueDate: new Date().getTime() / 1000,
    });
    setProspectReportData([]); 
    setOpportunityReportData([]);
    setSelectedFilters([]) ;
   
    return null;
  };
  

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFiledNames(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const handleSalesPersonChange = (selectedEmployeeID) => {
    setEmployeeID(selectedEmployeeID);

    // Find the selected employee object from employeeOptions
    const selectedEmployeeObject = employeeOptions.find(option => option.value === selectedEmployeeID);
    setSelectedEmployee(selectedEmployeeObject);
  };

  useEffect(() => {
    if(isEmpMount.current){
    axios.get(Rest.viewallemployees,{
      headers: {
          "X-fiftyaccess-Token": token
      }
    })
   
      .then((response) => {
        const options = response.data.data.map((employee) => ({
          value: employee.EmployeeID,
          label: `${employee.FirstName} ${employee.LastName}`,
        }));
        const EmpolyeeName = response?.data?.data.map((employee) => (`${employee.FirstName} ${employee.LastName}`));
        setEmployeeOptions(options);
        setSalespersonLabels(EmpolyeeName)
      })
      .catch((error) => console.error('Error fetching employee data:', error));
      isEmpMount.current=false;
    }
  }, []);
  useEffect(() => {
    const fetchInsuranceManagers = async () => {
      try {
        const response = await axios.post(Rest.getAllEmployees, {
          Type: 'insurancemanager',
        }, {
          headers: {
            'Content-Type': 'application/json',
            'X-fiftyaccess-Token': token,
          },
        });
  
        if (response?.data?.code === "S001") {
         
          const managerNames = response?.data?.data?.map((manager) => (`${manager?.FirstName} ${manager?.LastName}`));
          setmanagerLabels(managerNames);
        } else {
          alert(response?.data?.msg || response?.data?.data);
        }
      } catch (error) {
        alert('Error: ' + error.message);
        console.error('Error fetching insurance managers:', error);
      }
    };
    fetchInsuranceManagers();
  }, [token, setmanagerLabels]); 
  

  const handleOpportunitystatusReport = () => {
    const selectedEmployeeNames = salespersonChecked.salesperson;
    const selectedEmployeeIDs = selectedEmployeeNames.map((selectedName) => {
      const selectedEmployee = employeeOptions.find((option) => option.label.trim() === selectedName.trim());
      return selectedEmployee ? selectedEmployee.value : null;
    });
  
    const selectedValues = {
      opportunitystatus: opportunityChecked.opportunitystatus,
      EmployeeID: selectedEmployeeIDs,
      provider: providerChecked.provider,
      product: productChecked.product,
      startdate: Array.isArray(startDate) && startDate?.length === 0 ? "" : startDate,
      enddate: Array.isArray(endDate) && endDate?.length === 0 ? "" : endDate,
    };
  
    // Make an API request using Axios with selectedValues in the request body
    axios.post(Rest.viewopportunitybystatus, selectedValues,{
      headers: {
        'Content-Type': 'application/json', // Set the content type for file upload
        "X-fiftyaccess-Token": token
      },
    })
      .then(response => {
        if(response?.data?.code==="S001"){
          const opportunityWithIds = response?.data?.data?.map((opportunity, index) => ({
            ...opportunity,
            interactionsdetails:opportunity?.interactionsdetails?.[0]?.interactionDetails,
            prospectdetails:opportunity?.prospectdetails,
            employeedetails:opportunity?.employeedetails,
            productdetails:opportunity?.productdetails,
            id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
          }));
          setOpportunityReportData(opportunityWithIds)
        
        } else{
          alert(response?.data?.msg || response?.data?.data);
        }
      })
      .catch(error => {
        alert("Error: " + error.message);
        setOpportunityReportData([]);
        console.error('Error fetching Lead data:', error);
      });
  };

  const handleSalesReport = () => {
    const selectedEmployeeNames = salespersonChecked.salesperson;
    const selectedEmployeeIDs = selectedEmployeeNames.map((selectedName) => {
      const selectedEmployee = employeeOptions.find((option) => option.label.trim() === selectedName.trim());
      return selectedEmployee ? selectedEmployee.value : null;
    });
    const selectedValues = {
      opportunitystatus: opportunityChecked.opportunitystatus,
      EmployeeID: selectedEmployeeIDs,
      provider: providerChecked.provider,
      product: productChecked.product,
      startdate: Array.isArray(startDate) && startDate?.length === 0 ? "" : startDate,
      enddate: Array.isArray(endDate) && endDate?.length === 0 ? "" : endDate,
    };
  
    axios.post(Rest.salesreport, selectedValues,{
      headers: {
        'Content-Type': 'application/json', // Set the content type for file upload
        "X-fiftyaccess-Token": token
      },
    })
      .then(response => {
        if(response?.data?.code==="S001"){// Handle the API response here
        const salesWithIds = response?.data?.data?.result.map((sales, index) => ({
          ...sales,
          policydetails:sales?.policydetails,
          employeedetails:sales?.employeedetails,
          id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
        }));
        setSalesReportData(salesWithIds)
      } else{
        alert(response?.data?.msg || response?.data?.data);
      }
      })
      .catch(error => {
        alert(error?.response?.data?.data?.msg)
        setSalesReportData([]);
        console.error('Error fetching sales report data:', error);
      });
  };
 
  const handleDiscountReport = () => {
    const selectedManagerNames = managerChecked.manager;
    // Filtering managerLabels based on selectedManagerNames
    const selectedManagers = managerLabels.filter(manager => selectedManagerNames.includes(manager));
    console.log(selectedManagerNames,'selectedValues')

  
    const selectedValues = {
      manager: selectedManagers,
    };
   
    axios.post(Rest.discountcalculation, selectedValues, {
      headers: {
        'Content-Type': 'application/json',
        'X-fiftyaccess-Token': token,
      },
    })
      .then(response => {
        if (response?.data?.code==="S001") {
          const cashbackData = response?.data?.msg?.map((cashback, index) => ({
            ...cashback._id,
            totalCashback: cashback.totalCashback,
            id: index + 1,
          }));
          setDiscountReport(cashbackData);
        
        } else {
          alert(response?.data?.msg || response?.data?.data);
        }
      })
      .catch(error => {
        alert('Error: ' + error.message);
        setDiscountReport([]);
        console.error('Error fetching cashback report data:', error);
      });
  };



  const handleInvoiceReport = () => {
    const selectedEmployeeNames = salespersonChecked.salesperson;
    const selectedEmployeeIDs = selectedEmployeeNames.map((selectedName) => {
      const selectedEmployee = employeeOptions.find((option) => option.label.trim() === selectedName.trim());
      return selectedEmployee ? selectedEmployee.value : null;
    });
    
    const duedate = invoiceChecked.dueDate !== null ? format(new Date(invoiceChecked.dueDate * 1000), 'dd-MM-yyyy') : ''
    const selectedValues = {
      status:invoiceChecked.Status,
      provider: providerChecked.provider,
      dueDate:duedate,
      startdate: Array.isArray(startDate) && startDate.length === 0 ? "" : startDate,
      enddate: Array.isArray(endDate) && endDate.length === 0 ? "" : endDate,
    };

    axios.post(Rest.invoiceReports,selectedValues,{
      headers: {
        'Content-Type': 'application/json', // Set the content type for file upload
        "X-fiftyaccess-Token": token
      },
    } )
      .then(response => {
        if(response?.data?.code==="S001"){
        setInvoiceReportData(response?.data?.data)
      } else{
        alert(response?.data?.msg || response?.data?.data);
      }

      })
      .catch(error => {
        alert(error?.response?.data?.data?.msg)
        setInvoiceReportData([]);
        console.error('Error fetching sales report data:', error);
      });
  };

  const handleCommissionReport = () => {
    const selectedEmployeeNames = salespersonChecked.salesperson;
    const selectedEmployeeIDs = selectedEmployeeNames.map((selectedName) => {
      const selectedEmployee = employeeOptions.find((option) => option.label.trim() === selectedName.trim());
      return selectedEmployee ? selectedEmployee.value : null;
    });
    const selectedValues = {
      /* provider: providerChecked.provider, */
      startdate: Array.isArray(startDate) && startDate.length === 0 ? "" : startDate,
      enddate: Array.isArray(endDate) && endDate.length === 0 ? "" : endDate,
    };

    axios.post(Rest.commissionreport,selectedValues,{
      headers: {
        'Content-Type': 'application/json', // Set the content type for file upload
        "X-fiftyaccess-Token": token
      },
    } )
      .then(response => {
        if(response?.data?.code==="S001"){
        const commissionData = response?.data?.data?.[0]?.providerProductData.map((commission, index) => ({
          ...commission,
          productData:commission?.providerslabrule,
          commissionRates:commission?.providerslabrule?.commissionRates,
          id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
        }));
        setCommissionReportData(commissionData)
        console.log(commissionData,'commissionData-----')
      } else{
        alert(response?.data?.msg || response?.data?.data);
      }
      })
      .catch(error => {
        alert("Error: " + error.message);
        setCommissionReportData([]);
        console.error('Error fetching sales report data:', error);
      });
  };

  const handleEmpolyeeWiseReport = () => {
    const selectedValues = {
      status: invoiceChecked.Status,
      provider: providerChecked.provider,
      product: productChecked.product,
      startdate: Array.isArray(startDate) && startDate.length === 0 ? "" : startDate,
      enddate: Array.isArray(endDate) && endDate.length === 0 ? "" : endDate,
    };
  
    axios.post(Rest.viewallemployeecontactdata, selectedValues,{
      headers: {
        'Content-Type': 'application/json', // Set the content type for file upload
        "X-fiftyaccess-Token": token
      },
    })
      .then(response => {
        if (response?.data?.code === "S001") {
          const empolyeeData = response?.data?.data;
          setUnassignedData(response?.data?.unassigned)
          setEmpolyeeReportData(empolyeeData);
        } else {
          alert(response?.data?.msg || response?.data?.data);
        }
      })
      .catch(error => {
        alert("Error: " + error.message);
        setEmpolyeeReportData([]);
        console.error('Error fetching sales report data:', error);
      });
  };
  

  const handleMISReport = () => {
    const selectedEmployeeNames = salespersonChecked.salesperson;
    const selectedEmployeeIDs = selectedEmployeeNames.map((selectedName) => {
      const selectedEmployee = employeeOptions.find((option) => option.label.trim() === selectedName.trim());
      return selectedEmployee ? selectedEmployee.value : null;
    });
    const selectedValues = {
      status:invoiceChecked.Status,
      provider: providerChecked.provider,
      product:productChecked.product,
      startdate: Array.isArray(startDate) && startDate.length === 0 ? "" : startDate,
      enddate: Array.isArray(endDate) && endDate.length === 0 ? "" : endDate,
    };

    axios.post(Rest.MISReports,selectedValues,{
      headers: {
        'Content-Type': 'application/json', // Set the content type for file upload
        "X-fiftyaccess-Token": token
      },
    } )
      .then(response => {
        if(response?.data?.code==="S001"){
        const MISData = Object.entries(response?.data?.msg).map(([key, value], index) => ({
          id: (index + 1).toString(), // Start ID from 1
          ...value,
        }));
        setMISReportData(MISData)
      } else{
        alert(response?.data?.msg || response?.data?.data);
      }
      })
      .catch(error => {
        alert("Error: " + error.message);
        setMISReportData([]);
        console.error('Error fetching sales report data:', error);
      });
  };
 
 
  const handleProspectReport = async () => {
    try {
      const selectedValues = {
        Status: [...prospectChecked.Status],
        EmployeeAssigned: [],
        startdate: Array.isArray(startDate) && startDate.length === 0 ? "" : startDate,
        enddate: Array.isArray(endDate) && endDate.length === 0 ? "" : endDate,
      };

      if (prospectChecked.Assigned.length >0 ) {
        selectedValues.EmployeeAssigned.push('Assigned');
      }
      if (prospectChecked.Unassigned.length >0) {
        selectedValues.EmployeeAssigned.push('Unassigned');
      }
      // Send the selectedValues object to the backend API
      console.log('Selected values to send to API:', selectedValues);
      const response = await axios.post(Rest.prospectreports,selectedValues, {
        headers: {
          'Content-Type': 'application/json',
          'X-fiftyaccess-Token': token
        }
      });
      if(response?.data?.code==="S001"){
        const prospectsWithIds = response?.data?.data?.map((prospect, index) => ({
          ...prospect,
          employeedetails:prospect.employeedetails,
          opportunitydetails:prospect.opportunitydetails,
          id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
        }));
        setProspectReportData(prospectsWithIds)
      } else{
        alert(response?.data?.msg || response?.data?.data);
      }
      
    } catch (error) {
      alert(error?.response?.data?.data?.msg)
      setProspectReportData([]);
      console.error('Error fetching data from API:', error);
    }
  };

  const prospectColumns = [
    { field: 'prospectid', headerName: 'Prospect ID', width: 150,headerClassName:'custom-header' },
    { field: 'firstname', headerName: 'First Name', width: 150,headerClassName:'custom-header' },
    { field: 'lastname', headerName: 'Last Name', width: 150,headerClassName:'custom-header' },
    { field: 'emailid', headerName: 'Email ID', width: 200,headerClassName:'custom-header' },
    { field: 'phonenumber', headerName: 'Phone Number', width: 150,headerClassName:'custom-header' },
    {
      field: 'salespersonname',
      headerName: 'Sales Person Name',
      width: 150,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const firstName = params.row.employeefirstname || '';
        const lastName = params.row.employeelastname || '';
        const fullName = `${firstName} ${lastName}`.trim();
        return fullName ? fullName : '-';
      },
    },
    { field: 'prospecttype', headerName: 'Prospect Type', width: 150,headerClassName:'custom-header' },
    { field: 'opportunityCount', headerName: 'Lead Count', width: 150,headerClassName:'custom-header'  },
    { field: 'status', headerName: 'Status', width: 120,headerClassName:'custom-header' },
    { field: 'clienttype', headerName: 'Client Type', width: 150,headerClassName:'custom-header' },
    {
      field: 'referenceperencename',
      headerName: 'Reference Person',
      width: 150,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const firstName = params.row.refrencepersondata?.[0]?.firstname || '';
        const lastName = params.row.refrencepersondata?.[0]?.lastname || '';
        const fullName = `${firstName} ${lastName}`.trim();
        return fullName ? fullName : '-';
      },
    },
    
  ];

  const opportunityColumns = [
    { field: 'opportunityid', headerName: 'Lead ID', width: 150,headerClassName:'custom-header' },
    { field: 'name', headerName: 'Name', width: 150,headerClassName:'custom-header',
    renderCell: (params) => {
      const firstName = params?.row?.prospectdetails?.[0]?.firstname || '';
      const lastName = params?.row?.prospectdetails?.[0]?.lastname || '';
      const fullName = `${firstName} ${lastName}`.trim();
      return fullName ? fullName : '-';
    },
  },,
    { field: 'emailid', headerName: 'Email ID', width: 150,headerClassName:'custom-header',
    renderCell: (params) => {
      const { prospectdetails } = params.row;
      return prospectdetails
        ? prospectdetails.map((detail, index) => (
            <div key={index}>
               <div key={index}>
                  {detail.emailid  }
                </div>
            </div>
          ))
        : null;
    },
  
  },
  { field: 'phonenumber', headerName: 'Phone Number', width: 150,headerClassName:'custom-header' ,
  renderCell: (params) => {
    const { prospectdetails } = params.row;
    return prospectdetails
      ? prospectdetails.map((detail, index) => (
          <div key={index}>
             <div key={index}>
                {detail.phonenumber  }
              </div>
          </div>
        ))
      : null;
  },
  },
 
    { field: 'provider', headerName: 'Provider', width: 200,headerClassName:'custom-header' },
    { field: 'product', headerName: 'Product', width: 120,headerClassName:'custom-header' },
    { field: 'subproduct', headerName: 'Subproduct', width: 150,headerClassName:'custom-header' },
    { field: 'interestedpolicytype', headerName: 'Interested Policy Type', width: 180,headerClassName:'custom-header' },
    { field: 'businesstype', headerName: 'Business Type', width: 150,headerClassName:'custom-header' },
    { field: 'opportunitystatus', headerName: 'Lead Status', width: 150,headerClassName:'custom-header' },
    { field: 'probability', headerName: 'Probability', width: 150,headerClassName:'custom-header' },
    {
      field: 'salespersonname',
      headerName: 'Sales Person Name',
      width: 150,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const firstName = params.row.employeefirstname || '';
        const lastName = params.row.employeelastname || '';
        const fullName = `${firstName} ${lastName}`.trim();
        return fullName ? fullName : '-';
      },
    },
/*     { field: 'odpremium', headerName: 'OD Premium', width: 150,headerClassName:'custom-header' ,
    renderCell: (params) => {
      return `${params?.row?.subpremiums["OD Premium"]}`;
    },
     },
      { field: 'addonpremium', headerName: 'Addon Premium', width: 150,headerClassName:'custom-header',
      renderCell: (params) => {
        return `${params?.row?.subpremiums["Add-on Premium"]}`;
      },
    },
      { field: 'thirdpartypremium', headerName: 'Third Party Premium',width: 150,headerClassName:'custom-header',
      renderCell: (params) => {
        return `${params?.row?.subpremiums["Third-Party"]}`;
      },
    }, */
    /* {
      field: 'basepremium',
      headerName: 'Base Premium',
      width: 150,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const basePremium = params.row.subpremiums ? params.row.subpremiums["Base Premium"] : null;
        return basePremium ? `${basePremium}` : '-'; // Return the value or an empty string if it's missing
      },
    },
    { field: 'netpremium', headerName: 'Net Premium', width: 150,headerClassName:'custom-header' },
    { field: 'tax', headerName: 'Tax', width: 150,headerClassName:'custom-header' },
    { field: 'grosspremium', headerName: 'Gross Premium', width: 150,headerClassName:'custom-header' }, */
    {
      field: 'Make',headerName: 'Make',headerClassName:'custom-header',width: 200,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails?.Make;
    },
    },
    {
      field: 'Model',headerName: 'Model',headerClassName:'custom-header',width: 200,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails?.Model;
    },
    },
    {
      field: 'Year of Manufacture',headerName: 'Year of Manufacture',headerClassName:'custom-header',width: 190,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails["Year of Manufacture"];
    },
    },
    {
      field: 'CC',headerName: 'CC',headerClassName:'custom-header',width: 60,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails?.CC;
    },
    },
    {
      field: 'Fuel Type',headerName: 'Fuel Type',headerClassName:'custom-header',width: 120,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails["Fuel Type"];
    },
    },
    {
      field: 'Age of the Vechile',headerName: 'Age of the Vechile',headerClassName:'custom-header',width: 180,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails["Age of the Vechile"];
    },
    },
    {
      field: 'Registration Number',headerName: 'Registration Number',headerClassName:'custom-header',width: 190,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails["Registration Number"];
    },
    },
    {
      field: 'ClaimStatus',headerName: 'ClaimStatus',headerClassName:'custom-header',width: 120,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails?.ClaimStatus;
    },
    },
    {
      field: 'Eligible NCB %',headerName: 'Eligible NCB %',headerClassName:'custom-header',width: 150,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails["Eligible NCB %"];
    },
    },
    {
      field: 'Addons Opted (Y/N)',headerName: 'Addons Opted (Y/N)',headerClassName:'custom-header',width: 180,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails["Addons Opted (Y/N)"];
    },
    },
    {
      field: 'Sum Insured',headerName: 'Sum Insured',headerClassName:'custom-header',width: 120,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails["Sum Insured"];
    },
    },
    {
      field: 'Occupancy',headerName: 'Occupancy',headerClassName:'custom-header',width: 120,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails?.Occupancy;
    },
    },
    {
      field: 'Location',headerName: 'Location',headerClassName:'custom-header',width: 120,
      renderCell: (params) => {
      const { productdetails } = params.row;
      return productdetails?.Location;
    },
    },
    {
      field: 'interactiondateandtime',headerName: 'Interaction Date and Time',headerClassName:'custom-header',width: 250,
      renderCell: (params) => {
        const { interactionsdetails } = params.row;
        const date = interactionsdetails?.[0]?.interactiondateandtime;
        if (date && moment(date * 1000).isValid()) {
          const formattedDate = moment(date * 1000).format('DD-MM-YYYY');
          return formattedDate;
        } else {
          return null; // Display null if the date is missing or invalid
        }
      },
    },
    {
      field: 'nextoppointmentdateandtime',headerName: 'Next Contact Date and Time',headerClassName:'custom-header',width: 250,
      renderCell: (params) => {
        const { interactionsdetails } = params.row;
        const date = interactionsdetails?.[0]?.nextoppointmentdateandtime;
        if (date && moment(date * 1000).isValid()) {
          const formattedDate = moment(date * 1000).format('DD-MM-YYYY');
          return formattedDate;
        } else {
          return null; // Display null if the date is missing or invalid
        }
      },
    },
    {
      field: 'notes',headerName: 'Interaction',headerClassName:'custom-header',width: 250,
      renderCell: (params) => {
        const { interactionsdetails } = params.row;
        const notes = interactionsdetails?.[0]?.notes
        return notes
      },
    },
  ];
  const salesColumns = [
    { field: 'saleid', headerName: 'Sale ID', width: 150,headerClassName:'custom-header'  },
    { field: 'opportunityid', headerName: 'Lead ID', width: 150,headerClassName:'custom-header'  ,
    renderCell: (params) => {
      const { policydetails } = params.row;
      const opportunityid = policydetails?.[0]?.opportunityid
      return opportunityid
    },
  },
    { field: 'provider', headerName: 'Provider', width: 150,headerClassName:'custom-header'  },
    { field: 'product', headerName: 'Product', width: 150,headerClassName:'custom-header'  },
    { field: 'subproduct', headerName: 'Subproduct', width: 150,headerClassName:'custom-header'  },
    {
      field: 'saledate',
      headerName: 'Sale Date',
      width: 150,headerClassName:'custom-header' ,
      valueFormatter: (params) => {
        const date = new Date(params.value*1000);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
    },
    {
      field: 'salespersonname',
      headerName: 'Sales Person Name',
      width: 150,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const firstName = params.row.employeefirstname || '';
        const lastName = params.row.employeelastname || '';
        const fullName = `${firstName} ${lastName}`.trim();
        return fullName ? fullName : '-';
      },
    },
  { field: 'city', headerName: 'City', width: 150,headerClassName:'custom-header' ,
  renderCell: (params) => {
    const { policydetails } = params.row;
    const city = policydetails?.[0]?.city
    return city
  },
 },
  { field: 'state', headerName: 'State', width: 150,headerClassName:'custom-header',
  renderCell: (params) => {
    const { policydetails } = params.row;
    const state = policydetails?.[0]?.state
    return state
  },  
},
    { field: 'department', headerName: 'Department', width: 150,headerClassName:'custom-header'  },
    { field: 'bookingcode', headerName: 'Booking Code', width: 150,headerClassName:'custom-header'  },
    { field: 'odpremium', headerName: 'OD Premium', width: 150,headerClassName:'custom-header' ,
renderCell: (params) => {
  return `${params.row.subpremiums["OD Premium"]}`;
},
 },
  { field: 'addonpremium', headerName: 'Addon Premium', width: 150,headerClassName:'custom-header',
  renderCell: (params) => {
    return `${params.row.subpremiums["Add-on Premium"]}`;
  },
},
  { field: 'thirdpartypremium', headerName: 'Third Party Premium',width: 150,headerClassName:'custom-header',
  renderCell: (params) => {
    return `${params.row.subpremiums["Third-Party"]}`;
  },
},
{
  field: 'basepremium',
  headerName: 'Base Premium',
  width: 150,
  headerClassName: 'custom-header',
  renderCell: (params) => {
    const basePremium = params.row.subpremiums ? params.row.subpremiums["Base Premium"] : null;
    return basePremium ? `${basePremium}` : '-'; // Return the value or an empty string if it's missing
  },
},
  { field: 'netpremium', headerName: 'Net Premium', width: 150,headerClassName:'custom-header' , valueFormatter: (params) => `₹${params.value}` },
  { field: 'tax', headerName: 'Tax', width: 150,headerClassName:'custom-header' , valueFormatter: (params) => `₹${params.value}` },
  { field: 'grosspremium', headerName: 'Gross Premium', width: 150,headerClassName:'custom-header' , valueFormatter: (params) => `₹${params.value}` },
  { field: 'totalcommission', headerName: 'Total Commission', width: 150,headerClassName:'custom-header' , valueFormatter: (params) => `₹${params.value}` },
  { field: 'commisionstatus', headerName: 'Commission Status', width: 150,headerClassName:'custom-header'  },
  { field: 'referralfee', headerName: 'Referral Fee', width: 150,headerClassName:'custom-header'  },
  { field: 'referralstatus', headerName: 'Referral ', width: 150,headerClassName:'custom-header'  },
  ];

  const invoiceColumns = [
    { field: 'invoiceid', headerName: 'Invoice ID',width: 150,headerClassName:'custom-header'  },
    { field: 'provider', headerName: 'Provider', width: 150,headerClassName:'custom-header'  },
    { field: 'invoicedate', headerName: 'Invoice Date', width: 150,headerClassName:'custom-header' ,
    valueFormatter: (params) => {
      const date = new Date(params.value*1000);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
  },
 

{ field: 'externalinvoiceid', headerName: 'External Invoice ID', width: 150,headerClassName:'custom-header'  },
    { field: 'invoiceamount', headerName: 'Invoice Amount', width: 150,headerClassName:'custom-header'  },
    { field: 'status', headerName: 'Status', width: 150,headerClassName:'custom-header'  },
    { field: 'duedate', headerName: 'Due Date', width: 150,headerClassName:'custom-header' ,
    valueFormatter: (params) => {
      const date = new Date(params.value*1000);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
  },
  {
    field: 'paymentrecieveddate',
    headerName: 'Payment Received Date',
    width: 200,
    headerClassName: 'custom-header',
    valueFormatter: (params) => {
      const dateValue = params.value ? params.value * 1000 : null;
      if (dateValue) {
        const date = new Date(dateValue);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }
      return '-'; // Return an empty string for default date values
    },
  },
  ];

    const formattedCommissionData = commissionReportData?.map((item, index) => {
      const rowData = {
        id: index + 1,
        provider: item._id,
        totalpremium: item.totalpremium,
        totalcommission: item.totalcommission,
        count: item.count,
      };
    
      // Loop through 'sales' array and create fields for each commissionStatus
      item.sales.forEach((sale) => {
        rowData[sale.commissionStatus.replace(/ /g, '')] = sale.totalcommission;
      });
    
      return rowData;
    });
    
    // Extract unique commission statuses from all the data
    const uniqueStatuses = new Set(commissionReportData?.flatMap((item) => item.sales.map((sale) => sale.commissionStatus)));
    

  const commissionColumns = [
    { field: 'provider', headerName: 'Provider', width: 180 ,headerClassName:'custom-header',
  },
    { field: 'count', headerName: 'Number of Policies', width: 150,headerClassName:'custom-header',align: 'center', },
    { field: 'totalpremium', headerName: 'Total Net Premium', width: 160,headerClassName:'custom-header',align: 'center',
    valueFormatter: (params) => formatCurrency(params.value),
  },
    { field: 'totalcommission', headerName: 'Total Commission', width: 160,headerClassName:'custom-header',align: 'center',
    valueFormatter: (params) => formatCurrency(params.value),
  },
  ...Array.from(uniqueStatuses).map((status) => ({
    field: status.replace(/ /g, ''),
    headerName: status,
    headerClassName:'custom-header',align: 'center',
    width: 120,
    valueFormatter: (params) => formatCurrency(params.value || 0),
  })),
  ]

const empolyeeColumns = [
  { field: 'EmployeeName', headerName: 'Employee Name', width: 250,headerClassName:'custom-header', },
  { field: 'Assigned', headerName: 'Assigned', width: 120,headerClassName:'custom-header', },
  { field: 'Coldcalls', headerName: 'Cold Calls', width: 120,headerClassName:'custom-header', },
  { field: 'QuoteSent', headerName: 'Quote Sent', width: 120,headerClassName:'custom-header', },
  { field: 'ClosedandWon', headerName: 'Closed & Won', width: 140,headerClassName:'custom-header', },
  { field: 'Pipeline', headerName: 'Pipeline', width: 120,headerClassName:'custom-header', },
  { field: 'Lost', headerName: 'Lost', width: 100,headerClassName:'custom-header', },
  { field: 'Dropped', headerName: 'Dropped', width: 120,headerClassName:'custom-header', },
  /* { field: 'unassigned', headerName: 'Unassigned', width: 130,headerClassName:'custom-header', },
  { field: 'Open', headerName: 'Open', width: 100,headerClassName:'custom-header', }, */
]

const MISColumns = [
  { field: 'id', headerName: 'S.No', width: 60,headerClassName:'custom-header'  },
  {
    field: 'saledate',
    headerName: 'Sale Date',
    width: 150,headerClassName:'custom-header' ,
    valueFormatter: (params) => {
      const date = new Date(params.value*1000);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
  },
  { field: 'policynumber', headerName: 'Policy Number', width: 150,headerClassName:'custom-header'  },
  { field: 'customername', headerName: 'Customer Name', width: 150,headerClassName:'custom-header' ,
  renderCell: (params) => {
    return `${params?.row?.customerdetails?.[0]?.firstname} ${params?.row?.customerdetails?.[0]?.lastname}`;
  },
},
  { field: 'policytype', headerName: 'Policy Type', width: 150,headerClassName:'custom-header'  },
  { field: 'emailid', headerName: 'Email ID', width: 150,headerClassName:'custom-header',
  renderCell: (params) => {
    return `${params?.row?.customerdetails?.[0]?.emailid}`;
  },
},
{ field: 'phonenumber', headerName: 'Phone Number', width: 150,headerClassName:'custom-header' ,
renderCell: (params) => {
  return `${params?.row?.customerdetails?.[0]?.phonenumber}`;
},
},
{ field: 'provider', headerName: 'Provider', width: 150,headerClassName:'custom-header'  },
{ field: 'product', headerName: 'Product', width: 150,headerClassName:'custom-header'  },
{
  field: 'Make',headerName: 'Make',headerClassName:'custom-header',width: 60,
  renderCell: (params) => {
  const { productdetails } = params.row;
  return productdetails?.Make;
},
},
{
  field: 'Model',headerName: 'Model',headerClassName:'custom-header',width: 60,
  renderCell: (params) => {
  const { productdetails } = params.row;
  return productdetails?.Model;
},
},
{
  field: 'Year of Manufacture',headerName: 'Year of Manufacture',headerClassName:'custom-header',width: 190,
  renderCell: (params) => {
  const { productdetails } = params.row;
  return productdetails["Year of Manufacture"];
},
},
{
  field: 'Age of the Vechile',headerName: 'Age of the Vechile',headerClassName:'custom-header',width: 90,
  renderCell: (params) => {
  const { productdetails } = params.row;
  return productdetails["Age of the Vechile"];
},
},
{
  field: 'Eligible NCB %',headerName: 'Eligible NCB %',headerClassName:'custom-header',width: 90,
  renderCell: (params) => {
  const { productdetails } = params.row;
  return productdetails["Eligible NCB %"];
},
},
{
  field: 'Registration Number',headerName: 'Registration Number',headerClassName:'custom-header',width: 120,
  renderCell: (params) => {
  const { productdetails } = params.row;
  return productdetails["Registration Number"];
},
},

{ field: 'odpremium', headerName: 'OD Premium', width: 150,headerClassName:'custom-header' ,
renderCell: (params) => {
  return `${params.row.subpremiums["OD Premium"]}`;
},
 },
  { field: 'addonpremium', headerName: 'Addon Premium', width: 150,headerClassName:'custom-header',
  renderCell: (params) => {
    return `${params.row.subpremiums["Add-on Premium"]}`;
  },
},
  { field: 'thirdpartypremium', headerName: 'Third Party Premium',width: 150,headerClassName:'custom-header',
  renderCell: (params) => {
    return `${params.row.subpremiums["Third-Party"]}`;
  },
},
{
  field: 'basepremium',
  headerName: 'Base Premium',
  width: 150,
  headerClassName: 'custom-header',
  renderCell: (params) => {
    const basePremium = params.row.subpremiums ? params.row.subpremiums["Base Premium"] : null;
    return basePremium ? `${basePremium}` : '-'; // Return the value or an empty string if it's missing
  },
},
  { field: 'netpremium', headerName: 'Net Premium', width: 150,headerClassName:'custom-header'  },
  { field: 'grosspremium', headerName: 'Total Premium With GST', width: 180,headerClassName:'custom-header'  },
  {
    field: 'salespersonname',
    headerName: 'Sales Person Name',
    width: 150,
    headerClassName: 'custom-header',
    renderCell: (params) => {
      const firstName = params.row.employeefirstname || '';
      const lastName = params.row.employeelastname || '';
      const fullName = `${firstName} ${lastName}`.trim();
      return fullName ? fullName : '-';
    },
  },
{ field: 'policystartdate', headerName: 'Policy Start Date', width: 150,headerClassName:'custom-header',
valueFormatter: (params) => {
  const date = new Date(params.value*1000);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
},
},
{ field: 'policyenddate', headerName: 'Policy End Date', width: 150,headerClassName:'custom-header',
valueFormatter: (params) => {
  const date = new Date(params.value*1000);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
},
},
  { field: 'discount', headerName: 'Discount', width: 150,headerClassName:'custom-header'  },
  { field: 'discountpaidby', headerName: 'Discount Paid By', width: 150,headerClassName:'custom-header'  },
  { field: 'crm', headerName: 'CRM', width: 150,headerClassName:'custom-header'  },
  { field: 'management', headerName: 'Management', width: 150,headerClassName:'custom-header'  },
  { field: 'link', headerName: 'Link', width: 150,headerClassName:'custom-header'  },
    
  // { field: 'beneficiaryid', headerName: 'Beneficiary ID', width: 150,headerClassName:'custom-header'  },
  // 
 
  // { field: 'subproduct', headerName: 'Subproduct', width: 150,headerClassName:'custom-header'  },
 
 
 
//   { field: 'policyenddate', headerName: 'Policy End Date', width: 150,headerClassName:'custom-header' ,
//   valueFormatter: (params) => {
//     const date = new Date(params.value*1000);
//     const day = date.getDate().toString().padStart(2, '0');
//     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
//   },
//  },
  // { field: 'premiumpayfrequency', headerName: 'Premium Pay Frequency', width: 150,headerClassName:'custom-header'  },
  
 
  // { field: 'tax', headerName: 'Tax', width: 150,headerClassName:'custom-header'  },
  // 

  // { field: 'bookingcode', headerName: 'Booking Code', width: 150,headerClassName:'custom-header'  },
  // { field: 'city', headerName: 'City', width: 150,headerClassName:'custom-header'  },
  // { field: 'state', headerName: 'State', width: 150,headerClassName:'custom-header'  },
];

const empolyeewiseFormattedData = empolyeeReportData.map((data, index) => ({
  id: index + 1, // Using index + 1 as an example for the unique ID
  ...data, // Spread the existing data into the new object
}));

const Discountcolumns = [
  
  { field: 'cashbackpaidby', headerName: 'Cashback Paid By', width: 200 },

  { field: 'totalCashback', headerName: 'Total Cashback', width: 150 },
]

  return (
    <div style={{ marginTop: '4rem', padding:'5px',marginLeft:'1rem' }}>
      <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
        <h5>Reports</h5>
        <Grid item xs={2} style={{ marginLeft: '6rem' }}>
        <Controls.Dropdown 
        label="Reports"
        options={filters}
        value={selectedFilters}
        onChange={handleFilterChange}
        />
       
        </Grid>
           <Grid item xs={6} style={{ marginLeft: '6rem', display: 'flex', flexDirection: 'row' }}> 
          <Stack direction="row" spacing={4} alignItems="flex-start"> 
    <DateRangePicker
    format='dd-MM-yyyy'
    ranges={predefinedRanges}
    placeholder="Select Date"
    style={{ width: 210 }}
    onChange={onDateRangePicker}
  />
  </Stack> 
      <div style={{display:'flex',flexDirection:'row',marginLeft:'10rem'}}>
        <Controls.Button text="Generate" onClick={handleGenerateReport} />
        {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
            <Controls.Button text="Clear" onClick={handleClear} />
            <Controls.Button text="Cancel" onClick={handleCancel} />
        </div>
         <DrawerHeader>
      <button
        onClick={handleDrawerOpen}
        style={{
          backgroundColor: '#d8bfd8',
          display: 'flex',
          borderRadius: '18px', // Add border-radius
          marginLeft: '4rem',
          marginTop:'0.5rem',
          height:'23px',
          fontSize:'18px bold',
          marginBottom:'7rem',
        }}
      >
        <span>
          <FilterListIcon />
        </span>
        <span>Filter</span>
      </button>
      </DrawerHeader> 
        </Grid>
      </Grid>
        <Main side={side}>
        <Grid container spacing={2}>
          {/* <Grid>
          {selectedFilters}
          </Grid> */}
        <Grid >
      { selectedFilters.includes('Prospects Report') && (
        <Box
          sx={{
            marginTop:'0',
            '& .custom-header': {
              backgroundColor: 'rgb(234, 237, 240,1)',
            },
          }}
        >
          {selectedFilters}
          {prospectReportData?.length > 0 ? (
        <>
          {prospectReportData &&  (
        <DataGrid
        sx={{ marginTop: '0'}}
        rows={prospectReportData}
        columns={prospectColumns}
        pageSize={5}  
        rowsPerPageOptions={[5, 10, 20]}
        getRowId={(row) => row.prospectid}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
          )}
          </>
          ):(
            <>
            <DataGrid
            sx={{ marginTop: '0'}}
              rows={prospectReportData}
              columns={prospectColumns}
              pageSize={5}
              rowsPerPageOptions={[5, 10, 20]}
              getRowId={(row) => row.prospectid}
           />
           <Typography style={{marginLeft:'25rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
          No prospect details available.
        </Typography>
            </>
          )}
        </Box>
      )}
       </Grid>
       <Grid >
      { selectedFilters.includes('Leads Report') && (
        <Box
          sx={{
            marginTop: '0',
            '& .custom-header': {
              backgroundColor: 'rgb(234, 237, 240,1)',
            },
          }}
        >
          {selectedFilters}
          {opportunityReportData?.length > 0 ? (
        <>
          {opportunityReportData &&  (
           
           <DataGrid
           rows={opportunityReportData} 
           sx={{ marginTop: '0'}}
           columns={opportunityColumns} pageSize={5} 
           getRowId={(row) => row.opportunityid}
           slots={{
            toolbar: CustomToolbar,
          }}
           />
         
           )}
           </>
           ):(
             <>
              {opportunityReportData &&  (
           <DataGrid rows={opportunityReportData} 
           sx={{ marginTop: '0'}}
           columns={opportunityColumns} pageSize={5} 
           getRowId={(row) => row.opportunityid}
           />
           )}
        <Typography style={{marginLeft:'25rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
          No Lead details available.
        </Typography>
            </>
          )}
        </Box>
      )}
       </Grid>
       <Grid >
      { selectedFilters.includes('Sales Report') && (
        <Box
          sx={{
            marginTop: '0',
            '& .custom-header': {
              backgroundColor: 'rgb(234, 237, 240,1)',
            },
          }}
        >
          {selectedFilters}
          {salesReportData?.length > 0 ? (
        <>
          {salesReportData &&  (
           <DataGrid
           rows={salesReportData} 
           sx={{marginTop:'0'}}
           columns={salesColumns} pageSize={5} 
           getRowId={(row) => row.saleid}
           slots={{
            toolbar: CustomToolbar,
          }}
           />
           )}
           </>
           ):(
             <>
              {salesReportData &&  (
           <DataGrid rows={salesReportData} 
           sx={{marginTop:'0'}}
           columns={salesColumns} pageSize={5} 
           getRowId={(row) => row.saleid}
           />
           )}
        <Typography style={{marginLeft:'25rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
          No Sales details available.
        </Typography>
            </>
          )}
        </Box>
      )}
      </Grid>
      
      <Grid >
      { selectedFilters.includes('Cashback Report') && (
        <Box
          sx={{
            marginTop: '0',
            '& .custom-header': {
              backgroundColor: 'rgb(234, 237, 240,1)',
            },
          }}
        >
          {selectedFilters}
          {DiscountReport?.length > 0 ? (
        <>
          {DiscountReport &&  (
           <DataGrid
           rows={DiscountReport} 
           sx={{marginTop:'0'}}
           columns={Discountcolumns} pageSize={5} 
           getRowId={(row) => row.id}
           slots={{
            toolbar: CustomToolbar,
          }}
           />
           )}
           </>
           ):(
             <>
              {DiscountReport &&  (
           <DataGrid rows={DiscountReport} 
           sx={{marginTop:'0'}}
           columns={Discountcolumns} pageSize={5} 
           getRowId={(row) => row.id}
           />
           )}
        <Typography style={{marginLeft:'25rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
          No Cashback Detailsavailable.
        </Typography>
            </>
          )}
        </Box>
      )}
      </Grid>
      
      <Grid >
      { selectedFilters.includes('Invoice List') && (
        <Box
          sx={{
            marginTop: '0',
            '& .custom-header': {
              backgroundColor: 'rgb(234, 237, 240,1)',
            },
          }}
        >
          {selectedFilters}
          {invoiceReportData?.length > 0 ? (
        <>
       
          {invoiceReportData &&  (
           <DataGrid 
           rows={invoiceReportData} 
           sx={{ marginTop: '0'}}
           columns={invoiceColumns} pageSize={5} 
           getRowId={(row) => row.invoiceid}
           slots={{
            toolbar: CustomToolbar,
          }}
           />
           )}
           </>
           ):(
             <>
              {invoiceReportData &&  (
           <DataGrid
           rows={invoiceReportData} 
           sx={{ marginTop: '0'}}
           columns={invoiceColumns} pageSize={5} 
           getRowId={(row) => row.invoiceid}
           />
           )}
        <Typography style={{marginLeft:'25rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
          No invoice details available.
        </Typography>
            </>
          )}
        </Box>
      )}
      </Grid>
      <Grid >
      { selectedFilters.includes('Commission Report') && (
        <Box
          sx={{
            margintop:'0',
            '& .custom-header': {
              backgroundColor: 'rgb(234, 237, 240,1)',
            },
          }}
        >
          {selectedFilters}
          {commissionReportData?.length > 0 ? (
        <>
          {commissionReportData &&  (
           <DataGrid 
           rows={formattedCommissionData} 
           columns={commissionColumns} pageSize={5} 
           slots={{
            toolbar: CustomToolbar,
          }}
           />
           )}
           </>
           ):(
             <>
              {commissionReportData &&  (
           <DataGrid rows={formattedCommissionData} 
           sx={{marginTop:'0'}}
           columns={commissionColumns} pageSize={5} 
           />
           )}
        <Typography style={{marginLeft:'25rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
          No commission details available.
        </Typography>
            </>
          )}
        </Box>
      )}
      </Grid>
      <Grid>
      { selectedFilters.includes('Empolyee Wise Report') && (
        <Box
          sx={{
            marginTop: '0',
            '& .custom-header': {
              backgroundColor: 'rgb(234, 237, 240,1)',
            },
          }}
        >
          {selectedFilters}
          <div style={{marginTop:'2rem'}}>Unassigned:<span style={{marginLeft:'10px'}}>{unassignedData}</span></div>
          {empolyeeReportData?.length > 0 ? (
        <>
          {empolyeeReportData &&  (
           <DataGrid
           rows={empolyeewiseFormattedData} 
           columns={empolyeeColumns} pageSize={5} 
          getRowId={(row) => row.id}
          slots={{
            toolbar: CustomToolbar,
          }}
           />
           )}
           </>
           ):(
             <>
              {empolyeeReportData &&  (
           <DataGrid rows={empolyeewiseFormattedData} 
           columns={empolyeeColumns} pageSize={5} 
           />
           )}
        <Typography style={{marginLeft:'25rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
          No Empolyee wise details available.
        </Typography>
            </>
          )}
        </Box>
      )}
      </Grid>
      <Grid >
      { selectedFilters.includes('MIS Report') && (
        <Box
          sx={{
            marginTop: '0',
            '& .custom-header': {
              backgroundColor: 'rgb(234, 237, 240,1)',
            },
          }}
        >
          {selectedFilters}
          {MISReportData?.length > 0 ? (
        <>
          {MISReportData &&  (
           <DataGrid
           rows={MISReportData} 
           columns={MISColumns} pageSize={5} 
           slots={{
            toolbar: CustomToolbar,
          }}
           />
           )}
           </>
           ):(
             <>
              {MISReportData &&  (
           <DataGrid rows={MISReportData} 
           columns={MISColumns} pageSize={5} 
           />
           )}
        <Typography style={{marginLeft:'25rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
          No MIS details available.
        </Typography>
            </>
          )}
        </Box>
      )}
       <Drawer
        width="30%"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          justifyContent: 'flex-end',
          height: '100%',
          padding: 2,
          width: drawerWidth,
          flexShrink: 0,
         
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#f5f5f5' },
        }}
        variant="persistent"
        anchor="right"
        open={side}
      > 
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} style={{marginTop:'55px'}}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
         <List >
              <ListItem>
                {renderSidebar()}
              </ListItem>
          </List>
      </Drawer>
      </Grid>
      </Grid>
      </Main>
      <DrawerHeader />
    
    </div>
  );
};

export default Reports;