
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Grid, Typography, Card, CardContent } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import plus from '../assests/icons/plus.png';
import edits from '../assests/icons/edits.png';
import InteractionChat from '../pages/InteractionChat';
import * as Rest from  './../services/restapi';
import fileicon from './../assests/icons/fileicon.png';
import './../App.css';

const OpportunityDetails = () => {
  const [prospectValues, setProspectValues] = useState([]);
  const [beneficiaryValues, setBeneficiaryValues] = useState([]);
  const [opportunityValues, setOpportunityValues] = useState([]);
  const [referralValues, setReferralValues] = useState([]);
  const location =  useLocation();
  const customer = location.state;
  const prospectid = customer?.prospectid || customer?.customer?.prospectid;
  const navigate = useNavigate();
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [fileContent, setFileContent] = React.useState('');
  const [attachments, setAttachments] = React.useState([]);
  const isInitialMount = useRef(true);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const day = date.getDate().toString().padStart(2, '0');
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date); // Abbreviated month name
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${day}-${month}-${year} ${formattedHours}:${formattedMinutes} ${amOrPm}`;
};
  

  useEffect(() => {
    if (isInitialMount.current) {
    axios.get(Rest.viewprosect,{
      params:{
        prospectid:prospectid
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    }

    )
      .then(response => {
        const prospect = response?.data?.data;
        setProspectValues(prospect);
        setOpportunityValues(prospect?.opportunitydetails);
        setBeneficiaryValues(prospect?.beneficiarydetails);
        setReferralValues(prospect?.referralpersondetails);
      })
      .catch(err => {
      });
      isInitialMount.current = false;
    }
  }, [prospectid]);

  const handleEdit = (opportunityid) => {
    // Make an API call using Axios
    axios.get(Rest.viewOpportunity,{
      params:{
        opportunityid:opportunityid
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    }
  )
      .then(response => {
        // Navigate to the updateprospect page
        navigate(`/editopportunity/${opportunityid}`, { state: { updateOpportunity: response?.data?.data } });
        // Handle the API response data
        console.log('API Response:', response?.data?.data);
      })
      .catch(error => {
        // Handle API errors
        console.error('API Error:', error);
      });
  }; 
  const openPDF = (file) => {
    {
      window.open(file, '_blank')
    }
  };

    return (
      <div>
          <Card style={{marginTop:'5px',marginBottom:'5px',height:'3rem'}}>
            <CardContent >
            <Typography variant="body6" color="text.secondary" >
            Lead Details
          </Typography>
            <img
            style={{marginLeft:'62.6rem'}}
              onClick={() =>
                navigate(`/opportunity/${prospectid}`, { state: { customer: customer?.customer } })}
              src={plus} alt="plus " className="action-button-icon" />
              
            <Typography variant="body5" color="primary">Add</Typography>
            </CardContent>
          </Card>

       {opportunityValues?.length > 0 ? (
          <Grid container spacing={3} >
            <Grid item xs={12} md={6} lg={15} >
              {opportunityValues.map((opportunity, index) => (
                <Card key={index} style={{ marginBottom: '0.3rem',backgroundColor: '#fffafa', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'  }}>
                    <Typography style={{marginLeft:'73.5rem',paddingTop:'5px'}}variant="body5" color="primary">
            <img onClick={() => handleEdit(opportunity.opportunityid)}
              src={edits} alt="Edit" className="action-button-icon" />
            Edit</Typography>
                  <CardContent style={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', gridTemplateRows: 'repeat(4, auto)', gap: '0.5rem' }}>
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                    Lead ID:{' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                    {opportunity.opportunityid}
                            </span>
                       </Typography>
                       <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                       Interested Policy Type:{' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {opportunity.interestedpolicytype}
                            </span>
                       </Typography>
                       <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                       Probability:{' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {opportunity.probability}
                            </span>
                       </Typography>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Product:{' '}
                     <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                       {opportunity.product}
                      </span>
                    </Typography> 
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Contacted Date:{' '}
                  <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                  {opportunity.interactiondetails?.[0]?.interactionDetails?.[0]?.interactiondateandtime
                  ? formatDate(opportunity.interactiondetails?.[0]?.interactionDetails?.[0]?.interactiondateandtime)
                  : '--'}
                  </span>
                  </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Lead Status:{' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {opportunity.opportunitystatus}
                  </span>
                </Typography> 
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                       Subproduct:{' '}
                     <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                       {opportunity.subproduct}
                        </span>
                     </Typography>
                     <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Next Contact Date and Time:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                        {opportunity.interactiondetails?.[0]?.interactionDetails?.[0]?.nextoppointmentdateandtime
                          ? formatDate(opportunity.interactiondetails?.[0]?.interactionDetails?.[0]?.nextoppointmentdateandtime)
                          : '--'}
                      </span>
                    </Typography>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Prospect Name:{" "}
                  <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                  {prospectValues.firstname} {prospectValues.lastname}
                  </span>
                </Typography>
                  
                 <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Beneficiary Name:
                  <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}  >
                    {beneficiaryValues?.[0]?.firstname} {beneficiaryValues?.[0]?.lastname}
                  </span>
                </Typography>
               
                 <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Referral Person Name:{' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }} >
                    {referralValues?.[0]?.firstname} {referralValues?.[0]?.lastname}
                    {console.log('................',referralValues?.[0]?.firstname)}
                       </span>
                </Typography>
                <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Attachements{" "}
                  <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold',display:'flex',flexDirection:'row' }}>
                  {opportunity.attachments.map((file) => (
                        <>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {file.filetype}: <img onClick={() => openPDF(file.image)}
                              src={fileicon} alt="fileicon" className="file-icon" />
                          </div>
                        </>
                      ))}
                  </span>
                </Typography>
                  </CardContent>                   
                <div >
                <InteractionChat opportunity={opportunity} customer={customer?.customer} prospectValues={prospectValues} opportunityValues={opportunityValues} />
              </div>
              </Card>
             ))}
                  </Grid>
          </Grid>
        ) : (
          <Card>
            <CardContent>
              <Typography >
                No Lead details available.
              </Typography>
            </CardContent>
          </Card>
          
        )}
      </div>
    
    );
  };
  
  
  export default OpportunityDetails; 