import React, { useEffect, useRef, useState } from 'react'
import Controls from '../components/controls/Controls'
import { Grid, Tooltip, Typography } from '@material-ui/core';
import { Form } from './../components/useForm'
import { DataGrid } from '@mui/x-data-grid';
import './../App.css'
import { Box, Checkbox, ListItemText, Snackbar, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import update from "../assests/icons/update.png";
import * as Rest from './../services/restapi';
import LoadingSpinner from '../components/controls/spinner';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';



const formatCurrency = (value) => {
  return `₹${value?.toLocaleString('en-IN')}`;
};

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      display: 'row',
      backgroundColor: 'White'
    },
  },
};
const AvSales = () => {
  const [salesData, setSaleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalcommission, setTotalCommission] = useState(0);
  const navigate = useNavigate();
  const [selectedSaleId, setSelectedSaleId] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [providers, setProviders] = useState([]); // State to store providers
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [isupdateSaleOpen, setIsupdateSaleOpen] = useState(false);
  const [isCalculateSuccessSnackbarOpen, setIsCalculateSuccessSnackbarOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalCommissionSum, setTotalCommissionSum] = useState(0);
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [commissionRows, setCommissionRows] = useState([]);
  const [commissionStatusOptions, setCommissionStatusOptions] = useState([]);
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [externalInvoiceId, setExternalInvoiceId] = useState('');
  const [dueDate, setDueDate] = useState(null);
  const [invoiceDate, setInvoiceDate] = useState(null);
  const [isLoadingUpdateSale, setIsLoadingUpdateSale] = useState(false)
  const [isLoadingCalculateCommission, setIsLoadingCalculateCommission] = useState(false);
  const isProviderMount = useRef(true);
  const isCommissionMount = useRef(true);
  const isSalesMount = useRef(true);
  const [insuranceManagers, setInsuranceManagers] = useState([]);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogSubmit = () => {
    setOpenDialog(false);
    createInvoice(); // Call the createInvoice function on dialog submit
  };


  function getStyles(providers, selectedProvider, theme) {
    return {
      fontWeight:
        selectedProvider.indexOf(providers) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProvider(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];


  const handleDateRangeChange = (date) => {
    if (Array.isArray(date) && date.length === 2) {
      const stateDate = moment(date[0]).format("DD-MM-YYYY");
      const endDate = moment(date[1]).format("DD-MM-YYYY");
      setStartDate(stateDate);
      setEndDate(endDate);
    } else {

    }
    // fetchDataWithinDateRange(start, end);  
  };
  useEffect(() => {
    const fetchInsuranceManagers = async () => {
      try {
        const response = await axios.post(
          Rest.getAllEmployees,
          {
            Type: 'insurancemanager',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-fiftyaccess-Token': token,
            },
          }
        );
  
        if (response?.data?.code === 'S001') {
          const managerNames = response?.data?.data?.map((manager) => ({
            value: `${manager.FirstName} ${manager.LastName}`,
            label: `${manager.FirstName} ${manager.LastName}`,
          }));
          setInsuranceManagers(managerNames);
        } else {
          alert(response?.data?.msg || response?.data?.data);
        }
      } catch (error) {
        alert('Error: ' + error.message);
        console.error('Error fetching insurance managers:', error);
      }
    };
    fetchInsuranceManagers();
  }, [token]); 
  
  useEffect(() => {
     if(isCommissionMount.current){
     axios.get(Rest.commisionstatus,{
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
     })
     .then((response) => {
       if (response?.data?.commissionstatus) {
         // Update the state with the fetched commission status options
         setCommissionStatusOptions(response?.data?.commissionstatus);
       } else {
         console.error('Invalid response format for commission status options');
       }
     })
     .catch((error) => {
       console.error('Error fetching commission status options:', error);
     });
     isCommissionMount.current=false;
    }
 }, []);
 
  useEffect(() => {
    /* if(isProviderMount.current){ */
    axios
      .get(Rest.ViewAllProvider, {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
      .then((response) => {
        const providerData = response?.data?.Data || [];

        // Extract provider names from the response data
        const providerNames = providerData.map((provider) => provider.provider);

        // Filter the unique provider names
        const uniqueProviders = [...new Set(providerNames)];

        setProviders(uniqueProviders);
      })
      .catch((error) => {
        console.error('Error fetching providers:', error);
      });
      /* isProviderMount.current=false;
    } */
  }, []);

  const createInvoice = () => {
    const selectedSales = salesData.filter((sale) => selectedRows[sale.saleid]);
    const selectedProvider = selectedSales.length > 0 ? selectedSales[0].provider : '';

    // Check if all selected sales have the same provider
    const isProviderValid = selectedSales.every((sale) => sale.provider === selectedProvider);

    if (!isProviderValid) {
      alert("Selected provider is incorrect. Please select sales with the same provider.");
      return;
    }
    const saleIds = selectedSales.map((sale) => sale.saleid);
    // Example of payload structure for the API request
    const invoicePayload = {
      provider: selectedSales?.[0]?.Pollicydetails?.[0]?.provider,
      invoiceamount: totalCommissionSum,
      saleid: saleIds,
      duedate: moment(dueDate).format("DD-MM-YYYY"),
      invoicedate: moment(invoiceDate).format("DD-MM-YYYY"),
      externalinvoiceid: externalInvoiceId
    };
    // Make API request to create an invoice
    axios.post(Rest.createinvoice, JSON.stringify(invoicePayload), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then(response => {
        if (response?.data?.code === "S001") {
          setIsSuccessSnackbarOpen(true); // Open success snackbar
          // Redirect after a short delay (optional)
          setTimeout(() => {
            navigate('/invoice', { state: { selectedSales } });
          }, 2000);
        } else {
          alert(response?.data?.msg || response?.data?.data)
        }
      })
      .then(data => {
        console.log('Invoice created successfully:', data);
      })
      .catch(err => {
        alert("Error: " + err.message);
      });
  };

  const handleSaleRowClick = (params) => {
    setSelectedSaleId(params.row.saleid);
  };
  const handleAllUpdateSale = () => {
    setIsLoadingUpdateSale(true);
    /* if (selectedSaleId === null) {
      console.error('Please select a sale.');
      return;
    } */
    const selectedSales = salesData
      .filter((sale) => selectedRows[sale.saleid])
      .map((sale) => ({
        ...sale,
      }));

    axios
      .put(Rest.updatesale, selectedSales, {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
      .then((response) => {
        if (response?.data?.code === "S001") {
          setIsupdateSaleOpen(true); // Open success snackbar
          // Redirect after a short delay (optional)
          setTimeout(() => {
            navigate('/sales');
            fetchSalesData();
            /* setSaleData(selectedSales) */
          }, 2000);
        } else {
          alert(response?.data?.msg || response?.data?.data)
        }
      })
      .catch((err) => {
        alert("Error: " + err.message);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoadingUpdateSale(false);
        }, 1000);
      });
  };

  const handleUpdateSale = (selectedSaleId) => {
    if (selectedSaleId === null) {
      console.error('Please select a sale.');
      return;
    }

    const selectedSale = salesData.find((sale) => sale.saleid === selectedSaleId);

    if (!selectedSale) {
      console.error('Selected sale not found.');
      return;
    }
    const updatedData = {
      saleid: selectedSale.saleid,
      provider: selectedSale.provider,
      product: selectedSale.product,
      subproduct: selectedSale.subproduct,
      city: selectedSale.city,
      state: selectedSale.state,
      saledate: selectedSale.saledate,
      addonpremium: selectedSale.addonpremium,
      odpremium: selectedSale.odpremium,
      thirdpartypremium: selectedSale.thirdpartypremium,
      netpremium: selectedSale.netpremium,
      grosspremium: selectedSale.grosspremium,
      totalcommission: selectedSale.totalcommission,
      override: selectedSale.override,
      overridecommission: selectedSale.overridecommission,
      commisionstatus: selectedSale.commisionstatus,
      cashback: selectedSale.cashback,
      cashbackpaidby: selectedSale.cashbackpaidby,
      subpremiums: selectedSale.subpremiums
    };

    axios
      .put(Rest.updatesale, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
      .then((response) => {
        if (response?.data?.code === "S001") {
          setIsupdateSaleOpen(true);
          setTimeout(() => {
            navigate("/sales")
            fetchSalesData();
            /* window.location.href = window.location.href; */
          }, 1000);
        } else {
          alert(response?.data?.msg || response?.data?.data)
        }
      })
      .catch((err) => {
        alert("Error: " + err.message);
      });
  };


  useEffect(() => {
    // Calculate the total commission whenever rowData changes
    calculateTotalCommission();
  }, [salesData]);

  const calculateTotalCommission = () => {
    let total = 0;
    for (const row of salesData) {
      total += (row.odpremiumcommision || 0) + (row.addonpremiumcommision || 0) + (row.thirdpartypremiumcommision || 0);
    }
    setTotalCommission(total);
  };


  const handleDropdownChange = (id, selectedValue) => {
    // Find the index of the row with the matching saleid
    
    const rowIndex = salesData.findIndex((sale) => sale.saleid === id);

    if (rowIndex !== -1) {
      // Clone the salesData array to avoid mutating the state directly
      const updatedSalesData = [...salesData];
      updatedSalesData[rowIndex] = { ...updatedSalesData[rowIndex], commisionstatus: selectedValue };

      // Update the state with the updated data
      setSaleData(updatedSalesData);
    }
  };

  const DropdownCommissionStatus = ({ id, selectedValue, handleDropdownChange }) => {
    return (

      <Controls.Dropdown
        label="Commission Status"
        options={commissionStatusOptions.map((status) => ({
          value: status,
          label: status,
        }))}
        disabled={selectedValue === "Invoiced" || selectedValue === "Paid" || selectedValue === "Written Off"}
        value={selectedValue} // Use selectedValue for the current row
        onChange={(e) => handleDropdownChange(id, e.target.value)} // Pass id and selected value
      />
    );
  };

  const renderCommissionStatusCell = (params) => (
    <DropdownCommissionStatus
      id={params.id}
      selectedValue={params.row.commisionstatus || params.row.value}
      handleDropdownChange={handleDropdownChange}
    />
  );
  

  const fetchSalesData = () => {
    axios
      .get(Rest.viewallsales, {
        params: {
          startdate: startDate,
          enddate: endDate,
          provider: selectedProvider
        },
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
      .then((response) => {
        if (response?.data?.code === "S001") {
          const formattedData = response?.data?.data?.map((sale) => ({
            ...sale,
            id: sale.saleid,
            policyDetails: sale.Pollicydetails,
            employeedetails: sale.employeedetailsdata,
            Customerdetails: sale.Customerdetails,
            rulesdata: sale.rulesdata
          }));
          setSaleData(formattedData);
          setLoading(false);
        } else {
          setSaleData([])
          alert(response?.data?.msg || response?.data?.data);
        }
      })
      .catch((err) => {
        alert(err?.response?.data?.data?.msg)
        setLoading(false);
        setSaleData([])
      });
  };

  useEffect(() => {
    /* if(isSalesMount.current){ */
    fetchSalesData(); // Call the function to fetch sales data
    calculateTotalCommission();
    /* isSalesMount.current=false
    } */
  }, [startDate, endDate, selectedProvider]);


  const calculateSlabCommission = () => {
    setIsLoadingCalculateCommission(true);
    // Create the request payload with startdate and enddate
    const requestData = {
      startdate: startDate,
      enddate: endDate,
    };

    axios.post(Rest.slabcalculation, requestData, {
      headers: {
        'Content-Type': 'application/json',
        'X-fiftyaccess-Token': token,
      },
    })
      .then((response) => {
        if (response?.data?.code === "S001") {
          setIsCalculateSuccessSnackbarOpen(true)
          navigate("/sales");
          fetchSalesData();
          /* window.location.href = window.location.href; */
          console.log('Commission calculation successful');
        } else {
          alert(response?.data?.msg);
        }
      })
      .catch((err) => {
        alert("Error: " + err.message);
      }).finally(() => {
        setTimeout(() => {
          setIsLoadingCalculateCommission(false);
        }, 1000); // Change the duration (in milliseconds) as per your requirement
      });
  };


  function calculateTotalCommissionSum(salesData, selectedRows, commissionRows) {
    let sum = 0;

    salesData.forEach((sale) => {
      if (selectedRows[sale.saleid] && sale.override) {

        sum += (sale.overridecommission || 0);
      } else if (selectedRows[sale.saleid]) {
        sum += sale.totalcommission || 0;
      }
    });
    return sum;
  }
  useEffect(() => {
    const totalSum = calculateTotalCommissionSum(salesData, selectedRows, commissionRows);
    setTotalCommissionSum(totalSum);
  }, [selectedRows]);


  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = {};

    salesData.forEach((sale) => {
      updatedSelectedRows[sale.saleid] = isChecked;
    });

    setSelectedRows(updatedSelectedRows);
  };

  const handleRowSelect = (saleid) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [saleid]: !prevSelectedRows[saleid],
    }));
  };
  const isNumeric = (value) => {
    return /^-?\d*\.?\d*(%)?$/.test(value);
  };
  const handleCashback = (saleId, newCashback) => {
    if (!isNumeric(newCashback)) {
      // Display an error message for invalid input
      alert("Please enter a valid numbers.");
      return;
    }

    const updatedData = salesData.map((row) => {
      if (row.saleid === saleId) {
        return { ...row, cashback: newCashback };
      }
      return row;
    });
    setSaleData(updatedData);
  };

  const handleCashbackPaidBy = (saleId, newCashback) => {

    if (/^\d+$/.test(newCashback)) {
      // Display an error message for invalid input
      alert("Please enter name.");
      return;
    }

    const updatedData = salesData.map((row) => {
      if (row.saleid === saleId) {
        return { ...row, cashbackpaidby: newCashback };
      }
      return row;
    });
    setSaleData(updatedData);
  };


  const handleOverrideRowSelect = (saleid) => {
    // Find the row with the given saleid in your data
    const updatedData = salesData.map((row) => {
      if (row.saleid === saleid) {
        // Toggle the "override" property
        row.override = !row.override;
      }
      return row;
    });

    // Update your state with the updated data
    setSaleData(updatedData);
  };
  const CustomNetPremiumCell = ({
    netpremium,
    subpremiums
  }) => {
    const tooltipStyles = {
      maxWidth: 'none',
      width: '8rem'
    };
    const subpremiumsEntries = subpremiums
      ? Object.entries(subpremiums)
      : [];

    return (
      <div>
        <Tooltip
          title={
            subpremiums
              ? subpremiumsEntries.map(([key, value]) => (
                <div key={key}>
                  {`${key}: ${value}`}
                </div>
              ))
              : 'No subpremiums available'
          }
          arrow
          placement="top-start"
          interactive
          classes={{ tooltip: 'custom-tooltip' }}
          PopperProps={{
            style: tooltipStyles,
          }}
        >
          <span>{formatCurrency(netpremium)}</span>
        </Tooltip>
      </div>
    );
  };
  const CustomGrossPremiumCell = ({
    grosspremium,
    netpremium,
    tax,
  }) => {
    const tooltipStyles = {
      maxWidth: 'none',
      width: '7rem'
    };

    return (
      <div>
        <Tooltip
          title={`Net Premium: ${netpremium}, Tax Amount: ${tax}`}
          arrow
          placement="top-start"
          interactive
          classes={{ tooltip: 'custom-tooltip' }}
          PopperProps={{
            style: tooltipStyles,
          }}
        >
          <span>{formatCurrency(grosspremium)}</span>
        </Tooltip>
      </div>
    );
  };
  const CustomCommissionCell = ({
    notes,
    totalcommission
  }) => {
    const tooltipStyles = {
      maxWidth: 'none',
      width: '8rem'
    };

    return (
      <div>
        <Tooltip
          title={` ${notes}`}
          arrow
          placement="top-start"
          interactive
          classes={{ tooltip: 'custom-tooltip' }}
          PopperProps={{
            style: tooltipStyles,
          }}
        >
          <span>{formatCurrency(totalcommission)}</span>
        </Tooltip>
      </div>
    );
  };

  const handleOverrideChange = (id, newValue, setRows) => {
    // Find the index of the row with the matching id
    const rowIndex = salesData.findIndex((row) => row.id === id);

    if (rowIndex !== -1) {
      // Clone the rows array to avoid mutating the state directly
      const updatedRows = [...salesData];
      // Update the overridecommission for the specific row
      updatedRows[rowIndex].overridecommission = (parseFloat(newValue));

      // Set the updated rows
      setSaleData(updatedRows);
    }
  };


  const columns = [
    {
      field: 'checkbox',
      width: '65',
      headerCheckboxSelection: true,
      headerClassName: 'custom-header',
      checkboxSelection: true,
      headerCheckboxProps: {
        onChange: handleSelectAll,
      },
      renderHeader: (params) => (
        <div>
          <input
            type="checkbox"
            checked={Object.keys(selectedRows).length === salesData.length}
            onChange={handleSelectAll}
          />
        </div>
      ),
      renderCell: (params) => (
        <div>
          <input
            type="checkbox"
            checked={selectedRows[params.row.saleid] || false}
            disabled={params.row.commisionstatus === "Invoiced" || params.row.commisionstatus === "Paid" || params.row.commisionstatus === "Written Off"}
            onChange={() => handleRowSelect(params.row.saleid)}
          />
        </div>
      ),
    },
    {
      field: 'saleid',
      headerName: 'Sale ID',
      width: 110,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const handleClick = () => {
          // Navigate to the target page and pass state data
          axios.get(Rest.viewpolicy, {
            params: {
              policyid: params.row.policyid
            },
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          }
          )
            .then(response => {
              // Navigate to the updateprospect page
              navigate(`/editpolicy/${params.row.policyid}`, { state: { updatePolicy: response?.data?.data } });
              // Handle the API response data
              console.log('API Response:', response?.data?.data);
            })
            .catch(error => {
              // Handle API errors
              console.error('API Error:', error);
            });
        };

        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            style={{
              pointerEvents:
                params.row.commisionstatus === "Invoiced" || params.row.commisionstatus === "Paid" || params.row.commisionstatus === "Written Off"
                  ? 'none'
                  : 'auto',
              cursor:
                params.row.commisionstatus === "Invoiced" || params.row.commisionstatus === "Paid" || params.row.commisionstatus === "Written Off"
                  ? 'not-allowed'
                  : 'pointer',

            }}
            onClick={handleClick}
          >
            {params.row.saleid}
          </a>
        );
      },
    },
    {
      field: 'saledate', headerName: 'Sale Date', width: 110, headerClassName: 'custom-header',
      valueFormatter: (params) =>
        format(new Date(params.value * 1000), 'dd-MM-yyyy'),
    },
    {
      field: 'salespersonname', headerName: 'Sales Person', width: 120, headerClassName: 'custom-header',
      renderCell: (params) => (`${params.row.employeefirstname} ${params.row.employeelastname}`)
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      width: 170,
      headerClassName: 'custom-header',
      renderCell: (params) => {
        const { Customerdetails } = params.row;
        return Customerdetails ? (
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {Customerdetails.firstname} {Customerdetails.lastname}
          </div>
        ) : null;
      },
    },
    { field: 'provider', headerName: 'Provider', width: 150, headerClassName: 'custom-header' },
    { field: 'product', headerName: 'Product', width: 120, headerClassName: 'custom-header' },
    { field: 'subproduct', headerName: 'Sub Product', width: 120, headerClassName: 'custom-header' },
    {
      field: 'policytype', headerName: 'Policy Type', width: 120, headerClassName: 'custom-header',
      renderCell: (params) => {
        const { policyDetails } = params.row;
        return policyDetails
          ? policyDetails.map((detail, index) => (
            <div key={index}>
              {detail.policytype}
            </div>
          ))
          : null;
      },
    },
    {
      field: 'netpremium',
      headerName: 'Net Premium',
      width: 120,
      headerClassName: 'custom-header',
      align: 'right',
      renderCell: (params) => (
        <CustomNetPremiumCell
          netpremium={params.row.netpremium}
          subpremiums={params.row.subpremiums}
        />
      ),
    },
    {
      field: 'grosspremium',
      headerName: 'Gross Premium',
      width: 120,
      headerClassName: 'custom-header',
      align: 'right',
      renderCell: (params) => (
        <CustomGrossPremiumCell
          grosspremium={params.row.grosspremium}
          netpremium={params.row.netpremium}
          tax={params.row.tax}
        />
      ),
    },
    {
      field: 'totalcommission',
      headerName: 'Calculated Commission',
      headerClassName: 'custom-header',
      width: 195,
      valueFormatter: (params) => formatCurrency(params.value),
      align: 'center',
      renderCell: (params) => (
        <CustomCommissionCell
          totalcommission={params.row?.totalcommission}
          notes={
            params.row?.note ??
            params.row?.note ??
            "default rule"
          }
        />
      ),
    },
    {
      field: 'overridecommission',
      headerName: 'Override Commission',
      headerClassName: 'custom-header',
      width: 175,
      align: 'right',
      renderCell: (params) => {
        const override = Boolean(params.row.override);
        // Check if the value is a valid number, otherwise display an empty string
        const overrideCommissionValue = !isNaN(params.row.overridecommission)
          ? params.row.overridecommission
          : '';

        return (
          <>
            <input
              type="checkbox"
              disabled={
                params.row.commisionstatus === 'Finalized' ||
                params.row.commisionstatus === 'Invoiced' ||
                params.row.commisionstatus === 'Paid' 
              }
              checked={override}
              onChange={() => handleOverrideRowSelect(params.row.saleid)}
            />
            <span style={{ marginLeft: '5px' }}>₹</span>
            <input
              value={overrideCommissionValue} // Use the checked value here
              style={{ width: '100px' ,height:'2.7rem',borderRadius:'6px'}}
              disabled={
                params.row.commisionstatus === 'Finalized' ||
                params.row.commisionstatus === 'Invoiced' ||
                params.row.commisionstatus === 'Paid' ||
                !override
              }
              onChange={(e) => handleOverrideChange(params.row.id, e.target.value)}
            />
          </>
        );
      },
    },
    {
      field: 'commisionstatus',
      headerName: 'Commission Status',
      headerClassName: 'custom-header',
      width: 180,
      renderCell: renderCommissionStatusCell,
    },
    {
      field: 'cashback',
      headerName: 'Cashback',
      width: 165,
      headerClassName: 'custom-header',
      renderCell: (params) => (
      
        <input
        type="text"
        disabled={
          params.row.commisionstatus === 'Finalized' ||
          params.row.commisionstatus === 'Invoiced' ||
          params.row.commisionstatus === 'Paid' 
        }
          value={params.row.cashback || ''}
          onChange={(event) => handleCashback(params.row.saleid, event.target.value)}
          style={{ width: '100px' ,height:'2.7rem',borderRadius:'6px'}}
        />
      ),
    },
    {
      field: 'cashbackpaidby',
      headerName: 'Cashback Paid By',
      width: 165,
      headerClassName: 'custom-header',
      renderCell: (params) => (
        <Controls.Dropdown
          label="Paid By"
          disabled={
            params.row.commisionstatus === 'Finalized' ||
            params.row.commisionstatus === 'Invoiced' ||
            params.row.commisionstatus === 'Paid' 
          }
          value={params.row.cashbackpaidby || ''}
          options={insuranceManagers}
        onChange={(event) => handleCashbackPaidBy(params.row.saleid, event.target.value)}
      />
      ),
    },
    {
      field: 'Action', headerName: 'Action', width: 85, headerClassName: 'custom-header',
      renderCell: (params) => (
        <Controls.Button
          text="Update"
          disabled={params.row.commisionstatus === 'Invoiced' || params.row.commisionstatus === 'Paid' || params.row.commisionstatus === 'Written Off'}
          onClick={() => handleUpdateSale(params.row.id)}
        />
      ),
    },
  ];

  const filteredSalesData = selectedProvider
    ? salesData.filter((sale) => {
      const { policyDetails } = sale;
      return policyDetails?.some((detail) => detail.provider === selectedProvider);
    })
    : salesData;

  const handleSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };

  const handleCalculateSnackbarClose = () => {
    setIsCalculateSuccessSnackbarOpen(false);
  };

  const handleUpdateSaleClose = () => {
    setIsupdateSaleOpen(false);
  };


  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);

  const handleDueDateChange = (value) => {
    setDueDate(value);
  };
  const handleInvoiceDateChange = (value) => {
    setInvoiceDate(value);
  };

  return (
    <div style={{ marginTop: '1.5rem', marginLeft: '0.5rem', minHeight: '100vh' }}>
      {loading ? (
        <LoadingSpinner />
      ) : (

        <div style={{ marginTop: '4rem', padding: '10px', width: '100rem', }}>
          <Form>
            <div>
              <Grid container style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
                <h5>Sales Information</h5>

                <Grid item xs={2}>
                  {/* <Controls.Input label="Search" onChange='' /> */}

                  <Stack direction="row" spacing={4} alignItems="flex-start">
                    <DateRangePicker
                      format='dd-MM-yyyy'
                      ranges={predefinedRanges}
                      placeholder="Select Date"
                      style={{ width: 210, marginTop: '0.6rem', marginLeft: '3rem' }}
                      onChange={handleDateRangeChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={2} style={{ marginLeft: '3rem' }}>

                  {/* <Controls.Dropdown
   label="Filter by Provider"
   options={providers.map((provider) => ({ value: provider, label: provider }))}
   onChange={(e) => setSelectedProvider(e.target.value)}
   value={selectedProvider}
 /> */}
                  <div>
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel sx={{ fontSize: '12px' }} id="demo-multiple-checkbox-label">
                        Filter by Provider
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={selectedProvider}
                        onChange={handleChange}
                        input={<OutlinedInput label="Filter by Provider" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        style={{ maxHeight: '35px', marginTop: '4px', backgroundColor: 'white' }}
                        sx={{ backgroundColor: 'white' }}
                      >
                        {providers.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={{
                              ...getStyles(name, selectedProvider, theme),
                              display: 'block',
                              width: 'fit-content',
                              fontSize: '12px', // Set the desired font size for dropdown options
                            }}
                          >
                            <Checkbox
                              sx={{
                                '& .MuiSvgIcon-root': {
                                  fontSize: 12, // Adjust the font-size to decrease the checkbox size
                                },
                              }}
                              checked={selectedProvider.indexOf(name) > -1}
                            />
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                </Grid>
                <Grid item xs={2} style={{ display: 'flex', flexDirection: 'row', marginLeft: '9rem' }}>
                  <Controls.Button
                    text="Calculate Commission"
                    style={{ padding: '20px', width: '8rem' }}
                    onClick={calculateSlabCommission}
                  />
              {isLoadingCalculateCommission && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
                  <Controls.Button
                    text="Update All"
                    style={{ padding: '20px', width: '8rem' }}
                    onClick={handleAllUpdateSale}
                  />
       {isLoadingUpdateSale && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
           
                  <>
                    <Controls.Button
                      text="Create Invoice"
                      style={{ padding: '20px', width: '6rem' }}
                      onClick={handleDialogOpen}
                    />

                    <Dialog open={openDialog} onClose={handleDialogClose}>
                      <DialogTitle>Create Invoice</DialogTitle>
                      <DialogContent>
                        <Grid container direction="column" spacing={2}>
                          <Grid item>
                            <label style={{ marginRight: '12px' }}>External Invoice ID:</label>
                            <input
                              type="text"
                              value={externalInvoiceId}
                              onChange={(e) => setExternalInvoiceId(e.target.value)}
                              style={{ width: '160px' }}
                            />
                          </Grid>

                          <Grid item>
                            <label style={{ marginRight: '3.3rem' }}>
                              Invoice Date:
                            </label>

                            <input
                              type="date"
                              value={invoiceDate}
                              onChange={(e) => handleInvoiceDateChange(e.target.value)}
                            />
                          </Grid>
                          <Grid item>
                            <label style={{ marginRight: '5rem' }}>Due Date:</label>
                            <input
                              type="date"
                              value={dueDate}
                              onChange={(e) => handleDueDateChange(e.target.value)}
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={handleDialogSubmit} color="primary">
                          Submit
                        </Button>
                      </DialogActions>
                    </Dialog>

                  </>
                </Grid>

              </Grid>
            </div>
            <Snackbar
              sx={{ marginLeft: '30rem' }}
              open={isCalculateSuccessSnackbarOpen}
              autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
              onClose={handleCalculateSnackbarClose}
              message="Calculated succesfully"
            />
            <Snackbar
              sx={{ marginLeft: '30rem' }}
              open={isSuccessSnackbarOpen}
              autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
              onClose={handleSnackbarClose}
              message="Invoice successfully Created"
            />
            <Snackbar
              sx={{ marginLeft: '30rem' }}
              open={isupdateSaleOpen}
              autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
              onClose={handleUpdateSaleClose}
              message="Updated Data successfully"
            />
            {/* <div style={{ marginLeft: '30rem', marginTop: '1rem' }}>
              <span>Total Commission:{totalCommissionSum}</span>
            </div> */}
            {salesData.length > 0 ? (
              <>
                <Box
                  sx={{
                    marginTop: '2rem',
                    width: '82%',
                    '& .custom-header': {
                      backgroundColor: 'rgb(234, 237, 240,1)',
                    },
                  }}
                >
                  <DataGrid
                    rowHeight={60}
                    rows={salesData}
                    columns={columns}
                    onRowClick={handleSaleRowClick}
                    pageSize={5}
                    getRowId={(row) => row.saleid}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box
                  sx={{
                    marginTop: '2rem',
                    width: '82%',
                    '& .custom-header': {
                      backgroundColor: 'rgb(234, 237, 240,1)',
                    },
                  }}
                >
                  <DataGrid
                    rows={filteredSalesData}
                    columns={columns}
                    onRowClick={handleSaleRowClick}
                    pageSize={5}
                    getRowId={(row) => row.saleid}
                  />
                </Box>
                <Typography style={{ marginLeft: '33rem', marginTop: '8rem' }} variant="subtitle1" gutterBottom>
                  No sales details available.
                </Typography>
              </>
            )
            }
          </Form>
        </div>
      )}
    </div>
  )
}

export default AvSales;
