import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import calendar from'./../../assests/icons/calendar.png';
import moment from 'moment';
export default function LifeCycleDateFieldEmpty(props) {
  const [value, setValue] = React.useState(null);
  const { label,error=null,onChange,disableFuture } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker 
        value={value}
        label={label} 
        onChange={onChange}
        disableFuture={disableFuture}
        format="DD-MM-YYYY"
        {...(error && {error:true,helperText:error})}
        sx={{backgroundColor:'rgb(234, 237, 240,1)',
        borderRadius:'5px', 
        "& .MuiInputLabel-root": { // Applying styles to the label
          fontSize: '12px', // You can adjust this value as needed
        },
        '& .MuiSvgIcon-root': {
          width: '24px', // Adjust the width of the icon to fit your image
          height: '24px', // Adjust the height of the icon to fit your image
          background: `url(${calendar})`, // Set the custom calendar image as the background
          backgroundSize: 'contain', // Adjust the background size as needed
          display: 'block', // Set the display to block to occupy the space
        },
        '& input': {
          width: '30', // Adjust the width of the input field
          height: '30', // Adjust the height of the input field
          padding: '12px', // Adjust the padding around the input content
          fontSize: '12px', // Adjust the font size of the input content
          alignContent:'center',
        },
      }}
      slotProps={{ textField: { size: 'small' } }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}