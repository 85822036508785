import React from 'react';
import { Card, CardContent, Grid, Typography, Button } from '@mui/material';
import plus from '../assests/icons/plus.png';
import edits from '../assests/icons/edits.png';
import { useNavigate } from 'react-router-dom';
import * as Rest from  './../services/restapi';
import axios from 'axios';
import fileicon from './../assests/icons/fileicon.png';
import './../App.css';

const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
  const day = date.getDate().toString().padStart(2, '0');
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date); // Abbreviated month name
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const amOrPm = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${day}-${month}-${year} ${formattedHours}:${formattedMinutes} ${amOrPm}`;
};

const formatCurrency = (value) => {
  return `₹${value?.toLocaleString('en-IN')}`;
};


const PolicyDetails = ({ customerData }) => {
  const navigate = useNavigate();
  const customerid = customerData?.customerid;
  const policyDetails = customerData?.policydetails || [];
  const token = localStorage.getItem("X-fiftyaccess-Token");

  const handleEdit = (policyid) => {
    // Make an API call using Axios
    axios.get(Rest.viewpolicy,{
      params:{
        policyid:policyid
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    }
  )
      .then(response => {
        // Navigate to the updateprospect page
        navigate(`/editpolicy/${policyid}`, { state: { updatePolicy: response?.data?.data } });
        // Handle the API response data
        console.log('API Response:', response?.data?.data);
      })
      .catch(error => {
        // Handle API errors
        console.error('API Error:', error);
      });
  }; 

  const openPDF = (file) => {
    {
      window.open(file, '_blank')
    }
  };

  return (
    <div>
     <Card style={{marginTop:'5px',marginBottom:'5px',height:'3rem'}}>
            <CardContent >
            <Typography variant="body6" color="text.secondary" >
            Policy Details
          </Typography>
       
          <img style={{marginLeft:'65.7rem'}}
            onClick={() => navigate(`/addpolicy/${customerid}`, { state: customerData })}
            src={plus}
            alt="plus"
            className="action-button-icon"
          />
          <Typography variant="body5" color="text.secondary">
            Add
          </Typography>
          </CardContent>
          </Card>
           {policyDetails.map((policy, index) => (
          <Card key={index} style={{ marginBottom: '0.3rem',backgroundColor: '#fffafa', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'  }}>
             <Typography style={{marginLeft:'73.5rem',paddingTop:'5px'}}variant="body5" color="primary">
             <img
           onClick={() => handleEdit(policy.policyid)}
            src={edits}
            alt="Edit"
            className="action-button-icon"
          />Edit
             </Typography>
            <CardContent style={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', gridTemplateRows: 'repeat(4, auto)', gap: '0.5rem' }}>
              <Typography  color="textSecondary" style={{ fontSize: '12px' }}>
                Policy ID: {' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {policy.policyid}
                            </span>
                        </Typography>
              
              <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                Provider: {' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {policy.provider}
                 </span>
              </Typography>
              <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                Policy Type: {' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {policy.policytype}
                 </span>
              </Typography>
             
              <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                Product: {' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {policy.product}
                 </span>
              </Typography>
   <Typography color="textSecondary" style={{ fontSize: '12px' }}>
      Start Date: {' '}
      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
        {formatDate(policy.policystartdate)}
      </span>
    </Typography>
    
    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
      End Date: {' '}
      <span style={{ color: 'purple', fontSize: '12px', fontWeight: 'bold' }}>
        {formatDate(policy.policyenddate)}
      </span>
    </Typography>
       <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                sub product: {' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {policy.subproduct}
                 </span>
              </Typography>
              <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                    Attachements{" "}
                    <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold', display: 'flex', flexDirection: 'row' }}>
                      {policy.attachments.map((file) => (
                        <>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {file.filetype}: <img onClick={() => openPDF(file.image)}
                              src={fileicon} alt="fileicon" className="file-icon" />
                          </div>
                        </>
                      ))}
                    </span>
                  </Typography>
              </CardContent>
          </Card>
        ))}
      </div>
    
  );
};
export default PolicyDetails;


