import React, { useState, useEffect, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Controls from '../components/controls/Controls';
import { Form } from '../components/useForm';
import { Box, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import edit from "../assests/icons/edit.png";
import update from "../assests/icons/update.png";
import opportunity from "../assests/icons/opportunity.png";
import showdetails from "../assests/icons/showdetails.png";
import axios from 'axios';
import * as Rest from  './../services/restapi';
import Dropdown from 'rsuite/Dropdown';
import 'rsuite/dist/rsuite.min.css';
import LoadingSpinner from '../components/controls/spinner'; // Import your LoadingSpinner component
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import {  makeStyles } from "@material-ui/core";
import { styled, useTheme } from "@mui/material/styles";

import {  FormControlLabel } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import MuiAppBar from '@mui/material/AppBar';
import FilterListIcon from '@mui/icons-material/FilterList';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start"
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'rgb(234, 237, 240,1)',
    borderRadius: '5px',
  },
  menuList: {
    backgroundColor: 'rgb(234, 237, 240,1)',
  },
  menuItem: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align all options to the left
    marginLeft: '10px',
  },
  sidebar: {
    padding: '10px',
    marginLeft: '10px',
    width: '200px',
  },
}));
function AllCustomer() {

  const [customer, setCustomers] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentStatusFilter, setCurrentStatusFilter] = useState('All');
  const [selectedEmployeeName, setSelectedEmployeeName] = useState('All');

  const [selectedRows, setSelectedRows] = useState([]);
  const [salesperson, setSalesPerson] = useState(''); 
  const [editData, setEditData] = useState(null);
  const [showDetails, setShowDetails] = useState(null);
  const rowHeight = 35;
  const navigate = useNavigate();
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [employeeOptions, setEmployeeOptions] = useState([]); 
  const employeeID = localStorage.getItem("EmployeeID");
  const [loading, setLoading] = useState(true); // Initialize loading state
const employeeName = localStorage.getItem("EmployeeName");
  const [limit, setLimit] = useState(10);
  const hasFetchedAll = useRef(false);
  const isInitialMount = useRef(true);
  const [checked, setChecked] = React.useState(["", "", ""]);
  const drawerWidth = 190;
  const [selectedFilters, setSelectedFilters] = useState([]);
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages,setTotalpages] = useState('')
  const [TotalRecords,setTotalRecords]= useState('')
  const [perPage, setPerPage] = useState(limit);

  const handlePageChange = (event,pageNumber) => {
    setCurrentPage(pageNumber);
    // You may perform data fetching or any action on page change here
  };

  const handlePerPageChange = (value) => {
    setPerPage(value); // Update perPage state when dropdown value changes
    // You can perform additional actions here based on the selected value
  };

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'side' })(
    ({ theme, side }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      ...(side && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
      position: 'relative',
    }),
  );
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'side',
  })(({ theme, side}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(side && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));


  const theme = useTheme();
   const [side, setOpen] = useState(false); // Initialize the sidebar state

 
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {

    setOpen(false);
    };
    const [salespersonChecked, setSalespersonChecked] = React.useState({
      salesperson: [],
    });
    const [salespersonLabels, setSalespersonLabels] = React.useState([]);
    const [prospectChecked, setProspectChecked] = React.useState({
      Status: [],
    });
    const [prospectstatusLabels, setProspectStatusLabels] = useState(['Active','Inactive','Assigned']);

    const handleParentChange = (event, parent) => {
      let selectedValues = [];
  
      if (parent === 'Status') {
        selectedValues = event.target.checked ? [...prospectstatusLabels] : [];
      } else {
        selectedValues = event.target.checked ? [parent] : [];
      }

      setProspectChecked({
        ...prospectChecked,
        [parent]: selectedValues
      });
    };
  
    const handleChildChange = (event, parent, childIndex) => {
      const newSelectedValues = [...prospectChecked[parent]];
  
      if (event.target.checked) {
        newSelectedValues.push(prospectstatusLabels[childIndex]);
      } else {
        const indexToRemove = newSelectedValues.indexOf(prospectstatusLabels[childIndex]);
        if (indexToRemove !== -1) {
          newSelectedValues.splice(indexToRemove, 1);
        }
      }
      
      setProspectChecked({
        ...prospectChecked,
        [parent]: newSelectedValues
      });
    };
    const handleSalespersonChange = (event, parent) => {
      let selectedValues = [];
  
      if (parent === 'salesperson') {
        selectedValues = event.target.checked ? [...salespersonLabels] : [];
      } else {
        selectedValues = event.target.checked ? [parent] : [];
      }
  
      setSalespersonChecked({
        ...salespersonChecked,
        [parent]: selectedValues
      });
    };
  
    const handleSalespersonChildChange = (event, parent, childIndex) => {
      const newSelectedValues = [...salespersonChecked[parent]];
  
      if (event.target.checked) {
        newSelectedValues.push(salespersonLabels[childIndex]);
      } else {
        const indexToRemove = newSelectedValues.indexOf(salespersonLabels[childIndex]);
        if (indexToRemove !== -1) {
          newSelectedValues.splice(indexToRemove, 1);
        }
      }
      setSalespersonChecked({
        ...salespersonChecked,
        [parent]: newSelectedValues
      });
    };
    useEffect(() => {
      axios.get(Rest.viewallemployees,{
        headers: {
            "X-fiftyaccess-Token": token
        }
      })
     
        .then((response) => {
          const options = response.data.data.map((employee) => ({
            value: employee.EmployeeID,
            label: `${employee.FirstName} ${employee.LastName}`,
          }));
          const EmpolyeeName = response.data.data.map((employee) => (`${employee.FirstName} ${employee.LastName}`));
          setEmployeeOptions(options);
          setSalespersonLabels(EmpolyeeName)
        })
        .catch((error) => console.error('Error fetching employee data:', error));
    }, []);
  
    const [expanded, setExpanded] = useState([]);
    const renderAssignCheckbox = (label, parent) => {
      return (
        <FormControlLabel
          key={`parent-${parent}`}
          label={label}
          control={
            <Checkbox
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 16, // Adjust the font-size to decrease the checkbox size
                },
              }}
              checked={prospectChecked[parent]?.length > 0}
              indeterminate={false}
              onChange={(event) => handleParentChange(event, parent)}
            />
          }
        />
      );
    };
      const renderParentCheckbox = (label, parent) => {
        return (
          <TreeItem
          nodeId={parent}
          label={
          <FormControlLabel
            key={`parent-${parent}`}
            label={label}
            control={
              <Checkbox
                checked={prospectChecked[parent]?.length > 0}
                indeterminate={false}
                onChange={(event) => handleParentChange(event, parent)}
                />
              }
            />
          }
        >
          {renderChildCheckboxes(parent)}
        </TreeItem>
      );
    };

    const renderChildCheckboxes = (parent) => {
      return prospectstatusLabels.map((childLabel, index) => (
        <TreeItem
          key={`child-${index}`}
          nodeId={`${parent}-${index}`}
          label={childLabel}
          sx={{marginLeft:'1rem'}}
          icon={
            <Checkbox
              checked={prospectChecked[parent].includes(childLabel)}
              onChange={(event) => handleChildChange(event, parent, index)}
            />
          }
        />
      ));
    };
    const renderSalespersonCheckbox = (label, parent) => {
      return (
        <TreeItem
          nodeId={parent}
          label={
            <FormControlLabel
              label={label}
              control={
                <Checkbox
                  checked={salespersonChecked[parent]?.length > 0}
                  onChange={(event) => handleSalespersonChange(event, parent)}
                />
              }
            />
          }
        >
          {renderSalespersonChildCheckboxes(parent)}
        </TreeItem>
      );
    };
  
    const renderSalespersonChildCheckboxes = (parent) => {
      return salespersonLabels.map((childLabel, index) => (
        <TreeItem
          key={`child-${index}`}
          nodeId={`${parent}-${index}`}
          label={childLabel}
          sx={{marginLeft:'1rem'}}
          icon={
            <Checkbox
              checked={salespersonChecked[parent].includes(childLabel)}
              onChange={(event) => handleSalespersonChildChange(event, parent, index)}
            />
          }
        />
      ));
    };
  
    const handleFilterChange = (event) => {
      setSelectedFilters(event.target.value);
    };
    const renderSidebar = (label, parent) => {
      return (
        <div className={classes.sidebar}>
          
         
            <div>
              <h6>Filter By</h6>
              <FormControl component="fieldset">
              <TreeView
                aria-label="Status Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderParentCheckbox('Status', 'Status')}
              </TreeView>
                <TreeView
                  aria-label="Salesperson Checkbox"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expanded}
                  onNodeToggle={(event, nodes) => setExpanded(nodes)}
                  >
                  {renderSalespersonCheckbox('Sales Person', 'salesperson')}
                </TreeView>
              </FormControl>
            </div>
     
          
               </div>
      );
    }; 
    const selectedEmployeeNames = salespersonChecked.salesperson;
    const selectedEmployeeIDs = selectedEmployeeNames.map((selectedName) => {
      const selectedEmployee = employeeOptions.find((option) => option.label.trim() === selectedName.trim());
      return selectedEmployee ? selectedEmployee.value : null;
    });
  const handleRowSelect = (customerId) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [customerId]: !prevSelectedRows[customerId],
    }));
   
  };
 
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;

    const updatedSelectedRows = {};

    if (isChecked) {
      filteredData.slice(0, limit).forEach((customer) => {
        updatedSelectedRows[customer.customerid] = true;
      });
    }

    setSelectedRows(updatedSelectedRows);
  };
  const handleAssignClick = () => {
    const selectedCustomers = filteredData?.filter((customer) => selectedRows[customer.customerid])
      .map((customer) => ({
        ...customer,
        AssignName: salesperson,
      }));

    navigate('/customerassign', { state: { selectedCustomers, salesperson } });
  };
  const handleAssignClickone = () => {

    console.log("clicking")

    navigate('/customerassign');
  };



  // Update filtered data based on search query
    useEffect(() => {
      if ( selectedOption === "All") {
        async function fetchAll() {

          setLoading(true);
          const selectedValues = {
            Status: prospectChecked?.Status,
            salesperson: selectedEmployeeIDs, 
            limit:perPage,
            page:currentPage         
          }
          try {
   
            const response = await axios.post(Rest.viewallcustomers,selectedValues, {
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            });
            if (response?.data?.code==="S001") {
              const customerWithIds = response?.data?.Data?.data?.map((customer, index) => ({
                ...customer,
                employeedetails: customer?.employeedetailsdata,
                id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
              }));
              setTotalpages(response?.data?.Data?.pages);
              setTotalRecords(response?.data?.Data?.TotalRecords)
              setLimit(response?.data?.Data?.next?.limit)
              setCustomers(customerWithIds);
              setFilteredData(customerWithIds);
              setTimeout(() => {
                setLoading(false); // Data has loaded, stop the spinner
              }, 200);
            } else{
              setFilteredData([]);
              alert(response?.data?.msg  || response?.data?.data)
              setLoading(false);
            }
          } catch (err) {
            setFilteredData([]);

            alert("Error: " + err.message);
            setLoading(false);
          }
        }
        fetchAll();
        /* hasFetchedAll.current = true; */
      } else if (selectedOption === "Assigned to Me") {
        const fetchAssignedtome = async () => {
          setLoading(true); 
          const selectedValues = {
            EmployeeID:employeeID,
            Status: prospectChecked?.Status,
            salesperson: selectedEmployeeIDs, 
            limit:perPage,
            page:currentPage         
          }
          try {
            const response = await axios
            .post( Rest.viewCustomerassignedtome,selectedValues,{
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              }
            });
              if (response?.data?.code==="S001") {
            // Assuming the API response contains an array of data
            const formattedData = response?.data?.data?.data?.map((customer, index) => ({
              ...customer,
              employeedetails:customer.employeedetailsdata,
              id: index + 1, // Generating a simple unique ID for each row
            }));
            setTotalpages(response?.data?.data?.pages);
            setTotalRecords(response?.data?.data?.TotalRecords)
            setLimit(response?.data?.data?.next?.limit)
            setCustomers(formattedData);
            setFilteredData(formattedData);
            setTimeout(() => {
              setLoading(false); // Data has loaded, stop the spinner
            }, 200);
          } else{
            setFilteredData([])
            alert(response?.data?.msg  || response?.data?.data)
             
            setLoading(false);
          }
          } catch (err) {
            setFilteredData([])
            alert("Error: " + err.message);
        
            setLoading(false);
          }
        };
        fetchAssignedtome();

      } else if (selectedOption === "To be Contacted Today") {
        const fetchtobecontacted = async () => {
          setLoading(true);
          const selectedValues = {
            EmployeeID:employeeID,
            Status: prospectChecked?.Status,
            salesperson: selectedEmployeeIDs, 
            limit:perPage,
            page:currentPage         
          }
          try {
            const response = await axios
            .post( Rest.employeetoBeContactToday,selectedValues,{
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
              }
              );
              if (response?.data?.code==="S001") {
            const customersWithIds = response?.data?.data?.data?.map((customer, index) => ({
              ...customer,
              employeedetails:customer.employeedetailsdata,
              id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
            }));
            setTotalpages(response?.data?.data?.pages);
              setTotalRecords(response?.data?.data?.TotalRecords)
              setLimit(response?.data?.data?.next?.limit)
            setCustomers(customersWithIds);
            setFilteredData(customersWithIds);
            setTimeout(() => {
              setLoading(false); // Data has loaded, stop the spinner
            }, 200); 
          } else{
            setFilteredData([])
            alert(response?.data?.msg  || response?.data?.data)
            setLoading(false);
          }
          } catch (err) {
            setFilteredData([])
            alert("Error: " + err.message);
            
            setLoading(false);
          }
        };
        fetchtobecontacted();
      } else if (selectedOption === "Unassigned") {
        const fetchUnassigned = async () => {
          setLoading(true); 
          const selectedValues = {
            EmployeeID:employeeID,
            Status: prospectChecked?.Status,
            salesperson: selectedEmployeeIDs, 
            limit:perPage,
            page:currentPage         
          }
          try {
            const response = await axios.post(Rest.unAssignedCustmers,selectedValues,{
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            });
            if (response?.data?.code==="S001") {
            const customersWithIds = response?.data?.Data?.data?.map((customer, index) => ({
              ...customer,
              id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
            }));
            setTotalpages(response?.data?.Data?.pages);
              setTotalRecords(response?.data?.Data?.TotalRecords)
              setLimit(response?.data?.Data?.next?.limit)
            setCustomers(customersWithIds);
            setFilteredData(customersWithIds);
            setTimeout(() => {
              setLoading(false); // Data has loaded, stop the spinner
            }, 200);
          } else{
            setFilteredData([])
            alert(response?.data?.msg  || response?.data?.data)
            setLoading(false);
          }
          } catch (err) {
            setFilteredData([])
            alert("Error: " + err.message);
         
            setLoading(false);
          }
        };
        fetchUnassigned();
      }
    }, [selectedOption,prospectChecked,salespersonChecked,perPage,currentPage]);

     // Update filtered data based on search query
     useEffect(() => {
      const filteredResults = customer?.filter(customer => {
        const customerid = customer.customerid ? customer.customerid.toLowerCase() : '';
        const firstName = customer.firstname ? customer.firstname.toLowerCase() : '';
        const emailId = customer.emailid ? customer.emailid.toLowerCase() : '';
        const phoneNumber = customer.phonenumber ? customer.phonenumber.toString() : '';
    
        return (
          customerid.includes(searchQuery.toLowerCase()) ||
          firstName.includes(searchQuery.toLowerCase()) ||
          emailId.includes(searchQuery.toLowerCase()) ||
          phoneNumber.includes(searchQuery)
        );
      });
      setFilteredData(filteredResults);
    }, [searchQuery, customer]);

    // useEffect(() => {
    //   // API endpoint and request body
    //   const requestBody = {
    //     phonenumber: parseInt(searchQuery),
    //     /* startDate:startDate,
    //     endDate:endDate */
    //   };
  
    //   // Making the API call using axios
    //   axios.post(Rest.customerwithNumber, requestBody)
    //     .then(response => {
    //       const formattedData = response?.data?.data.map((item, index) => ({
    //         ...item,
    //         id: index + 1 // Generating an id (you can adjust this according to your data structure)
    //       }));
          
    //       setFilteredData(formattedData);
    //       console.log('API Response:', formattedData);
    //     })
    //     .catch(error => {
    //       // Handle errors, e.g., show an error message
    //       console.error('Error fetching data:', error);
    //     });
    // }, [searchQuery, customer]);
  const columns = [
    {
      field: 'checkbox',
      width:'65',
      headerClassName:'custom-header',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerCheckboxProps: {
        onChange: handleSelectAll,
      },
      renderHeader: (params) => (
        <div>
          <input
            type="checkbox"
            checked={Object?.keys(selectedRows)?.length === filteredData?.length}
            onChange={handleSelectAll}
          />
        </div>
      ),
      renderCell: (params) => (
        <div>
          <input
            type="checkbox"
            checked={selectedRows[params.row.customerid] || false}
            onChange={() => handleRowSelect(params.row.customerid)}
          />
        </div>
      ),
    },
    { field: 'customerid', headerName: 'Customer ID', width:130 ,headerClassName:'custom-header'}, // Adding an 'id' column
    {
      field: 'name',
      headerName: 'Name',
      width:200,
      headerClassName:'custom-header',
      valueGetter: (params) => `${params.row.firstname} ${params.row.lastname}`,
    },
    { field: 'emailid', headerName: 'Email', width:150 ,headerClassName:'custom-header'},
    { field: 'phonenumber', headerName: 'Phone Number', width:150 ,headerClassName:'custom-header'},
    { field: 'status', headerName: 'Status', width:120 ,headerClassName:'custom-header'},
    { field: 'salespersonname', headerName: 'Sales Person Name', width: 150, headerClassName: 'custom-header',
    renderCell: (params) => {
      const { employeefirstname, employeelastname } = params.row;
      return (
        <div>
          {employeefirstname} {employeelastname}
        </div>
      );
    },
  },
    {
        field: 'actions', // Custom actions column
        headerName:'Actions',
        headerClassName:'custom-header',
        width:180,
        renderCell: (params) => (
          <div>
            <div className="action-buttons">
                <Tooltip title="Edit">
                    <div onClick={() => handleEdit(params.row)}>
                        <img src={edit} alt="Edit" className="action-button-icon" />
                    </div>
                </Tooltip>
                <Tooltip title="Show Details">
                <div onClick={() => handleShowDetails(params.row)} text="Show Details">
                    <img
                    src={showdetails}
                    alt="Show Details"
                    className="action-button-icon"
                    />
                </div>
                </Tooltip>
                <Tooltip title="Update Status">
                <div onClick={() => handleUpdateStatus(params.row)}>
                    <img src={update} alt="Update" className="action-button-icon" />
                </div>
                </Tooltip>
                <Tooltip title="Add Lead">
                <div onClick={() => handleOpportunityDetails(params.row)}>
                    <img
                    src={opportunity}
                    alt="Add Lead"
                    className="action-button-icon"
                    />
                </div>
                </Tooltip>
            </div>
          </div>
        ),
      },
  ];

      const handleEdit = (row) => {
        const customerid = row.customerid
        // Make an API call using Axios
        axios.get(Rest.viewcustomer,{
          params:{
            customerid:customerid
          },
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        }
      )
          .then(response => {
            // Navigate to the updateprospect page
            navigate(`/updatecustomer/${customerid}`, { state: { updateCustomer: response?.data?.data } });
            // Handle the API response data
            console.log('API Response:', response?.data?.data);
          })
          .catch(error => {
            // Handle API errors
            console.error('API Error:', error);
          });
      }; 
    
      const handleShowDetails = (row) => {
        navigate(`/viewCustomer/${row.customerid}`, {state: {customer: row}});
      };
    
      const handleUpdateStatus = (row) => {
        navigate(`/customerinteraction/${row.customerid}`, {state: {customer: row}});
      };
    
      const handleOpportunityDetails = (row) => {
        navigate(`/customeropportunity/${row.customerid}`, {state: {customer: row}});
      };

      useEffect(() => {
        if (isInitialMount.current) {
        axios.get(Rest.viewallemployees,{
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        })
          .then((response) => {
            const options = response.data.data.map((employee) => ({
              value: employee.EmployeeID,
              label: `${employee.FirstName} ${employee.LastName}`,
            }));
            setEmployeeOptions(options);
          })
          .catch((error) => console.error('Error fetching employee data:', error));
          isInitialMount.current = false;
    }
      }, []);

      useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }, []);
    
      const filterByStatus = (status) => {
        setCurrentStatusFilter(status);
        // Filter the data based on the selected status
        const filteredDataByStatus = customer?.filter((customer) => {
          if (status === 'All') {
            return true; // Show all data when "All" is selected
          } else {
            return customer.status === status;
          }
        });
        setFilteredData(filteredDataByStatus);
      };
      const filterByEmployeeName = (employeeName) => {
        setSelectedEmployeeName(employeeName);
      
        // Filter the data based on the selected employee name
        const filteredDataByEmployee = customer?.filter((customer) => {
          if (employeeName === 'All') {
            return true; // Show all data when "All" is selected
          } else {
            return customer.employeedetails.some((detail) =>
              `${detail.FirstName} ${detail.LastName}` === employeeName
            );
          }
        });
        setFilteredData(filteredDataByEmployee);
      };
      
  return (
    <div style={{ marginTop: '1.5rem', marginLeft: '0.5rem', minHeight: '100vh' }}>
    {loading ? (
      <LoadingSpinner />
    ) : (
    <div >
      <Sidebar  setSelectedOption={setSelectedOption}/>
    <div style={{marginTop:'3.5rem',marginLeft:'8.5rem'}}>
      <Form>
      <Grid
        container
        alignItems="center"
      >
        <Grid item xs={3.5}>
        <h5>Customer Information</h5>
        </Grid>
        <Grid item xs={2.5} style={{display:'flex',flexDirection:'column',marginLeft: '10rem'}}>
  <TextField
    sx={{
      height: '40px',
      backgroundColor: '#ffff',
      '& .MuiOutlinedInput-root': {
        height: 40,
        borderRadius: '8px',
        '& fieldset': {
          borderColor: 'black', // Set the border line color to black
        },
      },
    }}
    InputLabelProps={{
      style: {
        fontSize: '14px',
        color: 'black', // Set the color of the label text to black
      },
    }}
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Grid>
        {/* <Controls.Dropdown 
              label="Filter by Status" 
              options={filterbystatus} 
              onChange=''
            /> */}
            
          <Controls.Button text="Add Customer" component={Link} to="/addcustomer" />
       
        <Grid ml={10}style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem', marginTop: '1rem' }} >
       
          <Controls.Button
       text="Assign"
  onClick={handleAssignClick}
  disabled={Object.values(selectedRows).every((value) => !value)}
  
/>
<Link component={Link} to="/customerassign" style={{ fontSize: '12px',marginLeft: '15px', cursor: 'pointer' }}>Bulk Assign</Link>


</Grid>

<DrawerHeader>
        <button
          onClick={handleDrawerOpen}
        style={{
          backgroundColor: '#914572',
          display: 'flex',
          justifyContent: 'center',  // Center horizontally
          alignItems: 'center',      // Center vertically
          borderRadius: '8px',
          // marginLeft: '14rem',
          // marginTop: '2.6rem',
          height: '35px',
          fontSize: '14px',
          // fontWeight: 'bold',  // 'bold' is the correct property for font weight
          color: '#fff',
          padding: '15px',
        }}
      >
        <span>
          <FilterListIcon />
        </span>
        <span>Filter </span>
      </button>
      </DrawerHeader> 
      <Main side={side}></Main>
      </Grid>
      </Form>
      <label style={{marginLeft:'58.3rem'}}>Total Records : {TotalRecords}</label>
      {filteredData.length> 0 ?(
        <>
      {filteredData &&  (
        <Box
        sx={{
        '& .custom-header': {
        backgroundColor: 'rgb(234, 237, 240,1)',
        },
      }}
    >
      <DataGrid 
       rowHeight={rowHeight}
      rows={filteredData} columns={columns} pageSize={10} 
      onSelectionModelChange={handleRowSelect}
      onSelectMenuChange={selectedOption}
      hideFooterPagination={true}
      />
      <div style={{marginLeft:'38rem',display:'flex',flexDirection:'row'}}>
            <label htmlFor="itemsPerPage">Leads per page:</label>
              <select id="itemsPerPage" value={limit} onChange={(e) => handlePerPageChange(e.target.value)}>
              <option value="10">10</option>
              <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
                    <Stack spacing={2}>
                      <Pagination 
                      count={totalPages} 
                      page={currentPage}
                      onChange={handlePageChange}
                      />
                    </Stack>
                      </div>
      </Box>
      )}
      </>
      ):(
        <>
        <Box
        sx={{
        marginTop:'2rem',
        '& .custom-header': {
        backgroundColor: 'rgb(234, 237, 240,1)',
        },
      }}
    >
      <DataGrid 
       rowHeight={rowHeight}
      style={{marginTop:'3rem'}} rows={filteredData} columns={columns} pageSize={10} 
      onSelectionModelChange={handleRowSelect}
      onSelectMenuChange={selectedOption}
      />
        <Typography style={{marginLeft:'25rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
          No customer details available.
        </Typography>
        </Box>
        </>
      )}
      <Drawer
        width="30%"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          justifyContent: 'flex-end',
          height: '100%',
          padding: 2,
          width: drawerWidth,
          flexShrink: 0,
         
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#f5f5f5' },
        }}
        variant="persistent"
        anchor="right"
        open={side}
      > 
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} style={{marginTop:'55px'}}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
         <List >
              <ListItem>
                {renderSidebar()}
              </ListItem>
          </List>
      </Drawer>
   
      <DrawerHeader />

    </div>
    </div>
    )}
    </div>

  );
}

export default AllCustomer;
