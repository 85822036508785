import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import 'typeface-poppins';
import { StyledEngineProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    // Customize your color palette here
    primary: {
      main: '#f0f5fff', // Example primary color
    },
    background: {
      default: '#f5f5f5', // Example background color for the content area
      header: '#333',     // Example background color for the header
    },
  },
  typography: {
    // Customize your typography here
    fontFamily: 'Poppins, Arial, sans-serif' ,// Example font family
  },
  // You can customize more theme settings here
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter >
    <React.StrictMode>
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <StyledEngineProvider injectFirst>
    <App />
    </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>
  </BrowserRouter>

);
