import React,{useState} from 'react'
import Controls from '../components/controls/Controls';
import { Grid, Snackbar } from '@mui/material';
import { Form } from '../components/useForm'
import { useNavigate,useLocation } from 'react-router';
import axios from 'axios';
import LoadingSpinner from '../components/controls/spinner'; // Import your LoadingSpinner component
import * as Rest from './../services/restapi';

const addresstype = [
  { value: 'Home', label: 'Home' },
  { value: 'Office', label: 'Office' },
  { value: 'Other', label: 'Other' }
];

const referraltype = [
   { value: 'Individual', label: 'Individual' },
   { value: 'Organization', label: 'Organization' },

 ];

const AddReferral = () => {
  const location = useLocation();
  const prospect = location?.state?.prospect;
  console.log("prospect",prospect)
  const [referralValues, setReferralValues] = useState(
   {
   referraltype:'',
   firstname:'',
   lastname:'',
   emailid:'',
   phonenumber:'',
   addressdetails:{
      addresstype:"Home",
       addressline1:"",
       addressline2:'',
       city:'',
       district:'',
       state:'',
       pincode:'',
       country:'India',
   }
  }
  );
  const navigate= useNavigate();
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState('');
console.log("referralValues",referralValues)
const [pinCodeError, setPinCodeError] = useState('');
const [loading, setLoading] = useState(false)
const token = localStorage.getItem("X-fiftyaccess-Token");
//   const handleInputChange = (event) => {
//    const { name, value } = event.target;
//    setReferralValues({
//      ...referralValues,
//      [name]: value,
//    });
//  };

//  const handleAddressDetailsChange = (event) => {
//   const { name, value } = event.target;
//   setReferralValues((prevValues) => ({
//     ...prevValues,
//     addressdetails: {
//       ...prevValues.addressdetails,
//       [name]: value,
//     },
//   }));
// };

const handleInputChange = (event) => {
  const { name, value } = event.target;

  // Validate and sanitize the phone number input
  if (name === 'phonenumber') {
    const sanitizedValue = value.replace(/\D/g, ''); // Remove non-digit characters
    const limitedValue = sanitizedValue.substring(0, 10);

    if (limitedValue.length < 10) {
      // Set the error message if the phone number is not 10 digits
      setPhoneNumberError('Please enter a 10-digit phone number');
    } else {
      // Clear the error message if the phone number is valid
      setPhoneNumberError('');
    }

    setReferralValues({
      ...referralValues,
      [name]: limitedValue,
    });
  } else {
    setReferralValues({
      ...referralValues,
      [name]: value,
    });
  }
};


const handleAlphabeticInputChange = (event, fieldName) => {
  const { name, value } = event.target;

  // Use a regular expression to allow only alphabetic characters and spaces
  const sanitizedValue = value.replace(/[^a-zA-Z\s]/g, '');

  setReferralValues((prevValues) => ({
    ...prevValues,
    addressdetails: {
      ...prevValues.addressdetails,
      [fieldName]: sanitizedValue,
    },
  }));
};

const handleAddressDetailsChange = (event) => {
  const { name, value } = event.target;

  if (name === 'pincode') {
    // Validate and sanitize the pin code input
    const sanitizedValue = value.replace(/\D/g, ''); // Remove non-digit characters
    const limitedValue = sanitizedValue.substring(0, 6); // Limit to 6 digits

    if (sanitizedValue.length < 6) {
      // Set the error message if the pin code is longer than 6 digits
      setPinCodeError('Please enter a 6-digit pin code');
    } else {
      // Clear the error message if the pin code is valid
      setPinCodeError('');
    }

    setReferralValues((prevValues) => ({
      ...prevValues,
      addressdetails: {
        ...prevValues.addressdetails,
        [name]: limitedValue,
      },
    }));
  } else {
    setReferralValues((prevValues) => ({
      ...prevValues,
      addressdetails: {
        ...prevValues.addressdetails,
        [name]: value,
      },
    }));
  }
};


 const handleReferralTypeChange = (event) => {
   setReferralValues({
     ...referralValues,
     referraltype: event.target.value,
   });
 };

 const handleAddressTypeChange = (event) => {
   setReferralValues({
     ...referralValues,
     addressdetails: {
       ...referralValues.addressdetails,
       addresstype: event.target.value,
     },
   });
 };

 const handleSnackbarClose = () => {
  setIsSuccessSnackbarOpen(false);
};
const handleSubmit = async (event) => {
  event.preventDefault();

  try {
    setLoading(true);

    const response = await axios.post(Rest.addreferralpartner,
      referralValues,
      {
        headers: {
          'X-fiftyaccess-Token': token,
          'Content-Type': 'application/json',
        },
      }
    );

    const data = response.data;
    console.log('data', data);

    if (data.code === 'S001') {
      setIsSuccessSnackbarOpen(true);

      setTimeout(() => {
        setLoading(false);
        navigate('/addlead',{state:{prospectdetails:prospect}});
      }, 2000);
    } else {
      alert(data?.msg || response?.data?.data);
    }
  } catch (err) {
    alert('Error: ' + err.message);
  
  }finally {
    setLoading(true); // Set loading to false after the API request is completed (success or failure)
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }
};


    return (
      <div style={{ marginTop: '1.5rem', marginLeft: '0.5rem', minHeight: '100vh' }}>
    
      <div style={{marginTop:'5rem'}}>
        <h4>Reference</h4>
        <Form onSubmit={handleSubmit}>
            <Grid container>
               <Grid item xs={4}>
                  <Controls.Dropdown 
                     label="Referral Type"
                     options={referraltype}
                     value={referralValues.referraltype}
                     onChange={handleReferralTypeChange}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Controls.Input 
                     label="First Name"
                     name="firstname"
                     value={referralValues.firstname}
                     onChange={handleInputChange}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Controls.Input 
                     label="Last name"
                     name="lastname"
                     value={referralValues.lastname}
                     onChange={handleInputChange}
                  />
               </Grid>
               <Grid item xs={4}>
               <Controls.Input
          label="Phone Number"
          name="phonenumber"
          value={referralValues.phonenumber}
          onChange={handleInputChange}
        />
        {/* Display the error message */}
        <div style={{ color: 'red',fontSize:'10px' }}>{phoneNumberError}</div>
               </Grid>
               <Grid item xs={4}>
                  <Controls.Input 
                     label="Email ID"
                     name="emailid"
                     value={referralValues.emailid}
                     onChange={handleInputChange}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Controls.Dropdown 
                     label="Address Type"
                     options={addresstype}
                     value={referralValues.addressdetails.addresstype}
                     onChange={handleAddressDetailsChange}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Controls.Input 
                     label="Address Line1"
                     name="addressline1"
                     value={referralValues.addressdetails.addressline1}
                     onChange={handleAddressDetailsChange}
                  />
               </Grid>
               <Grid item xs={4}>
                  <Controls.Input 
                     label="Address Line2"
                     name="addressline2"
                     value={referralValues.addressdetails.addressline2}
                     onChange={handleAddressDetailsChange}
                  />
               </Grid>
               <Grid item xs={4}>
                 
                    <Controls.Input
                    label="City"
                    name="city"
                    value={referralValues.addressdetails.city}
                    onChange={(event) => handleAlphabeticInputChange(event, "city")}
                  />
               </Grid>
               <Grid item xs={4}>
               <Controls.Input
          label="District"
             name="district"
              value={referralValues.addressdetails.district}
               onChange={(event) => handleAlphabeticInputChange(event, "district")}
              />
               </Grid>
               <Grid item xs={4}>
               <Controls.Input
               label="State"
               name="state"
               value={referralValues.addressdetails.state}
              onChange={(event) => handleAlphabeticInputChange(event, "state")}
  />
               </Grid>
               <Grid item xs={4}>
               <Controls.Input
          label="Pin Code"
          name="pincode"
          value={referralValues.addressdetails.pincode}
          onChange={handleAddressDetailsChange}
        />
        {/* Display the pin code error message */}
        <div style={{ color: 'red',fontSize:'10px' }}>{pinCodeError}</div>
               </Grid>
               <Grid item xs={4}>
                  <Controls.Input 
                     label="Country"
                     name="country"
                     value={referralValues.addressdetails.country}
                     onChange={(event) => handleAlphabeticInputChange(event, "country")}
  
                  />
               </Grid>
            </Grid>
            <div style={{marginLeft:'66rem'}}>
              <Controls.Button
               text="Save"
               type="submit"
              />
               {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
              <Controls.Button
               text="Cancel"
               onClick={()=>{navigate(-1)}}
              />
          
            </div>
        </Form>
        <Snackbar
        sx={{marginLeft:'30rem'}}
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
        onClose={handleSnackbarClose}
        message="Data successfully added"
      />
    </div>
  
      </div>
    );
  };
  
  export default AddReferral;
