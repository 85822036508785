
import React, { useEffect, useRef, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Grid, TextField,Checkbox } from '@mui/material';
import Controls from '../components/controls/Controls';
import { format } from 'date-fns';
import axios from 'axios';
import { Typography } from '@material-ui/core';
import { Form } from '../components/useForm';
import * as Rest from  './../services/restapi';
import LoadingSpinner from '../components/controls/spinner'; 
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment';

const filterbystatus = [
  { value: 'period', label: 'Given Period' },
  { value: 'leadmanager', label: 'Lead Manager' },
  { value: 'provider', label: 'Provider' },
];

const formatCurrency = (value) => {
  return `₹${value?.toLocaleString('en-IN')}`;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 200,
//       display:'row',
//       backgroundColor:'#f5f5f5'
//     },
//   },
// };
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 'auto', // Set this to 'auto' or a specific value to limit the height
      width: 120,
      display: 'row',
      backgroundColor: 'white'
    },
  },
};

function PolicyDataGrid() {
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [policies, setPolicies] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State to store the search query
  const rowHeight = 35;
  const [loading, setLoading] = useState(true); 
  const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const theme = useTheme();
  const isProviderMount = useRef(true);
  const isPolicyMount = useRef(true);
  const [searchableFields] = useState([
    'policyid',
    'provider',
    'product',
    'subproduct',
    'policytype',
    'policystartdate',
    'policyenddate',
    'premiumpayfrequency',
    'odpremium',
    'addonpremium',
    'thirdpartypremium',
    'netpremium',

  ]);

  function getStyles(providers, selectedProvider, theme) {
    return {
      fontWeight:
      selectedProvider.indexOf(providers) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
    const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedProvider(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];

  const handleDateRangeChange = (date) => {
    if (Array.isArray(date) && date.length === 2) {
      const stateDate = moment(date[0]).format("DD-MM-YYYY");
      const endDate = moment(date[1]).format("DD-MM-YYYY");
      setStartDate(stateDate);
      setEndDate(endDate);
    } else {
 
    }
    // fetchDataWithinDateRange(start, end);  
  };

  useEffect(() => {
    if(isProviderMount.current){
    axios
      .get(Rest.ViewAllProvider, {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
      .then((response) => {
        const providerData = response?.data?.Data || [];
 
        // Extract provider names from the response data
        const providerNames = providerData.map((provider) => provider.provider);
 
        // Filter the unique provider names
        const uniqueProviders = [...new Set(providerNames)];
 
        setProviders(uniqueProviders);
      })
      .catch((error) => {
        console.error('Error fetching providers:', error);
      });
      isProviderMount.current=false;
    }
  }, []);

  useEffect(() => {
   /*  if(isPolicyMount.current){ */
    const fetchData = async () => {
      try {
        const response = await axios.get(Rest.viewallpolicies,{
            params: {
              startdate: startDate,
              enddate: endDate,
              provider: selectedProvider
            },
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        });
        if(response?.data?.code==="S001"){
        const formattedData = response?.data?.data.map((policy) => ({
          ...policy,
          id: policy.policyid,
          subpremiums: policy.subpremiums,
        }));
        setPolicies(formattedData); // Assuming the API response is an array of policies
        } else{
          setPolicies([]);
          alert(response?.data?.msg || response?.data?.data);
        }
      } catch (error) {
        alert("Error: " + error.message);
        setPolicies([]);
      }
    };

    fetchData();
   /*  isPolicyMount.current=false;
  } */
  }, [startDate,endDate,selectedProvider]);

  const filterPolicies = () => {
    if (!searchQuery) {
      return policies;
    }
    return policies.filter((policy) => {
      const lowerCaseQuery = searchQuery.toLowerCase();
      return searchableFields.some((field) =>
        policy[field]?.toString()?.toLowerCase().includes(lowerCaseQuery)
      );
    });
  };

  const filteredPolicies = filterPolicies();

  const columns = [
    { field: 'policyid', headerName: 'Policy ID', width: 110,headerClassName:'custom-header' },
    { field: 'policynumber', headerName: 'Policy Number', width: 130,headerClassName:'custom-header' },
    { field: 'provider', headerName: 'Provider', width: 150,headerClassName:'custom-header' },
    { field: 'product', headerName: 'Product', width: 130,headerClassName:'custom-header' },
    { field: 'subproduct', headerName: 'Subproduct', width: 150,headerClassName:'custom-header' },
    { field: 'policytype', headerName: 'Policy Type', width: 150,headerClassName:'custom-header'},
    { 
      field: 'policystartdate', headerName: 'Start Date', width: 120,
      headerClassName:'custom-header',
      valueFormatter: (params) => {
        const startDateInSeconds = params.value;
        if (startDateInSeconds === null || startDateInSeconds === undefined) {
          return null; // Handle null or undefined values
        }
  
        const startDateMilliseconds = startDateInSeconds * 1000;
        return format(new Date(startDateMilliseconds), 'dd-MM-yyyy');
      },
    },
    { 
      field: 'policyenddate', headerName: 'End Date', width: 120,
      headerClassName:'custom-header',
      valueFormatter: (params) => {
        const endDateInSeconds = params.value;
        if (endDateInSeconds === null || endDateInSeconds === undefined) {
          return null; // Handle null or undefined values
        }
  
        const endDateMilliseconds = endDateInSeconds * 1000;
        return format(new Date(endDateMilliseconds), 'dd-MM-yyyy');
      },
    },
    { field: 'premiumpayfrequency', headerName: 'Premium Pay Frequency', width: 200,headerClassName:'custom-header' },
    { field: 'OD Premium', headerName: 'OD Premium', width: 100,headerClassName:'custom-header',
    align: 'right',
    renderCell: (params) => {
      const { subpremiums } = params.row;
      return subpremiums && subpremiums["OD Premium"]
        ? formatCurrency(subpremiums["OD Premium"])
        : '-';
    },
  },
  { field: 'Third-Party', headerName: 'TP Premium', width: 100,headerClassName:'custom-header',
  align: 'right' ,
  renderCell: (params) => {
    const { subpremiums } = params.row;
    return subpremiums && subpremiums["Third-Party"]
      ? formatCurrency(subpremiums["Third-Party"])
      : '-';
  },
},
  {
    field: 'Add-on Premium',
    headerName: 'ADD-ON Premium',
    width: 140,
    headerClassName: 'custom-header',
    align: 'right',
    renderCell: (params) => {
      const { subpremiums } = params.row;
      return subpremiums && subpremiums["Add-on Premium"]
        ? formatCurrency(subpremiums["Add-on Premium"])
        : '-';
    },
  },

    { field: 'Base Premium', headerName: 'Base Premium', width: 120,headerClassName:'custom-header',
    align: 'right',
    renderCell: (params) => {
      const { subpremiums } = params.row;
      return subpremiums && subpremiums["Base Premium"]
        ? formatCurrency(subpremiums["Base Premium"])
        : '-';
    },
  },
    { field: 'netpremium', headerName: 'Net Premium', width: 120,headerClassName:'custom-header',
    valueFormatter: (params) => formatCurrency(params.value),
    align: 'right' },
    { field: 'discount', headerName: 'Discount', width: 150,headerClassName:'custom-header'},
   
  ];
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);
  
  
  return (
    <div style={{ marginTop: '1.5rem', marginLeft: '0.5rem', minHeight: '100vh' }}>
      {loading ? (
        <LoadingSpinner />
      ):(
    <div style={{marginTop:'3.5rem',padding:'10px'}}>
      <Form>
      <Grid container style={{ display: 'flex', flexDirection: 'row', gap: '10rem' }}>
      <h5>Policy Information</h5>
                <Grid item xs={2}>
                  {/* <Controls.Input label="Search" onChange='' /> */}
 
                  <Stack direction="row" spacing={4} alignItems="flex-start">
                    <DateRangePicker
                    format='dd-MM-yyyy'
                      ranges={predefinedRanges}
                      placeholder="Select Date"
                      style={{ width: 210, marginTop: '0.6rem', marginLeft: '3rem' }}
                      onChange={handleDateRangeChange}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={2} style={{ marginRight: '1rem' }}>
 
 {/* <Controls.Dropdown
   label="Filter by Provider"
   options={providers.map((provider) => ({ value: provider, label: provider }))}
   onChange={(e) => setSelectedProvider(e.target.value)}
   value={selectedProvider}
 /> */} 
      <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel sx={{ fontSize:'12px' }} id="demo-multiple-checkbox-label">Filter by Provider</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedProvider}
          onChange={handleChange}
          input={<OutlinedInput  label="Filter by Provider" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          style={{ maxHeight: '35px',marginTop:'4px' }} 
          sx={{ backgroundColor: 'white' }}
        >
          {providers.map((name) => (
           <MenuItem
        key={name}
            value={name}
          style={{
   ...getStyles(name, selectedProvider, theme),
   display: 'block',
   width: 'fit-content',
   height: '30px',  // Adjust this value to control the height
   fontSize: '14px', 
 }}
>
               <Checkbox
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: 12, 
                  },
                }}
                checked={selectedProvider.indexOf(name) > -1}
              />
               {/* <ListItemText primary={name} />  */}
            { name} 
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>       
</Grid>        
</Grid>
          {/* <Grid container  style={{display:'flex',flexDirection:'row',gap:'12rem'}}>
          <h5>Policy Information</h5>
            <Grid item xs={2.5}>
  <TextField
    sx={{
      height: '40px',
      backgroundColor: '#f5f5f5',
      '& .MuiOutlinedInput-root': {
        height: 40,
        borderRadius: '8px',
        '& fieldset': {
          borderColor: 'black', // Set the border line color to black
        },
      },
    }}
    InputLabelProps={{
      style: {
        fontSize: '14px',
        color: 'black', // Set the color of the label text to black
      },
    }}
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
            </Grid>
         </Grid> */}
         </Form>
        <div>
        {policies?.length > 0 ? (
      <>
        <Box
            sx={{
            marginTop:'2rem',
            width: '100%',
            '& .custom-header': {
            backgroundColor: 'rgb(234, 237, 240,1)',
            },
          }}
        >
       <DataGrid
       rowHeight={rowHeight}
       rows={filteredPolicies} // Use the filtered policies
       columns={columns}
       pageSize={10}
       rowsPerPageOptions={[10]}
       getRowId={(row) => row.policyid}
     />
      </Box>
      </>
       ):(
        <>
        <Box
            sx={{
            marginTop:'2rem',
            width: '100%',
            '& .custom-header': {
            backgroundColor: 'rgb(234, 237, 240,1)',
            },
          }}
        >
       <DataGrid
       rowHeight={rowHeight}
       rows={filteredPolicies} // Use the filtered policies
       columns={columns}
       pageSize={10}
       rowsPerPageOptions={[10]}
       getRowId={(row) => row.policyid}
     />
      </Box>
        <Typography style={{marginLeft:'33rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
        No policy details available.
      </Typography>
      </>
      )
    }
        </div>
    </div>
    
   
   )}
 </div>
  );
}

export default PolicyDataGrid;

