import React, { useState, useEffect,useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Controls from '../components/controls/Controls';
import { Form } from '../components/useForm';
import { Box, Grid, TextField, Tooltip, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import edit from "../assests/icons/edit.png";
import update from "../assests/icons/update.png";
import opportunity from "../assests/icons/opportunity.png";
import showdetails from "../assests/icons/showdetails.png";
import axios from 'axios';
import * as Rest from  './../services/restapi';
import 'rsuite/dist/rsuite.min.css';
import LoadingSpinner from '../components/controls/spinner'; // Import your LoadingSpinner component
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import {  makeStyles } from "@material-ui/core";
import { styled, useTheme } from "@mui/material/styles";
import {  FormControlLabel } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import MuiAppBar from '@mui/material/AppBar';
import FilterListIcon from '@mui/icons-material/FilterList';
import Pagination from '@mui/material/Pagination';
/* import Stack from '@mui/material/Stack'; */
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment';

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start"
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'rgb(234, 237, 240,1)',
    borderRadius: '5px',
  },
  menuList: {
    backgroundColor: 'rgb(234, 237, 240,1)',
  },
  menuItem: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align all options to the left
    marginLeft: '10px',
  },
  sidebar: {
    padding: '10px',
    marginLeft: '10px',
    width: '200px',
  },
}));
function AvProspect() {
  const [limit, setLimit] = useState(10); 
  const [prospects, setProspects] = useState([]);
  const [viewassign, setViewAssign] = useState([]);
  const [status,setStatus] = useState("")
  const [selectedOption, setSelectedOption] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [salesperson, setSalesPerson] = useState('');
  const [editData, setEditData] = useState(null);
  const [showDetails, setShowDetails] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]); // Define employeeOptions here
  const [selectedProspectStatus, setSelectedProspectStatus] = useState(''); // Define selectedProspectStatus here
  const [selectedEmployee, setSelectedEmployee] = useState(''); // Define selectedEmployee here
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
 
  const rowHeight = 35;
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const isProspectStatusMount = useRef(true);
  const classes = useStyles();
  
   const [isLoading, setIsLoading] = useState(false);
   const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
   const hasFetchedAll = useRef(false);
   const isInitialMount = useRef(true);

  const employeeList = filteredData?.map((prospect)=>(prospect.EmployeeID))
  const employeeID = localStorage.getItem("EmployeeID");
  const employeeName = localStorage.getItem("EmployeeName");
  const assignedEmployee = location.state?.assignedEmployee || '';
  const [checked, setChecked] = React.useState(["", "", ""]);
  const drawerWidth = 190;
  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'side' })(
    ({ theme, side }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      ...(side && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
      position: 'relative',
    }),
  );
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'side',
  })(({ theme, side}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(side && {
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: drawerWidth,
    }),
  }));
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  }));


  const theme = useTheme();
   const [side, setOpen] = useState(false); // Initialize the sidebar state
   const [currentPage, setCurrentPage] = useState(1);
   const [totalPages,setTotalpages] = useState('')
   const [TotalRecords,setTotalRecords]= useState('')
   const [perPage, setPerPage] = useState(limit);

   const handlePageChange = (event,pageNumber) => {
     setCurrentPage(pageNumber);
     // You may perform data fetching or any action on page change here
   };
 
   const handlePerPageChange = (value) => {
     setPerPage(value); // Update perPage state when dropdown value changes
     // You can perform additional actions here based on the selected value
   };
 
   const predefinedRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left'
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left'
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left'
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left'
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left'
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left'
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left'
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left'
    },
    {
      label: 'All time',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()],
      placement: 'left'
    },
    {
      label: 'Last week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      appearance: 'default'
    },
    {
      label: 'Next week',
      closeOverlay: false,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), 7)
        ];
      },
      appearance: 'default'
    }
  ];
  
   const handleDateRangeChange = (date) => {
    if (Array.isArray(date) && date.length === 2) {
      const stateDate = moment(date[0]).format("DD-MM-YYYY");
      const endDate = moment(date[1]).format("DD-MM-YYYY");
      setStartDate(stateDate);
      setEndDate(endDate);
    } else {
 
    }
    // fetchDataWithinDateRange(start, end);  
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {

    setOpen(false);
    };
    const [salespersonChecked, setSalespersonChecked] = React.useState({
      salesperson: [],
    });
    const [salespersonLabels, setSalespersonLabels] = React.useState([]);
    const [prospectChecked, setProspectChecked] = React.useState({
      Status: [],
    });
  
    const [prospectstatusLabels, setProspectStatusLabels] = useState([]);
    useEffect(() => {
      if(isProspectStatusMount.current){
      async function fetchProspectStatusOptions() {
        try {
          const response = await axios.get(Rest.prospectstatusapi,{
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          });
          setProspectStatusLabels(response?.data?.prospectstatus);
        } catch (error) {
          console.error('Error fetching status options:', error);
        }
      }
      fetchProspectStatusOptions();
      isProspectStatusMount.current=false;
    }
    }, []);
    const handleParentChange = (event, parent) => {
      let selectedValues = [];
  
      if (parent === 'Status') {
        selectedValues = event.target.checked ? [...prospectstatusLabels] : [];
      } else {
        selectedValues = event.target.checked ? [parent] : [];
      }

      setProspectChecked({
        ...prospectChecked,
        [parent]: selectedValues
      });
    };
  
    const handleChildChange = (event, parent, childIndex) => {
      const newSelectedValues = [...prospectChecked[parent]];
  
      if (event.target.checked) {
        newSelectedValues.push(prospectstatusLabels[childIndex]);
      } else {
        const indexToRemove = newSelectedValues.indexOf(prospectstatusLabels[childIndex]);
        if (indexToRemove !== -1) {
          newSelectedValues.splice(indexToRemove, 1);
        }
      }
      
      setProspectChecked({
        ...prospectChecked,
        [parent]: newSelectedValues
      });
    };
    const handleSalespersonChange = (event, parent) => {
      let selectedValues = [];
  
      if (parent === 'salesperson') {
        selectedValues = event.target.checked ? [...salespersonLabels] : [];
      } else {
        selectedValues = event.target.checked ? [parent] : [];
      }
  
      setSalespersonChecked({
        ...salespersonChecked,
        [parent]: selectedValues
      });
    };
  
    const handleSalespersonChildChange = (event, parent, childIndex) => {
      const newSelectedValues = [...salespersonChecked[parent]];
  
      if (event.target.checked) {
        newSelectedValues.push(salespersonLabels[childIndex]);
      } else {
        const indexToRemove = newSelectedValues.indexOf(salespersonLabels[childIndex]);
        if (indexToRemove !== -1) {
          newSelectedValues.splice(indexToRemove, 1);
        }
      }
      setSalespersonChecked({
        ...salespersonChecked,
        [parent]: newSelectedValues
      });
    };
    useEffect(() => {
      axios.get(Rest.viewallemployees,{
        headers: {
            "X-fiftyaccess-Token": token
        }
      })
     
        .then((response) => {
          const options = response.data.data.map((employee) => ({
            value: employee.EmployeeID,
            label: `${employee.FirstName} ${employee.LastName}`,
          }));
          const EmpolyeeName = response.data.data.map((employee) => (`${employee.FirstName} ${employee.LastName}`));
          setEmployeeOptions(options);
          setSalespersonLabels(EmpolyeeName)
        })
        .catch((error) => console.error('Error fetching employee data:', error));
    }, []);
  
    const [expanded, setExpanded] = useState([]); 
    const renderAssignCheckbox = (label, parent) => {
      return (
        <FormControlLabel
          key={`parent-${parent}`}
          label={label}
          control={
            <Checkbox
              sx={{
                '& .MuiSvgIcon-root': {
                  fontSize: 16, // Adjust the font-size to decrease the checkbox size
                },
              }}
              checked={prospectChecked[parent]?.length > 0}
              indeterminate={false}
              onChange={(event) => handleParentChange(event, parent)}
            />
          }
        />
      );
    };
      const renderParentCheckbox = (label, parent) => {
        return (
          <TreeItem
          nodeId={parent}
          label={
          <FormControlLabel
            key={`parent-${parent}`}
            label={label}
            control={
              <Checkbox
                checked={prospectChecked[parent]?.length > 0}
                indeterminate={false}
                onChange={(event) => handleParentChange(event, parent)}
                />
              }
            />
          }
        >
          {renderChildCheckboxes(parent)}
        </TreeItem>
      );
    };

    const renderChildCheckboxes = (parent) => {
      return prospectstatusLabels.map((childLabel, index) => (
        <TreeItem
          key={`child-${index}`}
          nodeId={`${parent}-${index}`}
          label={childLabel}
          sx={{marginLeft:'1rem'}}
          icon={
            <Checkbox
              checked={prospectChecked[parent].includes(childLabel)}
              onChange={(event) => handleChildChange(event, parent, index)}
            />
          }
        />
      ));
    };
    const renderSalespersonCheckbox = (label, parent) => {
      return (
        <TreeItem
          nodeId={parent}
          label={
            <FormControlLabel
              label={label}
              control={
                <Checkbox
                  checked={salespersonChecked[parent]?.length > 0}
                  onChange={(event) => handleSalespersonChange(event, parent)}
                />
              }
            />
          }
        >
          {renderSalespersonChildCheckboxes(parent)}
        </TreeItem>
      );
    };
  
    const renderSalespersonChildCheckboxes = (parent) => {
      return salespersonLabels.map((childLabel, index) => (
        <TreeItem
          key={`child-${index}`}
          nodeId={`${parent}-${index}`}
          label={childLabel}
          sx={{marginLeft:'1rem'}}
          icon={
            <Checkbox
              checked={salespersonChecked[parent].includes(childLabel)}
              onChange={(event) => handleSalespersonChildChange(event, parent, index)}
            />
          }
        />
      ));
    };
  
    const handleFilterChange = (event) => {
      setSelectedFilters(event.target.value);
    };
    const renderSidebar = (label, parent) => {
      return (
        <div className={classes.sidebar}>
          
         
            <div>
              <h6>Filter By</h6>
              <FormControl component="fieldset">
              <TreeView
                aria-label="Status Checkbox"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                onNodeToggle={(event, nodes) => setExpanded(nodes)}
                >
                {renderParentCheckbox('Status', 'Status')}
              </TreeView>
                <TreeView
                  aria-label="Salesperson Checkbox"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={expanded}
                  onNodeToggle={(event, nodes) => setExpanded(nodes)}
                  >
                  {renderSalespersonCheckbox('Sales Person', 'salesperson')}
                </TreeView>
              </FormControl>
            </div>
     
          
               </div>
      );
    }; 

  const handleRowSelect = (prospectId) => {
    setSelectedRows((prevSelectedRows) => ({
      ...prevSelectedRows,
      [prospectId]: !prevSelectedRows[prospectId],
    }));
    setSelectedCheckboxes((prevSelectedCheckboxes) => ({
      ...prevSelectedCheckboxes,
      [prospectId]: !prevSelectedCheckboxes[prospectId],
    }));
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const updatedSelectedRows = {};

    if (isChecked) {
      filteredData.slice(0, limit).forEach((prospect) => {
        updatedSelectedRows[prospect.prospectid] = true;
      });
    }
    setSelectedRows(updatedSelectedRows);
  };

  const handleAssignClick = () => {
    const selectedProspects = filteredData
      .filter((prospect) => selectedRows[prospect.prospectid])
      .map((prospect) => ({
        ...prospect,
        AssignName: salesperson,
      }));

    navigate('/Assign', { state: { selectedProspects, salesperson } });
  };
  const handleAssignClickone = () => {

    console.log("clicking")
  
    navigate('/Assign');
  };


  // useEffect(() => {
  //   if (selectedEmployee) {
  //     // Filter data by selected employee
  //     const filteredResults = prospects.filter((prospect) => prospect.EmployeeID === selectedEmployee);
  //     setFilteredData(filteredResults);
  //   } else if (selectedProspectStatus) {
  //     // Filter data by selected prospect status
  //     const filteredResults = prospects.filter((prospect) => prospect.status === selectedProspectStatus);
  //     setFilteredData(filteredResults);
  //   } else {
  //     // If no filter is selected, show all data
  //     setFilteredData(prospects);
  //   }
  // }, [selectedEmployee, selectedProspectStatus, prospects]);
 
  useEffect(() => {
    if (isInitialMount.current) {
    fetch(Rest.prospectstatusapi,{
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStatusOptions(data?.prospectstatus);
      })
      .catch((error) => console.error("Error fetching status options:", error));
  
    // Fetch employee data
    axios
      .get(Rest.viewallemployees,{
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
      .then((response) => {
        const options = response.data.data.map((employee) => ({
          value: employee.EmployeeID,
          label: `${employee.FirstName} ${employee.LastName}`,
        }));
        setEmployeeOptions(options);
      })
      .catch((error) => console.error('Error fetching employee data:', error));
      isInitialMount.current = false;
    }
  }, []);
 
  // const handleStatusFilter = (selectedStatus) => {
  //   setSelectedProspectStatus(selectedStatus);
  //   setSelectedEmployee(''); 
  // };
  
  // const handleEmployeeFilter = (selectedEmployeeId) => {
  //   setSelectedEmployee(selectedEmployeeId);
  //   setSelectedProspectStatus(''); 
  // };

  // useEffect(() => {
  //   if (selectedEmployee) {
  //     // Filter data by selected employee
  //     const filteredResults = prospects.filter((prospect) => prospect.EmployeeID === selectedEmployee);
  //     setFilteredData(filteredResults);
  //   } else if (selectedProspectStatus) {
  //     // Filter data by selected prospect status
  //     const filteredResults = prospects.filter((prospect) => prospect.status === selectedProspectStatus);
  //     setFilteredData(filteredResults);
  //   } else {
  //     // If no filter is selected, show all data
  //     setFilteredData(prospects);
  //   }
  // }, [selectedEmployee, selectedProspectStatus, prospects]);

  const selectedEmployeeNames = salespersonChecked.salesperson;
  const selectedEmployeeIDs = selectedEmployeeNames.map((selectedName) => {
    const selectedEmployee = employeeOptions.find((option) => option.label.trim() === selectedName.trim());
    return selectedEmployee ? selectedEmployee.value : null;
  });

    useEffect(() => {
      if (selectedOption === "All") {
        async function fetchAll()  {
           setLoading(true); 
          const selectedValues = {
            prospectstatus: prospectChecked?.Status,
            salesperson: selectedEmployeeIDs,   
            limit:perPage,
            page:currentPage
          }
          try {
            const response = await axios.post(Rest.viewallProsects,selectedValues, {
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            });

            if (response?.data?.code==="S001") {
              const prospectsWithIds = response?.data?.data?.data?.map((prospect, index) => ({
                ...prospect,
                employeedetails: prospect.employeedetailsData,
                id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
              }));
              setTotalpages(response?.data?.data?.pages);
              setTotalRecords(response?.data?.data?.TotalRecords)
              setLimit(response?.data?.data?.next?.limit)
              setProspects(prospectsWithIds);
              setFilteredData(prospectsWithIds);
              setTimeout(() => {
                 setLoading(false);
              }, 200);
            } else{
              setFilteredData([]);
              alert(response?.data?.msg  || response?.data?.data)
              setLoading(false);
            }
          } catch (err) {
            setFilteredData([]);
            alert("Error: " + err.message);
           
            setLoading(false);
          }
        }
        fetchAll();
        /* hasFetchedAll.current = true; */
      }
       else if (selectedOption === "Assigned to Me") {
        const fetchAssignedtome = async () => {
          setLoading(true); 
          const selectedValues = {
            EmployeeID:employeeID,
            prospectstatus: prospectChecked?.Status,
            salesperson: selectedEmployeeIDs,   
            limit:perPage,
            page:currentPage
          }
          try {
            const response = await axios
            .post( Rest.viewAssignedtoMe,selectedValues,{
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            }
            );
            if (response?.data?.code==="S001") {
            const formattedData = response?.data?.data?.data?.map((prospect, index) => ({
              ...prospect,
              employeedetails:prospect.employeedetailsData,
              id: index + 1, // Generating a simple unique ID for each row
            }));
            setTotalpages(response?.data?.data?.pages);
              setTotalRecords(response?.data?.data?.TotalRecords)
              setLimit(response?.data?.data?.next?.limit)
            setViewAssign(formattedData)
            setProspects(formattedData);
            setFilteredData(formattedData);
            setTimeout(() => {
            setLoading(false);
          }, 200);
        } else{
          setFilteredData([]);
          alert(response?.data?.msg  || response?.data?.data)
          setLoading(false);
        }
          }   catch (err) {
            setFilteredData([]);
            alert("Error: " + err.message);
            
            setLoading(false); // Even if there's an error, stop the spinner
          }   
        };
        fetchAssignedtome();
      } else if (selectedOption === "To be Contacted Today") {
        const fetchtobecontacted = async () => {
          setLoading(true); 
          const selectedValues = {
            EmployeeID:employeeID,
            prospectstatus: prospectChecked?.Status,
            salesperson: selectedEmployeeIDs,   
            limit:perPage,
            page:currentPage
          }
          try {
            const response = await axios
            .post( Rest.toBeContactToday,selectedValues,{
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              },
            }
              ); 
              if (response?.data?.code==="S001") {    
            const prospectsWithIds = response?.data?.data?.data?.map((prospect, index) => ({
              ...prospect,
              employeedetails:prospect.employeedetailsData,
              id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
            }));
            setTotalpages(response?.data?.data?.pages);
              setTotalRecords(response?.data?.data?.TotalRecords)
              setLimit(response?.data?.data?.next?.limit)
            setProspects(prospectsWithIds);
            setFilteredData(prospectsWithIds);
            setTimeout(() => {
            setLoading(false);
          }, 200);
        } else{
          setFilteredData([]);
          alert(response?.data?.msg  || response?.data?.data)
          setLoading(false);
        }
          } catch (err) {
            setFilteredData([]);
            alert("Error: " + err.message);
            setLoading(false);
        
          }
        };
        fetchtobecontacted();
      } else if (selectedOption === "Unassigned") {
        const fetchUnassigned = async () => {
           setLoading(true); 
           const selectedValues = {
            EmployeeID:employeeID,
            prospectstatus: prospectChecked?.Status,
            salesperson: selectedEmployeeIDs,   
            limit:perPage,
            page:currentPage
          }
          try {
            const response = await axios.post(Rest.unAssignedProsects,selectedValues,{
              headers: {
                'Content-Type': 'application/json',
                "X-fiftyaccess-Token": token
              }
            });  
            if (response?.data?.code==="S001") {          
            const prospectsWithIds = response?.data?.data?.data?.map((prospect, index) => ({
              ...prospect,
              id: index + 1, // Using index + 1 as an example, you can choose an appropriate unique value
            }));  
            setTotalpages(response?.data?.data?.pages);
              setTotalRecords(response?.data?.data?.TotalRecords)
              setLimit(response?.data?.data?.next?.limit)          
            setProspects(prospectsWithIds);
            setFilteredData(prospectsWithIds);
            setTimeout(() => {
            setLoading(false); // Data has loaded, stop the spinner
            }, 200);
          } else{
            setFilteredData([]);
            alert(response?.data?.msg  || response?.data?.data)
            setLoading(false);
          }
          } catch (err) {
            setFilteredData([]);
            alert("Error: " + err.message);
           
            setLoading(false);
          }
        };
        fetchUnassigned();
      }
    }, [selectedOption,prospectChecked,salespersonChecked,,perPage,currentPage]);
  
     // Update filtered data based on search query
     useEffect(() => {
        const filteredResults = prospects.filter(
          prospect =>
          prospect?.prospectid?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          prospect?.firstname?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          prospect?.emailid?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
          /* prospect?.phonenumber?.toString().includes(searchQuery) || */
          prospect?.status?.toLowerCase().includes(searchQuery?.toLowerCase()) //
        );
        setFilteredData(filteredResults);
      }, [searchQuery, prospects]);  

      useEffect(() => {
        // API endpoint and request body
        const requestBody = {
          phonenumber: parseInt(searchQuery),
          /* startDate:startDate,
          endDate:endDate */
        };
    
        // Making the API call using axios
        axios.post(Rest.prospectwithNumber, requestBody)
          .then(response => {
            const formattedData = response?.data?.data.map((item, index) => ({
              ...item,
              id: index + 1 // Generating an id (you can adjust this according to your data structure)
            }));
            
            setFilteredData(formattedData);
            console.log('API Response:', formattedData);
          })
          .catch(error => {
            // Handle errors, e.g., show an error message
            console.error('Error fetching data:', error);
          });
      }, [searchQuery, prospects]);

  const columns = [
    {
      field: 'checkbox',
      width: '60',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      headerClassName: 'custom-header',
      headerCheckboxProps: {
        onChange: handleSelectAll,
      },
      renderHeader: (params) => (
        <div>
          <input
            type="checkbox"
            checked={Object.keys(selectedRows)?.length === filteredData?.length}
            onChange={handleSelectAll}
          />
        </div>
      ),
      renderCell: (params) => (
        <div>
          <input
            type="checkbox"
            checked={selectedRows[params.row.prospectid] || false}
            onChange={() => handleRowSelect(params.row.prospectid)}
          />
        </div>
      ),
    },
    { field: 'prospectid', headerName: 'Prospect ID', width:130, headerClassName:'custom-header' }, // Adding an 'id' column
    {
      field: 'name',
      headerName: 'Name',
      width:200,
      headerClassName:'custom-header',
      valueGetter: (params) => `${params.row.firstname} ${params.row.lastname}`,
    },
    { field: 'emailid', headerName: 'Email', width:180,headerClassName:'custom-header' },
    { field: 'phonenumber', headerName: 'Phone Number', width:150,headerClassName:'custom-header' },
    { field: 'status', headerName: 'Status', width:100,headerClassName:'custom-header' },
    { field: 'salespersonname', headerName: 'Sales Person Name', width: 150, headerClassName: 'custom-header',
    renderCell: (params) => {
      const { employeefirstname, employeelastname } = params.row;
      return (
        <div>
          {employeefirstname} {employeelastname}
        </div>
      );
    },
  },
    {
        field: 'actions', // Custom actions column
        headerName:'Actions',
        headerClassName:'custom-header',
        width:180,
        renderCell: (params) => (
          <div>
            <div className="action-buttons">
                <Tooltip title="Edit">
                    <div onClick={() => handleEdit(params.row)}>
                        <img src={edit} alt="Edit" className="action-button-icon" />
                    </div>
                </Tooltip>
                <Tooltip title="Show Details">
                <div onClick={() => handleShowDetails(params.row)} text="Show Details">
                    <img
                    src={showdetails}
                    alt="Show Details"
                    className="action-button-icon"
                    />
                </div>
                </Tooltip>
                <Tooltip title="Update Status">
                <div onClick={() => handleUpdateStatus(params.row)}>
                    <img src={update} alt="Update" className="action-button-icon" />
                </div>
                </Tooltip>
                <Tooltip title="Add Lead">
                <div onClick={() => handleOpportunityDetails(params.row)}>
                    <img
                    src={opportunity}
                    alt="Add Lead"
                    className="action-button-icon"
                    />
                </div>
                </Tooltip>
            </div>
          </div>
        ),
      },
  ];
     
      const handleEdit = (row) => {
        const prospectid = row.prospectid
        // Make an API call using Axios
        axios.get(Rest.viewprosect,{
          params:{
            prospectid:prospectid
          },
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        }
      )
          .then(response => {
            // Navigate to the updateprospect page
            navigate(`/updateprospect/${prospectid}`, { state: { updateProspect: response?.data?.data } });
            // Handle the API response data
            console.log('API Response:', response?.data?.data);
          })
          .catch(error => {
            // Handle API errors
            console.error('API Error:', error);
          });
      };  
 
      const handleShowDetails = (row) => {
        navigate(`/Viewprospect/${row.prospectid}`, {state: {customer: row}});
      };
    
      const handleUpdateStatus = (row) => {
        navigate(`/updatestatus/${row.prospectid}`, {state: {customer: row}});
      };
    
      const handleOpportunityDetails = (row) => {
        navigate(`/opportunity/${row.prospectid}`, {state: {customer: row}});
      };
      useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      }, []);
  return (
    <div style={{ marginTop: '1.5rem', marginLeft: '0.5rem', minHeight: '100vh' }}>
    {loading ? (
      <LoadingSpinner />
    ) : (
 
    <div>
      <Sidebar  setSelectedOption={setSelectedOption}/>
    <div style={{marginTop:'3.5rem',marginLeft:'8.5rem'}}>  
      <Form>
      <Grid
        container
        alignItems="center"
      >
        <Grid item xs={3.5}>
        <h5>Prospect Information</h5>
        </Grid>
       
        <Grid item xs={2.5} style={{display:'flex',flexDirection:'column',marginLeft: '10rem'}}>
  <TextField
    sx={{
      height: '40px',
      backgroundColor: '#ffff',
      '& .MuiOutlinedInput-root': {
        height: 40,
        borderRadius: '8px',
        '& fieldset': {
          borderColor: 'black', // Set the border line color to black
        },
      },
    }}
    InputLabelProps={{
      style: {
        fontSize: '14px',
        color: 'black', // Set the color of the label text to black
      },
    }}
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Grid>
        {/* <Grid item xs={2}>
                  <Stack direction="row" spacing={4} alignItems="flex-start">
                    <DateRangePicker
                    format='dd-MM-yyyy'
                      ranges={predefinedRanges}
                      placeholder="Select Date"
                      style={{ width: 210, marginTop: '0.6rem', marginLeft: '3rem' }}
                      onChange={handleDateRangeChange}
                    />
                  </Stack>
                </Grid> */}
         {/* <Grid ml={10} mt={2} style={{display:'flex',flexDirection:'column',marginLeft: '20rem'}}> */}
         <Controls.Button text="New Prospect" component={Link} to="/addlead" />
        <Grid ml={10} style={{ display: 'flex', flexDirection: 'column', marginLeft: '1rem', marginTop: '1rem' }}>
         
          {/* <Controls.Button text="Assign"  onClick={handleAssignClick} disabled={Object.keys(selectedCheckboxes).length === 0}  /> */}
          <Controls.Button
        text="Assign"
          onClick={handleAssignClick}
        disabled={Object.values(selectedRows).every((value) => !value)}/>
       
         <Link component={Link} to="/Assign" style={{ fontSize: '12px', marginLeft: '15px', cursor: 'pointer' }}>Bulk Assign </Link>
        </Grid> 
     
    <DrawerHeader>
        <button
          onClick={handleDrawerOpen}
        style={{
          backgroundColor: '#914572',
          display: 'flex',
          justifyContent: 'center',  // Center horizontally
          alignItems: 'center',      // Center vertically
          borderRadius: '8px',
          // marginLeft: '14rem',
          // marginTop: '2.6rem',
          height: '35px',
          fontSize: '14px',
          // fontWeight: 'bold',  // 'bold' is the correct property for font weight
          color: '#fff',
          padding: '15px',
        }}
      >
        <span>
          <FilterListIcon />
        </span>
        <span>Filter </span>
      </button>
      </DrawerHeader> 
      <Main side={side}></Main>
      </Grid>
      </Form>
      {filteredData?.length > 0 ? (
        <>
      <label style={{marginLeft:'58.3rem'}}>Total Records : {TotalRecords}</label>
      {filteredData && (
        <Box
        sx={{
        '& .custom-header': {
        backgroundColor: 'rgb(234, 237, 240,1)',
        },
      }}
    >
      <DataGrid 
       rowHeight={rowHeight}
      rows={filteredData} columns={columns} pageSize={10} 
      onSelectionModelChange={handleRowSelect}
      onSelectMenuChange={selectedOption}
      hideFooterPagination={true}
      />
      <div style={{marginLeft:'38rem',display:'flex',flexDirection:'row'}}>
            <label htmlFor="itemsPerPage">Leads per page:</label>
              <select id="itemsPerPage" value={limit} onChange={(e) => handlePerPageChange(e.target.value)}>
              <option value="10">10</option>
              <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
                    <Stack spacing={2}>
                      <Pagination 
                      count={totalPages} 
                      page={currentPage}
                      onChange={handlePageChange}
                      />
                    </Stack>
                      </div>
      </Box>
      )}
       </>
  
          ) : (

            <Box
        sx={{
        marginTop:'2rem',
        '& .custom-header': {
        backgroundColor: 'rgb(234, 237, 240,1)',
        },
      }}
    >
      <DataGrid 
       rowHeight={rowHeight}
      style={{marginTop:'3rem'}} rows={filteredData} columns={columns} pageSize={10} 
      onSelectionModelChange={handleRowSelect}
      onSelectMenuChange={selectedOption}
      />
        <Typography style={{marginLeft:'25rem',marginTop:'8rem'}} variant="subtitle1" gutterBottom>
          No prospect details available.
        </Typography>
      </Box>
        )
      }
      <Drawer
        width="30%"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          position: 'fixed',
          justifyContent: 'flex-end',
          height: '100%',
          padding: 2,
          width: drawerWidth,
          flexShrink: 0,
         
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: '#f5f5f5' },
        }}
        variant="persistent"
        anchor="right"
        open={side}
      > 
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} style={{marginTop:'55px'}}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
         <List >
              <ListItem>
                {renderSidebar()}
              </ListItem>
          </List>
      </Drawer>
   
      <DrawerHeader />
    </div>
    
        </div>
      )}
    </div>
  );
    }
export default AvProspect;

