import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import plus from '../assests/icons/plus.png';
import edits from '../assests/icons/edits.png';
import useToggleColumns from '../utils/toggleColumns';
import { Card, CardContent, Link, Typography, makeStyles } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import * as Rest from  './../services/restapi';
import Controls from '../components/controls/Controls';
import fileicon from './../assests/icons/fileicon.png';
import './../App.css';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1400,
    margin: 'auto',
    marginTop: theme.spacing(2),
    backgroundColor:'#fffafa',
  },
  highlighted: {
    padding:'10px',
    borderRadius:'40px'
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function BeneficiaryDetails() {
  const [prospectData, setProspectData] = React.useState({});
  const navigate = useNavigate();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [showData, toggleData] = useToggleColumns(false);
  const classes = useStyles();
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const location =  useLocation();
  const customer = location?.state;
  const prospectid = customer?.prospectid || customer?.customer?.prospectid;
  const opportunityid = customer?.customer?.opportunityid
  const [fileContent, setFileContent] = React.useState('');
  const [attachments, setAttachments] = React.useState([]);
  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (isInitialMount.current) {
    axios.get(Rest.viewOpportunity,{
      params:{
        opportunityid:opportunityid
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then(response => {
        setProspectData(response?.data?.data);
        const file = response?.data?.data?.beneficiarydetails?.[0]?.attachments?.[0].image
        setFileContent(file)
        setAttachments(response?.data?.data?.beneficiarydetails?.[0]?.attachments)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      isInitialMount.current = false;
    }
  }, [opportunityid]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigateAddBenificiary = () =>{
    navigate(`/opportunityaddbeneficiary/${prospectid}`,{state:customer})
  }
  const navigateEditBenificiary = () =>{
    axios.get(Rest.viewprosect,{
      params:{
        prospectid:prospectid
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    }
  )
      .then(response => {
        // Navigate to the updateprospect page
        navigate(`/updateprospect/${prospectid}`, { state: { updateProspect: response?.data?.data } });
        // Handle the API response data
        console.log('API Response:', response?.data?.data);
      })
      .catch(error => {
        // Handle API errors
        console.error('API Error:', error);
      });
  }

  const maskAadharNumber = (aadharnumber) => {
    if (!aadharnumber) return '';
      // Assuming aadharNumber is a string with 12 digits
      const lastFourDigits = aadharnumber?.substring(aadharnumber.length - 4);
      return `XXXX-XXXX-${lastFourDigits}`;
    };
    
    const maskPanNumber = (pannumber) => {
      if (!pannumber) return '';
      // Assuming panNumber is a string with 10 characters
      const lastFourDigits = pannumber?.substring(pannumber.length - 4);
      return `XXXXX${lastFourDigits}`;
    };
    const openPDF = () => {
      const pdfLink = fileContent;
      window.open(pdfLink, '_blank');
    };
  return (
    <div style={{marginTop:'8.1rem'}}>
      <Card className={classes.root}>
      <CardContent>
      <div style={{height:'8px',display:'flex',flexDirection:'row'}}>
        <Typography onClick={toggleData} variant="body6" color="text.secondary" style={{marginLeft:'2px'}}>
        {showData ? 'Beneficiary Details' : 'Beneficiary Details'}
        </Typography>
        <div style={{marginLeft:'57.5rem'}}>
        <img 
        onClick={navigateAddBenificiary}
        src={plus} alt="plus " className="action-button-icon" />
        <Typography variant="body5" color="text.secondary">Add</Typography>
        <img 
        style={{marginLeft:'2rem'}}
        onClick={navigateEditBenificiary}
        src={edits} alt="Edit" className="action-button-icon" />
        <Typography variant="body5" color="text.secondary">Edit</Typography>


        </div>
                </div>
        </CardContent>
        </Card>
        {showData && (
        <div>
        <AppBar position="static" style={{marginTop:'5px',backgroundColor:'#F5F5F5'}}>
        <Tabs
        sx={{ maxWidth: '400px'}}
          value={value}
          onChange={handleChange}
         /*  indicatorColor="secondary" */
          textColor="inherit"
          variant="fullWidth"
        >
          <Tab label="Self"  {...a11yProps(0)}/>
          <Tab label="Spouse"  {...a11yProps(1)}/>
          <Tab label="Children"  {...a11yProps(2)}/>
          <Tab label="Others"  {...a11yProps(3)}/>
        </Tabs>
      </AppBar>
      <Box sx={{ bgcolor: '#fffafa',marginTop:'5px',paddingTop:'0px',borderRadius:'5px'}}>
      {prospectData?.[0]?.beneficiarydetails?.map((beneficiary, index) => (
      <TabPanel key={index} value={value} index={index} dir={theme.direction}>
         <Card key={index} style={{ marginBottom: '0.3rem',backgroundColor: '#fffafa', borderRadius: '8px', boxShadow: '0 0px 0px rgba(0, 0, 0, 0.1)',paddingTop:'0px'  }}>
                  <CardContent style={{ display: 'grid', gridTemplateColumns: 'repeat(4,1fr)', gridTemplateRows: 'repeat(4, auto)', gap: '0.5rem' }}>
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                    Beneficiary ID:{' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                    {beneficiary.beneficiaryid}
                            </span>
                       </Typography>
                       <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                       Name:{' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {beneficiary?.firstname} {beneficiary?.lastname}
                            </span>
                       </Typography>
                       <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                       Email ID:{' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {beneficiary.emailid}
                            </span>
                       </Typography>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Phone Number:{' '}
                     <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                       {beneficiary.phonenumber}
                      </span>
                    </Typography> 
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Gender:{' '}
                     <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                       {beneficiary.gender}
                      </span>
                    </Typography> 
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Date Of Birth:{' '}
                  <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                    {/* {moment(parseInt(beneficiary.dateofbirth * 1000)).format("DD-MM-YYYY").replace(/01-01-1970/g, '')} */}
                    {parseInt(beneficiary.dateofbirth * 1000) ? moment(parseInt(beneficiary.dateofbirth * 1000)).format("DD-MM-YYYY").replace(/01-01-1970/g, '') : '' }
                  </span>
                </Typography>  
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Alternate Email ID:{' '}
                  <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                  {beneficiary.alternateemails}
                  </span>
                  </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                     Alternate Phone Number:{' '}
                <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                {beneficiary.alternatephonenumbers}
                  </span>
                </Typography> 
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                       Aadhar Number:{' '}
                     <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                     {maskAadharNumber(beneficiary?.aadharnumber)}
                        </span>
                     </Typography>
                     <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      PAN Number:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                      {maskPanNumber(beneficiary?.pannumber)}
                      </span>
                    </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Address Type:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                      {beneficiary?.addressdetails?.[0].addresstype}
                      </span>
                      </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Address Line1:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                      {beneficiary?.addressdetails?.[0].addressline1}
                      </span>
                      </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Address Line2:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                      {beneficiary?.addressdetails?.[0].addressline2}
                      </span>
                      </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Address Line3:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                      {beneficiary?.addressdetails?.[0].addressline3}
                      </span>
                      </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      City:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                      {beneficiary?.addressdetails?.[0].city}
                      </span>
                      </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      District:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                      {beneficiary?.addressdetails?.[0].district}
                      </span>
                      </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      State:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                      {beneficiary?.addressdetails?.[0].state}
                      </span>
                      </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Pincode:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                      {beneficiary?.addressdetails?.[0].pincode}
                      </span>
                      </Typography>
                      <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Country:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                      {beneficiary?.addressdetails?.[0].country}
                      </span>
                      </Typography>
                        <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                  Attachements{" "}
                  <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                  {beneficiary.attachments.map((file)=>(
                   <>
                   <p>
                  {file.filetype}: <img onClick={openPDF} 
                  src={fileicon} alt="fileicon" className="file-icon" />
                    </p>
                    </>
                  ))}
                  </span>
                </Typography>
                </CardContent>                   
              </Card>
        {/* <p>{beneficiary?.firstname} {beneficiary?.lastname}</p>
        <p>Phone Number: {beneficiary?.phonenumber}</p>
        <p>Aadhar Number: {maskAadharNumber(beneficiary?.aadharnumber)}</p>
        <p>PAN Number: {maskPanNumber(beneficiary?.pannumber)}</p> */}

      </TabPanel>
    ))}

      </Box>
      </div>
        )}
    </div>
  );
}