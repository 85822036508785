import React, { useState, useEffect } from 'react';
import Controls from '../components/controls/Controls';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from './../components/useForm';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Snackbar } from '@material-ui/core';
import axios from 'axios';
import * as Rest from  './../services/restapi';
import LoadingSpinner from '../components/controls/spinner';
import TextField from '@mui/material/TextField';

const CustomerAssign = () => {
  const location = useLocation();
  const { selectedCustomers } = location.state||[];
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null); // Use null to represent no selection
  const [employeeID, setEmployeeID] = useState('');
  // const customerids = selectedCustomers?.map((customer) => customer.customerid);
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [numbers, setNumbers] = useState("");

  let customerIds ;

  if (selectedCustomers && selectedCustomers.length > 0) {
    customerIds = selectedCustomers.map((customer) => customer.customerid);

  }

  // Fetch employee data from the API and set it to the employeeOptions state
  useEffect(() => {
   
    axios.get(Rest.viewallemployees,{
      headers: {
          "X-fiftyaccess-Token": token
      }
    })
      .then((response) => {
        const options = response?.data?.data?.map((employee) => ({
          value: employee.EmployeeID,
          label: `${employee.FirstName} ${employee.LastName}`,
        }));
        setEmployeeOptions(options);
      })
      .catch((error) => console.error('Error fetching employee data:', error));
  }, []);
  // ...

const handleAssignEmployee = () => {
  if (employeeID) {
    setIsLoading(true);
    // Find the selected employee object from employeeOptions
    const selectedEmployeeObject = employeeOptions.find(option => option.value === employeeID);

    // Extract the first name and last name from the label
    const [ employeefirstname,employeelastname] = selectedEmployeeObject.label.split(' ');

    // Make an API call to assign the selected prospects to the employee
    axios
      .post(Rest.assignCustomer, {
        EmployeeID: employeeID,
        customers: customerIds,
        numberOfRecords: numbers,
        employeefirstname: employeefirstname,
        employeelastname: employeelastname,
      
      },
      {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
      .then((response) => {
        if (response?.data?.code === 'S001') {
          setIsSuccessSnackbarOpen(true);
          setTimeout(() => {
            navigate('/allcustomer');
          }, 500);
        } else {
          alert(response?.data?.msg || response?.data?.data);
        }
      })
      .catch((err) => {
        alert('Error: ' + err.message);
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      });
  } else {
    console.error('No employee selected.');
  }
};



 
  // const handleAssignEmployee = () => {
  //   if (employeeID) {
  //     // Make an API call to assign the selected prospects to the employee
  //     axios
  //       .post(Rest.assignCustomer, {
  //         EmployeeID: employeeID,
  //         customers: customerIds,
  //       },
  //       {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         "X-fiftyaccess-Token": token
  //       },
  //     }
  //       )
  //       .then((response) => {
  //         if (response?.data?.code === "S001") {
  //           setIsSuccessSnackbarOpen(true);
  //           setTimeout(() => {
            
  //             navigate('/allcustomer');
  //           }, 500);
  //         }else{
  //           alert(response?.data?.msg)
  //         }
  //       })
  //       .catch((err) => {
  //         alert("Error: " + err.message);
  //       });
  //   } else {
  //     console.error('No employee selected.');
  //   }
  // };
  
  const handleSnackbarClose = () => {

    setIsSuccessSnackbarOpen(false);
  };
  const handleSalesPersonChange = (selectedEmployeeID) => {
    setEmployeeID(selectedEmployeeID); 

    // Find the selected employee object from employeeOptions
    const selectedEmployeeObject = employeeOptions.find(option => option.value === selectedEmployeeID);
    setSelectedEmployee(selectedEmployeeObject);
  };
  const handleOnnumberChange = (value) => {
    const sanitizedValue = value.replace(/^0+/, '');
    const parsedValue = parseInt(sanitizedValue, 10); // Parse the sanitized value to an integer
  
    if (!isNaN(parsedValue) || value === "") {
      setNumbers(isNaN(parsedValue) ? "" : parsedValue);
    }
  };
  
  return (
    <div style={{ marginTop: '5rem' }}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', padding: '5px' }}>
          <h3 style={{ marginLeft: '5px' }}>Assignee</h3>
          <Grid container style={{ marginLeft: '10rem' }}>
            <Grid item xs={4}>
              <Controls.Dropdown
                label="Sales Person"
                options={employeeOptions}
                value={employeeID}
                onChange={(e) => handleSalesPersonChange(e.target.value)}
              />
            </Grid>
            {!selectedCustomers && (
  <Grid item xs={6}>
  <div style={{marginTop:'10px',marginRight:'2rem'}}>Enter Number : 
  <input
    placeholder="Enter number"
    type="number"
    value={numbers}
    onChange={(e) => handleOnnumberChange(e.target.value)}
 
  />
  </div>
  </Grid>
)}
            <Grid style={{ marginLeft: '40rem' }}>
              <Controls.Button
                text="Save"
                onClick={handleAssignEmployee}
                variant="contained"
                color="primary"
              />
                             {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
              <Controls.Button
                text="Cancel"
                variant="contained"
                color="primary"
                onClick={() => navigate('/allcustomer')}
              />
            </Grid>
          </Grid>
        </div>
        <div>
        {selectedCustomers && selectedCustomers.length > 0 && (
        <div style={{ padding: '15px', marginTop: '2rem' }}>
          {/* <div style={{ padding: '15px', marginTop: '2rem' }}> */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: 'rgb(234, 237, 240,1)' }}>
                  <TableRow>
                    <TableCell>Customer ID</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Assignee Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ backgroundColor: "#F5F5F5" }}>
                  {selectedCustomers?.map((customer) => (
                    <TableRow key={customer.customerid}>
                      <TableCell>{customer.customerid}</TableCell>
                      <TableCell>{customer.firstname}</TableCell>
                      <TableCell>{customer.lastname}</TableCell>
                      <TableCell>{customer.emailid}</TableCell>
                      <TableCell>{customer.phonenumber}</TableCell>
                      <TableCell>{customer.status}</TableCell>
                      <TableCell>{selectedEmployee ? selectedEmployee.label : ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </div>)}
        </div>
      </Form>
      <Snackbar
            sx={{ marginLeft: '30rem' }}
            open={isSuccessSnackbarOpen}
            autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
            onClose={handleSnackbarClose}
            message="Data successfully Updated"
          />
    </div>
  );
};

export default CustomerAssign;
