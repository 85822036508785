import React from 'react';
import { MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { makeStyles } from "@material-ui/core";
import './../../App.css';

const useStyles = makeStyles((theme) => ({
  formControl: {
    backgroundColor: 'rgb(234, 237, 240,1)',
    borderRadius: '5px',
  },
  menuList: {
    backgroundColor: 'rgb(234, 237, 240,1)',
  },
  menuItem: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align all options to the left
    marginLeft:'10px'
  },
}));

const Dropdowns = ({ label, options, value, onChange }) => {
  const classes = useStyles();
  
  const updatedOptions = [
    { value: '', label: '' },
    ...options
  ];


  return (
    <FormControl fullWidth variant="outlined" className={classes.formControl}>
      <InputLabel style={{ fontSize: '12px' }}>{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        label={label}
        className="dropdown-icon"
        sx={{ height: '40px', fontSize: '12px' }}
        MenuProps={{
          classes: {
            paper: classes.menuList,
          },
        }}
      >
        <MenuItem value="">
      <em style={{fontSize:'12px',marginLeft:'10px'}}>Select</em>
          </MenuItem>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={classes.menuItem}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdowns;