import React, { useState, useEffect } from 'react';
import Controls from '../components/controls/Controls';
import { useLocation, useNavigate } from 'react-router-dom';
import { Form } from './../components/useForm';
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,Snackbar } from '@material-ui/core';
import axios from 'axios';
import * as Rest from './../services/restapi';
import LoadingSpinner from '../components/controls/spinner';
import TextField from '@mui/material/TextField';




const Assign = () => {
  const location = useLocation();
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
   const { selectedProspects } = location.state||[];
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null); // Use null to represent no selection
  const [employeeID, setEmployeeID] = useState('');
 
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [saving, setSaving] = useState(false); // Initialize saving state
  const token = localStorage.getItem("X-fiftyaccess-Token");

  // Fetch employee data from the API and set it to the employeeOptions state
  useEffect(() => {
    axios.get(Rest.viewallemployees,{
      headers: {
          "X-fiftyaccess-Token": token
      }
    })
      .then((response) => {
        const options = response.data.data.map((employee) => ({
          value: employee.EmployeeID,
          label: `${employee.FirstName} ${employee.LastName}`,
        }));
        setEmployeeOptions(options);
        setLoading(false); // Finished loading employee data
      })
      .catch((error) => {
        console.error('Error fetching employee data:', error);
        setLoading(false); // Finished loading with an error
      });
  }, [token]);
 

  const [numbers, setNumbers] = useState("");

  let prospectids

  if (selectedProspects && selectedProspects.length > 0) {
    prospectids = selectedProspects.map((prospect) => prospect.prospectid);

  }

const handleAssignEmployee = () => {
  // Check if a valid employee ID is selected
  if (employeeID && !saving) { // Disable the button when saving

    setSaving(true); // Start saving process
    const headers = {
      "X-fiftyaccess-Token": token,
      'Content-Type': 'application/json',
    };
    
    // Find the selected employee object from employeeOptions
    const selectedEmployeeObject = employeeOptions.find(option => option.value === employeeID);

    // Extract the first name and last name from the label
    const [ employeefirstname,employeelastname] = selectedEmployeeObject.label.split(' ');

    // Make an API call to assign the selected prospects to the employee
    axios
      .post(Rest.assignEmployee, {
        EmployeeID: employeeID,
        prospects: prospectids,
        numberOfRecords: numbers,
        employeefirstname: employeefirstname,
        employeelastname: employeelastname,
        
      }, { headers: headers })
      .then((response) => {
         if (response?.data?.code === "S001") {
          setIsSuccessSnackbarOpen(true);
          setSaving(false); // Finish saving process
            setTimeout(() => {
              navigate('/prospect');
            }, 500);
          } else {
            alert(response?.data?.msg || response?.data?.data)
          }
      })
      .catch((err) => {
        alert("Error: " + err.message);
      })
      .finally(() => {
        setSaving(false); // Finish saving process
      });
  } else {
    console.error('No employee selected or already saving.');
  }
};



  const handleSalesPersonChange = (selectedEmployeeID) => {
    setEmployeeID(selectedEmployeeID);

    // Find the selected employee object from employeeOptions
    const selectedEmployeeObject = employeeOptions.find(option => option.value === selectedEmployeeID);
    setSelectedEmployee(selectedEmployeeObject);
  };
  const handleSnackbarClose = () => {

    setIsSuccessSnackbarOpen(false);
  };
 
  const handleOnnumberChange = (value) => {
    const sanitizedValue = value.replace(/^0+/, '');
    const parsedValue = parseInt(sanitizedValue, 10); // Parse the sanitized value to an integer
  
    if (!isNaN(parsedValue) || value === "") {
      setNumbers(isNaN(parsedValue) ? "" : parsedValue);
    }
  };
  


  return (
    <div style={{ marginTop: '5rem' }}>
      <Form onSubmit={(e) => e.preventDefault()}>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem', padding: '5px' }}>
          <h3 style={{ marginLeft: '5px' }}>Assignee</h3>
          <Grid container style={{ marginLeft: '10rem' }}>
            <Grid item xs={4}>
              <Controls.Dropdown
                label="Sales Person"
                options={employeeOptions}
                value={employeeID}
                onChange={(e) => handleSalesPersonChange(e.target.value)}
              />
            </Grid>
            {!selectedProspects && (<Grid item xs={6}>
              <div style={{marginTop:'10px',marginRight:'2rem'}}>Enter Number:
           <input
    placeholder="Enter number"
    type="number"
    value={numbers}
    onChange={(e) => handleOnnumberChange(e.target.value)}
 
  />
  </div>

            </Grid>)}
            <Grid style={{ marginLeft: '40rem' }}>
              <Controls.Button
                text="Save"
                onClick={handleAssignEmployee}
                variant="contained"
                color="primary"
                disabled={saving || loading} // Disable the button when saving or loading
              >
                {saving ? <LoadingSpinner size={24} /> : 'Save'}
              </Controls.Button>
              <Controls.Button
                text="Cancel"
                variant="contained"
                color="primary"
                onClick={() => navigate('/prospect')}
              />
            </Grid>
          </Grid>
        </div>
        <div>
        {selectedProspects && selectedProspects.length > 0 && (
          <div style={{ padding: '15px', marginTop: '2rem' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead style={{ backgroundColor: 'rgb(234, 237, 240,1)' }}>
                  <TableRow>
                    <TableCell>Prospect ID</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone Number</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Assignee Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ backgroundColor: "#F5F5F5" }}>
                  {selectedProspects.map((prospect) => (
                    <TableRow key={prospect.prospectid}>
                      <TableCell>{prospect.prospectid}</TableCell>
                      <TableCell>{prospect.firstname}</TableCell>
                      <TableCell>{prospect.lastname}</TableCell>
                      <TableCell>{prospect.emailid}</TableCell>
                      <TableCell>{prospect.phonenumber}</TableCell>
                      <TableCell>{prospect.status}</TableCell>
                      <TableCell>{selectedEmployee ? selectedEmployee.label : ''}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </div>)}
          </div>
      
      </Form>
      <Snackbar
            sx={{ marginLeft: '30rem' }}
            open={isSuccessSnackbarOpen}
            autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
            onClose={handleSnackbarClose}
            message="Data successfully Updated"
          />
    </div>
  );
};

export default Assign;
