import React, { useEffect, useRef, useState } from "react";
import { Typography, Container, Grid} from "@material-ui/core";
import Controls from "../components/controls/Controls";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "../components/useForm";
import moment from "moment";
import axios from "axios";
import { Snackbar } from "@mui/material";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Fab, Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete'; 
import {  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,  } from "@mui/material";
import * as Rest from  './../services/restapi';
import LoadingSpinner from '../components/controls/spinner'; 

const addons =[
  {value:'Yes',label:'Yes'},
  {value:'No',label:'No'}
]

const ncb =[
  {value:0,label:0},
  {value:20,label:20},
  {value:25,label:25},
  {value:30,label:30},
  {value:35,label:35},
  {value:50,label:50},
]

function capitalizeFirstLetter(string) {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
}

const AvEditOpportunity = () => {
  const location = useLocation();
  const OpportunityData = location.state.updateOpportunity[0];
  const [opportunityValues, setOpportunityValues] = useState(OpportunityData) 
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [attachmentToDeleteIndex, setAttachmentToDeleteIndex] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const customer = location.state?.customer;
  const prospectid = opportunityValues.prospectid;
  const beneficiaryid = opportunityValues.beneficiaryid;
  const referralid = opportunityValues.referralid;
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(
    opportunityValues.beneficiarydetails?.[0]?.firstname
  );
  const [providerOptions, setProviderOptions] = useState([]);
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [filename, setFileName] = useState(null);
  const [fields, setFields] = useState([]);
  const [opportunityStatusOptions, setOpportunityStatusOptions] = useState([]);
  const [businessTypeOptions, setBusinessTypeOptions] = useState([]);
  const opportunityid = opportunityValues.opportunityid
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [attachments, setAttachments] = useState([{ file: null, fileName: '' }]);
  const isInitialMount = useRef(true);
  const isStatusMount = useRef(true);
  const isViewMount = useRef(true);
  const isProductMount = useRef(true);

  const handleFileChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const file = event.target.files[0];
  
    // Check if a file is selected
    if (file) {
      updatedAttachments[index].file = file;
      setAttachments(updatedAttachments);
    }
  };

  const handleFileNameChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const currentAttachment = updatedAttachments[index];
  
    const currentFile = currentAttachment.file;
    const newFileName = event.target.value;
  
    // Remove the existing extension if present and append the new extension
    const updatedFileName = removeFileExtension(newFileName) + getFileExtension(currentFile.name);
  
    const updatedFile = new File([currentFile], updatedFileName, { type: currentFile.type });
    // Update the attachment object with the modified file name and file
    updatedAttachments[index] = {
      ...updatedAttachments[index],
      file: updatedFile,
      fileName: event.target.value,
    };
  
    setAttachments(updatedAttachments);
  };
  
  // Helper function to get file extension
  const getFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(lastDotIndex) : '';
  };
  
  // Helper function to remove file extension
  const removeFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(0, lastDotIndex) : fileName;
  };
 
  const addAttachment = () => {
    setAttachments([...attachments, { file: null, fileName: '' }]);
  };
 
  const removeAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(updatedAttachments);
  };
 
  const handleCancel = () => {
    // Use the navigate function to go back to the previous page
    navigate(-1); // This will go back one step in the navigation history
  };
  
  const handleDeleteAttachment = (index) => {
    setAttachmentToDeleteIndex(index);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete operation
    const updatedAttachments = attachments.filter((_, i) => i !== attachmentToDeleteIndex);
    setAttachments(updatedAttachments);

    setIsDeleteDialogOpen(false);
    setAttachmentToDeleteIndex(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setAttachmentToDeleteIndex(null);
  };

  const formatDate = (date) => {
    const dateObject = new Date(date * 1000);

    if (isNaN(dateObject.getTime())) {
      // Handle invalid date
      return "";
    }
    return dateObject;
  };

  const handleSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };
  const [premiumErrors, setPremiumErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const subpremiumsdetails = products.find(product => product.product === opportunityValues.product)?.subpremiums[0];

  const subpremiums = Object.keys(subpremiumsdetails || {}).reduce((acc, key) => {
    acc[key] = (opportunityValues.subpremiums || {})[key] || subpremiumsdetails[key] || '';
    return acc;
  }, {});

const productDetails = products.find(product => product.product === opportunityValues.product)?.attributefields[0];

const productdetails = Object.keys(productDetails || {}).reduce((acc, key) => {
  acc[key] = (opportunityValues.productdetails || {})[key] || productDetails[key] || '';
  return acc;
}, {});

  const subProductOptions = products.find(
    (product) => product.product === opportunityValues.product
  )?.subProductDetails;

  const policyOption = subProductOptions
  ?.find((product) => product.product === opportunityValues.product)
  ?.subProductDetails?.policyTypeDetails.map((policyType) => ({
      value: policyType,
      label: policyType, 
  })) || products.find((product) => product.product === opportunityValues.product)
  ?.subProductDetails?.[0]?.policyTypeDetails.map((policyType)=>({
    value:policyType,
    label:policyType
  })) ||[]

  const beneficiaryOptions = opportunityValues.beneficiarydetails.map(
    (beneficiary, index) => ({
      value: `${beneficiary.firstname} ${beneficiary.lastname}`,
      label: `${beneficiary.firstname} ${beneficiary.lastname}`,
    })
  );

  useEffect(() => {
    const netPremium = parseFloat(opportunityValues.netpremium) || 0;
    const tax = parseFloat(opportunityValues.tax) || 0;
  
    const newGrossPremium = netPremium + tax;
  
    setOpportunityValues((prevValues) => ({
      ...prevValues,
      grosspremium: newGrossPremium || 0,
    }));
  }, [opportunityValues.netpremium, opportunityValues.tax]);

  useEffect(() => {
    // When the component mounts, populate the state with the customer data
    if (customer) {
      setOpportunityValues(customer);
    }
  }, [customer]);

  // Function to handle the form submission and update the data
  const handleUpdate = async () => {
    /* setLoading(true); */
    setIsLoading(true);
    const formData = new FormData();
        attachments.forEach((attachment, index) => {
          formData.append(attachment.file ? removeFileExtension(attachment.file.name) : '', attachment.file);
        });
    const updatedOpportunityValues = {...opportunityValues,opportunityid:opportunityid,prospectid:prospectid,beneficiaryid:beneficiaryid,referralid:referralid}
    formData.append('subpremiums', JSON.stringify(subpremiums));
    formData.append('opportunityValues', JSON.stringify(updatedOpportunityValues));
    try {
      const response = await fetch(Rest.updateopportunity, {
        method: "PUT",
        headers: {
          "X-fiftyaccess-Token": token
        },
        body: formData, // Set the formData as the body
      },
      );
      const data = await response.json();
      console.log("data",data)
      if(data.code === "S001"){
      setIsSuccessSnackbarOpen(true); 
      setTimeout(() => {
        setIsLoading(false);
       /*  setLoading(false);  */
        navigate("/allopportunity");
      }, 1000); // Redirect after 2 seconds
    }else {
      setIsLoading(false);
     
      alert(data?.msg|| data?.data?.data )
    }
      // You can also reset the form or perform other actions as needed
    } catch (err) {
      setIsLoading(false);
      alert("Error: " + err.message);
    }
  };
  const handleBeneficiaryChange = (event) => {
    setSelectedBeneficiary(event.target.value);
  };

  const handleProviderChange = (event) => {
    const provider = event;
    setOpportunityValues((opportunityValues) => ({
      ...opportunityValues,
      provider: provider,
    }));
  };
  const handleOpportunityChange = (field, value) => {
    // Regular expression to match numbers with optional decimal points
    const validNumberRegex = /^-?\d*\.?\d*(%)?$/;
  
    if (field === 'tax') {
      // Check if the input value is a valid number or decimal
      if (validNumberRegex.test(value)) {
        // Clear any previous error message
        setErrorMessage('');
        setOpportunityValues((prevValues) => ({
          ...prevValues,
          opportunityid: opportunityid,
          prospectid: prospectid,
          beneficiaryid: beneficiaryid,
          referralid: referralid,
          [field]: value,
        }));
      } else {
        // Set an error message when the input is not a valid number
        setErrorMessage('Please enter value only');
      }
    } else {
      setOpportunityValues((prevValues) => ({
        ...prevValues,
        opportunityid: opportunityid,
        prospectid: prospectid,
        beneficiaryid: beneficiaryid,
        referralid: referralid,
        [field]: value,
      }));
    }
  };
  
  function calculateNetpremium(subpremiums) {
    if (subpremiums) {
      let sum = 0;
      const myObject = subpremiums;
  
      for (const key in myObject) {
        const value = myObject[key];
        if (value) {
          sum += parseFloat(value);
        }
        
      }
      setOpportunityValues((prevValues) => ({
        ...prevValues,
        netpremium: sum,
      }));
      return sum;
    }
  
    return 0; // Return a default value if subpremiums are not available
  }
  
const handlePremiumChange = (fieldName, value) => {
    // Create a copy of the opportunityValues object to avoid mutating the state directly
    const updatedOpportunityValues = { ...opportunityValues,subpremiums };
    updatedOpportunityValues.subpremiums[fieldName] = value;
    updatedOpportunityValues.subpremiums = updatedOpportunityValues.subpremiums
    setOpportunityValues(updatedOpportunityValues);
  };

  /* const handleAttributeChange = (fieldName, value) => {
    // Create a copy of the opportunityValues object to avoid mutating the state directly
    const updatedOpportunityValues = { ...opportunityValues,productdetails };
    updatedOpportunityValues.productdetails[fieldName] = value;
    updatedOpportunityValues.productdetails = updatedOpportunityValues.productdetails
    setOpportunityValues(updatedOpportunityValues);
  }; */
  const handleAttributeChange = (fieldName, value) => {
    let updatedProductDetails = { ...opportunityValues.productdetails };
  
    // Update the productdetails object based on the selected fieldName and value
    updatedProductDetails[fieldName] = value;
  
    // If Claim Status is selected and its value is 'Yes'
    if (fieldName === "Claim Status" && value === "Yes") {
      updatedProductDetails["Eligible NCB %"] = 0; // Set Eligible NCB % to 0
    }
  
    // Update the opportunityValues state
    setOpportunityValues({
      ...opportunityValues,
      productdetails: updatedProductDetails,
    });
  };
  

  // Function to handle changes in subpremiums
  const handleSubpremiumChange = (event, index) => {
    const { name, value } = event.target;
    const updatedSubpremiums = [...opportunityValues?.subpremiums];
    updatedSubpremiums[index][2] = value;

    setOpportunityValues({
      ...opportunityValues,
      subpremiums: updatedSubpremiums,
    });
  };

  useEffect(() => {
    if (isInitialMount.current) {
    async function fetchBusinessTypes() {
      try {
        const response = await axios.get(Rest.viewallbusinesstypes,{
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        });
        if (response.status === 200) {
        
          const businessTypes = response.data.businesstypes;
          // Create an array of options with the required format
          const options = businessTypes.map((type) => ({
            value: type,
            label: type,
          }));
          // Set the business type options state
          setBusinessTypeOptions(businessTypes);
        }
      } catch (error) {
        console.error('Error fetching business types:', error);
      }
    }
    fetchBusinessTypes();
    isInitialMount.current = false;
    }
  }, []);

  
  useEffect(() => {
    if (isStatusMount.current) {
    async function fetchOpportunityStatusOptions() {
      try {
        const response = await axios.get(Rest.opportunitystatusapi,{
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        });
        if (response.status === 200) {
          setOpportunityStatusOptions(response?.data?.opportunitystatus);
        }
      } catch (error) {
        console.error('Error fetching Lead status options:', error);
      }
    }
    fetchOpportunityStatusOptions();
    isStatusMount.current = false;
    }
  }, []);

  useEffect(() => {
    if (isViewMount.current) {
    fetch(Rest.ViewAllProvider,{
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the options state with the fetched data
        setProviderOptions(data.Data); // Assuming `setDropdownOptions` is a state setter function
      })
      .catch((error) => {
        // Handle error
      });
      isViewMount.current = false;
    }
  }, []);

  // Fetch product data on component mount
  useEffect(() => {
    if(isProductMount.current){
    async function fetchProducts() {
      try {
        const response = await axios
        .get(Rest.viewallproduct,{
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        }
        );
        const productData = response?.data?.data;
        setProducts(productData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    }
    fetchProducts();
    isProductMount.current=false;
  }
  }, []);

  useEffect(() => {
    // Fetch product details when selected product changes
    async function fetchSelectedProductData(productid) {
      if (productid) {
        try {
          const response = await axios
          .get(Rest.viewproduct,{
            params:{
              productid:productid
            },
            headers: {
              'Content-Type': 'application/json',
              "X-fiftyaccess-Token": token
            },
          });
          setSelectedProduct(response?.data);
        } catch (error) {
          console.error("Error fetching selected product:", error);
        }
      }
    }

    // filter product by name and get productid
    let filteredproductID = products.find(product=>product.product===opportunityValues.product)

    if(filteredproductID){
    fetchSelectedProductData(filteredproductID.productid);}
  }, [opportunityValues.product, products]);
  const isValidNumber = (value) => {
    const validNumberRegex = /^-?\d*\.?\d*(%)?$/;;
    return validNumberRegex.test(value);
  };


  return (
    <div style={{ marginTop: '1.5rem', marginLeft: '0.5rem', minHeight: '100vh' }}>
    {/* {loading ? (
      <LoadingSpinner />
    ) : ( */}
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "25rem",
          marginTop: "5rem",
        }}
      >
        <Typography
          style={{ marginLeft: "3rem" }}
          variant="body6"
          align="center"
          gutterBottom
        >
          {OpportunityData?.prospectid}
          <p>
            {capitalizeFirstLetter(OpportunityData?.firstname)}{" "}
            {capitalizeFirstLetter(OpportunityData?.lastname)}
          </p>
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Lead
        </Typography>
      </div>
      <Container>
        <Form>
          <Grid container>
            <Grid item xs={4} style={{ marginTop: "0.5rem" }}>
              <Controls.Dropdown
                label="Beneficiary Name"
                options={beneficiaryOptions.map((beneficiary) => ({
                  value: beneficiary.value,
                  label: beneficiary.label,
                }))}
                value={
                  opportunityValues.beneficiarydetails?.[0]?.firstname +
                  " " +
                  opportunityValues.beneficiarydetails?.[0]?.lastname
                }
                onChange={handleBeneficiaryChange}
              />
              <Controls.Dropdown
                label="Provider"
                options={providerOptions?.map((option) => ({
                  value: option.provider,
                  label: option.provider,
                }))}
                value={opportunityValues.provider}
                onChange={(e) => handleProviderChange(e.target.value)}
              />
             
             <Controls.Dropdown
                options={businessTypeOptions.map((option) => ({ value: option, label: option }))}
                label="Business Type"
                value={opportunityValues.businesstype} // Make sure this is the correct value field
                onChange={(e) => handleOpportunityChange('businesstype', e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
            <Controls.Dropdown
                options={products?.map((product) => ({
                  value: product.product,
                  label: product.product,
                }))}
                label="Product"
                value={opportunityValues.product}
                onChange={(e) =>
                  handleOpportunityChange("product", e.target.value)
                }
              />
              { subProductOptions?.length >1 && (
              <Controls.Dropdown
                options={
                  subProductOptions?.map((subProduct) => ({
                    value: subProduct.subproduct,
                    label: subProduct.subproduct,
                  })) || []
                }
                label="Sub Product"
                value={opportunityValues?.subproduct||[]}
                onChange={(e) =>
                  handleOpportunityChange("subproduct", e.target.value)
                }
              />
              )}
              <Controls.Dropdown
                options={policyOption}
                label="Policy Type"
                value={opportunityValues.interestedpolicytype}
                onChange={(e) =>
                  handleOpportunityChange(
                    "interestedpolicytype",
                    e.target.value
                  )
                }
              />
            </Grid>
            <Grid item xs={4}>
            <Controls.Dropdown
              label="Lead Status"
              options={opportunityStatusOptions.map((option) => ({ value: option, label: option }))}
              value={opportunityValues.opportunitystatus}
              onChange={(e) => handleOpportunityChange('opportunitystatus', e.target.value)}
            />   
              <Controls.Input
                label="Probability"
                name="probability"
                value={opportunityValues.probability}
                onChange={(e) =>
                  handleOpportunityChange("probability", e.target.value)
                }
              />
            </Grid>
          </Grid>
          <div>Premium Details</div>
         <Grid container>
            {subpremiums && Object.entries(subpremiums).map(([fieldName, fieldValue]) => (
           <Grid item xs={4} key={fieldName}> 
           <Controls.Input
            type="number"
            label={fieldName}
            value={fieldValue}
            onChange={(e) => {  const value = e.target.value;
              if (isValidNumber(value)) {
                handlePremiumChange(fieldName, value);
                setPremiumErrors((prevErrors) => ({
                  ...prevErrors,
                  [fieldName]: '',
                }));
              } else {
                setPremiumErrors((prevErrors) => ({
                  ...prevErrors,
                  [fieldName]: 'Please Enter Only Values',
                }));
              }
              calculateNetpremium(subpremiums)
            }}
          />
          {premiumErrors[fieldName] && (
            <div style={{ color: 'red', fontSize: '10px' }}>{premiumErrors[fieldName]}</div>
          )}
              </Grid>
                ))} 
          
       <Grid item xs={4}>
      <Controls.Input
        label="Tax Amount"
        value={opportunityValues.tax}
        onChange={(e) => handleOpportunityChange("tax", e.target.value)}
      />
      {errorMessage && <div style={{ color: 'red', fontSize: '10px' }}>
        {errorMessage}
        </div>}
    </Grid>          
                  <Grid item xs={4}>  
                  <Controls.Input
                      label="Net Premium"
                      value={opportunityValues.netpremium}
                      onChange={(e) =>
                        handleOpportunityChange("netpremium", e.target.value)
                      }
                      readOnly={true}
                  />
                  </Grid>
                  <Grid item xs={4}>
                  <Controls.Input
                      label="Gross Premium"
                      value={opportunityValues.grosspremium}
                      onChange={(e) =>
                        handleOpportunityChange("grosspremium", e.target.value)
                      }
                      readOnly={true}
                  />
                  </Grid>
          </Grid>
          {subProductOptions && (
            <>
  <div>Product Details</div>
  <Grid container >
  {productdetails && Object.entries(productdetails)?.map(([fieldName, fieldValue]) => (
  <Grid item xs={4} key={fieldName}>
    {fieldName === "Addons Opted (Y/N)" ? (
      <Controls.Dropdown
        options={addons}
        label={fieldName}
        value={fieldValue}
        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
      />
    ):fieldName === "Eligible NCB %" ? (
      <Controls.Dropdown
        options={ncb}
        label={fieldName}
        value={fieldValue || 0}
        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
      />
    ) :fieldName === "Claim Status" ? (
      <Controls.Dropdown
        options={addons}
        label={fieldName}
        value={fieldValue}
        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
      />
    ) : (
      <Controls.Input
        type="text"
        label={fieldName}
        value={fieldValue}
        onChange={(e) => handleAttributeChange(fieldName, e.target.value)}
      />
    )}
  </Grid>
))}
          </Grid>
            </>
          )}
          <div>
          <IconButton onClick={addAttachment}>
        <h5>Attachements</h5> <AddIcon />
      </IconButton>
      {attachments.map((attachment, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <label htmlFor={`uploadfile-${index}`}>
                        <input
                          type="file"
                          id={`uploadfile-${index}`}
                          style={{ display: "none" }}
                          onChange={(event) => handleFileChange(event, index)}
                        />
                  <Fab
                    style={{ backgroundColor: '#914572', color: '#ffff', marginTop: '4px', marginLeft: '10px', fontSize: '8px', padding: '10px',fontsize:'10px',textAlign:'center',borderRadius:'5px' }}
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    Upload File
                  </Fab>
                </label>
                <Grid item xs={3}>
                <Controls.Input
                  label="File Name"
                  value={attachment.file ? removeFileExtension(attachment.file.name) : ''}
                  onChange={(event) => handleFileNameChange(event, index)}
                  />
                      </Grid>
                      <IconButton onClick={() => handleDeleteAttachment(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
      
          </div>
          {/* <div >
          <IconButton  onClick={handleAddField}>
         <h6>Attachements</h6> <AddIcon />
           </IconButton>
           {fields.map((field, index) => (
        <div key={index}
        style={{
          display: "flex",
          alignItems: "center", // Center the elements vertically
          gap: "1px", // Adjust the spacing between input and delete icon
          marginRight: "30rem",
        }}
    >
          {field}
         <IconButton
            color="black"
            aria-label="Delete"
            onClick={() => handleDeleteAttachment(index)}
          >
              <DeleteIcon />
          </IconButton>
            </div>
      ))} 
          </div> */}

          <div style={{ marginTop: "2rem", marginLeft:"64rem"}}>
            <Controls.Button text="Update" onClick={handleUpdate} />
            {isloading&& (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
            <Controls.Button text="Cancel" onClick={handleCancel} />

          </div>
        </Form>
        <Snackbar
          sx={{ marginLeft: "30rem" }}
          open={isSuccessSnackbarOpen}
          autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
          onClose={handleSnackbarClose}
          message="Data successfully Updated"
        />
      </Container>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Attachment?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this attachment? 

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    {/* )} */}
    </div>
  );
};

export default AvEditOpportunity;
