import React from 'react';

const DateFormater = (timestamp) => {
    console.log("timestamp", timestamp)
    const formattedDate = new Date(timestamp * 1000).toLocaleDateString('en-IN', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone: 'Asia/Kolkata',
    });
    return formattedDate


};

export default DateFormater;
