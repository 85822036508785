import * as React from 'react';
import DatePicker from 'react-date-picker'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './../../App.css';
import moment from 'moment';

export default function ReactDatepicker(props) {
  const [value, setValue] = React.useState(moment(props.defaultValue).toDate());
  const { label,error=null,onChange,maxDate } = props;
console.log(value)
  return (
    <div >
    <DatePicker 
    className="custom-datepicker"
    label={label}
    format='dd-MM-yyyy' 
    value={value} 
    maxDate={maxDate}
    onChange={(e)=>{onChange(moment(e).format('DD-MM-YYYY'))
    setValue(e)
    
    }} 
    />
    </div>
  );
}