import React from 'react'
import { Button as MuiButton, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5),
        backgroundColor: '#914572', // Your custom color
        height:'35px',
        padding:'15px',
        color: '#fff', // Set text color to white for better contrast
        '&:hover': {
          backgroundColor: '#7b3f5c', // Custom color on hover (optional)
        }
    },
    label: {
        textTransform: 'none', 
        fontSize:'12px'
   }
}))

export default function Button(props) {

    const { text, size, color, variant, onClick, ...other } = props
    const classes = useStyles();

    return (
        <MuiButton
            variant={variant || "contained"}
            size={size || "large"}
            onClick={onClick}
            {...other}
            classes={{ root: classes.root, label: classes.label }}>
            {text}
        </MuiButton>
    )
}
