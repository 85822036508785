


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as Rest from './../services/restapi';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Controls from '../components/controls/Controls';
import LoadingSpinner from '../components/controls/spinner';
import DeleteIcon from '@mui/icons-material/Delete';

import DateFormater from './DateFormate'

const ViewCommission = () => {
  const [commissionData, setCommissionData] = useState(null);
  const [openProviders, setOpenProviders] = useState([]);
  const token = localStorage.getItem('X-fiftyaccess-Token');
  const [commissionRulePermission, setCommissionRulePermission] = useState(true);

  let navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(Rest.viewcommission, {
          headers: {
            'Content-Type': 'application/json',
            'X-fiftyaccess-Token': token,
          },
        });

        if (response.data.code === 'S001') {
          setCommissionData(response?.data?.data);
        } else {
          alert(response?.msg || response?.data?.data);
        }
      } catch (error) {

        if (error.response.data.data.msg === "Permission Denied") {
          setCommissionRulePermission(false)

        }
        // alert(error.response.data.data.msg);

      }
    };

    fetchData();
  }, []);

  const handleDelete = async (ruleid) => {
    try {
      // Show a confirmation dialog
      const isConfirmed = window.confirm('Do you want to delete this Commission Rule?');

      if (!isConfirmed) {
        // If the user clicks "Cancel" in the confirmation dialog
        return;
      }

      const response = await axios.put(
        Rest.deletecommission,
        {
          ruleid: ruleid,
          status: 'inActive',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-fiftyaccess-Token': token,
          },
        }
      );

      if (response.data.code === 'S001') {
        // Handle successful deletion
        alert('Commission Rule deleted successfully');

        // Remove the deleted rule from the commissionData state
        setCommissionData((prevCommissionData) => {
          const updatedData = prevCommissionData.map((providerData) => ({
            ...providerData,
            commissionRules: providerData.commissionRules.filter(
              (rule) => rule.ruleid !== ruleid
            ),
          }));
          return updatedData;
        });
      } else {
        alert(response?.msg || response?.data?.data);
      }
    } catch (error) {
      alert('Error: ' + error.message);
    }
  };


  //   const handleDelete = async (ruleid) => {
  //   try {
  //     const response = await axios.put(
  //       Rest.deletecommission,  
  //       {
  //         ruleid: ruleid,
  //         status: 'inActive',
  //       },
  //       {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'X-fiftyaccess-Token': token,
  //         },
  //       }
  //     );

  //     if (response.data.code === 'S001') {
  //       // Handle successful deletion (e.g., update the UI)
  //       alert('Do You want Delete this Commission Rule');
  //     } else {
  //       alert(response?.msg || response?.data?.data);
  //     }
  //   } catch (error) {
  //     alert('Error: ' + error.message);
  //   }
  // };


  const handleProviderClick = (provider) => {
    setOpenProviders((prevOpenProviders) => {
      if (prevOpenProviders.includes(provider)) {
        return prevOpenProviders.filter((p) => p !== provider);
      } else {
        return [...prevOpenProviders, provider];
      }
    });
  };

  const handleEdit = (rule, event) => {
    event.preventDefault();
    const ruleid = rule.ruleid;
    navigate(`/updateslab/${ruleid}`, { state: { ruleid: ruleid } });
  };
  return (
    <div style={{ marginTop: '4rem' }}>
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', rowGap: '50rem' }} >
          {commissionRulePermission && <h4>Commission Rules List</h4>}
          <div style={{ marginLeft: '50rem' }}>
            {commissionRulePermission && <Controls.Button text="Create Commission Rules" type="Submit" onClick={() => navigate(`/slab/`)}>Create Slab</Controls.Button>}
          </div>
        </div>
        {commissionData?.map((providerData, index) => (
          <div key={index}>
            <h6
              onClick={() => handleProviderClick(providerData?.provider)}
              style={{ cursor: 'pointer', color: '#2f4f4f' }}
            >
              {providerData?.provider}{' '}
              {openProviders.includes(providerData?.provider) ? <ExpandLess /> : <ExpandMore />}
            </h6>
            {openProviders.includes(providerData?.provider) && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ul>
                  {providerData?.commissionRules?.map((rule, ruleIndex) => (
                    <li key={ruleIndex} style={{ display: 'flex', justifyContent: 'space-between' }}>


                      {/* <div style={{ flexDirection: 'column', marginLeft: '30px' }}>
                          <div>
                            <p >Product:{rule.product}</p>
                          </div> */}

                      <div style={{ flexDirection: 'column', }}>
                        <div>
                          <p style={{ color: 'gray', display: 'inline', fontSize: '12px' }}>Product: </p>
                          <p style={{ color: 'black', display: 'inline', fontSize: '12px' }}>{rule.product}</p>
                        </div>
                        <div style={{ flexDirection: 'row', marginLeft: '20px' }}>
                          <p style={{ color: 'gray', display: 'inline', fontSize: '12px' }}>Subproduct:</p>
                          <p style={{ color: 'black', display: 'inline', fontSize: '12px' }}>{rule.subproduct}</p>
                        </div>
                        <div style={{ flexDirection: 'row', marginLeft: '40px' }}>
                          <p style={{ color: 'gray', display: 'inline', fontSize: '12px' }}>Policy Type:</p>
                          <p style={{ color: 'black', display: 'inline', fontSize: '12px' }}>{rule.policytype}</p>
                        </div>


                      </div>
                      <div style={{ flexDirection: 'column', marginLeft: '10rem', display: 'flex' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Link onClick={(event) => handleEdit(rule, event)}>
                            <div style={{ flexDirection: 'row' }}>
                              <p style={{ fontSize: '12px', display: 'inline', color: 'gray' }}>Rule ID:</p>
                              <p style={{ fontSize: '12px', display: 'inline' }}>{rule.ruleid}</p>
                            </div>
                          </Link>
                          {/* Move the DeleteIcon to the right side */}
                          <DeleteIcon
                            style={{ cursor: 'pointer', color: 'black', marginLeft: '1rem' }}
                            onClick={() => handleDelete(rule.ruleid)}
                          />
                        </div>
                        <div >
                          <p style={{ color: 'gray', display: 'inline', fontSize: '12px' }}>Effective Start Date:{' '}</p>
                          <p style={{ color: 'black', display: 'inline', fontSize: '12px' }}>{DateFormater(rule.effectivestartdate)}</p>
                        </div>
                        <div>
                          <p style={{ color: 'gray', display: 'inline', fontSize: '12px' }}>Effective End Date:{' '}</p>
                          <p style={{ color: 'black', display: 'inline', fontSize: '12px' }}>{DateFormater(rule.effectiveenddate)}</p>
                          <div>
                            <p style={{ color: 'gray', fontSize: '12px', display: 'inline' }}>Type:</p>
                            <p style={{ color: 'purple', fontSize: '12px', display: 'inline', marginLeft: '5px' }}>{rule.type}</p>
                          </div>
                          <div>
                            <p style={{ color: 'gray', fontSize: '12px', display: 'inline' }}>Based on:</p>
                            <p style={{ color: 'black', fontSize: '12px', display: 'inline', marginLeft: '5px' }}>{rule.basedon}</p>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>


      {!commissionRulePermission && (
        <h5 style={{ textAlign: "center" }}>No Permission</h5>
      )}
    </div >



  );
};

export default ViewCommission;


