import React, { useState, useEffect, useRef } from 'react';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, Typography, makeStyles } from '@material-ui/core';
import { Grid, TextareaAutosize, Link, Snackbar } from '@mui/material';
import plus from '../assests/icons/plus.png';
import asterisk from './../assests/icons/asterisk.png'
import Controls from '../components/controls/Controls';
import moment from 'moment';
import axios from 'axios';
import { Form } from '../components/useForm';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import calendar from './../assests/icons/calendar.png';
import * as Rest from './../services/restapi';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingSpinner from '../components/controls/spinner';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiDateTimePicker: {
      styleOverrides: {
        day: {
          '&.Mui-selected': {
            backgroundColor: 'your_selected_color_here', // Change this to your desired color
            color: 'your_text_color_here', // Change this to the text color you want
            '&:hover': {
              backgroundColor: 'your_hover_color_here', // Change this to the hover color
            },
          },
        },
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1400,
    margin: 'auto',
    marginTop: theme.spacing(2),
    backgroundColor: '#FFFFF',
  },
  highlighted: {
    padding: '10px',
    borderRadius: '40px',
  },
  customIcon: {
    width: 8, // Set the desired width of your custom icon
    height: 8, // Set the desired height of your custom icon
    verticalAlign: 'super',
    fontSize: '0.7em',
  },
}));

const InteractionChat = ({ opportunity, opportunityValues }) => {
  const classes = useStyles();
  const location = useLocation();
  const customer = location?.state;
  const opportunitiesstatus = customer?.customer?.opportunitystatus
  const [prospectStatus, setProspectStatus] = useState([]);
  const [interactionValues, setInteractionValues] = useState(
    {
      prospectid: '',
      beneficiaryid: '',
      opportunityid: '',
      interactiondateandtime: '',
      notes: '',
      nextoppointmentdateandtime: '',
      lostreason:'',
      opportunitystatus: opportunitiesstatus,
    }
  )
  const [showAllCards, setShowAllCards] = useState(false); // Step 1: State variable to control card visibility
  const [lostReasonsOptions, setLostReasonsOptions] = useState([]);
  const [prospectData, setProspectData] = useState({});
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [interactionHistory, setInteractionHistory] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [attachmentToDeleteIndex, setAttachmentToDeleteIndex] = useState(null);
  // const classes = useStyles();
  const [opportunityStatusOptions, setOpportunityStatusOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
/*   const [attachments, setAttachments] = useState([]) */;
const [isLoading, setIsLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editedContent, setEditedContent] = useState('');
  const [cards, setCards] = useState([]);
  const [opportunitystatus, setOpportunityStatus] = useState('');
  const [currentDate, setCurrentDate] = useState(dayjs());
  const [interactionDate, setInteractionDate] = useState(null)
  const isInitialMount = useRef(true);
  const isStatusMount = useRef(true);
  const isViewMount = useRef(true);
  const isLostMount = useRef(true);

  const prospectid = customer?.customer?.prospectid;
  const customerid = customer?.customer?.customerid;

  const beneficiaryid = customer?.customer?.beneficiaryid;
  const opportunityid = customer?.customer?.opportunityid
  const opportunityInteraction = interactionHistory?.interactionDetails;
  const prospectstatus = prospectData?.status;

  const navigate = useNavigate();
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const [attachments, setAttachments] = useState([{ file: null, fileName: '' }]);

  const handleFileChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const file = event.target.files[0];
  
    // Check if a file is selected
    if (file) {
      updatedAttachments[index].file = file;
      setAttachments(updatedAttachments);
    }
  };

  const handleFileNameChange = (event, index) => {
    const updatedAttachments = [...attachments];
    const currentAttachment = updatedAttachments[index];
  
    const currentFile = currentAttachment.file;
    const newFileName = event.target.value;
  
    // Remove the existing extension if present and append the new extension
    const updatedFileName = removeFileExtension(newFileName) + getFileExtension(currentFile.name);
  
    const updatedFile = new File([currentFile], updatedFileName, { type: currentFile.type });
    // Update the attachment object with the modified file name and file
    updatedAttachments[index] = {
      ...updatedAttachments[index],
      file: updatedFile,
      fileName: event.target.value,
    };
  
    setAttachments(updatedAttachments);
  };
  
  // Helper function to get file extension
  const getFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(lastDotIndex) : '';
  };
  
  // Helper function to remove file extension
  const removeFileExtension = (fileName) => {
    const lastDotIndex = fileName?.lastIndexOf('.');
    return lastDotIndex !== -1 ? fileName?.slice(0, lastDotIndex) : fileName;
  };

  const addAttachment = () => {
    setAttachments([...attachments, { file: null, fileName: '' }]);
  };
  const handleDeleteAttachment = (index) => {
    setAttachmentToDeleteIndex(index);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    // Perform the delete operation
    const updatedAttachments = attachments.filter((_, i) => i !== attachmentToDeleteIndex);
    setAttachments(updatedAttachments);

    setIsDeleteDialogOpen(false);
    setAttachmentToDeleteIndex(null);
  };

  const handleCancelDelete = () => {
    setIsDeleteDialogOpen(false);
    setAttachmentToDeleteIndex(null);
  };


  const handleAddCard = () => {
    setEditing(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInteractionValues({
      ...interactionValues,
      prospectid: prospectid,
      beneficiaryid: beneficiaryid,
      opportunityid: opportunityid,
      status: prospectstatus,
      [name]: value,
    });
  };
  const handleOpportunityStatusChange = (field, value) => {
    setInteractionValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleInteractionDateChange = (date) => {
    const interactiondateandtime = moment(date)?.format('DD-MM-YYYY hh:mm A')
    setInteractionDate(interactiondateandtime);
    setInteractionValues((interactionValues) => ({ ...interactionValues, interactiondateandtime: interactiondateandtime }));
  }
  const handleNextAppointmentDateChange = (date) => {
    const nextoppointmentdateandtime = moment(date)?.format('DD-MM-YYYY hh:mm A')
    setInteractionValues((interactionValues) => ({ ...interactionValues, nextoppointmentdateandtime: nextoppointmentdateandtime }));
  }

  /*  const handleFileChange = (event) => {
     const newAttachments = Array.from(event.target.files).map((file) => ({
       name: file.name,
       url: URL.createObjectURL(file),
     }));
     setAttachments([...attachments, ...newAttachments]); 
   }; */
   useEffect(() => {
    if (isLostMount.current) {
    const fetchLostReasons = async () => {
      try {
        const response = await axios.get(Rest.lostreasons, {
          headers: {
            'Content-Type': 'application/json',
            'X-fiftyaccess-Token': token, // Make sure to include your token if required
          },
        });

        if (response.status === 200) {
          setLostReasonsOptions(response.data.lostReasons);
        }
      } catch (error) {
        console.error('Error fetching lost reasons:', error);
      }
    };

    fetchLostReasons();
    isLostMount.current = false;
    }
  }, [token]); // Make sure to include dependencies, e.g., token


  useEffect(() => {
    if (isInitialMount.current) {
    axios.get(Rest.viewOpportunity, {
      params: {
        opportunityid: opportunityid
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then(response => {
        setProspectData(response?.data?.data[0]);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      isInitialMount.current = false;
    }
  }, [prospectid]);

  const handleSaveClick = async () => {
    try {
      setIsLoading(true);
      let interactiondateandtime;
if (interactionDate) {
  interactiondateandtime = interactionDate;
} else {
  const currentDate = moment(); // Assuming moment is defined in your code
  interactiondateandtime = currentDate.format("DD-MM-YYYY hh:mm A");
}
      console.log("interactiondateandtime",interactiondateandtime)
      const updatedInteractionValues = { ...interactionValues, interactiondateandtime: interactiondateandtime }
      const formDataObject = new FormData();
      attachments.forEach((attachment, index) => {
        formDataObject.append(attachment.file ? removeFileExtension(attachment.file.name) : '', attachment.file);
      });
      formDataObject.append('prospectid', prospectid);
      formDataObject.append('customerid', customerid);

      formDataObject.append('beneficiaryid', beneficiaryid);
      formDataObject.append('opportunityid', opportunityid);
      formDataObject.append('interactiondateandtime', interactiondateandtime);
      formDataObject.append('nextoppointmentdateandtime', interactionValues.nextoppointmentdateandtime);
      formDataObject.append('notes', interactionValues.notes);
      formDataObject.append('opportunitystatus', interactionValues?.opportunitystatus);
      formDataObject.append('lostreason', interactionValues?.lostreason);
      formDataObject.append('status', prospectstatus);
      const response = await fetch(Rest.addinteractionhistory, {
        method: 'POST',
        headers: {
          /*  'Content-Type': 'application/json', */
          "X-fiftyaccess-Token": token
        },
        body: formDataObject,
      });
      const data = await response.json();
      if (data.code === "S001") {
        setIsSuccessSnackbarOpen(true);
        setTimeout(() => {
          fetchInteractionHistory();
          setInteractionValues({
            notes:'',
            nextoppointmentdateandtime:''
          })
          setSelectedDate('');
        }, 2000);
      } else{
        alert(data?.msg || data?.data?.data)
      }
    } catch (err) {
      alert("Error: " + err.message);
    }
    finally {
      setIsLoading(true); // Set loading to false after the API request is completed (success or failure)
      setTimeout(() => {
        setIsLoading(false);
      }, 200);
    }


    if (editedContent.trim() !== '' || attachments.length > 0) {
      const currentTime = moment()?.format('HH:mm:ss');
      const newCard = {
        editedContent: editedContent,
        selectedDate: selectedDate ? selectedDate?.format('DD/MM/YYYY') : moment()?.format('DD/MM/YYYY'),
        attachments: [...attachments],
        systemTime: currentTime,
        currentDate: currentDate ? currentDate?.format('DD/MM/YYYY') : moment()?.format('DD/MM/YYYY'),
        opportunityStatus: opportunitystatus,
      };
      setCards((prevCards) => [newCard, ...prevCards]);
      // Reset input fields
      setEditing(false);
      setEditedContent('');
      setSelectedDate(null);
      setAttachments([]);
      setCurrentDate(null);
      setOpportunityStatus('');

    }
  };
  useEffect(() => {
    if (isStatusMount.current) {
    async function fetchOpportunityStatusOptions() {
      try {
        const response = await axios.get(Rest.opportunitystatusapi, {
          headers: {
            'Content-Type': 'application/json',
            "X-fiftyaccess-Token": token
          },
        });
        if (response.status === 200) {
          setOpportunityStatusOptions(response?.data?.opportunitystatus);
        }
      } catch (error) {
        console.error('Error fetching Lead status options:', error);
      }
    }

    fetchOpportunityStatusOptions();
    isStatusMount.current = false;
    }
  }, []);

  const handleCancelClick = () => {
    setEditing(false);
    setEditedContent('');
    setSelectedDate(null);
    setAttachments([]);
    setCurrentDate(dayjs());
    setOpportunityStatus('');
    setInteractionValues({
      notes: ''
    })
  };

  const handleSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };
  const fetchInteractionHistory = async () => {
    try {
      const response = await axios.get(Rest.viewInteractionhistory,{
        params:{
          opportunityid:opportunityid
        },
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-fiftyaccess-Token': token,
          },
        }
      );
      if (response.status === 200) {
        setInteractionHistory(response?.data?.data);
      }
    } catch (error) {
      console.error('Error fetching interaction history:', error);
    }
  };

  useEffect(() => {
    if (isViewMount.current) {
    fetchInteractionHistory();
    isViewMount.current = false;
    }
  }, [opportunityid, token]);

  const renderInteractionCards = () => {
    const interactionsArray = Object.values(opportunityInteraction);
    return interactionsArray.map((interaction, index) => (
      <Card key={index} variant="outlined" style={{ marginTop: '10px' }}>
        <CardContent>
          {/* Display interaction data here */}
          <Typography variant="h6">Interaction {index + 1}</Typography>
          <Typography variant="body1">Interaction Date and Time: { }</Typography>
          <Typography variant="body1">Notes: { }</Typography>
          <Typography variant="body1">Next Appointment Date and Time: { }</Typography>
          <Typography variant="body1">Status: {prospectData?.status}</Typography>
          {/* Add other fields as needed */}
        </CardContent>
      </Card>
    ));
  };

  return (
    <div style={{ marginTop: '4rem' }}>
      <Card>
        <CardContent>
          <Typography variant="body6" align="center" gutterBottom>
            {customer?.customer?.customerid ? customer?.customer?.customerid : customer?.customer?.prospectid}
          </Typography>
          <Typography style={{ marginLeft: '10rem' }} variant="body6" align="center" gutterBottom>
            {prospectData?.firstname} {prospectData?.lastname}
          </Typography>
        </CardContent>
      </Card>
      <Form onSubmit={(e) => e.preventDefault()}>
        <Card style={{ marginTop: '5px', padding: '5px' }}>
          <Snackbar
            sx={{ marginLeft: '30rem' }}
            open={isSuccessSnackbarOpen}
            autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
            onClose={handleSnackbarClose}
            message="Data successfully added"
          />
          <CardContent style={{ height: '4rem', display: 'flex', flexDirection: 'row' }}>
            <Typography variant="h6" color="text.secondary">
              Interactions
            </Typography>
            {/* <img onClick={handleAddCard} style={{ marginLeft: '65.4rem', paddingTop: '0px' }} src={plus} alt="add" className="action-button-icon" />
            <Typography variant="body6" color="text.secondary">Add</Typography> */}
          </CardContent>
        </Card>
       
        <Card variant="outlined" >
            <CardContent>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '20rem' }}>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                      <DemoItem>
                        <DateTimePicker
                          label={
                            <span>
                              Contact Date and Time
                              <img src={asterisk} alt="Star Logo" className={classes.customIcon} />
                            </span>
                          }
                          defaultValue={currentDate}
                          onChange={(e) => { handleInteractionDateChange((e.toDate())) }}
                          format='DD-MM-YYYY hh:mm A'
                          maxDate={dayjs()}
                          sx={{
                            backgroundColor: 'rgb(234, 237, 240,1)',
                            borderRadius: '5px',
                            '& .MuiInputLabel-root': {
                              fontSize: '12px',
                            },
                            '& .MuiSvgIcon-root': {
                              width: '24px',
                              height: '24px',
                              background: `url(${calendar})`,
                              backgroundSize: 'contain',
                              display: 'block',
                            },
                            '& input': {
                              width: '30',
                              height: '30',
                              padding: '12px',
                              fontSize: '12px',
                              alignContent: 'center',
                            },
                            '& .MuiInput-underline': {
                              '&:before': {
                                borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color
                              },
                              '&:hover:not(.Mui-disabled):before': {
                                borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color
                              },
                            },
                          }}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                  </ThemeProvider>
                  <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DateTimePicker']}>
                      <DemoItem>
                        <DateTimePicker
                          label={
                            <span>
                              Next Contact Date and Time
                              <img src={asterisk} alt="Star Logo" className={classes.customIcon} />
                            </span>
                          }
                          value={selectedDate}
                          onChange={(e) => handleNextAppointmentDateChange((e.toDate()))}
                          format='DD-MM-YYYY hh:mm A'
                          disabled={interactionValues.opportunitystatus === 'ClosedandWon'||interactionValues.opportunitystatus === 'Lost'}
                          minDate={dayjs()}
                          sx={{
                            backgroundColor: 'rgb(234, 237, 240,1)',
                            borderRadius: '5px',
                            '& .MuiInputLabel-root': {
                              fontSize: '12px',
                            },
                            '& .MuiSvgIcon-root': {
                              width: '24px',
                              height: '24px',
                              background: `url(${calendar})`,
                              backgroundSize: 'contain',
                              display: 'block',
                            },
                            '& input': {
                              width: '30',
                              height: '30',
                              padding: '12px',
                              fontSize: '12px',
                              alignContent: 'center',
                            },
                            '& .MuiInput-underline': {
                              '&:before': {
                                borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the default underline color
                              },
                              '&:hover:not(.Mui-disabled):before': {
                                borderBottomColor: 'rgba(0, 0, 0, 0.42)', // Override the hover underline color
                              },
                            },
                          }}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>
                  </ThemeProvider>
                  </div>
                <div style={{ width: '30rem' }}>
                    <TextareaAutosize
                      name="notes"
                      label="Interaction"
                      placeholder='Enter your comment...'
                      value={interactionValues.notes}
                      onChange={handleChange}
                      maxRows={4}
                      minRows={4}
                      style={{ fontSize: '12px', width: '100%', marginTop: '0.5rem', height: '120px', marginLeft: '2rem', resize: 'none' }}
                    />
                 </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '20rem',marginLeft:'8rem' }}>
                    <Controls.Dropdown
                      label={
                        <span>
                          Lead Status
                          <img src={asterisk} alt="Star Logo" className={classes.customIcon} />
                        </span>
                      }
                      options={opportunityStatusOptions.map((option) => ({ value: option, label: option }))}
                      value={interactionValues.opportunitystatus}
                      onChange={(e) => handleOpportunityStatusChange('opportunitystatus', e.target.value)}
                    />
                      {interactionValues.opportunitystatus === 'Lost' && (
                    // Render the new dropdown only when the status is 'Lost'
                    <Controls.Dropdown
                      label={
                        <span>
                          Lost Reason
                          <img src={asterisk} alt="Star Logo" className={classes.customIcon} />
                        </span>
                      }
                      options={lostReasonsOptions.map((reason) => ({ value: reason, label: reason }))}
                      value={interactionValues.lostreason}
                      onChange={(e) => setInteractionValues((prevValues) => ({ ...prevValues, lostreason: e.target.value }))}
                    />
                  )}
                    <IconButton onClick={addAttachment}>
                      <span style={{ fontSize: '12px' }}>Attachements</span> <AddIcon />
                    </IconButton>
                    {attachments.map((attachment, index) => (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                      <label htmlFor={`uploadfile-${index}`}>
                        <input
                          type="file"
                          id={`uploadfile-${index}`}
                          style={{ display: "none" }}
                          onChange={(event) => handleFileChange(event, index)}
                        />
                  <Fab
                    style={{ backgroundColor: '#914572', color: '#ffff', marginTop: '4px', marginLeft: '10px', fontSize: '8px', padding: '10px',fontsize:'10px',textAlign:'center' }}
                    size="small"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    Upload File
                  </Fab>
                </label>
                <Grid item xs={6}>
                <Controls.Input
                  label="File Name"
                  value={attachment.file ? removeFileExtension(attachment.file.name) : ''}
                  onChange={(event) => handleFileNameChange(event, index)}
                  />
                      </Grid>
                      <IconButton onClick={() => handleDeleteAttachment(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                   </div>
                    <Dialog
                      open={isDeleteDialogOpen}
                      onClose={handleCancelDelete}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogTitle id="alert-dialog-title">Delete Attachment?</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          Are you sure you want to delete this attachment?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCancelDelete} color="primary">
                          Cancel
                        </Button>
                        <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                          Delete
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div style={{ marginLeft: '65.5rem' }}>
                  <Controls.Button
                    text="Save"
                    type="submit"
                    onClick={handleSaveClick}
                  />
                   {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
             background: 'rgba(0, 0, 0, 0.5)', // semi-transparent black background
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 9999, // Ensure the overlay is on top of other elements
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <LoadingSpinner />
          </div>
        </div>
      )}
                  <Controls.Button
                    text="Cancel"
                    onClick={handleCancelClick}
                  />
                </div>
            </CardContent>
          </Card>
        {cards.map((card, index) => (
          <Card key={index} variant="outlined" style={{ marginTop: '10px' }}>
            <CardContent >
              <div style={{ marginLeft: '55rem', marginTop: '0px' }}>
                {card.attachments.map((attachment, index) => (
                  <Link key={index} href={attachment.url} target="_blank" rel="noopener noreferrer">
                    {attachment.name}
                  </Link>
                ))}
              </div>
              <Grid>
                {/* <Grid ml={138}>
                <div style={{ marginLeft: '20px' }}  onClick={() => handleEditClick(index)}>
                  <img src={edits} alt="edit" className="action-button-icon" />
                  <Typography variant="body5" color="text.secondary">
                    Edit
                  </Typography>
                </div>
              </Grid> */}


              </Grid>
            </CardContent>
          </Card>
        ))}
        <div>
          <Grid container spacing={2} >
            {opportunityInteraction && opportunityInteraction.map((detail, index) => (
              <Grid item xs={12} style={{ paddingTop: '0px' }} key={index}>
                   {index < 3 || showAllCards ? ( // Step 2: Use slice to control card visibility
                <Card variant="outlined" style={{ marginTop: '5px' }}>
                  <CardContent style={{ display: 'grid', gridTemplateColumns: 'repeat(3,1fr)', gridTemplateRows: 'repeat(4, auto)', gap: '0.5rem' }}>
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Interaction Date and Time:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                        {moment(detail.interactiondateandtime * 1000)?.format('DD-MM-YYYY hh:mm A')}
                      </span>
                    </Typography>
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Message:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                        {detail.notes}
                      </span>
                    </Typography>
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Next Appointment Date and Time:{' '}
                      <span style={{ color: 'black', fontSize: '12px', fontWeight: 'bold' }}>
                        {moment(detail.nextoppointmentdateandtime * 1000)?.format('DD-MM-YYYY hh:mm A')}
                      </span>
                    </Typography>
                    {console.log("")}
                    <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                      Status:{' '}
                      <span style={{ color: 'purple', fontSize: '12px', fontWeight: 'bold' }}>
                        {detail?.interactionstatus}
                      </span>
                      {detail.interactionstatus === 'Lost' && detail.lostreason && (
              <Typography color="textSecondary" style={{ fontSize: '12px' }}>
                Lost Reason:{' '}
                <span style={{ color: 'Red', fontSize: '12px', fontWeight: 'bold' }}>
                  {detail.lostreason}
                </span>
              </Typography>
            )}
                    </Typography>
                    
                  </CardContent>
                </Card>
                ) : null}
              </Grid>
            ))}
          </Grid>
        </div>
        {opportunityInteraction && opportunityInteraction.length > 3 && ( // Show the button when there are more than 3 cards
          <div>
            <button
              onClick={() => setShowAllCards(!showAllCards)} // Step 3: Toggle the state variable
              style={{
                backgroundColor: 'orange',
                color: 'white',
                fontSize: '12px',
                borderRadius: '5px',
                float: 'right',
              }}
            >
              {showAllCards ? 'Show Less' : 'Show More'} {/* Toggle the button text */}
            </button>
          </div>
        )}
      </Form>
    </div>
  );
};
export default InteractionChat;