// ViewCustomer.js
import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import OpportunityBeneficiary from './OpportunityBeneficiary';
import PolicyDetails from './PolicyDetails';
import AvOpportunityDetails from './AvOpportunityDetails';
import InteractionChat from './InteractionChat';
import axios from 'axios';
import * as Rest from  './../services/restapi';

const ViewProspect = () => {
  const [prospectData, setProspectData] = useState({});
  const location =  useLocation();
  const customer = location.state;
  const prospectid = customer?.customer?.prospectid;
  const opportunityid = customer?.customer?.opportunityid;

  const token = localStorage.getItem("X-fiftyaccess-Token");
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
    axios.get(Rest.viewOpportunity,{
        params:{
            opportunityid:opportunityid
        },
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      }
    )
      .then(response => {
        setProspectData(response?.data?.data);
      })
      .catch(err => {
      });
      isInitialMount.current = false;
    }
  }, [opportunityid]);

    return (
      <div style={{padding:'10px'}}>
      <Card style={{width:'77.8rem',height:'5rem',position:'fixed',marginTop:'2.8rem',}}>
      <CardContent style={{display:'flex',flexDirection:'row'}}>
        <Typography variant="h6" component="div">
          {prospectData?.[0]?.firstname} {prospectData?.[0]?.lastname}
        </Typography>
        <Typography style={{marginLeft:'20rem'}} variant="h6" color="text.secondary">
          {prospectData?.[0]?.prospectdetailsdata?.emailid}
        </Typography>
        <Typography style={{marginLeft:'25rem'}} variant="h6" color="text.secondary">
          {prospectData?.[0]?.prospectdetailsdata?.phonenumber}
        </Typography>
      </CardContent>
    </Card>
    <div>
      <OpportunityBeneficiary />
    </div>
    <div>
    <AvOpportunityDetails />
    </div>
    </div>

  );
};

export default ViewProspect;
