
import React, { useEffect, useRef, useState } from "react";
import { CheckPicker, Grid } from 'rsuite';
import Controls from "../components/controls/Controls";
import '../App.css'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import * as Rest from './../services/restapi';
import { useLocation, useNavigate } from "react-router-dom";



function Main() {
  const [isEditMode, setIsEditMode] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [locations, setLocations] = useState({});

  const [selectedState, setSelectedState] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  const location = useLocation();
  const commissiondetails = location?.state?.commissiondetails;
  const provider = commissiondetails?.provider;
  const navigate = useNavigate();
  
  //   const states = Object.keys(locations);
  //   const cities = locations[selectedState] || [];
  const isLocMount = useRef(true);
  const states = Object.keys(locations).map(state => ({ label: state, value: state }));
  const cities = selectedState.reduce((cities, state) => {
    const citiesInState = (locations[state] || []).map(city => ({ label: city, value: city }));
    return cities.concat(citiesInState);
  }, []);

  
  console.log("!!!!!!!!!!!!!", cities)
  // const states = Object.keys(locations).map(state => ({ label: state, value: state }));
  // const cities = (locations[selectedState] || []).map(city => ({ label: city, value: city }));

  const [formInputData, setFormInputData] = useState({
    name: "",
    area: "",
    selectedState: [],
    selectedCity: [] // Add selectedOption2 to the state
  });


  const token = localStorage.getItem("X-fiftyaccess-Token");

  
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const checkEmptyInput = !Object.values(formInputData).every((res) => res === "");
  
    if (checkEmptyInput) {
      const newData = {
        area: formInputData.area,
        name: formInputData.name,
        provider: provider,
        state: formInputData.selectedState,
        city: formInputData.selectedCity,
      };
      if (!isDuplicateCombination(newData)) {
        // Continue with your existing logic
        // ...
      } else {
        alert("Combination already exists!");
      }
  
      axios.post(Rest.addarea, newData, {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token,
        },
      })
        .then((response) => {

          console.log("outside",response?.data)
          if (response?.data?.code === "S001") {
            console.log("inside",response?.data)
            fetchAreaData()
            // Fetch the updated data after adding a new area

  
            // Reset the form
            const emptyInput = { name: "", area: "", selectedCity: [], selectedState: [] };
            setFormInputData(emptyInput);
  
            // Optionally, navigate to another page or perform other actions
          } else {
            // alert(response?.data?.msg || response?.data?.data);
          }
        })
        .catch((error) => {
          console.error('Error posting data to the backend: ', error);
        });
    }
  };
  

  const isDuplicateCombination = (newData) => {
    return tableData.some((data) => {
      return (
        data.area === newData.area &&
        (data.state ? data.state.join(',') : '') === (newData.selectedState ? newData.selectedState.join(',') : '') &&
        (data.city ? data.city.join(',') : '') === (newData.selectedCity ? newData.selectedCity.join(',') : '')
      );
    });
  };


useEffect(()=>{
  fetchAreaData()

},[])

  const fetchAreaData = () => {
    axios.get(Rest.viewarea, {
      params: {
        provider: provider
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then(response => {
        if (response.data.code === "S001") {
          setTableData(response?.data?.data);
        } else {
          // alert(response?.msg || response?.data?.data);
        }
      })
      .catch(err => {
        alert("Error: " + err.message);
      });
  };
  // useEffect(() => {
  //   axios.get(Rest.viewarea, {
  //     params: {
  //       provider: provider
  //     },
  //     headers: {
  //       'Content-Type': 'application/json',
  //       "X-fiftyaccess-Token": token
  //     },
  //   }
  //   )
  //     .then(response => {
  //       if (response.data.code === "S001") {
  //         setTableData(response?.data?.data)
  //       } else {
  //         alert(response?.msg || response?.data?.data);
  //       }

  //     })
  //     .catch(err => {
  //       alert("Error: " + err.message);
  //     });
  // }, []);

  useEffect(() => {
    if (isLocMount.current) {
      axios.get(Rest.locations, {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
        .then((response) => {
          setLocations(response?.data?.locations);
        })
        .catch((error) => {
          console.error('Error fetching data: ', error);
        });
      isLocMount.current = false;
    }
  }, []);

  const handleChange = (event) => {
    const newInput = { ...formInputData, [event.target.name]: event.target.value };
    setFormInputData(newInput);
  };


  const handleDropdownSelect = (value, item) => {

    setSelectedState(value);
    setFormInputData({ ...formInputData, selectedState: value, selectedCity: [] });
    setSelectedCities([]);
    console.log("@@@", value)
  };
  const handleDropdownClean = () => {
    setSelectedState([]);
    setFormInputData({ ...formInputData, selectedState: [], selectedCities: [] });
    setSelectedCities([]);
  };

  const handleDropdown = (value, item) => {
    setSelectedCities(value);
    setFormInputData({ ...formInputData, selectedCity: value });
  };
  const handleDropdownCityClean = () => {
    setSelectedCities([]); // Clear selectedCities
    setFormInputData({ ...formInputData, selectedCity: [] });
  };

  const handleEdit = (areaid) => {
    setIsEditMode(true);


    setSelectedState(areaid?.state)
    const updatedFormInputData = {
      name: areaid?.name || '',
      area: areaid?.area || '',
      areaid: areaid?.areaid || '',
      selectedState: areaid?.state || [],
      selectedCity: areaid?.city || [],
      
    };

    setFormInputData(updatedFormInputData);


    axios.get(Rest.viewarea, {
      params: {
        provider: provider
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    }
    )
      .then(response => {
        if (response.data.code === "S001") {
          const responseData = response?.data?.data || [];
          if (responseData[areaid]) {
            const updatedFormInputData = {
              name: responseData[areaid]?.name || '',
              area: responseData[areaid]?.area || '',
              areaid: responseData[areaid]?.areaid || '',
              selectedState: responseData[areaid]?.state || [],
              selectedCity: responseData[areaid]?.city || [],
            };
            setFormInputData(updatedFormInputData)
            handleRemove(areaid);
          } 
          // else {
          //   alert(response?.msg || response?.data?.data);
          // }
        } 
        // else {
        //   alert(response?.msg || response?.data?.data);
        // }   

      })
      .catch(err => {
        alert("Error: " + err.message);
      });
  };

  const handleRemove = (index) => {
    const newData = [...tableData];
    newData.splice(index, 1);
    setTableData(newData);
  };
  const handleDelete = (areaData) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    
    setIsEditMode(false);
    const updatedData = {
      provider: areaData?.provider,
      areaid: areaData?.areaid,
      area: areaData?.area,
      name: areaData?.name,
      state: areaData?.state,
      city: areaData?.city,
      status: 'inActive',
    };
    axios
      .put(Rest.updatestatus, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
     
           
      .then((response) => {

        console.log("!!!!@@###")
        if (response?.data?.code === "S001") {

          console.log("!!!inside")
          fetchAreaData();
      
          setTimeout(() => {
            navigate("/area/");
           
          }, 1000);
        }
        //  else {
          // alert(response?.data?.msg || response?.data?.data);
        // }
      })
      .catch((err) => {
        alert("Error: " + err.message);
      });
  };
  const handleUpdate = (areaid) => {
    const updatedData = {
      provider: provider,
      areaid: formInputData?.areaid,
      area: formInputData?.area,
      name: formInputData?.name,
      state: formInputData?.selectedState,
      city: formInputData?.selectedCity,
    };
  
    if (!isDuplicateCombination(updatedData)) {
      // Continue with your existing logic
      // ...
    } else {
      alert("Combination already exists!");
    }
  
    axios
      .put(Rest.updatearea, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          "X-fiftyaccess-Token": token
        },
      })
      .then((response) => {
        if (response?.data?.code === "S001") {
          fetchAreaData();
          // Reset the form
          const emptyInput = { name: "", area: "", selectedCity: [], selectedState: [] };
          setFormInputData(emptyInput);
          setIsEditMode(false);
  
          // Optionally, navigate to another page or perform other actions
        } else {
          alert(response?.data?.msg || response?.data?.data)
        }
      })
      .catch((err) => {
        alert("Error: " + err.message);
      });
  };
  const handleCancel= (rule, event) => {
    event.preventDefault();
    const ruleid = rule.ruleid;
    navigate(`/updateslab/${ruleid}`, { state: { ruleid: ruleid } });
  };
  
  return (
    <React.Fragment>

      <div className="container">
        <div className="row">
          <div className="col-sm-8">
            <div className="form-row row">

              <div className="col" style={{ marginTop: '8rem', display: "flex", marginLeft: '10px', gap: '2rem' }}>

                <input
                  type="Area"
                  onChange={handleChange}
                  value={formInputData.area}
                  name="area"
                  style={{ height: '34px' }}
                  placeholder="area"
                />
                <input
                  type="text"
                  onChange={handleChange}
                  value={formInputData.name}
                  name="name"
                  style={{ height: '34px' }}
                  placeholder="Unique Name"
                />


                <CheckPicker
                  data={states || []}
                  style={{ width: 224 }}
                  value={formInputData.selectedState}
                  onSelect={(value, item) => handleDropdownSelect(value, item)}
                  onClean={() => handleDropdownClean()}
                />

                <CheckPicker
                  data={cities || []}
                  style={{ width: 224 }}
                  value={formInputData.selectedCity}
                  onSelect={(value, item) => handleDropdown(value, item)}
                  onClean={() => handleDropdownCityClean()}
                />
                  <Controls.Button
  text={isEditMode ? "Update" : "Add"}
  onClick={isEditMode ? handleUpdate : handleSubmit}
  disabled={!Object.values(formInputData).every((res) => res !== "")}
/>
    {/* <Controls.Button
                text="Cancel"
                onClick={() => { navigate('/slab/', { state: { commissionData: commissiondetails } }) }}
              /> */}
              {/* <Controls.Button
       text="Cancel"
    onClick={() => {
    const destination = commissiondetails.ruleid
      ? `/updateslab/${commissiondetails.ruleid}`
      : '/slab/';
    navigate(destination, { state: { commissionData: commissiondetails } });
  }}
/> */}
 <Controls.Button
  text="Cancel"
  onClick={() => {
    let destination;
    let stateObject;

    if (commissiondetails.ruleid) {
      destination = `/updateslab/${commissiondetails.ruleid}`;
      stateObject = {
        ruleid: commissiondetails.ruleid
        
      };
    } else {
      destination = '/slab/';
      stateObject = {
        commissionData: commissiondetails
      };
    }

    navigate(destination, {
      state: stateObject
    });
  }}
/> 


            </div>             
              <div>
                <h4>{provider}</h4>
              </div>
            </div>
            <div style={{ marginTop: '2rem' }}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Area ID</th>
                    <th>Area</th>
                    <th>Unique Name</th>
                    <th>State</th>
                    <th>City</th>
                    <th>Action</th>

                  </tr>
                </thead>
                <tbody>
                  {tableData.map((data, areaid) => (
                    <tr key={areaid}>
                      <td>{data?.areaid}</td>
                      <td>{data?.area}</td>
                      <td>{data?.name}</td>
                      <td>{data?.state ? data?.state.join(', ') : ''}</td>
                      <td>{data?.city ? data?.city.join(', ') : ''}</td>
                      <td>
                        {/* <button  text="Edit"onClick={() => handleEdit(index)}>Edit</button>
      <button onClick={() => handleRemove(index)}>X</button>  */}
                        <div style={{ display: "flex", gap: '2rem' }}>
                          <button onClick={() => handleEdit(data)}>
                            <EditIcon />
                          </button>
                          <button onClick={() => handleDelete(data)}>
                            <DeleteIcon />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ marginLeft: '64rem' }}>
{/* 
               <Controls.Button
                text="Save"
                onClick={() => { navigate('/slab/', { state: { commissionData: commissiondetails } }) }}

              />  */}
             
            </div>

          </div>
          <div className="col-sm-4"></div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Main;
