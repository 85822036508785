import React from 'react'
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { width } from '@mui/system';

const styles = {
    root: {
      height: 40, // Set the desired height here
      borderRadius: 5, // Set the desired border radius here
      '& .MuiOutlinedInput-root': {
        borderRadius: 5, // Apply the border radius to the input element inside the TextField
        height:40,
      },
    },

  };

const Input = ({ classes,labelFontSize,...props }) =>  {

    const { name, label, value,error=null,readOnly, onChange,disabled ,defaultValue} = props;
    return (
        <TextField
            className={classes.root}
            variant="outlined"
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            InputProps={{
              readOnly: readOnly, // Set readOnly based on the prop
              defaultValue: defaultValue,
            }}
            style={{backgroundColor:'rgb(234, 237, 240,1)'}}
            {...(error && {error:true,helperText:error})}
            InputLabelProps={{
              style: { 
                fontSize: labelFontSize || '12px'}, // Set the desired label font size here
            }}
        />
    )
}

export default withStyles(styles)(Input);
