import React from 'react'
import { Form } from '../components/useForm';
import { Grid } from '@mui/material';
import Controls from '../components/controls/Controls';

const addresstype = [
    { value: 'Home', label: 'Home' },
    { value: 'Office', label: 'Office' },
    { value: 'Other', label: 'Other' }
  ];

const AvAddOrganization = ({organization,handleOrganizationChange}) => {

 return (
    <div>
    <Form>
      <h4>Organization</h4>
      <Grid container >
      <Grid item xs={4}>
      <Controls.Input
         label='Organization Name'
         value={organization.organizationname}
         onChange={(e) => handleOrganizationChange('organizationname', e.target.value)}
        />
        </Grid>
        <Grid item xs={4}>
        <Controls.Input
           label='Phonenumber'
           value={organization.organizationphonenumber}
           onChange={(e) => handleOrganizationChange('organizationphonenumber', e.target.value)}
        />
        </Grid>
        <Grid item xs={4}>
        <Controls.Input
           label='Email ID'
           value={organization.organizationemailid}
           onChange={(e) => handleOrganizationChange('organizationemailid', e.target.value)}
        />
        </Grid>
          <Grid item xs={4}>
        <Controls.Dropdown 
           options={addresstype} 
           label='Address Type'
           value={organization.addresstype}
           onChange={(e) => handleOrganizationChange('addresstype', e.target.value)}
        />
        </Grid>
      <Grid item xs={4}>
        <Controls.Input
        label='Address Line1'
        value={organization.addressline1}
        onChange={(e) => handleOrganizationChange('addressline1', e.target.value)}
        />
        </Grid>
        <Grid item xs={4}>
        <Controls.Input
          label='Address Line2'
          value={organization.addressline2}
          onChange={(e) => handleOrganizationChange('addressline2', e.target.value)}
        />
        </Grid>
        <Grid item xs={4}>
        <Controls.Input
          label='City'
          value={organization.city}
          onChange={(e) => handleOrganizationChange('city', e.target.value)}
        />
        </Grid>
        <Grid item xs={4}>
        <Controls.Input
          label='District'
          value={organization.district}
          onChange={(e) => handleOrganizationChange('district', e.target.value)}
        />
        </Grid>
        <Grid item xs={4}>
        <Controls.Input
          label='State'
          value={organization.state}
          onChange={(e) => handleOrganizationChange('state', e.target.value)}
        />
        </Grid>
        <Grid item xs={4}>
        <Controls.Input
          label='Pincode'
          value={organization.pincode}
          onChange={(e) => handleOrganizationChange('pincode', e.target.value)}
        />
        </Grid>
        <Grid item xs={4}>
        <Controls.Input
          label='Country'
          value={organization.country}
          onChange={(e) => handleOrganizationChange('country', e.target.value)}
        />
        </Grid>
      </Grid>
    </Form>
  </div>
  )
}

export default AvAddOrganization