import React from 'react'
import { FormControl, FormLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    radio: {
      '&$checked': {
        color: '#DADADA', // Set the background color to #DADADA for the checked state
      },
      padding:'0px',
      marginLeft:'10px',
    },
    checked: {}, // Empty rule to be used for the checked state
  });

export default function RadioGroup(props) {

    const { name, label, value, onChange, items } = props;
    const classes = useStyles();

    return (
        <FormControl>
            <FormLabel style={{fontSize:'12px'}}>{label}</FormLabel>
            <MuiRadioGroup row
                name={name}
                value={value}
                onChange={onChange}>
                {
                    items.map(
                        item => (
                            <FormControlLabel key={item.id} value={item.id} control={<Radio size="small" classes={{ root: classes.radio, checked: classes.checked }}/>} label={item.title} />
                        )
                    )
                }
            </MuiRadioGroup>
        </FormControl>
    )
}
