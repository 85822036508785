// ViewCustomer.js
import React, { useEffect, useRef, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import CustomerBeneficiaryDetails from './CustomerBeneficiaryDetails';
import PolicyDetails from './PolicyDetails';
import CustomerOpportunityDetails from './CustomerOpportunityDetails';
import axios from 'axios';
import * as Rest from  './../services/restapi';

const ViewProspect = () => {
  const [customerData, setCustomertData] = useState({});
  const location =  useLocation();
  const customer = location.state;
  const customerid = customer?.customer?.customerid;
  const token = localStorage.getItem("X-fiftyaccess-Token");
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
    axios.get(Rest.viewcustomer,{
      params:{
        customerid:customerid
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then(response => {
        setCustomertData(response?.data?.data);
      })
      .catch(err => {
      });
      isInitialMount.current = false;
    }
  }, [customerid]);



    return (
      <div style={{padding:'10px'}}>
      <Card style={{width:'78.8rem',height:'5rem',position:'fixed',marginTop:'2.8rem',}}>
      <CardContent style={{display:'flex',flexDirection:'row'}}>
        <Typography variant="h6" component="div">
          {customerData?.firstname} {customerData?.lastname}
        </Typography>
        <Typography style={{marginLeft:'20rem'}} variant="h6" color="text.secondary">
          {customerData?.emailid}
        </Typography>
        <Typography style={{marginLeft:'25rem'}} variant="h6" color="text.secondary">
          {customerData?.phonenumber}
        </Typography>
      </CardContent>
    </Card>
    <div>
      <CustomerBeneficiaryDetails />
    </div>
    <div>
    <CustomerOpportunityDetails />
    </div>
    <div>
      <PolicyDetails customerData={customerData}/>
    </div>
    </div>

  );
};

export default ViewProspect;
