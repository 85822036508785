import React, { useEffect, useRef, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Box, Snackbar } from '@mui/material';
import Controls from '../components/controls/Controls';
import { Grid } from '@material-ui/core';
import AppBar from '@mui/material/AppBar';
import { Form} from '../components/useForm';
import * as Rest from  './../services/restapi';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';

const AvAddBeneficiary = () => {
  const[prospectData,setProspectData] = useState([])
const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
const navigate = useNavigate();
const location = useLocation();
const customer = location?.state?.customer;
const prospectid= customer?.prospectid;
const token = localStorage.getItem("X-fiftyaccess-Token");
const beneficiaryValues = prospectData?.[0]?.beneficiarydetails?.[0];
const opportunityid = customer?.opportunityid;
console.log("beneficiaryValues--->",beneficiaryValues)

  const [tabs, setTabs] = useState([
    {
      beneficiaryrelation: 'Self',
      firstname:'',
      lastname:'',
      emailid:'',
      phonenumber:'',
       alternateemails:[""],
       gender:"",
       dateofbirth:null,
       alternatephonenumbers:[""],
       communicationpreference:"Call",
       pannumber:"",
       aadharnumber:"",
       addresstype:"Home",
       addressline1:"",
       addressline2:'',
       city:'',
       district:'',
       state:'',
       pincode:'',
       country:'India',
       fields: [
        {id:'firstname', label: 'First Name', value: '' },
        {id:'lastname', label: 'Last Name', value: '' },
        {id:'emailid', label: 'Email ID', value: '' },
        {id:'phonenumber', label: 'Phone Number', value: '' },
        {id:'alternateemails', label: 'Alternate Email ID', value: '' },
        {
          id: 'gender',
          label: 'Gender',
          type: 'dropdown', // Add type 'dropdown' to indicate it's a dropdown field
          value: '', // Default value
          options: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
            { value: 'Others', label: 'Others' },
            { value: 'Not Specified', label: 'Not Specified' },
          ],
        },
        {
          id: 'dateofbirth',
          label: 'Date of Birth',
          type: 'datepicker', // Indicate it's a datepicker field
          value: null, // Default value for datepicker
        },
        {id:'alternatephonenumbers', label: 'Alternate Phonenumber', value: '' },
        {
         id:'communicationpreference',
         label: 'Communication Preference', 
         value: '' ,
         type: 'dropdown',
         options : [
          { value: 'Call', label: 'Call' },
          { value: 'SMS', label: 'SMS' },
          { value: 'Email', label: 'Email' },
          { value: 'Whatsapp', label: 'Whatsapp' },
        ]
        },
        {id:'pannumber', label: 'PAN Number', value: '' },
        {id:'aadharnumber', label: 'Aadhar Number', value: '' },
        {
          id:'addresstype', 
          label: 'Address Type', 
          value: '',
          type: 'dropdown',
          options:[
            { value: 'Home', label: 'Home' },
            { value: 'Office', label: 'Office' },
            { value: 'Other', label: 'Other' },
          ]
        },
        {id:'addressline1', label: 'Address Line1', value: '' },
        {id:'addressline2', label: 'Address Line2', value: '' },
        {id:'city', label: 'City', value: '' },
        {id:'district', label: 'District', value: '' },
        {id:'state', label: 'State', value: '' },
        {id:'pincode', label: 'Pincode', value: '' },
        {id:'country', label: 'Country', value: '',type: 'country' },         
      ],
    },
  ]);
  const [value, setValue] = useState(0);
  const [validationErrors, setValidationErrors] = useState({});
  const [selectedDropdownValues, setSelectedDropdownValues] = useState({});
  const isViewMount = useRef(true);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  
  const addTab = () => {
    const newTabs = [
      ...tabs,
      {
        beneficiaryrelation:"New Tab",
        firstname:'',
        lastname:'',
        emailid:'',
        phonenumber:'',
       alternateemails:[""],
       gender:"",
       dateofbirth:null,
       alternatephonenumbers:[""],
       communicationpreference:"Call",
       pannumber:"",
       aadharnumber:"",
       addresstype:"Home",
       addressline1:"",
       addressline2:'',
       city:'',
       district:'',
       state:'',
       pincode:'',
       country:'India',
       fields: [
        {id:'firstname', label: 'First Name', value: '' },
        {id:'lastname', label: 'Last Name', value: '' },
        {id:'emailid', label: 'Email ID', value: '' },
        {id:'phonenumber', label: 'Phone Number', value: '' },
        {id:'alternateemails', label: 'Alternate Email ID', value: '' },
        {
          id: 'gender',
          label: 'Gender',
          type: 'dropdown', // Add type 'dropdown' to indicate it's a dropdown field
          value: '', // Default value
          options: [
            { value: 'Male', label: 'Male' },
            { value: 'Female', label: 'Female' },
            { value: 'Others', label: 'Others' },
            { value: 'Not Specified', label: 'Not Specified' },
          ],
        },
        {
          id: 'dateofbirth',
          label: 'Date of Birth',
          type: 'datepicker', // Indicate it's a datepicker field
          value: null, // Default value for datepicker
        },
        {id:'alternatephonenumbers', label: 'Alternate Phonenumber', value: '' },
        {
         id:'communicationpreference',
         label: 'Communication Preference', 
         value: '' ,
         type: 'dropdown',
         options : [
          { value: 'Call', label: 'Call' },
          { value: 'SMS', label: 'SMS' },
          { value: 'Email', label: 'Email' },
          { value: 'Whatsapp', label: 'Whatsapp' },
        ]
        },
        {id:'pannumber', label: 'PAN Number', value: '' },
        {id:'aadharnumber', label: 'Aadhar Number', value: '' },
        {
          id:'addresstype', 
          label: 'Address Type', 
          value: '',
          type: 'dropdown',
          options:[
            { value: 'Home', label: 'Home' },
            { value: 'Office', label: 'Office' },
            { value: 'Other', label: 'Other' },
          ]
        },
        {id:'addressline1', label: 'Address Line1', value: '' },
        {id:'addressline2', label: 'Address Line2', value: '' },
        {id:'city', label: 'City', value: '' },
        {id:'district', label: 'District', value: '' },
        {id:'state', label: 'State', value: '' },
        {id:'pincode', label: 'Pincode', value: '' },
        {id:'country', label: 'Country', value: '' ,type: 'country'},         
      ],
      },
    ];
    setTabs(newTabs);
    setValue(newTabs.length - 1); // Switch to the newly added tab
  };

  const handleSnackbarClose = () => {
    setIsSuccessSnackbarOpen(false);
  };

  const removeTab = () => {
    if (tabs?.length <= 1) return; // Ensure at least one tab remains
    const newTabs = [...tabs];
    newTabs.splice(value, 1);
    setTabs(newTabs);
    setValue(Math.min(value, newTabs.length - 1));
  };

  const updateTabLabel = (index, label) => {
    const newTabs = [...tabs];
    newTabs[index].beneficiaryrelation = label;
    setTabs(newTabs);
  };

  React.useEffect(() => {
    if(isViewMount.current)
    axios.get(Rest.viewOpportunity,{
      params:{
        opportunityid:opportunityid
      },
      headers: {
        'Content-Type': 'application/json',
        "X-fiftyaccess-Token": token
      },
    })
      .then(response => {
        setProspectData(response?.data?.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
      isViewMount.current=false;
  }, [opportunityid]); 

  useEffect((id) => {
     if (beneficiaryValues) {
        const newTabs = [...tabs];
        const addressValues = beneficiaryValues?.addressdetails[0]
        console.log("address",addressValues)
        for (const field in newTabs[0]) {
          if (field === 'dateofbirth' && beneficiaryValues[field]) {
            newTabs[0][field] = moment(beneficiaryValues[field] * 1000).format('DD-MM-YYYY');
          } else if (addressValues && addressValues[field]) {
            newTabs[0][field] = addressValues[field];
          } else {
            newTabs[0][field] = beneficiaryValues[field] || '';
          }
        }
    
        setTabs(newTabs);
      }
  }, [beneficiaryValues]);
  

  const updateField = (tabIndex, fieldIndex, label,id, value) => {
    const newTabs = [...tabs];
    newTabs[tabIndex][id] = value;
    const errors = { ...validationErrors };
    if (id === 'firstname' || id === 'lastname' || id === 'city' || id === 'state' || id === 'country' || id === 'district') {
      if (!/^[A-Za-z]+$/.test(value)) {
        errors[id] = `Invalid ${id} name`;
      } else {
        delete errors[id];
      }
    } else if (id === 'phonenumber') {
      if (!/^\d{10}$/.test(value)) {
        errors[id] = 'Invalid phone number';
      } else {
        delete errors[id];
      }
    } else if (id === 'aadharnumber') {
      if (!/^\d{12}$/.test(value)) {
        errors[id] = 'Invalid Aadhar number';
      } else {
        delete errors[id];
      }
    } else if (id === 'pincode') {
      if (!/^\d{6}$/.test(value)) {
        errors[id] = 'Invalid pincode';
      } else {
        delete errors[id];
      }
    } else if (id === 'pannumber') {
      if (!/^[A-Z\d]{10}$/.test(value)) {
        errors[id] = 'PAN number is not Valid';
      } else {
        delete errors[id];
      }
    }
    // } else if (id === 'pannumber') {
    //   if (!/^[A-Z\d]{10}$/.test(value)) {
    //     errors[id] = 'Invalid PAN number';
    //   } else {
    //     delete errors[id];
    //   }
    // }
   

    setValidationErrors(errors);
    setTabs(newTabs);
 
   
    const validFields = tabs[0].fields.map(field => field.id);
    if (validFields.includes(id)) {
      tabs[tabIndex][id] = value;
      tabs[tabIndex]['dateofbirth'] = moment(value).format("DD-MM-YYYY") ;
    }
    newTabs[tabIndex].fields[fieldIndex] = { id,label, value };
    setTabs(newTabs);
  };

  const renderFields = (tabIndex) => {
    const tab = tabs[tabIndex];
    const renderedFields = [];
    let currentRow = [];
    let currentRowColumns = 0;
  
    const maxColumnsInRow = 3; // Define the maximum number of columns in a row
  
    for (let fieldIndex = 0; fieldIndex < tab.fields.length; fieldIndex++) {
      const field = tab.fields[fieldIndex];
  
      if (field.id === 'dateofbirth') {
        // Handle 'dateofbirth' field as a datepicker
        if (currentRowColumns + 1 <= maxColumnsInRow) {
          currentRow.push(
            <Grid item xs={4} key={fieldIndex}>
              <Controls.DatePicker
                disableFuture
                label={field.label}
                value={field.value}
                onChange={(date) =>
                  updateField(tabIndex, fieldIndex, field.label, field.id, date)
                }
                format="DD-MM-YYYY"
              />
            </Grid>
          );
          currentRowColumns += 1;
        }
      } else {
        const renderedField = (
          <div key={fieldIndex} style={{ marginBottom: '10px', flex: 1 }}>
            {field.type === 'dropdown' ? (
              <Grid item xs={12}>
                <Controls.Dropdown
                  label={field.label}
                  value={selectedDropdownValues[`${field.id}-${tabIndex}`] || ''}
                  options={field.options}
                  onChange={(e) =>
                    updateDropdownValue(tabIndex, field.id, e.target.value)
                  }
                />
              </Grid>
            ) : field.type === 'country' ? (
              <Grid item xs={4}>
                <Controls.Input
                  label="Country"
                  value={field.value}
                  onChange={(e) =>
                    updateField(tabIndex, fieldIndex, field.label, field.id, e.target.value)
                  }
                  type={field.type || 'text'}
                  error={validationErrors[field.id]}
                  helperText={validationErrors[field.id]}
                />
              </Grid>
            ) : (
              <Controls.Input
                label={field.label}
                value={field.value}
                onChange={(e) =>
                  updateField(tabIndex, fieldIndex, field.label, field.id, e.target.value)
                }
                type={field.type || 'text'}
                error={validationErrors[field.id]}
                helperText={validationErrors[field.id]}
              />
            )}
          </div>
        );
  
        currentRow.push(renderedField);
        currentRowColumns += 1;
  
        if (currentRowColumns >= maxColumnsInRow || fieldIndex === tab.fields.length - 1) {
          renderedFields.push(
            <div
              key={`row-${fieldIndex}`}
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: '10px',
              }}
            >
              {currentRow}
            </div>
          );
          currentRow = [];
          currentRowColumns = 0;
        }
      }
    }
  
    return <div>{renderedFields}</div>;
  };
  
  const updateDropdownValue = (tabIndex, fieldId, value) => {
    setTabs((prevTabs) => {
      const updatedTabs = [...prevTabs];
      // Assuming tabs structure is an array of objects and fieldId represents an ID in tabs
      updatedTabs[tabIndex][fieldId] = value;
      return updatedTabs;
    });
    setSelectedDropdownValues((prevValues) => ({
      ...prevValues,
      [`${fieldId}-${tabIndex}`]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const beneficiarydetails =[...tabs]
      const beneficiaryValues = {
        beneficiarydetails,
        prospectid,
      };
      const response = await axios.post(Rest.addbeneficiary, beneficiaryValues);
      console.log('API Response:', response.data);
      setIsSuccessSnackbarOpen(true); // Open success snackbar
      // Redirect after a short delay (optional)
      setTimeout(() => {
        navigate(-1);
      }, 2000); 
      // Reset form or perform other actions on successful submission
    } catch (error) {
      console.error('API Error:', error);
      // Handle error appropriately
    }
  };

  const initialFields = tabs[0];

  const [fields, setFields] = useState(initialFields);

  const handleChange = (key) => (event) => {
    const { value } = event.target;
    setFields((prevFields) => ({
      ...prevFields,
      [key]: value,
    }));
  };
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Others", label: "Others" },
    { value: "Not Specified", label: "Not Specified" },
  ];

  const communicationOptions = [
    { value: "Call", label: "Call" },
    { value: "SMS", label: "SMS" },
    { value: "Email", label: "Email" },
    { value: "Whatsapp", label: "Whatsapp" },
  ];

  const addressTypeOptions = [
    { value: "Home", label: "Home" },
    { value: "Office", label: "Office" },
    { value: "Other", label: "Other" },
  ];

const controlsByRows = [];
const filteredFields = Object.entries(tabs[0]).filter(([key]) => key !== 'beneficiaryrelation' && key !== 'fields');
const addressFields= tabs[0];
console.log("addressFields",addressFields)
for (let i = 0; i < filteredFields.length; i += 3) {
  const rowFields = filteredFields.slice(i, i + 3);

  const controls = rowFields.map(([key, value]) => {
    let inputComponent;
    if (key === 'dateofbirth') {
      inputComponent = (
        <Controls.DatePicker
          label={key}
          disableFuture
          value={fields[key]}
          onChange={(e) => handleChange(key, e.toDate())}
          // Additional props for the date picker component
        />
      );
    } else if (key === 'gender') {
      inputComponent = (
        <Controls.Dropdown
          label={key}
          value={fields[key]}
          onChange={handleChange(key)}
          options={genderOptions}
        />
      );
    } else if (key === 'communicationpreference') {
      inputComponent = (
        <Controls.Dropdown
          label={key}
          value={fields[key]}
          onChange={handleChange(key)}
          options={communicationOptions}
        />
      );
    } else if (key === 'addresstype') {
      inputComponent = (
        <Controls.Dropdown
          label={key}
          value={fields[key]}
          onChange={handleChange(key)}
          options={addressTypeOptions}
        />
      );
    } else {
      inputComponent = (
        <Controls.Input
          label={key}
          value={fields[key]}
          onChange={handleChange(key)}
        />
      );
    }

    return (
      <Grid item xs={4} key={key}>
        {inputComponent}
      </Grid>
    );
  });

  controlsByRows.push(
    <Grid container spacing={2} key={`row_${i / 3}`}>
      {controls}
    </Grid>
  );
}

  return (
    <div style={{marginTop:'5rem',paddingLeft:'10px'}}>
        <h6>Beneficiary Information</h6>
        <Form onSubmit={handleSubmit}>
      <AppBar
          position="static"
          style={{
            height:'3.3rem',
            marginTop: '2rem',
            backgroundColor: '#F5F5F5',
            width: '1140px',
            borderRadius: '5px',
            marginLeft: '1.5rem',
            padding: '5px',
          }}
        >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Tabs value={value} onChange={handleTabChange}>
          {tabs.map((tab, index) => (
            <Tab key={index} label={tab.beneficiaryrelation} />
          ))}
        </Tabs>
        <IconButton onClick={addTab}>
          <AddIcon />
        </IconButton>
        <IconButton onClick={removeTab}>
          <RemoveIcon />
        </IconButton>
      </div>
      </AppBar>
      <Box sx={{ bgcolor: '#f5f5f5', marginTop: '1px' }}>
      <div style={{ padding: '20px' }}>
        <div style={{ marginBottom: '10px' }}>
          <Grid item xs={4} >
          <Controls.Input
            label="Relation"
            value={tabs[value].beneficiaryrelation}
            onChange={(e) => updateTabLabel(value, e.target.value)}
          />
          </Grid>
          <Grid>
          {renderFields(value)}
          {controlsByRows}
          </Grid>
        </div>
       
      </div>
      </Box>
      <Snackbar
        sx={{marginLeft:'30rem'}}
        open={isSuccessSnackbarOpen}
        autoHideDuration={3000} // Snackbar will close automatically after 3 seconds
        onClose={handleSnackbarClose}
        message="Data successfully added"
      />
      <div style={{marginLeft:"63.5rem"}}>
      <Controls.Button text="save" type="submit" />
      <Controls.Button text="Cancel" onClick={()=>{navigate(-1)}}  />
      </div>
      </Form>
    </div>
  );
};

export default AvAddBeneficiary;

